import React from 'react'
import Cup from '../images/cup.gif'

import { Link } from "react-router-dom";

export default function Footer(props) {





	return(
		<div>
			<nav>
				<ul>
					<li id="sports">
						<Link to='/exchange/member/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img className="icon-sports" src={Cup} alt="gif" />Sports
						</Link></li>
					<li id="inPlay">
						<Link to='/exchange/member/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img className="icon-inplay" src={Cup} alt="gif" />In-Play
						</Link></li>
					<li id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<Link to='/' className="ui-link">
							<img className="icon-home" src={Cup} alt="gif" />Home</Link></li>
					<li id="multiMarket">
						<a href='/exchange/member/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-pin" src={Cup} alt="gif" />Multi Markets
						</a></li>
					<li id="account">
						{props.isLoggedIn && <Link to='/exchange/member/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>< img className="icon-account" src={Cup} alt="gif" />Account</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">< img className="icon-account" src={Cup} alt="gif" />Account</a>}
					</li>
				</ul>
			</nav>
		</div>
	)
}
