import React, { useState, useEffect } from 'react'
import Axios from 'axios';

export default function OpenBets(props) {

	const [matchOption, setmatchOption] = useState(1)
	const [view, setview] = useState(1)
	const [bets, setbets] = useState([])
	const [betVal1, setbetVal1] = useState([])
	const [betVal2, setbetVal2] = useState([])
	const [betVal3, setbetVal3] = useState([])
	const [betVal3F, setbetVal3F] = useState([])
	const [menuName, setmenuName] = useState('')



	const changeBetMenu = (obj) => {

		//e.preventDefault();
		if (!props.userid) return;
		if (obj.marketName === 'Match Odds') {
			//setmatchOption(2);

			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Tied Match') {
			//setmatchOption(2);

			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
				//	console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Completed Match') {
			//setmatchOption(2);

			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					// console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Super Over') {
			//setmatchOption(2);

			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);

					}

				}

				).catch(e => {
					console.log(e);
				});


		}
		else if (obj.marketName === 'Bookmaker' || obj.marketName === 'BOOKMAKER') {
			setmatchOption(3);
			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {

					if (result.data) {

						let obj1 = [];
						let obj2 = [];

						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'LAGAI') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'KHAI') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3(obj3);


					}

				}

				).catch(e => {
					console.log(e);
				});

		}
		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);

			Axios.post('https://millionbet247.com/api/client/clientgetbets', {
				id: props.userid,
				token: props.utoken,
				eventId: obj.eventId,
				marketId: obj.marketId,
				marketName: obj.marketName,

			})
				.then(result => {
					//console.log('bets', result.data);
					if (result.data) {

						var obj1 = [];
						var obj2 = [];
						for (let i = 0; i < result.data.Data1.length; i++) {
							if (result.data.Data1[i].type === 'YES') {
								obj1.push(result.data.Data1[i])
							}
							else if (result.data.Data1[i].type === 'NO') {
								obj2.push(result.data.Data1[i])
							}
						}
						var obj3 = result.data.Data2;
						setbetVal1(obj1);
						setbetVal2(obj2);
						setbetVal3F(obj3);

					}


				}

				).catch(e => {
					console.log(e);
				});

		}

	}




	useEffect(() => {

		if (!props.userid) return;

		Axios.post('https://millionbet247.com/api/client/clientgetbetsinfo', {

			id: props.userid,
			token: props.utoken,

		})
			.then(result => {
				//console.log('bets', result.data);
				if (result.data.length > 0) {
					var obj = result.data;

					setbets(obj);
					if (obj[0].marketName === 'Match Odds') {
						setmatchOption(2);
					}
					else if (obj[0].marketName === 'Tied Match') {
						setmatchOption(4);
					}
					else if (obj[0].marketName === 'Completed Match') {
						setmatchOption(5);
					}
					else if (obj[0].marketName === 'Super Over') {
						setmatchOption(6);
					}
					else if (obj[0].marketName === 'Bookmaker' || obj[0].marketName === 'BOOKMAKER') {
						setmatchOption(3);
					}
					else if (obj[0].marketName && obj[0].marketName != ' ') {
						setmatchOption(1);
					}

				}

			}

			).catch(e => {
				console.log(e);
			});

		/*  if(anyBets){
	  
		  }
		   */
	}, [props.userid]);


	const openBetHistry = (obj) => {

		changeBetMenu(obj);
		setmenuName(obj.eventName + " - " + obj.marketName);
		if (obj.marketName === 'Match Odds') {
			setmatchOption(2);
		}
		else if (obj.marketName === 'Tied Match') {
			setmatchOption(4);
		}
		else if (obj.marketName === 'Completed Match') {
			setmatchOption(5);
		}
		else if (obj.marketName === 'Super Over') {
			setmatchOption(6);
		}
		else if (obj.marketName === 'Bookmaker' || obj.marketName === 'BOOKMAKER') {
			setmatchOption(3);
		}
		else if (obj.marketName && obj.marketName != ' ') {
			setmatchOption(1);
		}
	}


	return (
		<React.Fragment>

			{view === 1 &&
				<ul id="eventMenuList" class="menu-list">

					{bets.map(function (item, index) {
						return (
							<li key={index} onClick={() => { openBetHistry(item); setview(2); }} id="eventMenuTemplate" class="inplay-off"><a style={{ display: 'block' }}>{item.eventName + " - " + item.marketName}</a></li>
						)
					})}

				</ul>}



			{view === 2 &&
				<React.Fragment>
					<div id="pathWrap" class="path-wrap" style={{}}>
						<a id="goBack" class="go_back" onClick={() => { setview(1); }}></a>

						<ul>
							<li id="eventName">{menuName}</li>
						</ul>
					</div>
				</React.Fragment>}


			{/* {view === 2 &&
				<React.Fragment>

					<h3 id="txnHeader" >
						Unmatched
						<a id="cancelAll" class="trashcan">Cancel All</a>
					</h3>


					{matchOption === 2 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<a id="editBtn" class="a-edit" href="#">Edit</a>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}





					{matchOption === 3 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>


								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {

									if (betVal3.length == 0) return null;

									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3.length == 0) return null;
									return (

										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}






					{matchOption === 1 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" >


								{betVal1.length > 0 && <dl id="header_yes" class="bets-head">
									<dt>
										<h4>Yes</h4>
									</dt>
									<dd id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (

										<dl key={index} id="txnTemplate" class="bets-back" >
											<dt>
												<span id="sideType" class="back">Yes</span>
												<h4 id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}


								{betVal2.length > 0 && <dl id="header_no" class="bets-head" >
									<dt>
										<h4>No</h4>
									</dt>
									<dd id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (
										<dl key={index} id="txnTemplate" class="bets-lay" >
											<dt>
												<span id="sideType" class="lay">No</span>
												<h4 id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}>
									<a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
				</React.Fragment>
				
				} */}






			{view === 2 &&
				<React.Fragment>


					<h3 id="txnHeader" >
						Matched
					</h3>


					{matchOption === 2 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

						{matchOption === 4 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
					
									if (betVal3.length == 0) return null;

									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

						{matchOption === 5 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
								
									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

						{matchOption === 6 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>

								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>
								}

								{betVal1.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;

									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}



								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									var rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);

									if (betVal3.length == 0) return null;
									return (
										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{rate}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
					{matchOption === 3 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" style={{}}>


								{betVal1.length > 0 && <dl id="header_back" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Back</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {

									if (betVal3.length == 0) return null;
									
									return (

										<dl key={index} id="txn_21164432" class="bets-back" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="back">Back</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


								{betVal2.length > 0 && <dl id="header_lay" class="bets-head" style={{ display: ' flex' }}>
									<dt>
										<h4>Lay</h4>
									</dt>
									<dd class="col-odds"><span>Odds</span></dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3.length == 0) return null;
									return (

										<dl key={index} id="txn_21164487" class="bets-lay" style={{ display: ' flex' }}>
											<dt>
												<span id="sideType" class="lay">Lay</span>
												<h4 id="selectionName">{item.teamName === 'A' ? betVal3[0].runnerName1 : item.teamName === 'B' ? betVal3[0].runnerName2 : 'The Draw'}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate * 100}</span></dd>
											<dd id="stake" class="col-stake" title=" 10"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(item.rate * item.amount).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}

							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}><a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}

					{matchOption === 1 &&
						<React.Fragment>

							<div id="txnList" class="slip-list" >


								{betVal1.length > 0 && <dl id="header_yes" class="bets-head">
									<dt>
										<h4>Yes</h4>
									</dt>
									<dd id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Profit</span></dd>
								</dl>}

								{betVal1.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (

										<dl key={index} id="txnTemplate" class="bets-back" >
											<dt>
												<span id="sideType" class="back">Yes</span>
												<h4 id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>
									)
								})}


								{betVal2.length > 0 && <dl id="header_no" class="bets-head" >
									<dt>
										<h4>No</h4>
									</dt>
									<dd id="runsOdds" class="col-odds">Runs/Odds</dd>
									<dd class="col-stake"><span>Stake</span></dd>
									<dd class="col-profit"><span>Liability</span></dd>
								</dl>}


								{betVal2.map(function (item, index) {
									if (betVal3F.length == 0) return null;
									return (
										<dl key={index} id="txnTemplate" class="bets-lay" >
											<dt>
												<span id="sideType" class="lay">No</span>
												<h4 id="selectionName">{betVal3F[0].runnerName}</h4>
											</dt>
											<dd id="odds" class="col-odds"><span>{item.rate}<br />{parseFloat(item.teamName * 100).toFixed(0)}</span></dd>
											<dd id="stake" class="col-stake"><span>{item.amount}</span></dd>
											<dd id="pl" class="col-profit"><span>{parseFloat(parseFloat(item.teamName) * parseFloat(item.amount)).toFixed(2)}</span></dd>
											<dd id="betInfo" class="col-ref" style={{ display: 'none' }}>
												<ul>
													<li id="betId"></li>
													<li id="placeDate"></li>
												</ul>
											</dd>
										</dl>)
								})}


							</div>

							<ul id="openBetOption" class="check-list" style={{ display: 'none' }}>
								<li><a id="showBetInfo" class="" >Bet Info</a></li>
								<li name="txnOption" style={{ display: 'none' }}>
									<a id="averageOdds" class="" >Average Odds</a></li>
							</ul>
						</React.Fragment>}
				</React.Fragment>}

		</React.Fragment>
	)
}
