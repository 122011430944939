import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BodyImage from '../images/lcexch247/bg-login-skyEX.jpg'
import RulesNRegulation from './RulesNRegulation'

export default function AgeRestrictionDisclaimer(props) {
	const [rulesnreg, setrulesnreg] = useState(false)

	const handleclick = () => {
		setrulesnreg(true);
	}

	return (
		<React.Fragment>
			{rulesnreg == false &&
				<React.Fragment>

					<body style={{
						background: `url(${BodyImage}) no-repeat center`,
						backgroundSize: 'cover'
					}}>
						<div class="announce-wrap">
							<div class="announce-header">
								<h1>AGE RESTRICTION DISCLAIMER</h1>
							</div>

							<div class="tc-content_few">
								<p>To play at this site, your age should be 18 years or above.</p>

								<p>You are required to truthfully state your age while registering. Minors and underage are strictly prohibited to participate in any activity on this site.</p>

								<p>Your account with this site may stand suspended till satisfactory documentation to prove your age is provided to us. If we find later that you are underage or minor we reserve the right to suspend or deactivate your account whichever we find appropriate.</p>

							</div>

							<ul class="announce-footer age_box">
								<li class="confirm-checkbox">
									<input type="checkbox" id="termVersion" />
									<label for="termVersion">Do not show again.</label>
								</li>
								<li><a id="decline" href='/home' class="btn secondary">I am below 18</a></li>
								<li><a id="accept" class="btn-send" onClick={() => { handleclick() }}>I am 18 or above</a></li>
							</ul>

						</div>
					</body>
				</React.Fragment>
			}
			{rulesnreg &&

				<RulesNRegulation firstlogin={props.firstlogin} password={props.password} Newpassword={props.Newpassword} />

			}
		</React.Fragment>
	)
}
