import Axios from 'axios';
import React, { useEffect,useState } from 'react'

import Cookies from 'universal-cookie';

var qs = require('qs');

//Axios.defaults.withCredentials = true;

const cookies = new Cookies();

cookies.set('dupBet', "2", { path: '/' });

export default function Betslipboard(props) {
   const [ssstake1, setSsstake1] = useState(0);
   const [ssstake2, setSsstake2] = useState(0);
   const [ssstake3, setSsstake3] = useState(0);
   const [ssstake4, setSsstake4] = useState(0);
   const [ssstake5, setSsstake5] = useState(0);
   const [ssstake6, setSsstake6] = useState(0);
   const [defaultStake, setdefaultStake] = useState(' ');
   const [betType, setbetType] = useState('Back')
   const [teamName, setteamName] = useState('A')

   

   
   const handleInput = (event)=>{
         
         setdefaultStake(event.target.value);
         props.changedStake(event.target.value);
       
   }
   const handleClick = (val)=>{

      setdefaultStake(val);
      props.changedStake(val);
      
      
  }
  const handleRefresh = () =>{
        
   const utoken = cookies.get('token');
   const user = cookies.get('id');
    Axios.post('https://millionbet247.com/api/client/clientrefresh',{
    id:user,
    token:utoken
  })
  .then(result => {
     if(result.status === 210){
      //console.log(result.data);    
         
      if(result.data[1].val){
        props.updateBalance(result.data[0].val - Math.abs(result.data[1].val),Math.abs(result.data[1].val));
        
     }
     else{
      props.updateBalance(result.data[0].val - Math.abs(result.data[1].val), 0);
       
     }
        }
    
     if(result.status === 220){
    //   console.log('refresh unsucessful');
                      
     }
   }
   
 ).catch(e => {
 //  setIsError(true);
 });
};

  const betPlace = ()=>{
   var dupBet = cookies.get('dupBet');

   

    Axios.post('https://millionbet247.com/api/placebet/placebets',{
     
      clientId:props.userid,
      token:props.utoken,  
      eventId: props.eventI,
      marketId: props.marketI,
      odds:props.odds,
      oddsType:betType,
      betType: 'match',
      coin:defaultStake,
      teamName:teamName,
      dupbet: dupBet
    
    },{withCredentials: true})
    
     .then(result => {
       
      props.changeWaiting(false);
      
       if(result.data == 6){
         var obj = {};
        
         obj.msgNumber = 1;
         obj.msg = 'Bet Placed Successfully!';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
         props.isanybets(!props.anyBets);

         handleRefresh();   
     }
     else if(result.data == 1){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Bet Blocked';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }

     else if(result.data == 2){
        
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Bet Closed !';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data == 3){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Insufficient Coin !';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data == 4){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Odds Changed !';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data == 5){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Bet can not be placed!';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data == 7){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Enter valid coin value!';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data == 123){
         var obj = {};
        
         obj.msgNumber = 2;
         obj.msg = 'Bet Over 75 not accepted!';
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
   //   else if(result.data == 13){
   //       var obj = {};
        
   //       obj.msgNumber = 2;
   //       obj.msg = 'Duplicate Bet Not allowed!';
   //       props.changebetMsg(obj);

   //       setTimeout(()=>{
   //           props.changebetMsg({});
   //       },2000);
   //   }
     else if(result.data.charAt(0) == 8){
         var obj = {};
         let arr = result.data.split('_');
         obj.msgNumber = 2;
         obj.msg = 'Min bet is'+' '+arr[1];
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
     else if(result.data.charAt(0) == 9){
         var obj = {};
         let arr = result.data.split('_');
         obj.msgNumber = 2;
         obj.msg = 'Maximum bet is'+' '+arr[1];
         props.changebetMsg(obj);

         setTimeout(()=>{
             props.changebetMsg({});
         },2000);
     }
    else{
     
     var obj = {};
    
     obj.msgNumber = 2;
     obj.msg = 'Bet can not be placed!';
     props.changebetMsg(obj);

     setTimeout(()=>{
         props.changebetMsg({});
     },2000);
     }
     


   }
   
 ).catch(e => {
   
 
 });
    
}

  useEffect(() => {

   if(props.onSelect === 1 || props.onSelect === 3 || props.onSelect === 5){
      setbetType('LAGAI');
      props.changeOddType('LAGAI');
   }
   else if(props.onSelect === 2 || props.onSelect === 4 || props.onSelect === 6){
      setbetType('KHAI');
      props.changeOddType('KHAI');
   }

   if(props.onSelect === 1 || props.onSelect === 2 ){
      setteamName('A');
      props.changeteam('A');
   }
   else if(props.onSelect === 3 || props.onSelect === 4){
      setteamName('B');
      props.changeteam('B');
   }
   else if(props.onSelect === 5 || props.onSelect === 6){
      setteamName('T');
      props.changeteam('T');
   }
  

  });

   
   useEffect(() => {
        
      Axios.post('https://millionbet247.com/api/client/clientssgetclickstake',{
     
      id:props.userid,
      token:props.utoken,
    
    })
     .then(result => {
       if(result.status === 210){

         
          setdefaultStake(result.data[0].defaultStake);
          setSsstake1(result.data[0].SSstake1);
          setSsstake2(result.data[0].SSstake2);
          setSsstake3(result.data[0].SSstake3);
          setSsstake4(result.data[0].SSstake4);
          setSsstake5(result.data[0].SSstake5);
          setSsstake6(result.data[0].SSstake6);
           
        } 
   }
   
 ).catch(e => {
 
 });
      },[])

    return (
        <React.Fragment>
                     <div id="betSlipBoard" class="bet_slip">
      <div id="betList" class="slip-list" style={{maxHeight:'calc(100% - 86px)'}}>			
   <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="template" style={{display:'none'}}>
      <ul>
         <li><a class="btn" id="selectStake_1" stake="30" style={{cursor:'pointer'}}>{ssstake1}</a></li>
         <li><a class="btn" id="selectStake_2" stake="40" style={{cursor:'pointer'}}>{ssstake2}</a></li>
         <li><a class="btn" id="selectStake_3" stake="50" style={{cursor:'pointer'}}>{ssstake3}</a></li>
         <li><a class="btn" id="selectStake_4" stake="60" style={{cursor:'pointer'}}>{ssstake4}</a></li>
         <li><a class="btn" id="selectStake_5" stake="70" style={{cursor:'pointer'}}>{ssstake5}</a></li>
         <li><a class="btn" id="selectStake_6" stake="80" style={{cursor:'pointer'}}>{ssstake6}</a></li>
      </ul>
   </dd>
   
   
   
   <div id="oddsTipsPopup" class="tips-popup" style={{display:'none'}}>min 1.01</div>
   
   
   <div id="stakeTipsPopup" class="tips-popup" style={{display:'none'}}>Min 4.00</div>

  {(props.onSelect === 1 || props.onSelect === 3 || props.onSelect === 5) &&  <ul id="backSlipHeader" class="slip-head" name="slipHeader">
      <li class="col-bet">Back (Bet For)</li>
      <li id="oddsHeader" class="col-odd">Odds</li>
      <li id="runsHeader" class="col-odd" style={{display:'none'}}>Unit:Runs</li>
      <li class="col-stake">Stake</li>
      <li class="col-profit">Profit</li>
   </ul>}
   

   {(props.onSelect === 2 || props.onSelect === 4 || props.onSelect === 6) &&    <ul id="laySlipHeader" class="slip-head" name="slipHeader">
      <li class="col-bet">Lay (Bet Against)</li>
      <li id="oddsHeader" class="col-odd">Odds</li>
      <li id="runsHeader" class="col-odd" style={{display:'none'}}>Unit:Runs</li>
      <li class="col-stake">Stake</li>
      <li class="col-profit">Liability</li>
   </ul>}
   <div id="header_Back_30030230" style={{display:'block'}} count="1">
         <h4><img class="icon-in_play" src="/images/transparent.gif"/>{props.eventname}</h4>
      <dl id="bet_30030230_1_173400449_215817_1" className={`${(props.onSelect === 1 || props.onSelect === 3 || props.onSelect === 5)? "slip-back": "slip-lay"}`} style={{display:'block'}} marketid="1_173400449" selectionid="215817" eventtype="1" eventid="30030230" sidetype="1" markettype="MATCH_ODDS">
         <dt>
    <a id="delete" class="delete" style={{cursor:'pointer'}}>delete</a><span class="short-amount">{props.runnername}</span> 
            <span id="marketName">Match Odds</span>
         </dt>
         <dd class="col-odd">
            <input onChange = {(e)=>{props.changeOdds(e.target.value);}} id="odds" type="text" value={props.odds} maxLength="6" tabIndex="0"/>

            <ul class="odd-add">
               <li><a onClick = {()=>{props.changeOdds((parseFloat(props.odds) + 0.01).toFixed(2).toString())}} id="oddsUp" class="up" style={{cursor:'pointer'}}>up</a></li>
               <li><a onClick = {()=>{if(props.odds > 0.1 )props.changeOdds((parseFloat(props.odds) - 0.01).toFixed(2).toString())}} id="oddsDown" class="down" style={{cursor:'pointer'}}>down</a></li>
            </ul>
            
            <ul id="oddsTipsPoint"></ul>

         </dd>
         <dd class="col-stake">
            <input id="inputStake" type="text" value={defaultStake} onChange = {(event)=>{handleInput(event);}} maxLength="7" tabIndex="0"/>
            <ul id="listPoint"></ul>
         </dd>
    <dd id="profitLiability" class="col-profit">{parseFloat((parseFloat(props.odds)-1)*parseFloat(defaultStake)).toFixed(2)}</dd><dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{display:'block'}} bet="bet_30030230_1_173400449_215817_1">
      <ul>
         <li><a  onClick ={()=>{handleClick(ssstake1);}}  class="btn" id="selectStake_1" stake="30" style={{cursor:'pointer'}}>{ssstake1}</a></li>
         <li><a onClick ={()=>{handleClick(ssstake2);}} class="btn" id="selectStake_2" stake="40" style={{cursor:'pointer'}}>{ssstake2}</a></li>
         <li><a onClick ={()=>{handleClick(ssstake3);}} class="btn" id="selectStake_3" stake="50" style={{cursor:'pointer'}}>{ssstake3}</a></li>
         <li><a onClick ={()=>{handleClick(ssstake4);}} class="btn" id="selectStake_4" stake="60" style={{cursor:'pointer'}}>{ssstake4}</a></li>
         <li><a onClick ={()=>{handleClick(ssstake5);}} class="btn" id="selectStake_5" stake="70" style={{cursor:'pointer'}}>{ssstake5}</a></li>
         <li><a onClick ={()=>{handleClick(ssstake6);}} class="btn" id="selectStake_6" stake="80" style={{cursor:'pointer'}}>{ssstake6}</a></li>
       </ul>
     </dd>
      
         <dd class="keep-option">
             <p class="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet">{props.minStack}</strong></p>
         </dd>
         
         <dd id="inningsRuns" class="inn_runs" style={{display:'none'}}>
            <dl>
               <dt id="ifRuns"></dt>
               <dd><span id="runsPL" class="green"></span></dd>
               <dt id="ifLess"></dt>
               <dd><span id="lessPL" class="green"></span></dd>
            </dl>
         </dd>
         
         
       </dl>
      </div>

  
      
      
      
      <dl id="betTemplate" class="slip-back" style={{display:'none'}} marketid="" selectionid="">
         <dt>
            <a id="delete" class="delete" style={{cursor:'pointer'}}>delete</a> 
            <span id="marketName">Match Odds</span>
         </dt>
         <dd class="col-odd">
            <input onChange = {(e)=>{props.changeOdds(e.target.value);}} id="odds" type="text" maxlength="6" value = {props.odds}/>

            <ul class="odd-add">
               <li><a id="oddsUp" class="up" style={{cursor:'pointer'}}>up</a></li>
               <li><a id="oddsDown" class="down" style={{cursor:'pointer'}}>down</a></li>
            </ul>
            
            <ul id="oddsTipsPoint"></ul>

         </dd>
         <dd class="col-stake">
            <input id="inputStake" type="text" value={defaultStake} maxlength="7"/>
            <ul id="listPoint"></ul>
         </dd>
         <dd id="profitLiability" class="col-profit"></dd>
       
         <dd class="keep-option">
             <p class="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet">{props.minStack}</strong></p>
         </dd>
         
         <dd id="inningsRuns" class="inn_runs" style={{display:'none'}}>
            <dl>
               <dt id="ifRuns"></dt>
               <dd><span id="runsPL" class="green"></span></dd>
               <dt id="ifLess"></dt>
               <dd><span id="lessPL" class="green"></span></dd>
            </dl>
         </dd>
         
         
      </dl>
      
   </div>

   
   <div id="betSlipFullBtn" class="full_btn">
      <dl class="sum">
         <dt>Liability</dt>
         <dd>
            <span id="total" class="red">0.00</span>
         </dd>
      </dl>

      <ul class="btn-wrap">
         <li><a  onClick = {()=>{props.showBetForm(false,0);props.setOnClick(0);}}  id="cancelAll" class="btn" style={{cursor:'pointer'}} tabindex="0">Cancel All</a></li>
         <li><a  onClick = {(e)=>{e.preventDefault(); props.changeWaiting(true); betPlace(); props.showBetForm(false,0);props.setOnClick(0);}} id="placeBets" class="btn-send" style={{cursor:'pointer'}} tabIndex="0">Place Bet</a></li>
      </ul>

      <ul class="slip-option">
         <li><input id="comfirmBets" type="checkbox"/><label for="comfirmBets">Please confirm your bets.</label></li>
      </ul>

      
      </div>

     </div>
        </React.Fragment>
    )
 }
