import React, { useState, useEffect } from "react";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../images/loading40.gif'
import Transparent from '../images/transparent.gif'
import calender from '../images/sprite/calender.png'
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";

import { toast } from 'react-toastify'


window.pnlView = 1;
window.daypnl = 3;
toast.configure()

export default function ProfitLoss(props) {

	//const date = new Date().toLocaleDateString();
	const [time, setDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [sTime, setsTime] = useState('09:00')
	const [eTime, seteTime] = useState('08:59')
	const [select, setselect] = useState(0)
	const [profitLoss, setprofitLoss] = useState([])
	const [click, setclick] = useState(-1);
	const [eventType, seteventType] = useState('0');
	const [eventProfitLoss, seteventProfitLoss] = useState([])
	const [netpl, setnetpl] = useState(0);





	const changeStartDate = (val) => {
		setsDate(val);
	}
	const changeEndDate = (val) => {
		seteDate(val);
	}
	const changeStartTime = (val) => {
		setsTime(val);
	}
	const changeEndTime = (val) => {
		seteTime(val);
	}

	useEffect(() => {
		var timerID = setInterval(() => tick(), 1000);
		return function cleanup() {
			clearInterval(timerID);
		};
	}, []);

	function tick() {
		setDate(moment().format('YYYY-MM-DD HH:mm:ss'));
	}


	const changeEvent = (e) => {
		var selectBox = document.getElementById("sportsevent");
		var selectedValue = selectBox.options[selectBox.selectedIndex].value;

		if (selectedValue == 0) {
			seteventType('0');
		}
		else if (selectedValue == 1) {
			seteventType('1');
		}
		else if (selectedValue == 2) {
			seteventType('2');
		}
		else if (selectedValue == 4) {
			seteventType('4');
		}

		window.pnlView = 1;
		getpnl(selectedValue);



	}

	const getpnl = (valEventtype) => {

		var start = '';
		var end = '';

		if (window.daypnl === 3) {
			start = sDate + ' ' + sTime + ':00';
			end = eDate + ' ' + eTime + ':00';
		}
		else if (window.daypnl === 1) {
			var now = moment();
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");




		}
		else if (window.daypnl === 2) {
			var now = moment().subtract(1, 'days');
			start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
			end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		}


		axios.post('https://millionbet247.com/api/client/clientprofitloss', {
			id: props.userid,
			token: props.utoken,
			startDate: start,
			endDate: end,
			eventType: valEventtype,
			view: window.pnlView


		})
			.then(result => {
				if (result.data.length == 0) {
					toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
					if (eventType != '0') {
						seteventType('0');
					}
				}

				if (result.status === 200) {
					setprofitLoss(result.data);

					var arr = [];
					var netSum = 0;
					result.data.map((item) => {
						arr.push(item.eventId);
						netSum = netSum + parseFloat(item.pl);
					});
					seteventProfitLoss(arr);
					setnetpl(netSum.toFixed(2));
					if (arr.length === 0) {
						return;
					}

					axios.post('https://millionbet247.com/api/client/clienteventprofitloss', {
						id: props.userid,
						token: props.utoken,
						eventId: arr,
					})
						.then(result => {
							if (result.status === 200) {
								seteventProfitLoss(result.data);
							}



						})
						.catch(e => {
							//setIsError(true);
						})


				}
			}

			).catch(e => {
				//setIsError(true);
			});

	}


	const handleSlip = (index) => {
		if (click === index) {
			setclick(-1);
		}
		else {
			setclick(index);
		}

	}



	return (

		<div>
			<div class="col-left">
				<div class="sub_path">
					<div class="path">

						<ul>
							<li class="path-last"><a >My Account</a></li>
						</ul>
					</div>
					<ul id="path-pop" class="path-pop">
					</ul>
					<ul class="menu-list">
						<li><a id="sub_menu_detail" href="/exchange/member/myprofile">My Profile</a></li>
						<li><a id="sub_menu_summary" href="/exchange/member/balance">Balance Overview</a></li>
						<li><a id="sub_menu_accountCashStatement_log" href="/exchange/member/AccountStatement">Account Statement
						</a></li>
						<li><a id="sub_menu_my_bets" href="/exchange/member/mybets" class="select">My Bets</a></li>
						<li><a id="sub_menu_activity_log" href="/exchange/member/activitylog">Activity Log</a></li>
						{/* <li><a id="sub_menu_activity_log"  onClick={props.handlepassmodel} >Change password</a></li> */}
					</ul>
				</div>
			</div>
			<div class="col-center report">
				<div id="loading" class="loading-wrap" style={{ display: 'none' }}>
					<ul class="loading">
						<li><img src={Loading} alt="loading" /></li>
						<li>Loading...</li>
					</ul>
				</div>
				<div id="message" class="message-wrap success">
					<a class="btn-close">Close</a>
					<p></p>
				</div>
				<div id="message" class="message-wrap success">
					<a class="btn-close"  >Close</a>
					<p>Your cash out was successful !</p>
				</div>
				<h2>My Bets</h2>
				<ul style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li><a href="/exchange/member/mybets"  >Current Bets</a></li>
					<li><a href="/exchange/member/bethistory" >Bets History</a></li>
					<li><a href="/exchange/memberProfitLoss" class="select"  >Profit &amp; Loss</a></li>
					{/* <li><a href="/exchange/member/casinoProfitLoss">Casino Report</a></li> */}
				</ul>
				<div class="white-wrap">
					<h3>Profit &amp; Loss - Main wallet</h3>
					<ul class="acc-info">
						<li class="user">{props.userid}</li>
						<li class="time">{time}</li>
					</ul>
					<ul class="report-tab-wrap" >
						<li onClick={() => { setselect(0) }} className={`report-tab ${(select === 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
							Exchange
						</li>
						<li onClick={() => { setselect(1) }} className={`report-tab ${(select === 1) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
							FancyBet
						</li>
						<li onClick={() => { setselect(4) }} className={`report-tab ${(select === 4) ? "select" : "null"}`} id="reportType_bPoker" data-reporttabtype="4">
							Casino
						</li>
						<li onClick={() => { setselect(2) }} className={`report-tab ${(select === 2) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
							Sportsbook
						</li>
						<li onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
							BookMaker
						</li>

						{/* <li onClick={() => { setselect(5) }} className={`report-tab ${(select === 5) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
							Binary
						</li> */}
					</ul>
					<div class="function-wrap">
						<ul class="input-list">
							<div id="statusCondition" style={{ display: 'none' }}>
								<li><label>Bet Status:</label></li>
								<li>
									<select name="betStatus" id="betStatus">
									</select>
								</li>
							</div>
							<li><label>Period</label></li>
							<li>

								<ul class="input-list" style={{ display: 'inline-flex' }}>

									<DatePicker
										selectsStart
										dateFormat="yyyy-MM-dd"
										selected={startDate}
										placeholderText="YYYY-MM-DD"
										className="cal-input"
										onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
									/>
									<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

									<DatePicker
										selectsEnd
										dateFormat="yyyy-MM-dd"
										placeholderText="YYYY-MM-DD"
										selected={endDate}
										onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
										className="cal-input"
									/>
									<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


								</ul>


							</li>

							<li style={{ display: 'none' }}>(TimeZone:IST)</li>
						</ul>
						<ul class="input-list">
							<li><a id="today" onClick={() => { window.daypnl = 1; window.pnlView = 1; getpnl(eventType); }} class="btn">Just For Today</a></li>
							<li><a id="yesterday" onClick={() => { window.daypnl = 2; window.pnlView = 1; getpnl(eventType); }} class="btn">From Yesterday</a></li>
							<li style={{ display: 'none' }}><a id="last7days" class="btn">Last 7 days</a></li>
							<li style={{ display: 'none' }}><a id="last30days" class="btn">Last 30 days</a></li>
							<li style={{ display: 'none' }}><a id="last2months" class="btn">Last 2 Months</a></li>
							<li style={{ display: 'none' }}><a id="last3months" class="btn">Last 3 Months</a></li>
							<li><a id="getPL" onClick={() => { window.daypnl = 3; window.pnlView = 1; getpnl(eventType); }} class="btn-send">Get P &amp; L</a></li>
						</ul>
					</div>
					{profitLoss.length == 0 && <div id="noReportMessage"><p>Betting Profit &amp; Loss enables you to review the bets you have placed. <br />
						Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.</p>
						<p>Betting Profit &amp; Loss is available online for the past 2 months.</p></div>}
				</div>



				{profitLoss.length > 0 && <div id="report" data-report="profitAndLossReport">
					<ul id="spotsUl" class="total-show">
						<li id="totalPL">Total P/L: USD <span className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>
						<li id="sumOfQuery" class="sports-switch">USD  <span className={`${netpl >= 0 ? "" : "red"}`}>{netpl >= 0 ? parseFloat(netpl).toFixed(2) : '(' + Math.abs(netpl).toFixed(2) + ')'}</span></li>
						<li class="sports-switch">
							<select name="sports" id="sportsevent" onChange={(e) => { changeEvent(e); }}>

								<option value="0" selected="selected">ALL</option><option value="1">SOCCER</option><option value="2">TENNIS</option><option value="4">CRICKET</option></select>
						</li>
					</ul>


					<table id="reportTable" class="table01 table-pnl" style={{ display: 'table' }}>
						<tbody>
							<tr>
								<th width="" class="align-L">Market
								</th>
								<th width="15%">Start Time
								</th>
								<th width="15%">Settled date
								</th>
								<th width="18%">Profit/Loss
								</th>
							</tr>

							<tr id="summaryTemplate" style={{ display: 'none' }}>
								<td id="title" class="align-L"></td>
								<td id="startTime"></td>
								<td id="settledDate"></td>
								<td>
									<a id="pl" class="expand-close"></a>
								</td>
							</tr>


							{profitLoss.map((item, index) => {
								var matchName;

								if (item.sportId == '4') {
									matchName = 'CRICKET';
								} else if (item.sportId == '1') {
									matchName = 'SOCCER';
								}
								else if (item.sportId == '2') {
									matchName = 'TENNIS';
								}



								return (

									<React.Fragment key={index}>

										<tr id="summary0" style={{ display: 'table-row' }}>
											<td id="title" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong></td>
											<td id="startTime">{item.startTime}</td>
											<td id="settledDate">{item.settledDate}</td>

											<td>
												<a id="pl0" className={`${click === index ? "expand-open" : "expand-close"}`} onClick={() => { handleSlip(index); }}>
													<span className={`${item.pl >= 0 ? "" : "red"}`}>{item.pl >= 0 ? parseFloat(item.pl).toFixed(2) : '(' + Math.abs(item.pl).toFixed(2) + ')'}</span>
												</a>
											</td>

										</tr>

										{click === index && <tr id="detail0" class="expand" style={{ display: 'table-row' }}>
											<td colspan="4">
												<img class="expand-arrow-R" src={Transparent} />

												<table class="table-commission">
													<tbody><tr>
														<th width="9%">Bet ID
														</th>
														<th width="">Selection
														</th>
														<th width="9%">Odds
														</th>
														<th width="13%">Stake
														</th>
														<th width="8%">Type
														</th>
														<th width="16%">Placed
														</th>
														<th width="23%">Profit/Loss
														</th>
													</tr>

														{eventProfitLoss.map((item2, index2) => {

															//console.log(item2,'allprofitlossdata');
															var playerName;
															var odds;
															var matchtype;
															var profit = '';
															if (item2.betType === 'match') {
																odds = parseFloat(parseFloat(item2.rate) + 1).toFixed(2);
																if (item2.teamName === 'A') {
																	playerName = item2.runnerName1;

																}
																else if (item2.teamName === 'B') {
																	playerName = item2.runnerName2;
																}
																else if (item2.teamName === 'T') {
																	playerName = 'The Draw';
																}
															}
															else if (item2.betType === 'fancy') {
																playerName = item2.runnerName;
																odds = item2.rate + '/' + parseFloat(item2.teamName * 100).toFixed(0);
															}

															if (item2.type === 'LAGAI') {
																matchtype = 'BACK';
															}
															else if (item2.type === 'KHAI') {
																matchtype = 'LAY';
															}
															else {
																matchtype = item2.type;
															}


															if (item2.betType == 'fancy' && item2.result) {
																if (item2.type == "YES") {
																	if (parseFloat(item2.rate) <= parseFloat(item2.result)) {
																		profit = parseFloat(item2.amount * item2.teamName).toFixed(2);
																	}
																	else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																}
																else {
																	if (parseFloat(item2.rate) > parseFloat(item2.result)) {
																		profit = parseFloat(item2.amount).toFixed(2);
																	}
																	else profit = parseFloat((item2.amount * item2.teamName) * (-1)).toFixed(2);
																}
															}
															else if (item2.betType == 'match' && item2.winner) {
																if (item2.type == "LAGAI") {
																	if (item2.teamName == 'A') {
																		if (item2.winner == "A") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																	else if (item2.teamName == "B") {
																		if (item2.winner == "B") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																	else if (item2.teamName == "T") {
																		if (item2.winner == "T") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
																		else profit = parseFloat(item2.amount * (-1)).toFixed(2);
																	}
																}
																else if (item2.type == "KHAI") {
																	if (item2.teamName == 'A') {
																		if (item2.winner != "A") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																	else if (item2.teamName == "B") {
																		if (item2.winner != "B") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																	else if (item2.teamName == "T") {
																		if (item2.winner != "T") profit = parseFloat(item2.amount).toFixed(2);
																		else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
																	}
																}
																if ((item2.runnerId3 == null || item2.runnerId3 == '' || item2.runnerId3 == ' ') && item2.winner == "T") {
																	profit = 0;
																	//console.log(profit,'true');
																}
															}

															return (
																<React.Fragment key={index2}>
																	{item.eventId === item2.eventId && <tr id="txTemplate" className={`${(index2 % 2) == 0 ? "even" : ""}`}>
																		<td id="betID">{item2.id}</td>
																		<td id="matchSelection">{playerName}</td>
																		<td id="txOddsMatched">{odds}</td>
																		<td id="txStake">{item2.amount}</td>
																		<td><span id="matchType" className={`${item2.type === 'LAGAI' || item2.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
																		<td id="placed">{item2.betTime}</td>
																		<td id="txLiability" className={`${profit >= 0 ? "green" : "red"}`}>{profit >= 0 ? profit : '(' + Math.abs(profit).toFixed(2) + ')'}</td>
																	</tr>}
																</React.Fragment>
															)
														})}


													</tbody>
												</table>

											</td>
										</tr>}
									</React.Fragment>
								)
							})}


						</tbody>
					</table>
					<p class="table-other">Profit and Loss is shown net of commission.
					</p>
				</div>}


				<div>
					{profitLoss.length > 0 && <ul id="pageNumberContent" class="pages">
						<li id="prev"><a onClick={() => { if (window.pnlView > 1) { window.pnlView = window.pnlView - 1; getpnl(eventType); } }} className={`${(window.pnlView > 1) ? "" : "disable"}`}>Prev</a></li>
						<li id="pageNumber"><a class="select" style={{ pointerEvents: ' none' }}>{window.pnlView}</a></li>
						<li id="next"><a onClick={() => { if (profitLoss.length >= 50) { window.pnlView = window.pnlView + 1; getpnl(eventType); } }} className={`${(profitLoss.length >= 50) ? "" : "disable"}`} >Next</a></li></ul>}

				</div>

			</div>

		</div>

	)
}
