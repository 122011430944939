import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
 import Ipl1 from '../images/kv/kv-all-casino.jpeg'
import Ipl2 from '../images/kv/255.jpg'
import Soccer from '../images/kv/AddText_02-12-08.10.59.jpg'
import Tennis from '../images/kv/kv-all-lc-roulette.jpeg'
 import Eid from '../images/kv/kv-all-live-games.jpg'
 import premiumba from '../images/kv/kv-all-premium-casino.jpeg'
 import allpremium from '../images/kv/kv-all-premium.jpeg'
 import newnum from '../images/kv/kv-all-roulette.png'
 import slotsnum from '../images/kv/kv-all-slots.jpeg'
 import superwith from '../images/kv/kv-all-super-withdrawal.jpg'

const photos = [

   {
      name: 'photo 1',
      url: [Eid]
   },
   {
      name: 'photo 2',
      url: [Ipl1]
   },
   {
      name: 'photo 3',
      url: [Ipl2]
   },
   {
      name: 'photo 4',
      url: [Soccer]
   },
   {
      name: 'photo 5',
      url: [Tennis]
   }, {
      name: 'photo 6',
      url: [premiumba]
   }, {
      name: 'photo 7',
      url: [allpremium]
   }, {
   name: 'photo 8',
   url: [newnum]
}, {
   name: 'photo 9',
   url: [slotsnum]
}
, {
   name: 'photo 10',
   url: [superwith]
}
]

const settings = {
   dots: false,
   slide: true,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 3500,
   speed: 800,
   slidesToShow: 1,
   arrows: false,
   slidesToScroll: 1,
   className: 'slidesN'
}


export default function Carousel() {
   return (
      <React.Fragment>
         <Slider  {...settings} style={{ height: '18vw' }}>
            {photos.map((photo, id) => {
               return (
                  <div key={id}>
                     <img width="100%" src={photo.url} alt="cric" style={{ height: '18vw' }} />

                  </div>
               )
            })}
         </Slider>
      </React.Fragment>
   )
}
