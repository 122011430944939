import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Transparent from '../images/transparent.gif'
import Cookies from 'universal-cookie';

import { suid } from 'rand-token';
import axios from 'axios';
import FirsttimeLogin from './Mobilefirstlogin'



const cookies = new Cookies();

window.flag = 0;

var cc;
var tt;


export default function Loginmobile(props) {

	const [Socialicon, setSocialicon] = useState(0);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [userid, setId] = useState("");
	const [password, setPassword] = useState("");
	const [captchaSuccess, setCaptcha] = useState(false);
	const [loading, setloading] = useState(false);
	const [firstlogin, setfirstlogin] = useState(0)





	function createCaptcha() {
		if (document.getElementById('captch')) {
			document.getElementById("captch").remove();
		}

		var captcha = [];
		while (captcha.length < 4) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * 10); //get the next character from the array

			captcha.push(index);

		}
		var canv = document.createElement("canvas");
		canv.style.width = 'inherit';
		canv.id = "captch";
		canv.width = 70;
		canv.height = 50;


		var ctx = canv.getContext("2d");
		ctx.font = "600 30px Arial";
		ctx.fillText(captcha.join(""), 0, 30)
		//ctx.strokeText(captcha.join(""), 0, 30);
		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		cc = captcha.join("");

		if (document.getElementById("popupcaptcha")) {
			document.getElementById("popupcaptcha").appendChild(canv);
		}
		else {
			document.getElementById("captcha").appendChild(canv);
		}



	}


	function validateCaptcha(event) {
		//event.preventDefault();

		if (event.target.value === cc) {
			setCaptcha(true);

		} else {
			setCaptcha(false);
		}

	}

	useEffect(() => {

		createCaptcha();

	}, []);



	const handleLogin = () => {


		if (userid === '') {

			createCaptcha();
			//toast.warn('Username can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Username is empty";


			return;
		}
		if (password === '') {
			createCaptcha();
			//toast.warn('Password can not be blank!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Password is empty";
			return;
		}

		if (!captchaSuccess) {

			createCaptcha();
			// toast.warn('Captcha is not valid!', {position:toast.POSITION.TOP_CENTER})
			document.getElementById("errorMsg").innerHTML = "Captcha is not valid!";
			return;
		}


		var demoid1 = 'demo01';
		//var demoid2 = 'demo02'
		var token = suid(16);
		if (userid.toUpperCase() === demoid1.toUpperCase()) {
			token = '123456789';
		}
		tt = token;

		cookies.set('id', userid, { path: '/' });
		cookies.set('token', token, { path: '/' });


		axios.post('https://millionbet247.com/api/client/clientlogin', {
			id: userid,
			password: password,
			token: token
		})
			.then(result => {

				if (result.status === 201) {

					setfirstlogin(1);

				}
				else if (result.status === 202) {

					setfirstlogin(2);

				}

				else if (result.status === 200) {

					setLoggedIn(true);
					//props.checkLogin(true);
					setCaptcha(false);
					window.location.href = '/home';
					//window.location.reload();


				}
				else if (result.status === 205) {
					setCaptcha(false);
					//toast.warn('User Blocked!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "User Blocked!";
					createCaptcha();
				}
				else {
					setCaptcha(false);
					//toast.warn('Username or password incorrect!', {position:toast.POSITION.TOP_CENTER})
					document.getElementById("errorMsg").innerHTML = "Username or password incorrect!";
					createCaptcha();
				}
			}

			).catch(e => {
				setIsError(true);
			});



	};



	return (
		<React.Fragment>
			{firstlogin == 0 &&
				<React.Fragment>

					<body style={{
						color: '#222',

						backgroundColor: 'rgb(103, 240, 194)',
						backgroundImage: 'linear-gradient(56deg, rgb(103, 240, 194) 4%, rgb(103, 240, 194) 42%)',
						backgroundAttachment: 'fixed'
					}}>

						<header className="login-head">
							<Link to="/" className="close ui-link" style={{ padding: 0 }} onClick={() => { props.checkShowLogin(false) }}></Link>


							<h1>lc247</h1>
						</header>
						<dl className="form-login" >
							<dd id="loginNameErrorClass" >
								<input type="email" value={userid} onChange={(e) => { setId(e.target.value) }} id="loginName" data-role="none"
									placeholder="Username" />
							</dd>
							<dd id="passwordErrorClass">
								<input type="password" id="password" value={password} onChange={(e) => { setPassword(e.target.value) }}
									data-role="none" placeholder="Password" />
							</dd>
							<dd id="validCodeErrorClass">
								<input onChange={(e) => { validateCaptcha(e) }} type="text" id="validCode" inputMode="numeric" maxLength="4/" placeholder="Validation" maxLength="4" />  <div id="popupcaptcha" className="CaptchaPopup">

								</div>
							</dd>

							<dd>
								<a onClick={handleLogin} className="btn-send ui-link" id="loginBtn">Login</a>
							</dd>
							<dd id="errorMsg" className="state"></dd>
						</dl>

						<ul class="policy-link link-login" style={{ display: ' block' }}>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(4, true) }}>KYC</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
							<li><a class="ui-link" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>
						</ul>
						{/* <div className="support-wrap extend-support">
							<div className="extend-btn">
								<img src={Transparent} title="customer" className="support-customer" />
								<a href="#" target="_blank">Customer support1</a>
								<a href="#" target="_blank" className="split-line">support2</a>
							</div>
							<div className="extend-btn">
								<img src={Transparent} title="WhatsApp" className="support-whatsapp" />
								<a href="#" target="_blank">+5352532535345</a>
								<a href="#" target="_blank" className="split-line">+260763986993</a>
							</div>
							<div className="extend-btn">
								<img src={Transparent} title="Telegram" className="support-telegram" />
								<a href="#" target="_blank">+445234523444</a>
								<a href="#" target="_blank" className="split-line">+235345235435</a>
							</div>
							<div className="support-social">
								<div className="social-btn">
									<img src={Transparent} title="Skype" className="support-skype" />
									<a href="skype:skyexchangeofficial?chat" target="_blank">Skype</a>
								</div>
								<div className="social-btn">
									<img src={Transparent} title="Email" className="support-mail" />
									<a href="mailto:info@skyexchange.com" target="_blank">Email</a>
								</div>
								<div className="social-btn">
									<img src={Transparent} title="Instagram" className="support-ig" />
									<a href="https://www.instagram.com/sky_exch_?igshid=YmMyMTA2M2Y=" target="_blank" className="ui-link">Instagram</a>
								</div>
							</div>
						</div> */}
						<div id="supportWrap" class="support-wrap">
							<div class="support-service">



								<a id="support_whatsapp"
									onClick={() => { setSocialicon(0) }}
									className={`support-whatsapp ui-link ${(Socialicon === 0) ? "open" : "null"}`}
								><img src={Transparent} title="WhatsApp" /></a>




								{/* <a id="support_skype"   
		onClick ={()=>{setSocialicon(3)}} 
		className={`support-skype ui-link ${(Socialicon===3 )? "open": "null"}`}
		><img src={Transparent} title="Skype"/></a>
		 */}

								{/* <a id="support_instagram"  
		onClick ={()=>{setSocialicon(4)}} 
		className={`support-ig ui-link ${(Socialicon===4 )? "open": "null"}`}
		><img src={Transparent} title="Instagram"/></a> */}

							</div>

							<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
									{Socialicon === 0 &&
										<a href="https://api.whatsapp.com/send/?phone=13605835688&text&app_absent=0" class="ui-link"><span>+13605835688</span></a>
									}
									{Socialicon === 0 &&
										<a href="https://api.whatsapp.com/send/?phone=447441441362&text&app_absent=0" class="ui-link"><span>+447441441362</span></a>
									}

								</div>
							{/* <div class="support-info">
								{ Socialicon ===0 &&
		<div id="supportDetail_email" 
		className={`support-detail ${(Socialicon===0 )? "open": "null"}`}
		><a   class="ui-link"><span>info@lcexch247.live</span></a></div>
	}

								<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
									{Socialicon === 0 &&
										<a href="https://wa.me/message/3HAIYJE2YQF7I1" class="ui-link"><span>wa.me/message/3HAIYJE2YQF7I1</span></a>
									}

								</div>

								<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`}>
									{Socialicon === 2 &&
										<a href="https://t.me/lcexch247" class="ui-link"><span>t.me/lcexch247</span></a>
									}
								</div>



								<div id="supportDetail_skype" className={`support-detail ${(Socialicon===3 )? "open": "null"}`}>
		{ Socialicon ===3 &&
			<a   class="ui-link"><span>lcexch247official</span></a>
		}
			</div>

										
		<div id="supportDetail_instagram" className={`support-detail ${(Socialicon===4 )? "open": "null"}`}>
		{ Socialicon ===4 &&
			
			<a   class="ui-link"><span>officiallcexch247</span></a>
			}
			</div>

							</div>
					 */}
						</div>


					</body>

				</React.Fragment>
			}
			{(firstlogin == 1 || firstlogin == 2) &&

				<FirsttimeLogin password={password} firstlogin={firstlogin} userid={userid} utoken={tt} />

			}
		</React.Fragment>
	)

}
