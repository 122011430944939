import React, { useEffect, useState } from 'react'
import ConfirmBet from './ConfirmBet'
import TVStremaing from './TVStremaing'
import FancyBook from './Fancybook'
import Axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';


require('moment-timezone');
var qs = require('qs');
const cookies = new Cookies();
var matchEndedMarket = false;
var matchEndedBook = false;
var matchEndedManual = false;
var matchEndedComMatch = false;
var matchEndedSuperMatch = false;
var matchEndedTieMatch = false;

cookies.set('dupBet', "2", { path: '/' });


const io = require('socket.io-client');

const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if(ranNum == 1){
    ENDPOINT = "https://millionbet247.com:2053";
}
else if(ranNum == 2){
    ENDPOINT = "https://millionbet247.com:2083"; 
}
else if(ranNum == 3){
    ENDPOINT = "https://millionbet247.com:2087";
}
else{
    ENDPOINT = "https://millionbet247.com:2096";
}

var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;


export const Newfullmarket = (props) => {

    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [Maxstake, setMaxstake] = useState(false)
    const [Bookstake, setBookstake] = useState(false)
    const [Fancypin, setFancypin] = useState(false)
    const [bookpin, setbookpin] = useState(false)
    const [livemarpin, setlivemarpin] = useState(false)
    const [marketData, setmarketData] = useState({})
    const [fancyData, setfancyData] = useState([])
    const [defaultStake, setdefaultStake] = useState('');
    const [dStake, setdStake] = useState('');
    const [selectFancy, setselectFancy] = useState(null)
    const [bookData, setbookData] = useState({})
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [click, setclick] = useState(-1);
    const [bOdds, setbOdds] = useState(' ')
    const [mOdds, setmOdds] = useState(' ')
    const [COdds, setCOdds] = useState(' ')
    const [SOdds, setSOdds] = useState(' ')
    const [TOdds, setTOdds] = useState(' ')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('A')
    const [bookData2, setbookData2] = useState({})
    const [field, setfield] = useState(0);
    const [Bookclick, setBookclick] = useState(false)
    const [Bookclick2, setBookclick2] = useState(false)
    const [Bookclick3, setBookclick3] = useState(false)
    const [MatchoddsData, setMatchoddsData] = useState({})
    const [Matchoddsclick, setMatchoddsclick] = useState(false)
    const [Matchoddsclick2, setMatchoddsclick2] = useState(false)
    const [Matchoddsclick3, setMatchoddsclick3] = useState(false)
    const [selectMatchBack, setselectMatchBack] = useState(null)
    const [selectBack, setselectBack] = useState(null)
    const [selectLay, setselectLay] = useState(true)
    
    
    const [showConfirmBet, setshowConfirmBet] = useState(false)
    const [showFancyBook, setshowFancyBook] = useState(false)
    const [fancySelection, setfancySelection] = useState(null)
    const [fancyRunner, setfancyRunner] = useState(null)

    const [Manualclick, setManualclick] = useState(false)
    const [Manualclick2, setManualclick2] = useState(false)
    const [Manualclick3, setManualclick3] = useState(false)
    const [SuperMatchclick, setSuperMatchclick] = useState(false)
    const [SuperMatchclick2, setSuperMatchclick2] = useState(false)
    const [SuperMatchclick3, setSuperMatchclick3] = useState(false)
    const [ComMatchclick, setComMatchclick] = useState(false)
    const [ComMatchclick2, setComMatchclick2] = useState(false)
    const [ComMatchclick3, setComMatchclick3] = useState(false)
    const [TieMatchclick, setTieMatchclick] = useState(false)
    const [TieMatchclick2, setTieMatchclick2] = useState(false)
    const [TieMatchclick3, setTieMatchclick3] = useState(false)


    const [selectManualBack, setselectManualBack] = useState(null)
    const [selectSuperMatchBack, setselectSuperMatchBack] = useState(null)
    const [selectCompMatchBack, setselectCompMatchBack] = useState(null)
    const [selectTieMatchBack, setselectTieMatchBack] = useState(null)


    const [showTieConfirmBet, setshowTieConfirmBet] = useState(false)
    const [showComConfirmBet, setshowComConfirmBet] = useState(false)
    const [showSupConfirmBet, setshowSupConfirmBet] = useState(false)

    const [pnlManual2, setpnlManual2] = useState({})
    const [pnlManual, setpnlManual] = useState({})
    
    const [pnlSuperMatch2, setpnlSuperMatch2] = useState({})
    const [pnlSuperMatch, setpnlSuperMatch] = useState({})
    const [pnlCompMatch2, setpnlCompMatch2] = useState({})
    const [pnlCompMatch, setpnlCompMatch] = useState({})
    const [pnlTieMatch2, setpnlTieMatch2] = useState({})
    const [pnlTieMatch, setpnlTieMatch] = useState({})


    const [ManualData, setManualData] = useState({});
    const [Manualdata2, setManualdata2] = useState({})
    const [SuperMatchData, setSuperMatchData] = useState({});
    const [SuperMatchdata2, setSuperMatchdata2] = useState({})
    const [TieMatchData, setTieMatchData] = useState({});
    const [TieMatchdata2, setTieMatchdata2] = useState({})
    const [CompMatchData, setCompMatchData] = useState({});
    const [CompMatchdata2, setCompMatchdata2] = useState({})
    const [getOdds, setgetOdds] = useState([]) 
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)
    const [fancybettabtype, setfancybettabtype] = useState(1)
    const [fancybettabtypePrimium, setfancybettabtypePrimium] = useState(1)
    const [SoccerMenutab, setSoccerMenutab] = useState(1)



    const [Tierunner1BackRate1, setTierunner1BackRate1] = useState(' ');
    const [Tierunner1BackSize1, setTierunner1BackSize1] = useState(' ');
    const [Tierunner1LayRate1, setTierunner1LayRate1] = useState(' ');
    const [Tierunner1LaySize1, setTierunner1LaySize1] = useState(' ');
    const [Tierunner2BackRate1, setTierunner2BackRate1] = useState(' ');
    const [Tierunner2LayRate1, setTierunner2LayRate1] = useState(' ');
    const [Tierunner2BackSize1, setTierunner2BackSize1] = useState(' ');
    const [Tierunner2LaySize1, setTierunner2LaySize1] = useState(' ');

    const [Suprunner1BackRate1, setSuprunner1BackRate1] = useState(' ');
    const [Suprunner1BackSize1, setSuprunner1BackSize1] = useState(' ');
    const [Suprunner1LayRate1, setSuprunner1LayRate1] = useState(' ');
    const [Suprunner1LaySize1, setSuprunner1LaySize1] = useState(' ');
    const [Suprunner2BackRate1, setSuprunner2BackRate1] = useState(' ');
    const [Suprunner2LayRate1, setSuprunner2LayRate1] = useState(' ');
    const [Suprunner2BackSize1, setSuprunner2BackSize1] = useState(' ');
    const [Suprunner2LaySize1, setSuprunner2LaySize1] = useState(' ');
    const [SupmarketBetStatus, setSupmarketBetStatus] = useState(0);

    const [Comrunner1BackRate1, setComrunner1BackRate1] = useState(' ');
    const [Comrunner1BackSize1, setComrunner1BackSize1] = useState(' ');
    const [Comrunner1LayRate1, setComrunner1LayRate1] = useState(' ');
    const [Comrunner1LaySize1, setComrunner1LaySize1] = useState(' ');
    const [Comrunner2BackRate1, setComrunner2BackRate1] = useState(' ');
    const [Comrunner2LayRate1, setComrunner2LayRate1] = useState(' ');
    const [Comrunner2BackSize1, setComrunner2BackSize1] = useState(' ');
    const [Comrunner2LaySize1, setComrunner2LaySize1] = useState(' ');
    const [CommarketBetStatus, setCommarketBetStatus] = useState(0);

    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])

    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState({})

    const [newrefresh, setnewrefresh] = useState(true);
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [liquidityStatus, setliquidityStatus] = useState(0);
    const [fancyStakeInfo, setfancyStakeInfo] = useState(-1);
    const [coinFactor, setcoinFactor] = useState(1);
    const [onclickPremium, setonclickPremium] = useState(false)
    const [highlightTab, sethighlightTab] = useState(0);
    const [SuperOver, setSuperOver] = useState({})
    const [completedMatch, setcompletedMatch] = useState({});
    const [TieMatch, setTieMatch] = useState({});
    const [TiemarketBetStatus, setTiemarketBetStatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [marketStartTime, setmarketStartTime] = useState('')
    const [ActiveTeam, setActiveTeam] = useState('');
   
    const [isShown, setisShown] = useState(false);
    const [Tvopen, setTvopen] = useState(true);
    const [getScoreId, setgetScoreId] = useState('');
const [getSecScoreId, setgetSecScoreId] = useState('');

    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[6];
    const eventid = para[7];
    const marketid = para[8];

    var matchType = '';

    if (eventT == 4) {
        matchType = 'Cricket';
    }
    else if (eventT == 1) {
        matchType = 'Soccer';
    }
    else if (eventT == 2) {
        matchType = 'Tennis';
    }


    const handlepremium = (vwall) => {
        setonclickPremium(vwall)
    }


    // const changeLiveStreaming = (val)=>{
    //     setliveStream(val);
    //     setTvopen(true);
        
    //  }
 

    useEffect(() => {
        socket = io(ENDPOINT);
        console.log('socket connected');
        socket.on('connect', function (data) {
            socket.emit('room1', eventid);
        });
        return () => {
            socket.close();
            console.log('socket disconnected');
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;

        }
    }, [])
    useEffect(() => {

       // setdefaultStake(0);
        return () => {
            
        }
    }, []) 


    useEffect(() => {
      //  setdStake(0)

        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientssgetclickstake', {

            id: props.userid,
            token: props.utoken,

        })
            .then(result => {

                if (result.status === 210) {
                    setcoinFactor(parseInt(result.data[0].coin_type));
                    setdStake(result.data[0].defaultStake);
                    setSsstake1(result.data[0].SSstake1);
                    setSsstake2(result.data[0].SSstake2);
                    setSsstake3(result.data[0].SSstake3);
                    setSsstake4(result.data[0].SSstake4);
                    setSsstake5(result.data[0].SSstake5);
                    setSsstake6(result.data[0].SSstake6);
                }
            }


            ).catch(e => {

            });

        return () => {

        }
    }, [props.userid]);


    useEffect(() => {

        if (!eventid) {
            props.setliveStream(null);
            return;
        }
        Axios.post("https://millionbet247.com/api/client/clientlivestreaming", {
            eventid: eventid,
        })
            .then((result) => {
                if (result.status === 210) {
                  
                    if (result.data[0].tvId && result.data[0].tvId != '') {
                        props.setliveStream(result.data[0].tvId);
                        setScoreData(result.data[0].scoreId);
                       setActiveTeam(result.data[0].activeTeam);
                
                    }
                }
            })
            .catch((e) => {
                //setIsError(true);
            });
    }, [eventid]);



    useEffect(() => {
        if (!eventid) return;
        Axios.post('https://millionbet247.com/api/client/clientgetFullMarket', {
            eventId: eventid,
        })
            .then(result => {
                if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                    matchEndedMarket = true;
                }
                if (result.data[0]) {
                    let obj = result.data[0];
                    setmarketStartTime(obj.marketStartTime)
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    if (obj.runnerId3) {
                        settieBackRate1(obj.tieBackRate1);
                        settieLayRate1(obj.tieLayRate1);
                    }

                    obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                    setmarketData(obj);
                    var Arry =[]
                    for(let i = 0; i < result.data.length; i++){
                        Arry.push(result.data[i])
                        //console.log(Arry);
                        setgetOdds(Arry);
                        }
                }

                if (newrefresh) {
                    setnewrefresh(false);
                }

            })
            .catch(e => {

            });

 // tiematch

 Axios.post('https://millionbet247.com/api/client/clientgetTieMarket', {
    eventId: eventid,
})
    .then(result => {
        if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
            matchEndedTieMatch = true;
        }
        if (result.data[0]) {
            let obj = result.data[0];
            setmarketStartTime(obj.marketStartTime)
            setrunner1BackRate1(obj.runner1BackRate1);
            setrunner1LayRate1(obj.runner1LayRate1);
            setrunner2BackRate1(obj.runner2BackRate1);
            setrunner2LayRate1(obj.runner2LayRate1);
            if (obj.runnerId3) {
                settieBackRate1(obj.tieBackRate1);
                settieLayRate1(obj.tieLayRate1);
            }
            
            obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
            setTieMatchData(obj);
        }
        if (newrefresh) {
            setnewrefresh(false);
        }
    })
    .catch(e => {
    });

    //Complete match

    Axios.post('https://millionbet247.com/api/client/clientgetCompMarket', {
        eventId: eventid,
    })
        .then(result => {
            if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                matchEndedComMatch = true;
            }
            if (result.data[0]) {
                let obj = result.data[0];
                setmarketStartTime(obj.marketStartTime)
                setrunner1BackRate1(obj.runner1BackRate1);
                setrunner1LayRate1(obj.runner1LayRate1);
                setrunner2BackRate1(obj.runner2BackRate1);
                setrunner2LayRate1(obj.runner2LayRate1);
                if (obj.runnerId3) {
                    settieBackRate1(obj.tieBackRate1);
                    settieLayRate1(obj.tieLayRate1);
                }
                
                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setCompMatchData(obj);
            }
            if (newrefresh) {
                setnewrefresh(false);
            }
        })
        .catch(e => {
        });

       // superover match

       Axios.post('https://millionbet247.com/api/client/clientgetsuperMarket', {
        eventId: eventid,
    })
        .then(result => {
            if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                matchEndedSuperMatch = true;
            }
            if (result.data[0]) {
                let obj = result.data[0];
                setmarketStartTime(obj.marketStartTime)
                setrunner1BackRate1(obj.runner1BackRate1);
                setrunner1LayRate1(obj.runner1LayRate1);
                setrunner2BackRate1(obj.runner2BackRate1);
                setrunner2LayRate1(obj.runner2LayRate1);
                if (obj.runnerId3) {
                    settieBackRate1(obj.tieBackRate1);
                    settieLayRate1(obj.tieLayRate1);
                }
                
                obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                setSuperMatchData(obj);
            }
            if (newrefresh) {
                setnewrefresh(false);
            }
        })
        .catch(e => {
        });

        Axios.post('https://millionbet247.com/api/client/clientgetBookMakerMarket', {
            eventId: eventid,
        })
            .then(result => {

                if (result.data.length === 0 || (result.data.length > 0 && result.data[0].matchStatus != 1)) {
                    matchEndedBook = true;
                }
                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                }
                if (newrefresh) {
                    setnewrefresh(false);
                }
            })
            .catch(e => {
            });

            Axios.post('https://millionbet247.com/api/client/clientgetTossMarket', {
                eventId: eventid,
            })
                .then(result => {
    
                    if (result.data.length === 0 || (result.data.length > 0 && result.data[0].matchStatus != 1)) {
                        matchEndedBook = true;
                    }
                    if (result.data[0]) {
                        let obj = result.data[0];
                        setbookData2(obj);
                    }
                    if (newrefresh) {
                        setnewrefresh(false);
                    }
                })
                .catch(e => {
                });
    }, []);

    if ( matchEndedBook && matchEndedMarket && matchEndedTieMatch && matchEndedComMatch) {
        window.location.href = '/';
    }

    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);
        //console.log(value,'matches');
            if (value.messageType === 'betfair_match_rate') {
                setmarketBetStatus(value.status);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);

                }
                if (s1 !== value.runner1BackRate1) {
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(() => { c1 = 0; }, 700);
                }


                if (s2 !== value.runner1BackSize1) {
                    setrunner1BackSize1(value.runner1BackSize1);
                    s2 = value.runner1BackSize1;
                }

                if (s3 !== value.runner1LayRate1) {
                    setrunner1LayRate1(value.runner1LayRate1);
                    s3 = value.runner1LayRate1;
                    c2 = 1;
                    setTimeout(() => { c2 = 0; }, 700);
                }

                if (s4 !== value.runner1LaySize1) {
                    setrunner1LaySize1(value.runner1LaySize1);
                    s4 = value.runner1LaySize1;
                }

                if (s5 !== value.runner2BackRate1) {
                    setrunner2BackRate1(value.runner2BackRate1);
                    s5 = value.runner2BackRate1;
                    c3 = 1;
                    setTimeout(() => { c3 = 0; }, 700);
                }

                if (s6 !== value.runner2BackSize1) {
                    setrunner2BackSize1(value.runner2BackSize1);
                    s6 = value.runner2BackSize1;
                }

                if (s7 !== value.runner2LayRate1) {
                    setrunner2LayRate1(value.runner2LayRate1);
                    s7 = value.runner2LayRate1;
                    c4 = 1;
                    setTimeout(() => { c4 = 0; }, 700);
                }

                if (s8 !== value.runner2LaySize1) {
                    setrunner2LaySize1(value.runner2LaySize1);
                    s8 = value.runner2LaySize1;

                }

                if (s9 !== value.tieBackRate1) {
                    settieBackRate1(value.tieBackRate1);
                    s9 = value.tieBackRate1;
                    c5 = 1;
                    setTimeout(() => { c5 = 0; }, 700);
                }
                if (s10 !== value.tieBackSize1) {
                    settieBackSize1(value.tieBackSize1);
                    s10 = value.tieBackSize1;
                }

                if (s11 !== value.tieLayRate1) {
                    settieLayRate1(value.tieLayRate1);
                    s11 = value.tieLayRate1;
                    c6 = 1;
                    setTimeout(() => { c6 = 0; }, 700);
                }

                if (s12 !== value.tieLaySize1) {
                    settieLaySize1(value.tieLaySize1);
                    s12 = value.tieLaySize1;
                }


            }


            if (value[0] && value[0].messageType === 'session_rate') {
                let temp = value;

                if(value[0].skymode == 'skyRate'){
                    temp.sort(function(a,b){
                    return a.selectionId - b.selectionId;
                   }); 
                }

                setfancyData(temp);


            }

            if (value.messageType === 'bookmaker_match_rate') {

                let temp = { ...bookData };
                temp.messageType = value.messageType;
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieLayRate1 = value.tieLayRate1;

                console.log('book1',temp.gameStatus,temp.runner1Status,temp.runner2Status);
                setbookData(temp);

            }
            // Completed_Match_rate

            if (value.messageType === 'Super_over_match') {

                setSupmarketBetStatus(value.status);
                // console.log(value.status, 'dsfg');
                //  if(value.status == '0' && liquidityStatus == 0) {
                //      setliquidityStatus(1);

                //  }
                if (s13 !== value.runner1BackRate1) {
                    setSuprunner1BackRate1(value.runner1BackRate1);
                    s13 = value.runner1BackRate1;
                    c7 = 1;
                    setTimeout(() => { c7 = 0; }, 700);
                }


                if (s14 !== value.runner1BackSize1) {
                    setSuprunner1BackSize1(value.runner1BackSize1);
                    s14 = value.runner1BackSize1;
                }

                if (s15 !== value.runner1LayRate1) {
                    setSuprunner1LayRate1(value.runner1LayRate1);
                    s15 = value.runner1LayRate1;
                    c8 = 1;
                    setTimeout(() => { c8 = 0; }, 700);
                }

                if (s16 !== value.runner1LaySize1) {
                    setSuprunner1LaySize1(value.runner1LaySize1);
                    s16 = value.runner1LaySize1;
                }

                if (s17 !== value.runner2BackRate1) {
                    setSuprunner2BackRate1(value.runner2BackRate1);
                    s17 = value.runner2BackRate1;
                    c9 = 1;
                    setTimeout(() => { c9 = 0; }, 700);
                }

                if (s18 !== value.runner2BackSize1) {
                    setSuprunner2BackSize1(value.runner2BackSize1);
                    s18 = value.runner2BackSize1;
                }

                if (s19 !== value.runner2LayRate1) {
                    setSuprunner2LayRate1(value.runner2LayRate1);
                    s19 = value.runner2LayRate1;
                    c10 = 1;
                    setTimeout(() => { c10 = 0; }, 700);
                }

                if (s20 !== value.runner2LaySize1) {
                    setSuprunner2LaySize1(value.runner2LaySize1);
                    s20 = value.runner2LaySize1;

                }

            }


            if (value.messageType === 'Completed_Match_rate') {

                setCommarketBetStatus(value.status);

                //  if(value.status == '0' && liquidityStatus == 0) {
                //      setliquidityStatus(1);

                //  }
                if (s13 !== value.runner1BackRate1) {
                    setComrunner1BackRate1(value.runner1BackRate1);
                    s13 = value.runner1BackRate1;
                    c11 = 1;
                    setTimeout(() => { c11 = 0; }, 700);
                }


                if (s21 !== value.runner1BackSize1) {
                    setComrunner1BackSize1(value.runner1BackSize1);
                    s21 = value.runner1BackSize1;
                }

                if (s22 !== value.runner1LayRate1) {
                    setComrunner1LayRate1(value.runner1LayRate1);
                    s22 = value.runner1LayRate1;
                    c12 = 1;
                    setTimeout(() => { c12 = 0; }, 700);
                }

                if (s23 !== value.runner1LaySize1) {
                    setComrunner1LaySize1(value.runner1LaySize1);
                    s23 = value.runner1LaySize1;
                }

                if (s24 !== value.runner2BackRate1) {
                    setComrunner2BackRate1(value.runner2BackRate1);
                    s24 = value.runner2BackRate1;
                    c13 = 1;
                    setTimeout(() => { c13 = 0; }, 700);
                }

                if (s25 !== value.runner2BackSize1) {
                    setComrunner2BackSize1(value.runner2BackSize1);
                    s25 = value.runner2BackSize1;
                }

                if (s26 !== value.runner2LayRate1) {
                    setComrunner2LayRate1(value.runner2LayRate1);
                    s26 = value.runner2LayRate1;
                    c14 = 1;
                    setTimeout(() => { c14 = 0; }, 700);
                }

                if (s27 !== value.runner2LaySize1) {
                    setComrunner2LaySize1(value.runner2LaySize1);
                    s27 = value.runner2LaySize1;

                }

            }


            if (value.messageType === 'Tied_Match_Rate') {

                setTiemarketBetStatus(value.status);

                //  if(value.status == '0' && liquidityStatus == 0) {
                //      setliquidityStatus(1);

                //  }
                if (s28 !== value.runner1BackRate1) {
                    setTierunner1BackRate1(value.runner1BackRate1);
                    s28 = value.runner1BackRate1;
                    c15 = 1;
                    setTimeout(() => { c15 = 0; }, 700);
                }


                if (s29 !== value.runner1BackSize1) {
                    setTierunner1BackSize1(value.runner1BackSize1);
                    s29 = value.runner1BackSize1;
                }

                if (s30 !== value.runner1LayRate1) {
                    setTierunner1LayRate1(value.runner1LayRate1);
                    s30 = value.runner1LayRate1;
                    c16 = 1;
                    setTimeout(() => { c16 = 0; }, 700);
                }

                if (s31 !== value.runner1LaySize1) {
                    setTierunner1LaySize1(value.runner1LaySize1);
                    s31 = value.runner1LaySize1;
                }

                if (s32 !== value.runner2BackRate1) {
                    setTierunner2BackRate1(value.runner2BackRate1);
                    s32 = value.runner2BackRate1;
                    c17 = 1;
                    setTimeout(() => { c17 = 0; }, 700);
                }

                if (s33 !== value.runner2BackSize1) {
                    setTierunner2BackSize1(value.runner2BackSize1);
                    s33 = value.runner2BackSize1;
                }

                if (s34 !== value.runner2LayRate1) {
                    setTierunner2LayRate1(value.runner2LayRate1);
                    s34 = value.runner2LayRate1;
                    c18 = 1;
                    setTimeout(() => { c18 = 0; }, 700);
                }

                if (s35 !== value.runner2LaySize1) {
                    setTierunner2LaySize1(value.runner2LaySize1);
                    s35 = value.runner2LaySize1;

                }

                // if(s21 !== value.tieBackRate1  ){ 
                //      setTieBackRate1(value.tieBackRate1);
                //      s21 = value.tieBackRate1;
                //      c11 = 1;
                //      setTimeout(()=>{c11 = 0;},  700);
                //     }
                // if(s22 !== value.tieBackSize1  ){ 
                //      setTieBackSize1(value.tieBackSize1);
                //      s22 = value.tieBackSize1;
                //     }

                // if(s23 !== value.tieLayRate1 ){ 
                //      setTieLayRate1(value.tieLayRate1);
                //      s23 = value.tieLayRate1;
                //      c12 = 1;
                //      setTimeout(()=>{c12 = 0;},  700);
                //     }

                // if(s24 !== value.tieLaySize1){ 
                //      setTieLaySize1(value.tieLaySize1);
                //      s24 = value.tieLaySize1;
                //     }

            }

            // if (value.messageType === 'match_score') {
            //     let temp = value;
            //     setScoreData(temp);
                
            // }
        })

        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner2BackRate1(' ');
            setrunner1LayRate1(' '); setrunner2LayRate1(' ');
            setrunner1BackSize1(' '); setrunner2BackSize1(' ');
            setrunner1LaySize1(' '); setrunner2LaySize1(' ');

            setTierunner1BackRate1(' '); setTierunner2BackRate1(' ');
            setTierunner1LayRate1(' '); setTierunner2LayRate1(' ');
            setTierunner1BackSize1(' '); setTierunner2BackSize1(' ');
            setTierunner1LaySize1(' '); setTierunner2LaySize1(' ');

            setfancyData([]);
        }

    }, [])


        //completematch
        const changeCompMatchBook = (val1, val2, val3) => {

            if (!props.isLoggedIn) {
                props.checkShowLogin(true);
                return;
            }
            setdefaultStake(dStake);
            if (val2 === 2) {
    
                setComMatchclick(true);
                setComMatchclick2(false);
                setComMatchclick3(false);
                setteamName('B');
            }
            else if (val2 === 1) {
    
                setComMatchclick2(true);
                setComMatchclick(false);
                setComMatchclick3(false);
                setteamName('A');
            }
            else if (val2 === 3) {
    
               setComMatchclick3(true);
               setComMatchclick2(false);
               setComMatchclick(false);
                setteamName('T');
            }
            setselectCompMatchBack(val1);
            setCOdds(val3);
    
            if (val1 === 1 || val1 === 3 || val1 === 5) {
                setoddsType('LAGAI');
            }
            else if (val1 === 2 || val1 === 4 || val1 === 6) {
                setoddsType('KHAI');
            }
    
        }
    //super overmatch
    const changeSuperMatchBook = (val1, val2, val3) => {
    
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (val2 === 2) {
    
            setSuperMatchclick(true);
            setSuperMatchclick2(false);
            setSuperMatchclick3(false);
            setteamName('B');
        }
        else if (val2 === 1) {
    
            setSuperMatchclick2(true);
            setSuperMatchclick(false);
            setSuperMatchclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {
    
            setSuperMatchclick3(true);
            setSuperMatchclick2(false);
            setSuperMatchclick(false);
            setteamName('T');
        }
        setselectSuperMatchBack(val1);
        setSOdds(val3);
    
        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }
    
    }
    //Tie match
        const changeTieMatchBook = (val1, val2, val3) => {
    
            if (!props.isLoggedIn) {
                props.checkShowLogin(true);
                return;
            }
            setdefaultStake(dStake);
            if (val2 === 2) {
    
                setTieMatchclick(true);
                setTieMatchclick2(false);
                setTieMatchclick3(false);
                setteamName('B');
            }
            else if (val2 === 1) {
    
                setTieMatchclick2(true);
                setTieMatchclick(false);
                setTieMatchclick3(false);
                setteamName('A');
            }
            else if (val2 === 3) {
    
                setTieMatchclick3(true);
                setTieMatchclick2(false);
                setTieMatchclick(false);
                setteamName('T');
            }
            setselectTieMatchBack(val1);
            setTOdds(val3);
    
            if (val1 === 1 || val1 === 3 || val1 === 5) {
                setoddsType('LAGAI');
            }
            else if (val1 === 2 || val1 === 4 || val1 === 6) {
                setoddsType('KHAI');
            }
    
        }

    const changeBook = (val1, val2, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (val2 === 2) {

            setBookclick(true);
            setBookclick2(false);
            setBookclick3(false);
            setteamName('B');
        }
        else if (val2 === 1) {

            setBookclick2(true);
            setBookclick(false);
            setBookclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {

            setBookclick3(true);
            setBookclick2(false);
            setBookclick(false);
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeMatchodds = (val1, val2, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setdefaultStake(dStake);
        if (val2 === 2) {
            setMatchoddsclick(true);
            setMatchoddsclick2(false);
            setMatchoddsclick3(false);
            setteamName('B');

        }
        else if (val2 === 1) {
            setMatchoddsclick2(true);
            setMatchoddsclick(false);
            setMatchoddsclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {
            setMatchoddsclick3(true);
            setMatchoddsclick2(false);
            setMatchoddsclick(false);

            setteamName('T');
        }

        setselectMatchBack(val1);
        setmOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }



    }
    const closeMatchoddsBet = () => {

        setselectMatchBack(0);
        setMatchoddsclick2(false);
        setMatchoddsclick(false)
        setMatchoddsclick3(false);


    }
    const closeBookBet = () => {

        setselectBack(0);
        setBookclick2(false);
        setBookclick(false);
        setBookclick3(false);


    }


    const closeManualBet = () => {
        setselectManualBack(0);
        setManualclick2(false);
        setManualclick(false);
        setManualclick3(false);
    }
    const closeTieMatchBet = () => {
        setselectTieMatchBack(0);
        setTieMatchclick2(false);
        setTieMatchclick(false);
        setTieMatchclick3(false);
    }
    const closeCompMatchBet = () => {
        setselectCompMatchBack(0);
        setComMatchclick2(false);
        setComMatchclick(false);
        setComMatchclick3(false);
    }
    const closeSuperMatchBet = () => {
        setselectSuperMatchBack(0);
        setSuperMatchclick2(false);
        setSuperMatchclick(false);
        setSuperMatchclick3(false);
    }


    const changeDefaultStake = (event) => {
        setdefaultStake(event.target.value);
    }

    useEffect(() => {
        //console.log(defaultStake);

        if (Matchoddsclick || Matchoddsclick2 || Matchoddsclick3) {

            var amount = defaultStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(mOdds - 1);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(mOdds - 1);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(mOdds - 1);


                }

            }

            var obj = {};
            if(win_teamA.toFixed(2)=='NaN')obj.teamA=0;     else obj.teamA = win_teamA.toFixed(2);
            if(win_teamB.toFixed(2)=='NaN')obj.teamB=0;     else obj.teamB = win_teamB.toFixed(2);
            if(win_tie.toFixed(2)=='NaN')obj.tie=0;         else obj.tie = win_tie.toFixed(2);

            //obj.teamA = win_teamA.toFixed(2);
           // obj.teamB = win_teamB.toFixed(2);
           // obj.tie = win_tie.toFixed(2);

           
            setpnlMarket2(obj);



        }

        else if (Bookclick || Bookclick2 || Bookclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if(win_teamA.toFixed(2)=='NaN')obj.teamA=0;     else obj.teamA = win_teamA.toFixed(2);
            if(win_teamB.toFixed(2)=='NaN')obj.teamB=0;     else obj.teamB = win_teamB.toFixed(2);
            if(win_tie.toFixed(2)=='NaN')obj.tie=0;         else obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }
        //super over match
        else if (SuperMatchclick || SuperMatchclick2 || SuperMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlSuperMatch.teamA && pnlSuperMatch.teamB && pnlSuperMatch.tie && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0)) {
                win_teamA = parseFloat(pnlSuperMatch.teamA);
                win_teamB = parseFloat(pnlSuperMatch.teamB);
                win_tie = parseFloat(pnlSuperMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(SOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(SOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(SOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if(win_teamA.toFixed(2)=='NaN')obj.teamA=0;     else obj.teamA = win_teamA.toFixed(2);
            if(win_teamB.toFixed(2)=='NaN')obj.teamB=0;     else obj.teamB = win_teamB.toFixed(2);
            if(win_tie.toFixed(2)=='NaN')obj.tie=0;         else obj.tie = win_tie.toFixed(2);

            setpnlSuperMatch2(obj);
        }
        //complate match
        else if (ComMatchclick || ComMatchclick2 || ComMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlCompMatch.teamA && pnlCompMatch.teamB && pnlCompMatch.tie && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0)) {
                win_teamA = parseFloat(pnlCompMatch.teamA);
                win_teamB = parseFloat(pnlCompMatch.teamB);
                win_tie = parseFloat(pnlCompMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(COdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(COdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(COdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if(win_teamA.toFixed(2)=='NaN')obj.teamA=0;     else obj.teamA = win_teamA.toFixed(2);
            if(win_teamB.toFixed(2)=='NaN')obj.teamB=0;     else obj.teamB = win_teamB.toFixed(2);
            if(win_tie.toFixed(2)=='NaN')obj.tie=0;         else obj.tie = win_tie.toFixed(2);

            setpnlCompMatch2(obj);
        }
        //Tie match
        else if (TieMatchclick || TieMatchclick2 || TieMatchclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlTieMatch.teamA && pnlTieMatch.teamB && pnlTieMatch.tie && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0)) {
                win_teamA = parseFloat(pnlTieMatch.teamA);
                win_teamB = parseFloat(pnlTieMatch.teamB);
                win_tie = parseFloat(pnlTieMatch.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(TOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(TOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(TOdds / 100);


                }

            }

            var obj = {};
            // obj.teamA = win_teamA.toFixed(2);
            // obj.teamB = win_teamB.toFixed(2);
            // obj.tie = win_tie.toFixed(2);
            if(win_teamA.toFixed(2)=='NaN')obj.teamA=0;     else obj.teamA = win_teamA.toFixed(2);
            if(win_teamB.toFixed(2)=='NaN')obj.teamB=0;     else obj.teamB = win_teamB.toFixed(2);
            if(win_tie.toFixed(2)=='NaN')obj.tie=0;         else obj.tie = win_tie.toFixed(2);

            setpnlTieMatch2(obj);
        }

    }, [defaultStake])





    const handlebookpin = () => {
        setbookpin(!bookpin)
    }
    const handlefancypin = () => {
        setFancypin(!Fancypin)
    }

    const handlelivempin = () => {
        setlivemarpin(!livemarpin)
    }

    const OpenfancyBet = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancy(1)
        setfield(1);

    }

    const OpenfancyBetLay = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }
    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleMaxStake = () => {
        setMaxstake(true)
    }

    const handleMaxStakeclose = () => {
        setMaxstake(false)
    }

    const handleFancyBook = (val) => {
        setshowFancyBook(val);
    }


    const handleRefresh = () => {

        const utoken = cookies.get('token');
        const user = cookies.get('id');
        Axios.post('https://millionbet247.com/api/client/clientrefresh', {
            id: user,
            token: utoken
        })
            .then(result => {
                if (result.status === 210) {

                    props.changeBalance(result.data[0].val - Math.abs(result.data[1].val));
                    if (result.data[1].val) {

                        props.changeExposure(Math.abs(result.data[1].val));
                    }
                    else {
                        props.changeExposure(0);
                    }
                }

                if (result.status === 220) {
                  
                 

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    };

    const plMarket = () => {

        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId

        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlMarket(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }

    const plComMarket = () => {

        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: CompMatchData.marketId
        })
            .then(result => {
                if (result.status === 210) {
                    let obj = result.data;
                    setpnlCompMatch(obj);
                }
                if (result.status === 220) {
                    console.log('refresh unsucessful');
                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }
// super over match

const plSuperMarket = () => {

    if (!props.userid) return;
    Axios.post('https://millionbet247.com/api/client/clientplmarket', {
        id: props.userid,
        token: props.utoken,
        eventId: eventid,
        marketId: SuperMatchData.marketId
    })
        .then(result => {
            if (result.status === 210) {
                let obj = result.data;
                setpnlSuperMatch(obj);
            }
            if (result.status === 220) {
                console.log('refresh unsucessful');
            }
        }

        ).catch(e => {
            //  setIsError(true);
        });
}
// Tie match
const plTieMarket = () => {

    if (!props.userid) return;
    Axios.post('https://millionbet247.com/api/client/clientplmarket', {
        id: props.userid,
        token: props.utoken,
        eventId: eventid,
        marketId: TieMatchData.marketId
    })
        .then(result => {
            if (result.status === 210) {
                let obj = result.data;
                setpnlTieMatch(obj);
            }
            if (result.status === 220) {
                console.log('refresh unsucessful');
            }
        }

        ).catch(e => {
            //  setIsError(true);
        });
}

    const plBook = () => {
        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlBook(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });

    }



    const plFancy = () => {
        if (!props.userid || !eventid) return;
        Axios.post('https://millionbet247.com/api/client/clientplfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);


                }

                if (result.status === 220) {
                    // console.log('fancy Pnl unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }



    useEffect(() => {

        if (marketData.matchStatus == 1) plMarket();
        if (bookData2.matchStatus == 1) plBook();
        if (CompMatchdata2.matchStatus == 1) plComMarket();
        if (SuperMatchdata2.matchStatus == 1) plSuperMarket();
        if (TieMatchdata2.matchStatus == 1) plTieMarket();

        return () => {

            setpnlMarket({});
            setpnlBook({});
            plComMarket({});
            plSuperMarket({});
            plTieMarket({})

        }
    }, [props.userid, bookData2.marketId,CompMatchdata2.marketId,SuperMatchdata2.marketId,TieMatchdata2.marketId ,marketData.marketId])




    useEffect(() => {
        plFancy();

    }, [props.userid, eventid])


    const fancyBet = (selectionId, fld, runnerName) => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');
        var oddT;
        if (fld === 1) {
            oddT = 'NO';
        }
        else if (fld === 2) {
            oddT = 'YES';
        }

        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: selectionId, //fancy selection id
            odds: fOdds,                  //run 
            oddsType: oddT,           //YES NO
            betType: 'fancy',            //fancy
            coin: defaultStake,
            teamName: fsize,          //rate 110,100
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddT, runner: runnerName, amount: defaultStake, rate: fOdds + '/' + fsize });
                    handleRefresh();
                    plFancy();
                }

                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == 8) {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }



            }

            ).catch(e => {
                console.log(e);

            });

    };




    const bookBet = () => {

        if (!props.userid) {
            return;
        }

        var dupBet = cookies.get('dupBet');
        //console.log(Math.round(((bOdds/100) + 1 + Number.EPSILON) * 100) / 100);

        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId,
            odds: parseFloat(bOdds / 100 + 1).toFixed(2).toString(),
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? bookData2.runnerName1 : bookData2.runnerName2, amount: defaultStake, rate: bOdds });
                    handleRefresh();
                    plBook();
                }

                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == 8) {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });

    };



    const marketBet = () => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');

        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId,
            odds: mOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet


        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2, amount: defaultStake, rate: mOdds });
                    handleRefresh();
                    plMarket();
                }
                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == '8') {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });
    };



    //SuperMAtch

    const SuperMatchBet = () => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');

        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: SuperMatchData.marketId,
            odds: SOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet


        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(1500, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? SuperMatchData.runnerName1 : SuperMatchData.runnerName2, amount: defaultStake, rate: mOdds });
                    handleRefresh();
                    plSuperMarket();
                }
                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if(result.data == 20){
                    var obj = {};
                   
                    obj.msgNumber = 2;
                    obj.msg = 'Odds Chnanged!';
                    props.changebetMsg(obj);
              
                    setTimeout(()=>{
                        props.changebetMsg({});
                    },2000);
                }
                else if (result.data.charAt(0) == '8') {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });
    };

    //comMatch

    const ComMatchBet = () => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');

        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: CompMatchData.marketId,
            odds: COdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet


        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(1500, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? CompMatchData.runnerName1 : CompMatchData.runnerName2, amount: defaultStake, rate: mOdds });
                    handleRefresh();
                    plComMarket();
                }
                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if(result.data == 20){
                    var obj = {};
                   
                    obj.msgNumber = 2;
                    obj.msg = 'Odds Chnanged!';
                    props.changebetMsg(obj);
              
                    setTimeout(()=>{
                        props.changebetMsg({});
                    },2000);
                }
                else if (result.data.charAt(0) == '8') {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });
    };
// TieMatch

const TieMatchBet = () => {

    if (!props.userid) {
        return;
    }
    var dupBet = cookies.get('dupBet');

    Axios.post('https://millionbet247.com/api/placebet/placebets', {

        clientId: props.userid,
        token: props.utoken,
        eventId: eventid,
        marketId: TieMatchData.marketId,
        odds: TOdds,
        oddsType: oddsType,
        betType: 'match',
        coin: defaultStake,
        teamName: teamName,
        dupbet: dupBet


    }, { withCredentials: true }
    )
        .then(result => {
            props.handleRefreshhh(1500, 'Placing bet...', 2);
            if (result.data == 6) {
                props.changeMsgNumber(1);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);

                props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? TieMatchData.runnerName1 : TieMatchData.runnerName2, amount: defaultStake, rate: mOdds });
                handleRefresh();
                plTieMarket();
            }
            else if (result.data == 1) {
                props.changeMsgNumber(3);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }

            else if (result.data == 2) {
                props.changeMsgNumber(4);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 3) {
                props.changeMsgNumber(5);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 4) {
                props.changeMsgNumber(6);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 5) {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 7) {
                props.changeMsgNumber(8);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 123) {
                props.changeMsgNumber(9);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if (result.data == 13) {
                props.changeMsgNumber(10);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }
            else if(result.data == 20){
                var obj = {};
               
                obj.msgNumber = 2;
                obj.msg = 'Odds Chnanged!';
                props.changebetMsg(obj);
          
                setTimeout(()=>{
                    props.changebetMsg({});
                },2000);
            }
            else if (result.data.charAt(0) == '8') {
                props.changeMsgNumber(11);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            }
            else if (result.data.charAt(0) == 9) {
                props.changeMsgNumber(12);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
                props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
            }
            else {
                props.changeMsgNumber(7);
                setTimeout(() => {
                    props.changeMsgNumber(0);
                }, 1500);
            }


        }

        ).catch(e => {
            console.log(e);

        });
};

//marketConfirmBox
    const marketBetClicked = () => {
        setshowConfirmBet(true);
    }
    const closeConfirmBet = () => {
        setshowConfirmBet(false);
    }

    //TieMatch ConfirmBox
    const TieMatchBetClicked = () => {
        setshowTieConfirmBet(true);
    }
    const TieMatchcloseConfirmBet = () => {
        setshowTieConfirmBet(false);
    }

    //superMatch confrimBox

    const SuperMatchBetClicked = () => {
        setshowSupConfirmBet(true);
    }
    const SuperMatchcloseConfirmBet = () => {
        setshowSupConfirmBet(false);
    }

    //Com match

    const ComMatchBetClicked = () => {
        setshowComConfirmBet(true);
    }
    const ComMatchcloseConfirmBet = () => {
        setshowComConfirmBet(false);
    }

    const handleNewRefresh = () => {
        setloading(true);
        setTimeout(() => { setloading(false); }, 600);
    }
   

 
    useEffect(() => {
      
        if(ActiveTeam == '1'){
            Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/m` + props.liveStream).then((result) => {
              
                 var MatchId = result.data.queryUrl;
                 setgetScoreId(result.data.doc[0].event);
                   var str = MatchId;
                    var matches = str.match(/(\d+)/);
                         var ScoreId=matches[0];
        
                    window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", {layout: "double", scoreboard: "extended", momentum: "bars", collapseTo: "momentum", matchId:ScoreId});
                    }).catch((err) => {
                    console.log(err);
                    })
                   
                }
       
           else if(ActiveTeam == '0' || getScoreId === 'exception'){
                    if(props.liveStream){
                        Axios.get(`https://widgets.fn.sportradar.com/common/en/Etc:UTC/cricket/get_event/` + ScoreData).then((result) => {
                        
                            var MatchId = result.data.queryUrl;
                            setgetSecScoreId(result.data.doc[0].event);
                            var str = MatchId;
                                var matches = str.match(/(\d+)/);
                                    var ScoreId=matches[0];
    
                    window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", {layout: "double", scoreboard: "extended", momentum: "bars", collapseTo: "momentum", matchId:ScoreId});         
                    }).catch((err) => {
                    console.log(err);
                    })
                    }
                } 
    }, [ActiveTeam])
           
    console.log(bookData.gameStatus,bookData.runner1Status,bookData.runner2Status);

    return (
        <React.Fragment>

{loading === true &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>}
     

            {newrefresh === true &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>}

                {/* liveStream && liveStream != '' && */}
        { props.Tvopen && moment() >= moment(marketStartTime) &&  props.isLoggedIn === true && 
        
 <div id="streamingBox" class="tv-fix" style={props.isLoggedIn?{top:'21.6vw'}:{top:'14.6vw'}} onPointerEnter={()=>{props.TvControl()}}>
	<div class="tv-wrap">
		<div id="streamContainer" class="tv-box" >
			<div id="tv" class="tv">

				{/* {isShown && Tvopen && */}
				<ul class="tv-control">
					<li><a class="close" id="closeStreamingBox" onClick={()=>{props.ChangeSetTv()}}>Close</a></li>
				</ul>
			 {/* }  */}
				<ul class="tv-control">
					<li>
					
						<a class="btn-volume_off" id="mute" style={{display:' none'}}>Close</a>
					</li>
					<li>
						<a class="btn-line" id="changeLineBtn" style={{display:' none'}}><span id="tvLine">1</span></a>
					</li>
				</ul>
			
            
        	<iframe style={{height:'100%',width:'100%', border:0}} 
            autoplay="" preload="" playsinline=""
          title="mob-live-stream"
        allowFullScreen={false}
           src={`https://vid.dreamcasino.live/GetAPI.html?MatchID=${props.liveStream}`}
         sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
         </iframe>
            </div>
		</div>
	</div>
</div>
}

                <div id="matchTrackerWrap" class="match-tracker-wrap" >
 
                { getSecScoreId != 'exception' ? (
            <div class="widgets">
                        <div><div class="sr-widget sr-widget-1"></div></div>
                    </div>):
            ( 
            
<div className="game-wrap">
  <h4 id="gameInfo" className="game-info">Cricket<ul id="infoIcon" className="info-icon">
      <li id="inPlayIcon" style={{display: 'none'}}>
        <img className="info-inplay" src="/images/mobile/transparent.gif" />In-Play
      </li>
      <li style={{display: 'none'}}><img className="info-cashout" src="/images/mobile/transparent.gif" />
        Cash Out
      </li>
      <li id="fancyBetIcon" style={{display: 'none'}}><span className="game-fancy">Fancy</span></li>
      <li id="bookMakerIcon" style={{display: 'none'}}><span className="game-bookmaker">BookMaker</span></li>
      <li id="feedingSiteIcon" style={{display: 'none'}}><span className="game-sportsbook">Sportsbook</span></li>
      <li><span id="lowLiquidityTag" className="game-low_liq" style={{display: 'none'}}>Low</span></li>
    </ul></h4>
  <table id="gameTeam" className="game-team" style={{}}>
    <tbody><tr>
        <th style={{color:'#fff'}}>
          <a id="multiMarketPin" className="pin-off" href="#" />
          <h4 id="teamHome">{marketData.runnerName1}</h4>
          <h4 id="teamAway">{marketData.runnerName2}</h4>
          <ul id="time" className="scores-time"><li>{marketStartTime}</li></ul>
        </th>
        <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
      </tr>
    </tbody></table>
</div>
            )}
       
    <ul class="match-btn">
        <li><a id="liveMultiMarketPin" onClick={handlelivempin} style={{cursor:'pointer'}} name="gameHeadPin"  className={`btn-pin ${livemarpin ? "select" : ""}`} title="Add to Multi Markets" eventtype="4" eventid="30062362" marketid="1.174202586">Pin</a></li>
        <li><a style={{cursor:'pointer'}} onClick={()=>{props.handleRefreshhh();}} class="btn-refresh">Refresh</a></li>
    </ul>
    
   </div>


            { newrefresh === false && <React.Fragment> 
               { showConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={mOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2} closeConfirmBet={closeConfirmBet} marketBet={marketBet} />}
                    {/* TieMarket */}
                    { showTieConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={TOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? 'Yes' : 'No'} closeConfirmBet={TieMatchcloseConfirmBet} marketBet={TieMatchBet} />}
                   {/* // ComMarket */}
                   { showComConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={COdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? 'Yes' : 'No'} closeConfirmBet={ComMatchcloseConfirmBet} marketBet={ComMatchBet} />}
                   {/* //SuperMarket */}
                   { showSupConfirmBet && <ConfirmBet handleRefreshhh={props.handleRefreshhh} mOdds={SOdds} coin={defaultStake} oddsType={oddsType === 'LAGAI' ? 'BACK' : 'LAY'} runnerName={teamName === 'A' ? SuperMatchData.runnerName1 : SuperMatchData.runnerName2} closeConfirmBet={SuperMatchcloseConfirmBet} marketBet={SuperMatchBet} />}
                <div id="mainWrap" className="mian-wrap"  eventtype={4} eventid={31102920} marketid="1.192573657">

                    <div id="naviMarket" className="market-type ps ps--theme_default" data-ps-id="dd7c990b-079c-38b8-0677-cc1ec473ee97">
                        {eventT == 4 &&
                            <ul id="naviMarketList">

                                <li id="naviMarket_1_192573657" onClick={() => { props.handleRefreshhh(500); sethighlightTab(0) }} className={`${(highlightTab === 0) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                    <a >Match Odds</a></li>

                                {CompMatchData.matchStatus === '1' &&
                                    <li id="naviMarket_1_192573659" href="" onClick={() => { props.handleRefreshhh(500); sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                        <a >Completed Match</a></li>}

                                        {TieMatchData.matchStatus === '1' &&
                                    <li id="naviMarket_1_192573659" onClick={() => { props.handleRefreshhh(500); sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                        <a >Tied Match</a></li>}

                                {SuperMatchData.matchStatus === '1' &&
                                    <li id="naviMarket_1_192573659" onClick={() => { props.handleRefreshhh(500); sethighlightTab(3) }} className={`${(highlightTab === 3) ? "select" : "null"}`} style={{ display: 'list-item' }}>
                                        <a >Super Over</a></li>}
                            </ul>}

                        {eventT == 1 &&
                            <ul id="naviMarketList">
                                <li id="naviMarketTemaplate" style={{ display: 'none' }}><a href="#" /></li>
                                <li id="naviMarket_1_192656073" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 1) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(1)}}  ><a >Match Odds</a></li>
                                {/* 
                                <li id="naviMarket_1_192656077" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 2) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(2)}}><a >Over/Under 0.5 Goals</a></li>
                                <li id="naviMarket_1_192656121" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 3) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(3)}}><a >Over/Under 1.5 Goals</a></li>
                                <li id="naviMarket_1_192656118" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 4) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(4)}}><a >Over/Under 2.5 Goals</a></li>
                                <li id="naviMarket_1_192656122" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 5) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(5)}}><a >Over/Under 3.5 Goals</a></li>
                                <li id="naviMarket_1_192656119" style={{ display: 'list-item' }}  className={`${(SoccerMenutab == 6) ? "select" : "null"}`} onClick={()=>{setSoccerMenutab(6)}}><a >Over/Under 4.5 Goals</a></li>
                                */}
                            </ul>}

                        {eventT == 2  &&
                            <ul id="naviMarketList">
                                <li id="naviMarket_1_192573657" style={{ display: 'list-item' }} className="select">
                                    <a >Match Odds</a></li>
                            </ul>}

                        <div className="ps__scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}>
                            <div className="ps__scrollbar-x" tabIndex={0} style={{ left: '0px', width: '0px' }} /></div>
                        <div className="ps__scrollbar-y-rail" style={{ top: '0px', right: '0px' }}>
                            <div className="ps__scrollbar-y" tabIndex={0} style={{ top: '0px', height: '0px' }} /></div>
                    </div>

                    {/* {TiedCric === 2 && <>
                    <React.Fragment> */}
                    {loading === false &&
                        <React.Fragment>
                            { highlightTab === 0 && 

                                <div id="marketBetsWrap" style={(marketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(marketBetStatus != 1) ? "disabled" : ""}`}>

                                    {Maxstake === true &&
                                        <div id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                                            <a class="close" onClick={() => handleMaxStakeclose()} >Close</a>
                                            <dl>
                                                <dt id="minMaxDt" style={{ display: ' none' }}>Min / Max</dt>
                                                <dt id="maxDt" >Max</dt>
                                                <dd id="minMaxInfo">{parseInt(marketData.maxStack) * coinFactor}</dd>
                                            </dl>
                                        </div>}
                                    <dl id="betsHead" className="bets-selections-head">
                                        <dt>
                                            <a className="a-depth" id="marketDepthBtn">Markets Depth</a>
                                            <p><span>Matched</span> 
                                            {/* <strong id="totalMatched">USD  31,838</strong> */}
                                             </p>
                                        </dt>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                        <dd>Back </dd>
                                        <dd>Lay </dd>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                    </dl>
                                    {/* matchoodss runner1 */}
                                    { marketData.matchStatus == 1 &&  <React.Fragment>
                                     
                                        <dl id="selection_37302" className={`bets-selections ${(runner1BackRate1>=25 )? "disabled": "null"}`} style={runner1BackRate1 >=25 ? {pointerEvents:'none',display:'flex'}:{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">{marketData.runnerName1}</h4>
                                                <ul id="winLoss">
                                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamA > 0 ? "win" : "lose"}`} >{pnlMarket.teamA > 0 ? pnlMarket.teamA : '(' + Math.abs(pnlMarket.teamA) + ')'}</li>}
                                                    {(Matchoddsclick2 || Matchoddsclick || Matchoddsclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</li>}
                                                </ul>
                                            </dt>


                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (runner1BackRate1 != ' ' && runner1BackRate1 > 0) changeMatchodds(1, 1, runner1BackRate1); }}>
                                                <a className={`back-1 ${(selectMatchBack === 1) ? "select" : "null"} ${(c1 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{runner1BackRate1}<span>{runner1BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (runner1LayRate1 != ' ' && runner1LayRate1 > 0) changeMatchodds(2, 1, runner1LayRate1) }}>
                                                <a className={`lay-1 ${(selectMatchBack === 2) ? "select" : "null"} ${(c2 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{runner1LayRate1}<span>{runner1LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>
                                       
                                        {Matchoddsclick2 === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectMatchBack === 1) ? "back" : "lay"}`}>
                                                <ul className="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                            <span id="odds" class="typed">{mOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}} >Min Bet: {parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                </ul>



                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>



                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="placeBet" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* End matchoodss runner1 */}
                                        {/* matchoodss runner2 */}
                                        <dl id="selection_37302" className={`bets-selections ${(runner2BackRate1>=25 )? "disabled": "null"}`}  style={runner2BackRate1 >=25 ? {pointerEvents:'none',display:'flex'}:{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">{marketData.runnerName2}</h4>
                                                <ul id="winLoss">
                                                    {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</li>}

                                                    {(Matchoddsclick2 || Matchoddsclick || Matchoddsclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</li>}
                                                </ul>
                                            </dt>
                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (runner2BackRate1 != ' ' && runner2BackRate1 > 0) changeMatchodds(3, 2, runner2BackRate1) }}>
                                                <a className={`back-1 ${(selectMatchBack === 3) ? "select" : "null"} ${(c3 === 1) ? "spark" : "null"}`} fullmarketodds="1.81">{runner2BackRate1}<span>{runner2BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (runner2LayRate1 != ' ' && runner2LayRate1 > 0) changeMatchodds(4, 2, runner2LayRate1) }}>
                                                <a className={`lay-1 ${(selectMatchBack === 4) ? "select" : "null"} ${(c3 === 1) ? "spark" : "null"}`} fullmarketodds="1.82">{runner2LayRate1}<span>{runner2LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>
                                        {Matchoddsclick === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectMatchBack === 1) ? "back" : "lay"}`}>

                                                <ul class="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                            <span id="odds" class="typed">{mOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                </ul>


                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>
                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="placeBet" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* end matchoodss runner2 */}
                                        {/* matchoodss runner3 */}
                                        {marketData.runnerId3 && marketData.runnerId3 != ' ' &&
                                            <dl id="selection_37302" className={`bets-selections ${(tieBackRate1>=25 )? "disabled": "null"}`} style={tieBackRate1 >=25 ? {pointerEvents:'none',display:'flex'}:{ display: 'flex' }}>

                                                <dt>
                                                    <h4 id="runnerName">The Draw</h4>
                                                    <ul id="winLoss">

                                                        {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <li id="withoutBet" className={`${pnlMarket.tie > 0 ? "win" : "lose"}`} >{pnlMarket.tie > 0 ? pnlMarket.tie : '(' + Math.abs(pnlMarket.tie) + ')'}</li>}

                                                        {(Matchoddsclick2 || Matchoddsclick || Matchoddsclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlMarket2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.tie > 0 ? pnlMarket2.tie : '(' + Math.abs(pnlMarket2.tie) + ')'}</li>}

                                                    </ul>
                                                </dt>


                                                <dd id="back_3" className="mode-land">
                                                    <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                                </dd>
                                                <dd id="back_2" className="mode-land">
                                                    <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                                </dd>
                                                <dd id="back_1" onClick={() => { if (tieBackRate1 != ' ' && tieBackRate1 > 0) changeMatchodds(5, 3, tieBackRate1) }}>
                                                    <a className={`back-1 ${(selectMatchBack === 5) ? "select" : "null"} ${(c5 === 1) ? "spark" : "null"}`} fullmarketodds="1.81">{tieBackRate1}<span>{tieBackSize1}</span></a>
                                                </dd>
                                                <dd id="lay_1" onClick={() => { if (tieLayRate1 != ' ' && tieLayRate1 > 0) changeMatchodds(6, 3, tieLayRate1) }}>
                                                    <a className={`lay-1 ${(selectMatchBack === 6
                                                    ) ? "select" : "null"} ${(c6 === 1) ? "spark" : "null"}`} fullmarketodds="1.82">{tieLayRate1}<span>{tieLaySize1}</span></a>
                                                </dd>
                                                <dd id="lay_2" className="mode-land">
                                                    <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                                </dd>
                                                <dd id="lay_3" className="mode-land">
                                                    <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                                </dd>
                                            </dl>
                                        }

                                        {Matchoddsclick3 === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectMatchBack === 1) ? "back" : "lay"}`}>
                                                <ul className="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                            <span id="odds" class="typed">{mOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (mOdds != '' || mOdds != ' ') setmOdds(parseFloat((parseFloat(mOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(marketData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(marketData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                </ul>
                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>
                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="placeBet" onClick={() => { marketBetClicked(); closeMatchoddsBet(); }} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* end matchoodss runner3 */}
                                    </React.Fragment>}
                                </div>
                            }

                         {/* tierate componant */}
                         {highlightTab === 2 && 

                                <div id="marketBetsWrap" style={(TiemarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(TiemarketBetStatus != 1) ? "disabled" : ""}`}>

                                    {Maxstake === true &&
                                        <div id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                                            <a class="close" onClick={() => handleMaxStakeclose()} >Close</a>
                                            <dl>
                                                <dt id="minMaxDt" style={{ display: ' none' }}>Min / Max</dt>
                                                <dt id="maxDt" >Max</dt>
                                                <dd id="minMaxInfo">{parseInt(TieMatchData.maxStack) * coinFactor}</dd>
                                            </dl>
                                        </div>}
                                    <dl id="betsHead" className="bets-selections-head">
                                        <dt>
                                            <a className="a-depth" id="marketDepthBtn">Markets Depth</a>
                                            <p><span>Matched</span> 
                                            {/* <strong id="totalMatched">USD  31,838</strong>  */}
                                            </p>
                                        </dt>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                        <dd>Back </dd>
                                        <dd>Lay </dd>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                    </dl>
                                    {/* matchoodss runner1 */}
                                    {TieMatchData.matchStatus == 1 && <React.Fragment>
                                        <dl id="selection_37302" className="bets-selections" style={{ display: 'flex' }}>
                                        <dd id="suspend" class="suspend" style={TiemarketBetStatus != 1 ?{display:'flex'}:{ display: 'none' }}><p>Suspend
                                                  </p></dd>
                                            <dt>
                                                <h4 id="runnerName">Yes</h4>
                                                <ul id="winLoss">
                                                    {pnlTieMatch.teamA && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0) && <li id="withoutBet" className={`${pnlTieMatch.teamA > 0 ? "win" : "lose"}`} >{pnlTieMatch.teamA > 0 ? pnlTieMatch.teamA : '(' + Math.abs(pnlTieMatch.teamA) + ')'}</li>}
                                                    {(TieMatchclick2 || TieMatchclick || TieMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlTieMatch2.teamA > 0 ? pnlTieMatch2.teamA : '(' + Math.abs(pnlTieMatch2.teamA) + ')'}</li>}
                                                </ul>
                                            </dt>


                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Tierunner1BackRate1 != ' ' && Tierunner1BackRate1 > 0) changeTieMatchBook(1, 1, Tierunner1BackRate1); }}>
                                                <a className={`back-1 ${(selectTieMatchBack === 1) ? "select" : "null"} ${(c7 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{Tierunner1BackRate1}<span>{Tierunner1BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Tierunner1LayRate1 != ' ' && Tierunner1LayRate1 > 0) changeTieMatchBook(2, 1, Tierunner1LayRate1) }}>
                                                <a className={`lay-1 ${(selectTieMatchBack === 2) ? "select" : "null"} ${(c8 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{Tierunner1LayRate1}<span>{Tierunner1LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>

                                        {TieMatchclick2 === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectTieMatchBack === 1) ? "back" : "lay"}`}>
                                                <ul className="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (TOdds != '' || TOdds != ' ') setTOdds(parseFloat((parseFloat(TOdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                            <span id="odds" class="typed">{TOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (TOdds != '' || TOdds != ' ') setTOdds(parseFloat((parseFloat(TOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(TieMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                </ul>



                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>



                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeTieMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {/* onClick = {()=>{marketBetClicked();closeMatchoddsBet();}} */}
                                                        <a id="placeBet" onClick = {()=>{TieMatchBetClicked();closeTieMatchBet();}} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* End matchoodss runner1 */}
                                        {/* matchoodss runner2 */}
                                        <dl id="selection_37302" className="bets-selections" style={{ display: 'flex' }}>
                                        <dd id="suspend" class="suspend" style={TiemarketBetStatus != 1 ?{display:'flex'}:{ display: 'none' }}><p>Suspend
                                                  </p></dd>
                                            <dt>
                                                <h4 id="runnerName">No</h4>
                                                <ul id="winLoss">
                                                    {pnlTieMatch.teamA && (pnlTieMatch.teamA != 0 || pnlTieMatch.teamB != 0 || pnlTieMatch.tie != 0) && <li id="withoutBet" className={`${pnlTieMatch.teamB > 0 ? "win" : "lose"}`} >{pnlTieMatch.teamB > 0 ? pnlTieMatch.teamB : '(' + Math.abs(pnlTieMatch.teamB) + ')'}</li>}

                                                    {(TieMatchclick2 || TieMatchclick || TieMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlTieMatch2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlTieMatch2.teamB > 0 ? pnlTieMatch2.teamB : '(' + Math.abs(pnlTieMatch2.teamB) + ')'}</li>}
                                                </ul>
                                            </dt>
                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Tierunner2BackRate1 != ' ' && Tierunner2BackRate1 > 0) changeTieMatchBook(3, 2, Tierunner2BackRate1) }}>
                                                <a className={`back-1 ${(selectTieMatchBack === 3) ? "select" : "null"} ${(c9 === 1) ? "spark" : "null"}`} fullmarketodds="1.81">{Tierunner2BackRate1}<span>{Tierunner2BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Tierunner2LayRate1 != ' ' && Tierunner2LayRate1 > 0) changeTieMatchBook(4, 2, Tierunner2LayRate1) }}>
                                                <a className={`lay-1 ${(selectTieMatchBack === 4) ? "select" : "null"} ${(c10 === 1) ? "spark" : "null"}`} fullmarketodds="1.82">{Tierunner2LayRate1}<span>{Tierunner2LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>
                                        {TieMatchclick === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectTieMatchBack === 1) ? "back" : "lay"}`}>

                                                <ul class="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (TOdds != '' || TOdds != ' ') setTOdds(parseFloat((parseFloat(TOdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                            <span id="odds" class="typed">{TOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (TOdds != '' || TOdds != ' ') setTOdds(parseFloat((parseFloat(TOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(TieMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(TieMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                </ul>


                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>
                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeTieMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {/* onClick = {()=>{marketBetClicked();closeTieMatchBet();}} */}
                                                        <a id="placeBet" onClick = {()=>{TieMatchBetClicked();closeTieMatchBet();}} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* end matchoodss runner2 */}

                                    </React.Fragment>}
                                </div>
                            }
                            {/* tierate componantend */}



                            {highlightTab === 1 && 
                                <div id="marketBetsWrap" style={(CommarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(CommarketBetStatus != 1) ? "disabled" : ""}`}>

                                    {Maxstake === true &&
                                        <div id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                                            <a class="close" onClick={() => handleMaxStakeclose()} >Close</a>
                                            <dl>
                                                <dt id="minMaxDt" style={{ display: ' none' }}>Min / Max</dt>
                                                <dt id="maxDt" >Max</dt>
                                                <dd id="minMaxInfo">{parseInt(CompMatchData.maxStack) * coinFactor}</dd>
                                            </dl>
                                        </div>}
                                    <dl id="betsHead" className="bets-selections-head">
                                        <dt>
                                            <a className="a-depth" id="marketDepthBtn">Markets Depth</a>
                                            <p><span>Matched</span>
                                             {/* <strong id="totalMatched">USD  31,838</strong>  */}
                                             </p>
                                        </dt>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                        <dd>Back </dd>
                                        <dd>Lay </dd>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                    </dl>
                                    {/* matchoodss runner1 */}
                                    {CompMatchData.matchStatus == 1 && <React.Fragment>
                                        <dl id="selection_37302" className={`bets-selections ${(runner1BackRate1>=25 )? "disabled": "null"}`} style={runner1BackRate1 >=25 ? {pointerEvents:'none',display:'flex'}:{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">Yes</h4>
                                                <ul id="winLoss">
                                                    {pnlCompMatch.teamA && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0) && <li id="withoutBet" className={`${pnlCompMatch.teamA > 0 ? "win" : "lose"}`} >{pnlCompMatch.teamA > 0 ? pnlCompMatch.teamA : '(' + Math.abs(pnlCompMatch.teamA) + ')'}</li>}
                                                    {(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlCompMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlCompMatch2.teamA > 0 ? pnlCompMatch2.teamA : '(' + Math.abs(pnlCompMatch2.teamA) + ')'}</li>}
                                                </ul>
                                            </dt>


                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Comrunner1BackRate1 != ' ' && Comrunner1BackRate1 > 0) changeCompMatchBook(1, 1, Comrunner1BackRate1); }}>
                                                <a className={`back-1 ${(selectCompMatchBack === 1) ? "select" : "null"} ${(c11 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{Comrunner1BackRate1}<span>{Comrunner1BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Comrunner1LayRate1 != ' ' && Comrunner1LayRate1 > 0) changeCompMatchBook(2, 1, Comrunner1LayRate1) }}>
                                                <a className={`lay-1 ${(selectCompMatchBack === 2) ? "select" : "null"} ${(c12 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{Comrunner1LayRate1}<span>{Comrunner1LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>

                                        {ComMatchclick2 === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectCompMatchBack === 1) ? "back" : "lay"}`}>
                                                <ul className="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (COdds != '' || COdds != ' ') setCOdds(parseFloat((parseFloat(COdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                            <span id="odds" class="typed">{COdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (COdds != '' || COdds != ' ') setCOdds(parseFloat((parseFloat(COdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(CompMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                </ul>



                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>



                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeCompMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="placeBet" onClick={() => { ComMatchBetClicked(); closeCompMatchBet(); }} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* End matchoodss runner1 */}
                                        {/* matchoodss runner2 */}
                                        <dl id="selection_37302" className="bets-selections" style={{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">No</h4>
                                                <ul id="winLoss">
                                                    {pnlCompMatch.teamA && (pnlCompMatch.teamA != 0 || pnlCompMatch.teamB != 0 || pnlCompMatch.tie != 0) && <li id="withoutBet" className={`${pnlCompMatch.teamB > 0 ? "win" : "lose"}`} >{pnlCompMatch.teamB > 0 ? pnlCompMatch.teamB : '(' + Math.abs(pnlCompMatch.teamB) + ')'}</li>}

                                                    {(ComMatchclick2 || ComMatchclick || ComMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlCompMatch2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlCompMatch2.teamB > 0 ? pnlCompMatch2.teamB : '(' + Math.abs(pnlCompMatch2.teamB) + ')'}</li>}
                                                </ul>
                                            </dt>
                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Comrunner2BackRate1 != ' ' && Comrunner2BackRate1 > 0) changeCompMatchBook(3, 2, Comrunner2BackRate1) }}>
                                                <a className={`back-1 ${(selectCompMatchBack === 3) ? "select" : "null"} ${(c13 === 1) ? "spark" : "null"}`} fullmarketodds="1.81">{Comrunner2BackRate1}<span>{Comrunner2BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Comrunner2LayRate1 != ' ' && Comrunner2LayRate1 > 0) changeCompMatchBook(4, 2, Comrunner2LayRate1) }}>
                                                <a className={`lay-1 ${(selectCompMatchBack === 4) ? "select" : "null"} ${(c14 === 1) ? "spark" : "null"}`} fullmarketodds="1.82">{Comrunner2LayRate1}<span>{Comrunner2LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>
                                        {ComMatchclick === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectCompMatchBack === 1) ? "back" : "lay"}`}>

                                                <ul class="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (COdds != '' || COdds != ' ') setCOdds(parseFloat((parseFloat(COdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                            <span id="odds" class="typed">{COdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (COdds != '' || COdds != ' ') setCOdds(parseFloat((parseFloat(COdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(CompMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(CompMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                </ul>


                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>
                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeCompMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {/* onClick = {()=>{marketBetClicked();closeMatchoddsBet();}} */}
                                                        <a id="placeBet" onClick = {()=>{ComMatchBetClicked();closeCompMatchBet();}} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* end matchoodss runner2 */}

                                    </React.Fragment>}
                                </div>
                            }


                            {highlightTab === 3 && 
                                <div id="marketBetsWrap" style={(SupmarketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets-wrap ${(SupmarketBetStatus != 1) ? "disabled" : ""}`}>

                                    {Maxstake === true &&
                                        <div id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                                            <a class="close" onClick={() => handleMaxStakeclose()} >Close</a>
                                            <dl>
                                                <dt id="minMaxDt" style={{ display: ' none' }}>Min / Max</dt>
                                                <dt id="maxDt" >Max</dt>
                                                <dd id="minMaxInfo">{parseInt(SuperMatchData.maxStack) * coinFactor}</dd>
                                            </dl>
                                        </div>}
                                    <dl id="betsHead" className="bets-selections-head">
                                        <dt>
                                            <a className="a-depth" id="marketDepthBtn">Markets Depth</a>
                                            <p><span>Matched</span> 
                                            {/* <strong id="totalMatched">USD  31,838</strong> */}
                                             </p>
                                        </dt>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                        <dd>Back </dd>
                                        <dd>Lay </dd>
                                        <dd className="mode-land" />
                                        <dd className="mode-land" />
                                    </dl>
                                    {/* matchoodss runner1 */}
                                    {SuperMatchData.matchStatus == 1 && <React.Fragment>
                                        <dl id="selection_37302" className="bets-selections" style={{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">{SuperMatchData.runnerName1}</h4>
                                                <ul id="winLoss">
                                                    {pnlSuperMatch.teamA && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0) && <li id="withoutBet" className={`${pnlSuperMatch.teamA > 0 ? "win" : "lose"}`} >{pnlSuperMatch.teamA > 0 ? pnlSuperMatch.teamA : '(' + Math.abs(pnlSuperMatch.teamA) + ')'}</li>}
                                                    {(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlSuperMatch2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlSuperMatch2.teamA > 0 ? pnlSuperMatch2.teamA : '(' + Math.abs(pnlSuperMatch2.teamA) + ')'}</li>}
                                                </ul>
                                            </dt>


                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Suprunner1BackRate1 != ' ' && Suprunner1BackRate1 > 0) changeSuperMatchBook(1, 1, Suprunner1BackRate1); }}>
                                                <a className={`back-1 ${(selectSuperMatchBack === 1) ? "select" : "null"} ${(c15 === 1) ? "spark" : "null"}`} fullmarketodds={50}>{Suprunner1BackRate1}<span>{Suprunner1BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Suprunner1LayRate1 != ' ' && Suprunner1LayRate1 > 0) changeSuperMatchBook(2, 1, Suprunner1LayRate1) }}>
                                                <a className={`lay-1 ${(selectSuperMatchBack === 2) ? "select" : "null"} ${(c16 === 1) ? "spark" : "null"}`} fullmarketodds="2.24">{Suprunner1LayRate1}<span>{Suprunner1LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>

                                        {SuperMatchclick2 === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectSuperMatchBack === 1) ? "back" : "lay"}`}>
                                                <ul className="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (SOdds != '' || SOdds != ' ') setSOdds(parseFloat((parseFloat(SOdds) - 0.01).toFixed(2)).toString()) }}></a>
                                                            <span id="odds" class="typed">{SOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (SOdds != '' || SOdds != ' ') setSOdds(parseFloat((parseFloat(SOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(SuperMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200">{ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500">{ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000">{ssstake6}</a></li>
                                                </ul>



                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>



                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeSuperMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {/* onClick = {()=>{marketBetClicked();closeMatchoddsBet();}}  */}
                                                        <a id="placeBet" onClick = {()=>{SuperMatchBetClicked();closeSuperMatchBet();}} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* End matchoodss runner1 */}
                                        {/* matchoodss runner2 */}
                                        <dl id="selection_37302" className="bets-selections" style={{ display: 'flex' }}>

                                            <dt>
                                                <h4 id="runnerName">{SuperMatchData.runnerName2}</h4>
                                             
                                                <ul id="winLoss">
                                                    {pnlSuperMatch.teamA && (pnlSuperMatch.teamA != 0 || pnlSuperMatch.teamB != 0 || pnlSuperMatch.tie != 0) && <li id="withoutBet" className={`${pnlSuperMatch.teamB > 0 ? "win" : "lose"}`} >{pnlSuperMatch.teamB > 0 ? pnlSuperMatch.teamB : '(' + Math.abs(pnlSuperMatch.teamB) + ')'}</li>}

                                                    {(SuperMatchclick2 || SuperMatchclick || SuperMatchclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlSuperMatch2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlSuperMatch2.teamB > 0 ? pnlSuperMatch2.teamB : '(' + Math.abs(pnlSuperMatch2.teamB) + ')'}</li>}
                                                </ul>
                                            </dt>
                                            <dd id="back_3" className="mode-land">
                                                <a className="back-3" fullmarketodds={34}>34<span> 411</span></a>
                                            </dd>
                                            <dd id="back_2" className="mode-land">
                                                <a className="back-2" fullmarketodds={40}>40<span> 495</span></a>
                                            </dd>
                                            <dd id="back_1" onClick={() => { if (Suprunner2BackRate1 != ' ' && Suprunner2BackRate1 > 0) changeMatchodds(3, 2, Suprunner2BackRate1) }}>
                                                <a className={`back-1 ${(selectSuperMatchBack === 3) ? "select" : "null"} ${(c17 === 1) ? "spark" : "null"}`} fullmarketodds="1.81">{Suprunner2BackRate1}<span>{Suprunner2BackSize1}</span></a>
                                            </dd>
                                            <dd id="lay_1" onClick={() => { if (Suprunner2LayRate1 != ' ' && Suprunner2LayRate1 > 0) changeMatchodds(4, 2, Suprunner2LayRate1) }}>
                                                <a className={`lay-1 ${(selectSuperMatchBack === 4) ? "select" : "null"} ${(c18 === 1) ? "spark" : "null"}`} fullmarketodds="1.82">{Suprunner2LayRate1}<span>{Suprunner2LaySize1}</span></a>
                                            </dd>
                                            <dd id="lay_2" className="mode-land">
                                                <a className="lay-2" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                            <dd id="lay_3" className="mode-land">
                                                <a className="lay-3" fullmarketodds="1.01"><span /></a>
                                            </dd>
                                        </dl>
                                        {SuperMatchclick === true &&
                                            <div id="betBoardTemplate" className={`bet_slip-wrap ${(selectSuperMatchBack === 1) ? "back" : "lay"}`}>

                                                <ul class="btn-list">
                                                    <li>
                                                        <p class="dynamic-min-bet">&nbsp;</p>
                                                        <div id="inputOdds" class="input-num">
                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (SOdds != '' || SOdds != ' ') setSOdds(parseFloat((parseFloat(SOdds) - 0.01).toFixed(2)).toString()) }} ></a>
                                                            <span id="odds" class="typed">{SOdds}</span>
                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (SOdds != '' || SOdds != ' ') setSOdds(parseFloat((parseFloat(SOdds) + 0.01).toFixed(2)).toString()) }}></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(SuperMatchData.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                        <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num">
                                                            <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                            <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                            <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(SuperMatchData.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul id="stakePopupList" class="coin-list">
                                                    <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="10"> {ssstake1}</a></li>
                                                    <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="30"> {ssstake2}</a></li>
                                                    <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                    <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="200"> {ssstake4}</a></li>
                                                    <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="500"> {ssstake5}</a></li>
                                                    <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="1000"> {ssstake6}</a></li>
                                                </ul>


                                                <div id="keyboard" class="keyboard-wrap">
                                                    <ul id="numPad" class="btn-tel">
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                        <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                        <li><a  >.</a></li>
                                                    </ul>
                                                    <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                                </div>
                                                <ul className="btn-list">
                                                    <li>
                                                        <a id="cancel" onClick={() => { closeSuperMatchBet(); setdefaultStake(dStake); }} className="btn">Cancel
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {/* onClick = {()=>{marketBetClicked();closeMatchoddsBet();}} */}
                                                        <a id="placeBet" onClick = {()=>{SuperMatchBetClicked();closeSuperMatchBet();}} className="btn-send">Place Bet
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div id="acceptAnyOddsBox" className="bet-setting" style={{ display: 'none' }}>
                                                    <a id="acceptAnyOdds" className="bet-check">Accept Any Odds</a>
                                                    <span id="sportsBookMaxStake" style={{ display: 'none' }}><a id="getSportsBookMaxStakeBtn" className="max-bet" >Max</a></span>
                                                </div>
                                            </div>}
                                        {/* end matchoodss runner2 */}

                                    </React.Fragment>}
                                </div>
                            }
                            {/* Copletedmatchratecom */}




                            {bookData2.matchStatus == 1 && <div id="bookMakerWrap" >

                                <div id="bookMakerMarket_30062362_44598" class="bets-wrap bets-bookmaker" style={{ display: 'block' }}>
                                    <h4>
                                        <a id="multiMarketPin" onClick={() => { handlebookpin(); }} className={`pin${bookpin ? "-on" : "-off"}`}>Pin to Muilti Markets</a>
                                        <span>Bookmaker Market</span>
                                        {/* <p>| Zero Commission</p> */}
                                        <a id="openBookMakerInfo" onClick={() => { setBookstake(true); }} class="btn-fancy_info" ></a>
                                        {Bookstake === true &&
                                            <div id="bookMakerMinMax" class="fancy_info-popup" style={{ display: 'flex' }}>
                                                <dl>
                                                    <dt>Min / Max</dt>
                                                    <dd id="minMax"> {parseInt(bookData2.minStack) * coinFactor} / {parseInt(bookData2.maxStack) * coinFactor}</dd>
                                                </dl>

                                                <a id="closeBookMakerInfo" onClick={() => { setBookstake(false); }} class="close">Close</a>
                                            </div>}
                                    </h4>
                                    <dl class="bets-selections-head">
                                        <dt>
                                        </dt>
                                        <dd class="mode-land"></dd>
                                        <dd class="mode-land"></dd>
                                        <dd>Back</dd>
                                        <dd>Lay</dd>
                                        <dd class="mode-land"></dd>
                                        <dd class="mode-land"></dd>
                                    </dl>


                                    <dl id="bookMakerSelection_30062362_44598_131707" class="bets-selections" style={{ display: 'flex' }}>
                                        <dt>
                                            <h4 id="runnerName">{bookData2.runnerName1}</h4>
                                            <ul>
                                                {pnlBook.teamA && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.teamA > 0 ? "win" : "lose"}`} >{pnlBook.teamA > 0 ? pnlBook.teamA : '(' + Math.abs(pnlBook.teamA) + ')'}</li>}

                                                {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamA > 0 ? pnlBook2.teamA : '(' + Math.abs(pnlBook2.teamA) + ')'}</li>}

                                            </ul>
                                        </dt>


                                        {(bookData.gameStatus != 2 || bookData.runner1Status == 2) && <dd id="suspend" class="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner1Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}

                                        <dd id="delayBetting" class="suspend-fancy" style={{ display: ' none' }}><p id="info"></p></dd>

                                        <dd class="backlay_box">
                                            <dl class="back-gradient">

                                                <dd id="back_1" onClick={() => changeBook(1, 1, bookData.runner1BackRate1)} className={`${(selectBack === 1) ? "select" : "null"}`} style={{ cursor: 'pointer' }} class=""><a>{bookData.runner1BackRate1}</a></dd>
                                            </dl>
                                            <dl class="lay-gradient">
                                                <dd id="lay_1" onClick={() => changeBook(2, 1, bookData.runner1LayRate1)} className={`${(selectBack === 2) ? "select" : "null"}`}><a >{bookData.runner1LayRate1}</a></dd>

                                            </dl>
                                        </dd>

                                    </dl>
                                    {(Bookclick2 === true && !(bookData.gameStatus != 2 || bookData.runner1Status == 2)) &&
                                        <div id="betBoard_30062366_45474_134321_1" className={`bet_slip-wrap ${(selectBack === 1) ? "back" : "lay"}`} >

                                            <div id="messageWrap" class="message-wrap" style={{ display: ' none' }}>
                                                <div class="message">
                                                    <h4 id="header">The stake you have entered are below the minimum.</h4>
                                                    <p id="info">
                                                        Your stake has been updated accordingly.
                                                    </p>
                                                </div>
                                                <a id="close" class="close">Close</a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                    <div id="inputOdds" class="input-num disable">
                                                        <a id="oddsDown" class="icon-minus" style={{ display: ' none' }}></a>
                                                        <span id="odds" class="typed">{bOdds}</span>
                                                        <a id="oddsUp" class="icon-plus" style={{ display: ' none' }}></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet:{parseInt(bookData2.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num input-stake">
                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                    </div>
                                                </li>
                                            </ul>

                                            <ul id="stakePopupList" class="coin-list">
                                                <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="30"> {ssstake1}</a></li>
                                                <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="40"> {ssstake2}</a></li>
                                                <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="60"> {ssstake4}</a></li>
                                                <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="70"> {ssstake5}</a></li>
                                                <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="80"> {ssstake6}</a></li>
                                            </ul>

                                            <div id="keyboard" class="keyboard-wrap">
                                                <ul id="numPad" class="btn-tel">
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                    <li><a onClick={() => { }} >.</a></li>
                                                </ul>
                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <a id="cancel" onClick={() => { closeBookBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                    </a>
                                                </li>
                                                <li>
                                                    <a id="placeBet" class="btn-send" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}>Place Bet
                                                    </a>
                                                </li>
                                            </ul>

                                            <div id="acceptAnyOddsBox" class="bet-setting" style={{ display: ' flex' }}>
                                                <a id="acceptAnyOdds" onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" class="max-bet"  >Max</a></span>
                                            </div>

                                        </div>}


                                    <dl id="bookMakerSelection_30062362_44598_131708" class="bets-selections" style={{ display: 'flex' }}>
                                        <dt>
                                            <h4 id="runnerName">{marketData.runnerName2}</h4>
                                            <ul>
                                                {pnlBook.teamB && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >{pnlBook.teamB > 0 ? pnlBook.teamB : '(' + Math.abs(pnlBook.teamB) + ')'}</li>}

                                                {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamB > 0 ? pnlBook2.teamB : '(' + Math.abs(pnlBook2.teamB) + ')'}</li>}


                                            </ul>
                                        </dt>

                                        {((bookData.gameStatus != 2) || bookData.runner2Status == 2) && <dd id="suspend" class="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner2Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                        <dd id="delayBetting" class="suspend-fancy" style={{ display: ' none' }}><p id="info"></p></dd>
                                        <dd class="backlay_box">
                                            <dl class="back-gradient">

                                                <dd id="back_1" onClick={() => changeBook(3, 2, bookData.runner2BackRate1)} className={`${(selectBack === 3) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2BackRate1}</a></dd>
                                            </dl>
                                            <dl class="lay-gradient">
                                                <dd id="lay_1" onClick={() => changeBook(4, 2, bookData.runner2LayRate1)} className={`${(selectBack === 4) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2LayRate1}</a></dd>

                                            </dl>
                                        </dd>
                                    </dl>


                                    {(Bookclick === true && !(bookData.gameStatus != 2 || bookData.runner2Status == 2)) &&
                                        <div id="betBoard_30062366_45474_134321_1" className={`bet_slip-wrap ${(selectBack === 3) ? "back" : "lay"}`} >

                                            <div id="messageWrap" class="message-wrap" style={{ display: ' none' }}>
                                                <div class="message">
                                                    <h4 id="header">The stake you have entered are below the minimum.</h4>
                                                    <p id="info">
                                                        Your stake has been updated accordingly.
                                                    </p>
                                                </div>
                                                <a id="close" class="close"  >Close</a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                    <div id="inputOdds" class="input-num disable">
                                                        <a id="oddsDown" class="icon-minus" style={{ display: ' none' }}></a>
                                                        <span id="odds" class="typed">{bOdds}</span>
                                                        <a id="oddsUp" class="icon-plus" style={{ display: ' none' }}></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet:{parseInt(bookData2.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num input-stake">
                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                    </div>
                                                </li>
                                            </ul>

                                            <ul id="stakePopupList" class="coin-list">
                                                <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="30"> {ssstake1}</a></li>
                                                <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="40"> {ssstake2}</a></li>
                                                <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="60"> {ssstake4}</a></li>
                                                <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="70"> {ssstake5}</a></li>
                                                <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="80"> {ssstake6}</a></li>
                                            </ul>

                                            <div id="keyboard" class="keyboard-wrap">
                                                <ul id="numPad" class="btn-tel">
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                    <li><a onClick={() => { }} >.</a></li>
                                                </ul>
                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <a id="cancel" onClick={() => { closeBookBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                    </a>
                                                </li>
                                                <li>
                                                    <a id="placeBet" class="btn-send" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}>Place Bet
                                                    </a>
                                                </li>
                                            </ul>

                                            <div id="acceptAnyOddsBox" class="bet-setting" style={{ display: ' flex' }}>
                                                <a id="acceptAnyOdds" onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" class="max-bet"  >Max</a></span>
                                            </div>

                                        </div>}

                                    {/* The Draw component */}

                                    {bookData2.runnerId3 && bookData2.runnerId3 != ' ' && <dl id="bookMakerSelection_30062362_44598_131708" class="bets-selections" style={{ display: 'flex' }}>
                                        <dt>
                                            <h4 id="runnerName">The Draw</h4>
                                            <ul>
                                                {pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <li id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >{pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</li>}


                                                {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <li id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</li>}


                                            </ul>
                                        </dt>

                                        {((bookData.gameStatus != 2) || bookData.runner3Status == 2) && <dd id="suspend" class="suspend-fancy" ><p id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                        <dd id="delayBetting" class="suspend-fancy" style={{ display: ' none' }}><p id="info"></p></dd>
                                        <dd class="backlay_box">
                                            <dl class="back-gradient">

                                                <dd id="back_1" onClick={() => changeBook(5, 3, bookData.tieBackRate1)} className={`${(selectBack === 5) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieBackRate1}</a></dd>
                                            </dl>
                                            <dl class="lay-gradient">
                                                <dd id="lay_1" onClick={() => changeBook(6, 3, bookData.tieLayRate1)} className={`${(selectBack === 6) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieLayRate1}</a></dd>

                                            </dl>
                                        </dd>
                                    </dl>}


                                    {(Bookclick3 === true && !(bookData.gameStatus != 2 || bookData.runner3Status == 2)) &&
                                        <div id="betBoard_30062366_45474_134321_1" className={`bet_slip-wrap ${(selectBack === 5) ? "back" : "lay"}`} >

                                            <div id="messageWrap" class="message-wrap" style={{ display: ' none' }}>
                                                <div class="message">
                                                    <h4 id="header">The stake you have entered are below the minimum.</h4>
                                                    <p id="info">
                                                        Your stake has been updated accordingly.
                                                    </p>
                                                </div>
                                                <a id="close" class="close"  >Close</a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <p class="dynamic-min-bet">&nbsp;</p>
                                                    <div id="inputOdds" class="input-num disable">
                                                        <a id="oddsDown" class="icon-minus" style={{ display: ' none' }}></a>
                                                        <span id="odds" class="typed">{bOdds}</span>
                                                        <a id="oddsUp" class="icon-plus" style={{ display: ' none' }}></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet:{parseInt(bookData2.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                    <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num input-stake">
                                                        <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                        <span id="stake" class="typeing typed" autoFocus>{defaultStake}</span>
                                                        <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }} ></a>
                                                    </div>
                                                </li>
                                            </ul>

                                            <ul id="stakePopupList" class="coin-list">
                                                <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="30"> {ssstake1}</a></li>
                                                <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="40"> {ssstake2}</a></li>
                                                <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="60"> {ssstake4}</a></li>
                                                <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="70"> {ssstake5}</a></li>
                                                <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="80"> {ssstake6}</a></li>
                                            </ul>

                                            <div id="keyboard" class="keyboard-wrap">
                                                <ul id="numPad" class="btn-tel">
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                    <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                    <li><a onClick={() => { }} >.</a></li>
                                                </ul>
                                                <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }} ></a>
                                            </div>

                                            <ul class="btn-list">
                                                <li>
                                                    <a id="cancel" onClick={() => { closeBookBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                    </a>
                                                </li>
                                                <li>
                                                    <a id="placeBet" class="btn-send" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}>Place Bet
                                                    </a>
                                                </li>
                                            </ul>

                                            <div id="acceptAnyOddsBox" class="bet-setting" style={{ display: ' flex' }}>
                                                <a id="acceptAnyOdds" onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" class="max-bet"  >Max</a></span>
                                            </div>

                                        </div>}
                                </div>
                            </div>}
{/* TennisPremium */}
{eventT == 2 &&   props.isLoggedIn === true &&
<React.Fragment>

<div id="sportsBookWrap" style={{}}>
  <div id="sportsBookHead" className="sportsbook_bet-head">
    <h4 id="betHeader">
      <span id="sportsBookName"><pre id="inPlayTag" style={{display: 'none'}}>in-play</pre>Premium Tennis</span>
      <a id="sportsBookRules" className="btn-head_rules">Rules</a>
    </h4>
    <a id="showFancyBetBtn" className="other-tab" style={{display: 'none'}}>Fancy Bet</a>
    <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{display: 'none'}}>Min/Max</a>
    <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{}}>Min</a>
    <div id="minMaxWrap_1" className="fancy_info-popup" style={{display: 'none'}}>
      <dl>
        <dt>Min/Max</dt>
        <dd id="minMaxInfo_1">0.00</dd>
      </dl>
      <dl>
        <dt id="rebateName_1">Rebate</dt>
        <dd id="rebateRatio_1">0.00%</dd>
      </dl>
      <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
    </div>
    <div id="minMaxWrap_2" className="fancy_info-popup" style={{display: 'none'}}>
      <dl>
        <dt>Min</dt>
        <dd id="minMaxInfo_2"> 10.00</dd>
      </dl>
      <dl>
        <dt id="rebateName_2" style={{display: 'none'}}>Rebate</dt>
        <dd id="rebateRatio_2" style={{display: 'none'}}>0.00%</dd>
      </dl>
      <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
    </div>
  </div>
  <div id="sportsBookTab_2_4" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Popular">Popular</a></li>
      <li><a id="tab_Match">Match</a></li>
      <li><a id="tab_Innings">Innings</a></li>
      <li><a id="tab_Over">Over</a></li>
      <li><a id="tab_Batsman">Batsman</a></li>
      <li><a id="tab_Partnership">Partnership</a></li>
      <li><a id="tab_Group">Group</a></li>
      <li><a id="tab_Range">Range</a></li>
      <li><a id="tab_HeadtoHead">Head-to-Head</a></li>
      <li><a id="tab_Player">Player</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_2_2" className="special_bets-tab-wrap ps ps--theme_default" style={{}} data-ps-id="b7279a70-eaa3-594b-f3dd-f4b89e7e42e7">
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Main">Main</a></li>
      <li><a id="tab_Total">Total</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
    <div className="ps__scrollbar-x-rail" style={{left: 0, bottom: 0}}><div className="ps__scrollbar-x" tabIndex={0} style={{left: 0, width: 0}} /></div><div className="ps__scrollbar-y-rail" style={{top: 0, right: 0}}><div className="ps__scrollbar-y" tabIndex={0} style={{top: 0, height: 0}} /></div></div>
  <div id="sportsBookTab_2_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Popular">Popular</a></li>
      <li><a id="tab_Half">Half</a></li>
      <li><a id="tab_Goals">Goals</a></li>
      <li><a id="tab_FastMarkets">Fast Markets</a></li>
      <li><a id="tab_Specials">Specials</a></li>
      <li><a id="tab_Corners">Corners</a></li>
      <li><a id="tab_Cards">Cards</a></li>
      <li><a id="tab_Players">Players</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_2_138" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Main">Main</a></li>
      <li><a id="tab_Half">Half</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab" />
  </div>
</div>


<div id="feedingSiteNoBet" class="bets-wrap">
	<p class="no-game">Sports Book has No market</p>
</div>

</React.Fragment>}


{/* Soccer Premium */}

    {eventT == 1 &&   props.isLoggedIn === true &&
<React.Fragment>
    
<div id="sportsBookWrap" style={{}}>
  <div id="sportsBookHead" className="sportsbook_bet-head">
    <h4 id="betHeader">
      <span id="sportsBookName"><pre id="inPlayTag" style={{display: 'none'}}>in-play</pre>Premium Soccer</span>
      <a id="sportsBookRules" className="btn-head_rules">Rules</a>
    </h4>
    <a id="showFancyBetBtn" className="other-tab" style={{display: 'none'}}>Fancy Bet</a>
    <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{display: 'none'}}>Min/Max</a>
    <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{}}>Min</a>
    <div id="minMaxWrap_1" className="fancy_info-popup" style={{display: 'none'}}>
      <dl>
        <dt>Min/Max</dt>
        <dd id="minMaxInfo_1">0.00</dd>
      </dl>
      <dl>
        <dt id="rebateName_1">Rebate</dt>
        <dd id="rebateRatio_1">0.00%</dd>
      </dl>
      <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
    </div>
    <div id="minMaxWrap_2" className="fancy_info-popup" style={{display: 'none'}}>
      <dl>
        <dt>Min</dt>
        <dd id="minMaxInfo_2"> 10.00</dd>
      </dl>
      <dl>
        <dt id="rebateName_2" style={{display: 'none'}}>Rebate</dt>
        <dd id="rebateRatio_2" style={{display: 'none'}}>0.00%</dd>
      </dl>
      <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
    </div>
  </div>
  <div id="sportsBookTab_2_4" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Popular">Popular</a></li>
      <li><a id="tab_Match">Match</a></li>
      <li><a id="tab_Innings">Innings</a></li>
      <li><a id="tab_Over">Over</a></li>
      <li><a id="tab_Batsman">Batsman</a></li>
      <li><a id="tab_Partnership">Partnership</a></li>
      <li><a id="tab_Group">Group</a></li>
      <li><a id="tab_Range">Range</a></li>
      <li><a id="tab_HeadtoHead">Head-to-Head</a></li>
      <li><a id="tab_Player">Player</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_2_2" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Main">Main</a></li>
      <li><a id="tab_Total">Total</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_2_1" className="special_bets-tab-wrap ps ps--theme_default ps--active-x" style={{}} data-ps-id="fe4aaf12-c31b-b04c-5f56-35d2fe3072c1">
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Popular">Popular</a></li>
      <li><a id="tab_Half">Half</a></li>
      <li><a id="tab_Goals">Goals</a></li>
      <li><a id="tab_FastMarkets">Fast Markets</a></li>
      <li><a id="tab_Specials">Specials</a></li>
      <li><a id="tab_Corners">Corners</a></li>
      <li><a id="tab_Cards">Cards</a></li>
      <li><a id="tab_Players">Players</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
    <div className="ps__scrollbar-x-rail" style={{width: 499, left: 62, bottom: 0}}><div className="ps__scrollbar-x" tabIndex={0} style={{left: 38, width: 308}} /></div><div className="ps__scrollbar-y-rail" style={{top: 0, right: '-62px'}}><div className="ps__scrollbar-y" tabIndex={0} style={{top: 0, height: 0}} /></div></div>
  <div id="sportsBookTab_2_138" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab">
      <li><a id="tab_All">All</a></li>
      <li><a id="tab_Main">Main</a></li>
      <li><a id="tab_Half">Half</a></li>
      <li><a id="tab_Others">Others</a></li>
    </ul>
  </div>
  <div id="sportsBookTab_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
    <ul className="special_bets-tab" />
  </div>	
</div>


<div id="feedingSiteNoBet" class="bets-wrap" >
	<p class="no-game">Sports Book has no market</p>
</div>


</React.Fragment>}

                            {/* fancyyyy */}
                            {eventT == 4 &&

                                <React.Fragment>
                                    { onclickPremium === false &&

                                        <div id="fancyBetTable_30062362" name="multiMarketItem">

                                            {showFancyBook && <FancyBook runner={fancyRunner} userid={props.userid} token={props.utoken} handleFancyBook={handleFancyBook} eventId={eventid} selection={fancySelection} />}

                                            <div id="fancyBetHead" className="fancy_bet-head" style={{}}>
                                                {/*a id="multiMarketPin" class="pin-off" href="#">Pin to Muilti Markets</a*/}
                                                <h4 className="in-play">
                                                    <a id="multiMarketPin" onClick={() => { handlefancypin(); }} className={`pin${Fancypin ? "-on" : "-off"}`} >Pin to Muilti Markets</a>
                                                    <span><pre>in-play</pre>Fancy Bet</span>
                                                    <a className="btn-head_rules" >Rules</a>
                                                </h4>
                                                {props.isLoggedIn === true &&
                                                    <a id="showSportsBookBtn" className="other-tab" onClick={() => { handlepremium(true) }} style={{}}>
                                                        <span className="tag-new">New</span>
                                                        Premium Cricket
                                                    </a>
                                                }
                                            </div>


                                            <div id="fancyBetTabWrap" className="fancy_bet_tab-wrap ps ps--theme_default ps--active-x" style={{}} data-ps-id="cace61a7-33b8-1ce3-037a-56d58b59ed92">
                                                <ul className="special_bets-tab" style={{overflowY:'scroll'}}>
                                                    <li className={`${(fancybettabtype == 1) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(1) }}>All</a></li>
                                                    <li className={`${(fancybettabtype == 2) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(2) }}>Fancy</a></li>
                                                    <li className={`${(fancybettabtype == 3) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(3) }}>Ball by Ball</a></li>
                                                    <li className={`${(fancybettabtype == 4) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(4) }}>Khadda</a></li>
                                                    <li className={`${(fancybettabtype == 5) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(5) }}>Lottery</a></li>
                                                    <li className={`${(fancybettabtype == 6) ? "select" : "null"}`}><a onClick={() => { setfancybettabtype(6) }}>Odd/Even</a></li>
                                                </ul>
                                                {/* <div className="ps__scrollbar-x-rail" style={{ width: 430, left: 7, bottom: 0 }}><div className="ps__scrollbar-x" tabIndex={0} style={{ left: 7, width: 422 }} /> </div>
                                                <div className="ps__scrollbar-y-rail" style={{ top: 0, right: '-7px' }}><div className="ps__scrollbar-y" tabIndex={0} style={{ top: 0, height: 0 }} /> </div> */}
                                            </div>
                                           
                                            {  moment() <= moment(marketStartTime) && fancyData.length == 0 &&

                                            <div id="noGameWrap" class="bets-wrap" style={{ display: ' block' }}>
                                                <p class="no-game">Fancybet market will be available after Event at In-Play</p>
                                            </div>

                                            }

                                            {fancyData.length > 0 &&
                                            <div id="fancyBetField" class="bets-wrap fancy-bet" >
                                                <dl class="bets-selections-head">
                                                    <dt>

                                                    </dt>
                                                    <dd class="mode-land"></dd>
                                                    <dd class="mode-land"></dd>
                                                    <dd>No</dd>
                                                    <dd>Yes</dd>
                                                    <dd class="mode-land"></dd>
                                                    <dd class="mode-land"></dd>
                                                </dl>


                                            </div>}
                                            <div id="fancyBetTag">
                                                {fancyData.map(function (item, index) {

                                                    var pnl = null;
                                                    var pnl2 = null;


                                                    if (pnlFancy[item.selectionId]) {
                                                        pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                                    }
                                                    if (click === index) {

                                                        if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                                            pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                                        }
                                                        else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                                            pnl2 = -Math.abs(defaultStake);
                                                        }

                                                    }



                                                    return (
                                                        <React.Fragment key={index}>



                                                            <div id="fancyBetMarket_176347" class="bets-wrap fancy-bet" >

                                                                <h5>
                                                                    <span id="marketName">{item.runnerName}</span>
                                                                    <a onClick={() => { setfancyStakeInfo(index); }} id="open-fancy_info" class="btn-fancy_info">fancybet info</a>
                                                                    {fancyStakeInfo === index && <div id="fancy_popup2" class="fancy_info-popup" style={{ display: 'flex' }}>
                                                                        <dl>
                                                                            <dt>Min / Max</dt>
                                                                            <dd id="minMax"> {parseInt(item.minStack) * coinFactor} / {parseInt(item.maxStack) * coinFactor}</dd>
                                                                        </dl>
                                                                        <a onClick={() => { setfancyStakeInfo(-1); }} id="close-fancy_info" class="close">Close</a>
                                                                    </div>}
                                                                </h5>
                                                                <dl class="bets-selections">
                                                                    <dt class="line_market-selection">
                                                                        <dl class="tips">
                                                                            <dt>
                                                                                {pnl && <span id="before" className={`${pnl > 0 ? "win" : "lose"}`}>{pnl > 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span>}
                                                                                {/* click === index && pnl2 && <span id="after" className={`${pnl2 > 0 ? "to-win":"to-lose"}`}>{pnl2 > 0 ? pnl2 : '('+ Math.abs(pnl2) +')'}</span> */}
                                                                            </dt>
                                                                            <dd id="remarkFirstRow" style={{ display: ' none' }}></dd>
                                                                            <dd id="remarkSecondRow" style={{ display: ' none' }}></dd>
                                                                        </dl>
                                                                        {pnl && <a id="fancyBetBookBtn" class="btn-book" onClick={() => {
                                                                            setfancySelection(item.selectionId);
                                                                            setfancyRunner(item.runnerName); handleFancyBook(true);
                                                                        }}>Book</a>}
                                                                    </dt>

                                                                    {(item.gameStatus != 2) && <dd id="suspend" class="suspend-fancy"><p id="info">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</p></dd>}
                                                                    <dd id="delayBetting" class="suspend-fancy" style={{ display: ' none' }}><p id="info"></p></dd>
                                                                    <dd id="lay_1">
                                                                        <a class="lay-1" onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBet(index); setfOdds(item.layPrice); setfsize(item.laySize); }} className={`lay-1 ${(selectFancy === 1 && click === index && field === 1) ? "select" : "null"}`}  >{item.layPrice ? item.layPrice : '--'}<span>{item.laySize ? item.laySize : '--'}</span></a>
                                                                    </dd>
                                                                    <dd id="back_1">
                                                                        <a class="back-1" onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBetLay(index); setfOdds(item.backPrice); setfsize(item.backSize); }} className={`back-1 ${(selectFancyLay === 1 && click === index && field === 2) ? "select" : "null"}`}  >{item.backPrice ? item.backPrice : '--'}<span>{item.backSize ? item.backSize : '--'}</span></a>
                                                                    </dd>
                                                                    <dd class="mode-land"></dd>
                                                                    <dd class="mode-land"></dd>
                                                                </dl>
                                                            </div>



                                                            <div id="messageWrap" class="message-wrap" style={{ display: "none" }}>
                                                                <div class="message">
                                                                    <h4 id="header">The stake you have entered are below the minimum.</h4>
                                                                    <p id="info"></p>
                                                                </div>
                                                                <a id="close" class="close">Close</a>
                                                            </div>

                                                            {click === index &&
                                                                <div id="betBoard_30062366_176773_1" className={`bet_slip-wrap ${field === 1 ? "lay" : "back"}`} style={{ display: ' block' }}>

                                                                    <div id="messageWrap" class="message-wrap" style={{ display: ' none' }}>
                                                                        <div class="message">
                                                                            <h4 id="header">The stake you have entered are below the minimum.</h4>
                                                                            <p id="info">
                                                                                Your stake has been updated accordingly.
                                                                            </p>
                                                                        </div>
                                                                        <a id="close" class="close" >Close</a>
                                                                    </div>

                                                                    <ul class="btn-list">
                                                                        <li>
                                                                            <p class="dynamic-min-bet">&nbsp;</p>
                                                                            <div id="inputOdds" class="input-num disable">
                                                                                <a id="oddsDown" class="icon-minus" style={{ display: ' none' }}></a>
                                                                                <span id="odds" class="typed">{fOdds} / {fsize}</span>

                                                                                <a id="oddsUp" class="icon-plus" style={{ display: ' none' }}></a>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <p class="dynamic-min-bet" style={{textAlign:'center'}}>Min Bet: {parseInt(item.minStack) * coinFactor} <strong id="dynamicMinBet"></strong></p>
                                                                            <div id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} class="input-num input-stake" maxLength="7">
                                                                                <a id="stakeDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} ></a>
                                                                                <span id="stake" class="typeing typed" autoFocus autoFocus>{defaultStake}</span>
                                                                                <a id="stakeUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}></a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>

                                                                    <ul id="stakePopupList" class="coin-list">
                                                                        <li><a id="selectStake_1" onClick={() => setdefaultStake(ssstake1)} stake="30"> {ssstake1}</a></li>
                                                                        <li><a id="selectStake_2" onClick={() => setdefaultStake(ssstake2)} stake="40"> {ssstake2}</a></li>
                                                                        <li><a id="selectStake_3" onClick={() => setdefaultStake(ssstake3)} stake="50"> {ssstake3}</a></li>
                                                                        <li><a id="selectStake_4" onClick={() => setdefaultStake(ssstake4)} stake="60"> {ssstake4}</a></li>
                                                                        <li><a id="selectStake_5" onClick={() => setdefaultStake(ssstake5)} stake="70"> {ssstake5}</a></li>
                                                                        <li><a id="selectStake_6" onClick={() => setdefaultStake(ssstake6)} stake="80"> {ssstake6}</a></li>
                                                                    </ul>

                                                                    <div id="keyboard" class="keyboard-wrap">
                                                                        <ul id="numPad" class="btn-tel">
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} >1</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} >2</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} >3</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} >4</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} >5</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} >6</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} >7</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} >8</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} >9</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} >0</a></li>
                                                                            <li><a onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} >00</a></li>
                                                                            <li><a onClick={() => { }} >.</a></li>
                                                                        </ul>
                                                                        <a id="delete" class="btn-delete" onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}></a>
                                                                    </div>

                                                                    <ul class="btn-list">
                                                                        <li>
                                                                            <a id="cancel" onClick={() => { closeFancyBet(); setdefaultStake(dStake); }} class="btn">Cancel
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a id="placeBet" class="btn-send" onClick={(e) => { e.preventDefault(); fancyBet(item.selectionId, field, item.runnerName); closeFancyBet(); props.handleRefreshhh(3000, 'Placing bet...', 1); setdefaultStake(dStake); }}>Place Bet
                                                                            </a>
                                                                        </li>
                                                                    </ul>

                                                                    <div id="acceptAnyOddsBox" class="bet-setting" style={{ display: ' flex' }}>
                                                                        <a id="acceptAnyOdds" onClick={() => setacceptAnyOdds(!acceptAnyOdds)} className={`bet-check ${acceptAnyOdds === true ? "checked" : ""}`}>Accept Any Odds</a>
                                                                        <span id="sportsBookMaxStake" style={{ display: ' none' }}><a id="getSportsBookMaxStakeBtn" class="max-bet"  >Max</a></span>
                                                                    </div>

                                                                </div>}




                                                        </React.Fragment>)
                                                })}
                                            </div>
                                        </div>

                                    }

                                    { onclickPremium === true && props.isLoggedIn === true &&

                                        <div id="sportsBookWrap" style={{ display: 'block' }}>
                                            <div id="sportsBookHead" className="sportsbook_bet-head">
                                                <h4 id="betHeader">
                                                    <span id="sportsBookName">
                                                        <pre id="inPlayTag" style={{ display: 'none' }}>in-play</pre>
                                                        Premium Cricket
                                                    </span>
                                                    <a id="sportsBookRules" className="btn-head_rules">Rules</a>
                                                </h4>
                                                <a id="showFancyBetBtn" className="other-tab" onClick={() => { handlepremium(false) }} style={{}}>Fancy Bet</a>
                                                <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{ display: 'none' }}>Min/Max</a>
                                                <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{}}>Min</a>
                                                <div id="minMaxWrap_1" className="fancy_info-popup" style={{ display: 'none' }}>
                                                    <dl>
                                                        <dt>Min/Max</dt>
                                                        <dd id="minMaxInfo_1">0.00</dd>
                                                    </dl>
                                                    <dl>
                                                        <dt id="rebateName_1">Rebate</dt>
                                                        <dd id="rebateRatio_1">0.00%</dd>
                                                    </dl>
                                                    <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
                                                </div>
                                                <div id="minMaxWrap_2" className="fancy_info-popup" style={{ display: 'none' }}>
                                                    <dl>
                                                        <dt>Min</dt>
                                                        <dd id="minMaxInfo_2">10.00</dd>
                                                    </dl>
                                                    <dl>
                                                        <dt id="rebateName_2" style={{ display: 'none' }}>Rebate</dt>
                                                        <dd id="rebateRatio_2" style={{ display: 'none' }}>0.00%</dd>
                                                    </dl>
                                                    <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
                                                </div>
                                            </div>
                                            <div id="sportsBookTab_2_4" className="special_bets-tab-wrap ps ps--theme_default" style={{}} data-ps-id="c1a7e042-6db9-7e96-cce6-9403f3fb2558">
                                                <ul className="special_bets-tab">
                                                    <li><a className={`${(fancybettabtypePrimium == 1) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(1) }}/>All</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 2) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(2) }}/>Popular</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 3) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(3) }}/>Match</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 4) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(4) }}/>Innings</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 5) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(5) }}/>Over</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 6) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(6) }}/>Batsman</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 7) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(7) }}/>Partnership</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 8) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(8) }}/>Group</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 9) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(9) }}/>Range</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 10) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(10) }}/>Head-to-Head</a></li>
                                                    <li><a className={`${(fancybettabtypePrimium == 11) ? "select" : "null"}`}><a onClick={() => { setfancybettabtypePrimium(11) }}/>Player</a></li>
                                                </ul>
                                                <div className="ps__scrollbar-x-rail" style={{ left: 0, bottom: 0 }}><div className="ps__scrollbar-x" tabIndex={0} style={{ left: 0, width: 0 }} /></div>
                                                <div className="ps__scrollbar-y-rail" style={{ top: 0, right: 0 }}><div className="ps__scrollbar-y" tabIndex={0} style={{ top: 0, height: 0 }} /></div>
                                            </div>
                                            
                                            <div id="sportsBookNoBet" class="bets-wrap" >
                                                <p class="no-game">Sports Book has no market</p>
                                            </div>
                                            
                                            
                                            {/* 
                                            <div id="sportsBookMarket_2_1886757_97016100" className="bets-wrap sports-book" style={{}}>
                                                <h4>
                                                    <a id="multiMarketPin" className="pin-off" title="Add to Multi Markets" />
                                                    <span id="marketName" className="to-collapse">1st innings over 1 - Auckland Aces total</span>

                                                </h4>
                                                <dl id="sportsBookSelectionTemplate" className="bets-selections" style={{ display: 'none' }}>
                                                    <dt className>
                                                        <h4 id="selectionName" />
                                                        <ul>
                                                            <li id="before" className="win" style={{ display: 'none' }} />
                                                            <li id="after" className="to-lose" style={{ display: 'none' }} />
                                                        </ul>
                                                    </dt>
                                                    <dd id="suspend" className="suspend" style={{ display: 'none' }}><p>Suspend</p></dd>
                                                    <dd>
                                                        <a id="back_1" className="back-1" href="#"><span /></a>
                                                    </dd>
                                                    <dd className="mode-land" />
                                                </dl>
                                                <dl id="sportsBookSelection_2_1886757_97016100_1813977990" className="bets-selections" style={{ display: 'flex' }} eventid={1886757} marketpk="2_1886757_97016100" isactive={1} status={1}>
                                                    <dt className>
                                                        <h4 id="selectionName">over 2.5</h4>
                                                        <ul>
                                                            <li id="before" className="win" style={{ display: 'none' }} />
                                                            <li id="after" className="to-lose" style={{ display: 'none' }} />
                                                        </ul>
                                                    </dt>
                                                    <dd id="suspend" className="suspend" style={{ display: 'none' }} isactive={1} status={1}><p>Suspend</p></dd>
                                                    <dd>
                                                        <a id="back_1" className="back-1" href="#"><span>1.43</span></a>
                                                    </dd>
                                                    <dd className="mode-land" />
                                                </dl>
                                                <dl id="sportsBookSelection_2_1886757_97016100_1813977991" className="bets-selections" style={{ display: 'flex' }} eventid={1886757} marketpk="2_1886757_97016100" isactive={1} status={1}>
                                                    <dt className>
                                                        <h4 id="selectionName">under 2.5</h4>
                                                        <ul>
                                                            <li id="before" className="win" style={{ display: 'none' }} />
                                                            <li id="after" className="to-lose" style={{ display: 'none' }} />
                                                        </ul>
                                                    </dt>
                                                    <dd id="suspend" className="suspend" style={{ display: 'none' }} isactive={1} status={1}><p>Suspend</p></dd>
                                                    <dd>
                                                        <a id="back_1" className="back-1" href="#"><span>2.56</span></a>
                                                    </dd>
                                                    <dd className="mode-land" />
                                                </dl>
                                            </div>
                                       */}
                                      
                                        </div>

                                    }

                                </React.Fragment>
                            }

                        </React.Fragment>}

                </div>






            </React.Fragment>}







        </React.Fragment>
    )
}
