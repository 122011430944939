import React, { useState, useEffect } from 'react'
import axios from 'axios';
import moment from 'moment';
import Transparent from '../images/transparent.gif';
import Soccer from '../images/photo13.png';
import Tennis from '../images/photo14.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
// import Indi from '../images/casino/indi.jpeg'
// import betsoft from '../images/casino/betsoft.jpeg'
import Supernova from '../images/casino/banner_supernowa-half.png'
import mozos from '../images/casino/banner_7mojos-half.png'
import img1 from '../images/mobile-logo.png'
// import blackjack from '../images/casino/caleta.jpeg'
// import Banneryc from '../images/banner_binary.png';
// import CoinToss from '../images/casino/playngoo.jpeg'
import Pragmatic1 from '../images/casino/Pragmatic.png'
import Baccarat from '../images/JILI.jpeg'
import BannerCasino from '../images/banner_royalgaming-half.png'
import OneTouch from '../images/casino/onetouch.jpeg';
import Sports from '../images/banner_sports.png';
import mineswiper from '../images/banner_minesweeper-half.png'
import Ebets from '../images/ag.jpeg'
import NumberMatka from '../images/superspade.jpeg'
import Sicbo from '../images/banner_betgames-half.png';
import Evolution from '../images/banner_blackjack-half.png'
import betgames from '../images/ebet.jpeg';
// import Ezugi from '../images/ezuginew.jpeg'
// import micro from '../images/casino/micro.jpeg'
import livecasino from '../images/casino/banner_casino.png'
import { Link } from 'react-router-dom';
import horserace from '../images/casino/banner_horsebook-half.png'
import Ipl2 from '../images/photo12.png'
import teenpatti from '../images/casino/banner_TeenPatti2020-half.png'
import Kings1 from '../images/banner_sevenUpDown-half.png'
import Kings2 from '../images/banner_blackjack-half.png'
import Kings3 from '../images/banner_andarBahar-half.png'
import Kings4 from '../images/banner_minesweeper-half.png'
import Kings5 from '../images/banner_sicbo-half.png'
import Kings6 from '../images/banner_CoinToss-half.png'
import Kings7 from '../images/kv/europeanroulette.jpeg'
import Kings8 from '../images/banner_cardMatka-half.png'
import Kings9 from '../images/kv/32card-half.png'
import Kings10 from '../images/kv/Bacarrat.jpeg'
import Cookies from 'universal-cookie';
import cardhilo from '../images/kv/cardshilow.jpeg'
import pokgend from '../images/casino/pokdeng.jpeg'
import Aecasino from '../images/banner_AESexy-half.png'
import NumberKing from '../images/casino/banner_NumberKing-half.png'
import BigSmall from '../images/casino/banner_BigSmall-half.png'
import TeenPattiJoker from '../images/casino/banner_TeenPattiJoker-half.png'
import DragonNTiger from '../images/casino/banner_DragonNTiger-half.png'
import CallbreakQuick from '../images/casino/banner_CallbreakQuick-half.png'
import SicBoJili from '../images/casino/banner_SicBo-Jili-half.png'
import Baccarats from '../images/casino/banner_Baccarat-half.png'
import AESexy from '../images/banner_AESexy-half.png'
import Evolutionnew from '../images/casino/EVOLUTION-GAMING-min.webp'
import ezugy from '../images/ezugi-casino.webp'
import betradar from '../images/casino/banner_virtualsports.png'
const cookies = new Cookies();

const photos = [
    // {
    //     name: 'photo 1',
    //     url: [Eid]
    // },
    // {
    //     name: 'photo 2',
    //     url: [Ipl1]
    // },
    {
        name: 'photo 3',
        url: [Ipl2]
    },

    {
        name: 'photo 4',
        url: [Soccer]
    },
    {
        name: 'photo 5',
        url: [Tennis]
    },

]

const settings = {
    dots: true,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    className: 'slidesN'
}

export default function Bannerpage(props) {
    const [Socialicon, setSocialicon] = useState(1);
    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [casinoWait, setcasinoWait] = useState(false);

    function changecount(cri, socc, tenn) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
    }



    //AE Sexy
    const openLudo = (gameI) => {
        if (!props.isLoggedIn) {
            props.handleOpen(true);
            return;

        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);

            axios.post('https://millionbet247.com/api/gapcasino/game/url', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }

    //AE Sexy
    const openAESexy = (gameI) => {
        if (!props.isLoggedIn) {
            props.handleOpen(true);
            return;

        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);

            axios.post('https://millionbet247.com/api/gapcasino/game/url', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }

    const openEvolution = (gameI) => {

        if (!props.isLoggedIn) {
            props.handleOpen(true);
            return;
        }
        else {
            var utoken = cookies.get('token');
            var user = cookies.get('id');
            var mil = Date.now();
            if (!user || !utoken) {
                return;

            }
            setcasinoWait(true);
            axios.post('https://millionbet247.com/api6/casino/game/hurl', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        //window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });
        }
    }


    useEffect(() => {
        axios.all([
            axios.get('https://millionbet247.com/api/client/clientcricket'),
            axios.get('https://millionbet247.com/api/client/clientsoccer'),
            axios.get('https://millionbet247.com/api/client/clienttennis')
        ])
            .then(axios.spread((obj1, obj2, obj3) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                changecount(count1, count2, count3);
            }));
    }, [])
    return (
        <React.Fragment>

            {casinoWait === true &&


                <div className="loading-overlay" id="loading"><div className="loading-wrap" style={{ display: 'flex' }}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>


            }
            {/* { casinoWait && <div class="bl-Preloader" id="__-plContainer">
         <div class="bl-Preloader_Header">
           <div class="bl-Preloader_ProductHeader "></div>
               <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_MainHeaderLogo "></div>
           </div>
          </div>
         <div class="bl-Preloader_SpinnerContainer ">
           <div class="bl-Preloader_Spinner "></div>
         </div>
       </div> } */}

            {/* <div class="bl-Preloader" id="__-plContainer">
    <div class="bl-Preloader_Header">
        <div class="bl-Preloader_ProductHeader "></div>
        <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_MainHeaderLogo "></div>
        </div>
    </div>
    <div class="bl-Preloader_SpinnerContainer ">
        <div class="bl-Preloader_Spinner "></div>
    </div>
</div> */}
            {/* <div class="overlay" style={{display: "flex",justifyContent:'center',alignItems:'center',backgroundColor:'rgb(0 0 0 / 90%)'}}>  
                            <div id="page-wrap">
                    <div class="meter red">
                        <span style={{width: "100%"}}></span>
                    </div>
                        </div>
                        </div> */}
            {props.open === true && props.isLoggedIn === false && <div id="loginBox" className="overlay">
                <div className="dialog-wrap-w login_to_go">
                    <div className="kv" style={{ width: '250px', height: '100%', borderRadius: '8px 0 0 8px' }}>
                        {/* <h1 style={{marginLeft: '-143px',marginTop:'80px'}}>
        <img src={Login}  alt="logo" style={{height: 'auto', width: '480px'}}/>
  </h1> */}
                        <img class="logoimgset" src={img1} alt="login-icon" />
                    </div>
                    <dl className="login-panel">
                        <dt>Please login to continue</dt>
                        <dd><input value={props.userid} onChange={(e) => { props.isLoggedInsetId(e.target.value) }} id="loginBoxLoginName" type="text" placeholder="Username" /></dd>
                        <dd><input value={props.password} onChange={(e) => { props.setPassword(e.target.value) }} id="loginBoxPassword" type="password" placeholder="Password" /></dd>
                        <dd className="valid-code">
                            <input onChange={(e) => { props.validateCaptcha(e) }} id="loginBoxValidCode" type="text" placeholder="Validation Code" maxLength="4" />
                            <div id="popupcaptcha" style={{ position: 'absolute', right: '5px', width: '44px', top: '5px' }}>

                            </div>
                        </dd>

                        <dd><a onClick={props.handleLogin} id="loginBoxLoginBtn" className="btn-send">Login<img className="icon-login" src={Transparent} alt="login-icon" /></a></dd>
                        <dd id="loginBoxErrorMsg" className="error"></dd>
                    </dl>
                    <a id="close" style={{ cursor: ' pointer', position: 'absolute' }} onClick={props.handleClose} className="close">.</a>
                </div>
            </div>}

            <div id="centerColumn" class="col-center gamehall">

                <div id="overWrap" class="over-wrap" style={{ height: "calc(100% - 0px)" }}>
                    <div class="kv-wrap">
                        <Slider  {...settings}>
                            {photos.map((photo, id) => {
                                return (
                                    <div key={id}>
                                        <img src={photo.url} alt="cric" style={{ height: '192px' }} />
                                    </div>
                                )
                            })}
                        </Slider>

                    </div>
                    <div class="gamehall-wrap-simple">
                        <a href="/" style={{ cursor: "pointer" }}>
                            <dl id="onLiveBoard" class="on_live">
                                <dt>
                                    <p class="live_icon"><span></span> LIVE</p>
                                </dt>

                                <dd id="onLiveCount_CRICKET">
                                    <p>Cricket</p><span id="count">{countCricket}</span></dd>

                                <dd id="onLiveCount_SOCCER">
                                    <p>Soccer</p><span id="count">{countSoccer}</span></dd>

                                <dd id="onLiveCount_TENNIS">
                                    <p>Tennis</p><span id="count">{countTennis}</span></dd>
                            </dl>
                            <dl class="entrance-title">
                                <dt>Sports</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Sports} style={{ height: '194px' }} alt="" draggable="false" />
                        </a>



                        <a style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Betradar</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img style={{ height: '194px' }} src={betradar} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Royal Gaming</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img style={{ height: '194px' }} src={BannerCasino} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>AE Sexy</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={AESexy} style={{ height: '194px' }} alt="" draggable="false" />
                        </a>

                        <a style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Live Casino</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={livecasino} style={{ height: '194px' }} alt="" draggable="false" />
                        </a>


                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Evolution Gaming</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Evolutionnew} style={{ height: '194px' }} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Ezugi</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={ezugy} style={{ height: '194px' }} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>betgames</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Sicbo} alt="" draggable="false" />
                        </a>

                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>BlackJack Casino</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Evolution} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Supernova Casino</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Supernova} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>7 Mozos Casino</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={mozos} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Horse Racing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={horserace} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Mineswiper</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={mineswiper} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Teen patti 20-20</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={teenpatti} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>NumberKing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={NumberKing} alt="" draggable="false" />
                        </a>

                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>BigSmall</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={BigSmall} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>TeenPattiJoker</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={TeenPattiJoker} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Drangon Tiger</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={DragonNTiger} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Call break Quick</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={CallbreakQuick} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>SicBo Jili</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={SicBoJili} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Baccarat</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Baccarats} alt="" draggable="false" />
                        </a>


                        <Link class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Pragmatic Play</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Pragmatic1} alt="" draggable="false" />
                        </Link>


                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Ebets</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={betgames} alt="" draggable="false" />
                        </a>
                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Asia Gaming</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Ebets} alt="" draggable="false" />
                        </a>

                        <a class="entrance-half">
                            <dl class="entrance-title">
                                <dt>OneTouch</dt>
                                <dd><span>Play Now</span></dd>

                            </dl>
                            <img src={OneTouch} alt="" draggable="false" />

                        </a>



                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Super Spade</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={NumberMatka} alt="" draggable="false" />
                        </a>
                        {/* <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Microgaming</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={micro} alt="" draggable="false" />
                        </a> */}

                        {/* <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Caleta Gaming</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={blackjack} alt="" draggable="false" />
                        </a> */}
                        {/* <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Indi Slots</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Indi} alt="" draggable="false" />
                        </a> */}
                        {/* <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>BetSoft</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={betsoft} alt="" draggable="false" />
                        </a> */}
                        {/* <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Play'n Go</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={CoinToss} alt="" draggable="false" />
                        </a> */}


                        <a class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Teenpatti Jili</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Baccarat} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400067); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>7 UP 7 Down</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings1} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400071); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Andar Bahar</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings3} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400056); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Sic Bo</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings5} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400075); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Coin Toss</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings6} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400062); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Card Mutka</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings8} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400078); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>32 Card</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings9} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400066); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>European Roulette</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings7} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400070); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Baccarat</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings10} alt="" draggable="false" />
                        </a>
                        <a onClick={() => { openAESexy(400072); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Pok deng</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={pokgend} alt="" draggable="false" />
                        </a>

                        <a onClick={() => { openAESexy(400076); }} class="entrance-half" style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Card Hilo</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={cardhilo} alt="" draggable="false" />
                        </a>

                        {/* <a style={{ cursor: "pointer" }}>
                            <dl class="entrance-title">
                                <dt>Binary</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Banneryc} alt="" draggable="false" />
                        </a> */}

                    </div>

                    <div class="footer" >

                        <div id="supportWrap" class="support-wrap">
                            <div class="support-service">

                                <a id="support_whatsapp" onMouseOver={() => { setSocialicon(0) }} className={`support-whatsapp ${(Socialicon === 0) ? "open" : "null"}`}><img src={Transparent} title="WhatsApp" /></a>
                                {/* <a id="support_telegram" onMouseOver={() => { setSocialicon(1) }} className={`support-telegram ${(Socialicon === 1) ? "open" : "null"}`}><img src={Transparent} title="Telegram" /></a> */}

                            </div>
                            {/* <div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
                                {Socialicon === 0 &&
                                    <a href='https://api.whatsapp.com/send/?phone=917980153252&text&app_absent=0'><span>+917980153252 </span></a>
                                }
                                {Socialicon === 0 &&
                                    <a href='https://api.whatsapp.com/send/?phone=15678037291&text&app_absent=0'><span>+1(567)8037291</span></a>
                                }
                            </div> */}
                        </div>
                        {/* <div class="support-wrap extend-support">
                            <div class="extend-wrap">
                                <div class="extend-btn">

                                    <img src={Transparent} title="customer" class="support-customer" />
                                    <a href="#" target="_blank">Customer support1</a>


                                    <a href="#" target="_blank" class="split-line">support2</a>

                                </div>
                                <div class="extend-btn">

                                    <img src={Transparent} title="WhatsApp" class="support-whatsapp" />
                                    <a href="#" target="_blank">+917777009073</a>


                                    <a href="#" target="_blank" class="split-line">+260763986993</a>

                                </div>
                            </div>
                            <div class="extend-btn">


                                <img src={Transparent} title="Telegram" class="support-telegram" />
                                <a href="#" target="_blank">+917777002932</a>


                                <a href="#" target="_blank" class="split-line">+917900007644</a>

                            </div>
                            <div class="extend-wrap">
                                <div class="support-social">

                                    <img src={Transparent} title="Skype" class="support-skype" />
                                    <a href="#" target="_blank">skyexchangeofficial</a>

                                </div>
                                <div class="support-social">

                                    <img src={Transparent} title="Email" class="support-mail" />
                                    <a href="#" target="_blank">info@skyexchange.com</a>

                                </div>
                                <div class="support-social">

                                    <img src={Transparent} title="Instagram" class="support-ig" />

                                    <a href="#" target="_blank">officialskyexchange</a>


                                </div>
                            </div>
                        </div> */}

                        <div class="power-wrap" style={{ width: "109px" }}>
                            <h3 style={{ backgroundImage: 'none', lineHeight: '15px', color: '#484745', marginTop: '-5px', textAlign: 'left' }}><span>Powered by</span><img src={Transparent} alt="poweredby" /></h3>
                        </div>
                        <div class="browser-wrap">

                            <img src={Transparent} alt="gif" />
                            <br></br>
                            Our website works best in the newest and last prior version of these browsers: <br></br>Google Chrome.
                        </div>


                        <ul class="policy-link">
                            <li><a >Privacy Policy</a></li>
                            <li><a>Terms and Conditions</a></li>
                            <li><a >Rules and Regulations</a></li>
                            <li><a >KYC</a></li>
                            <li><a >Responsible Gaming</a></li>
                            <li><a >About Us</a></li>
                            <li><a >Self-exclusion Policy</a></li>
                            <li><a >Underage Policy</a></li>
                        </ul>

                        <ul class="RnR-link">
                            <li><a >Rules and Regulations</a></li>
                        </ul>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}
