import React, { useState, useEffect } from "react";
import axios from "axios";
import Transparent from "../images/transparent.gif";

export default function Myprofile(props) {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [Comm, setComm] = useState("");

  useEffect(() => {
    axios
      .post("https://millionbet247.com/api/client/clientuserprofile", {
        id: props.userid,
      })
      .then((result) => {
        if (result.status === 210) {
          // console.log(result);
          setfirstname(result.data[0].firstname);
          setlastname(result.data[0].lastname);
          setComm(result.data[0].comm);

        }
      })
      .catch((e) => {
        //setIsError(true);
      });
  }, []);
  return (
    <React.Fragment>
      <div class="col-left">
        <div class="sub_path">
          <div class="path">

            <ul>
              <li class="path-last">
                <a>My Account</a>
              </li>
            </ul>
          </div>
          <ul id="path-pop" class="path-pop"></ul>
          <ul class="menu-list">
            <li>
              <a id="sub_menu_detail" href="/exchange/member/myprofile" class="select">
                My Profile
              </a>
            </li>
            <li>
              <a id="sub_menu_summary" href="/exchange/member/balance">
                Balance Overview
              </a>
            </li>
            <li>
              <a
                id="sub_menu_accountCashStatement_log"
                href="/exchange/member/AccountStatement"
              >
                Account Statement
              </a>
            </li>
            <li>
              <a id="sub_menu_my_bets" href="/exchange/member/mybets">
                My Bets
              </a>
            </li>
            {/* <li>
              <a id="sub_menu_activity_log" href="/exchange/member/activitylog">
                Activity Log
              </a>
            </li> */}
            {/* <li>
              <a id="sub_menu_activity_log" onClick={props.handlepassmodel}>
                Change password
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div class="col-center report">
        <div id="loading" class="loading-wrap" style={{ display: "none" }}>
          <ul class="loading">
            <li>
              <img src={Transparent} alt="gif" />
            </li>
            <li>Loading...</li>
          </ul>
        </div>

        <div id="message" class="message-wrap success">
          <a class="btn-close">Close</a>
          <p></p>
        </div>

        <div class="loading-wrap" style={{ display: "none" }}>
          <ul class="loading">
            <li>
              <img src={Transparent} alt="gif" />
            </li>
            <li>Loading...</li>
          </ul>
        </div>

        <h2>Account Details</h2>

        <div class="event-left">
          <div class="profile-wrap">
            <h3>About You</h3>
            <dl>
              <dt>First Name</dt>
              <dd>{firstname}</dd>

              <dt>Last Name</dt>
              <dd>{lastname}</dd>

              <dt>Birthday</dt>
              <dd>--</dd>

              <dt>E-mail</dt>
              <dd>--</dd>

              <dt>Password</dt>
              <dd>
                ********************************
                <a onClick={props.handlepassmodel} class="favor-set">
                  Edit
                </a>
              </dd>


            </dl>
          </div>

          <div class="profile-wrap">
            <h3>Address</h3>
            <dl>
              <dt>Address</dt>
              <dd>--</dd>

              <dt>Town/City</dt>
              <dd>--</dd>

              <dt>Country</dt>
              <dd>--</dd>

              <dt>Country/State</dt>
              <dd>--</dd>

              <dt>Postcode</dt>
              <dd>--</dd>

              <dt>Timezone</dt>
              <dd>IST</dd>
            </dl>
          </div>
        </div>

        <div class="event-right">
          <div class="profile-wrap">
            <h3>Contact Details</h3>
            <dl>
              <dt>Primary number</dt>
              <dd>--</dd>
            </dl>
          </div>

          <div class="profile-wrap">
            <h3>Setting</h3>
            <dl>
              <dt>Currency</dt>
              <dd>USD</dd>

              <dt>Odds Format</dt>
              <dd>--</dd>
            </dl>
          </div>

          <div class="profile-wrap">
            <h3>Commission</h3>
            <dl>
              <dt>Comm charged</dt>
              <dd>{Comm} %</dd>
            </dl>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
