import React, { useState, useEffect } from 'react'
import Loading from '../images/loading40.gif'
import Transparent from '../images/transparent.gif'
import axios from 'axios';



export default function Mybets(props) {
	const [select, setselect] = useState(0)
	const [currentBet, setcurrentBet] = useState([])


	useEffect(() => {


		axios.post('https://millionbet247.com/api/client/clientcurrentBet1', {
			id: props.userid,
			token: props.utoken,



		})
			.then(result => {

				if (result.status === 200) {

					setcurrentBet(result.data);
				}

			}

			).catch(e => {
				//setIsError(true);
			});

	}, [props.userid]);



	return (


		<React.Fragment>

			<div class="col-left">
				<div class="sub_path">
					<div class="path">

						<ul>
							<li class="path-last"><a >My Account</a></li>
						</ul>
					</div>
					<ul id="path-pop" class="path-pop">
					</ul>
					<ul class="menu-list">
						<li><a id="sub_menu_detail" href="/exchange/member/myprofile">My Profile</a></li>
						<li><a id="sub_menu_summary" href="/exchange/member/balance">Balance Overview</a></li>
						<li><a id="sub_menu_accountCashStatement_log" href="/exchange/member/AccountStatement">Account Statement
						</a></li>
						<li><a id="sub_menu_my_bets" href="/exchange/member/mybets" class="select">My Bets</a></li>
						<li><a id="sub_menu_activity_log" href="/exchange/member/activitylog">Activity Log</a></li>
						{/* <li><a id="sub_menu_activity_log"  onClick={props.handlepassmodel} >Change password</a></li>	 */}
					</ul>
				</div>
			</div>
			<div class="col-center report">
				<div id="loading" class="loading-wrap" style={{ display: 'none' }}>
					<ul class="loading">
						<li><img src={Loading} alt="loading" /></li>
						<li>Loading...</li>
					</ul>
				</div>
				<div id="message" class="message-wrap success">
					<a class="btn-close">Close</a>
					<p></p>
				</div>
				<h2>My Bets</h2>
				<ul style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li><a class="select" href="/exchange/member/mybets">Current Bets</a></li>
					<li><a href="/exchange/member/bethistory">Bets History</a></li>
					<li><a href="/exchange/member/ProfitLoss">Profit &amp; Loss</a></li>
					{/* <li><a href="/exchange/member/casinoProfitLoss">Casino Report</a></li> */}
				</ul>
				<ul class="report-tab-wrap" >
					<li onClick={() => { setselect(0) }} className={`report-tab ${(select === 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
						Exchange
					</li> <li onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
						FancyBet
					</li>
					<li onClick={() => { setselect(1) }} className={`report-tab ${(select === 1) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
						Sportsbook
					</li>
					<li onClick={() => { setselect(2) }} className={`report-tab ${(select === 2) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
						BookMaker
					</li>
					{/* */}
				</ul>
				<div class="function-wrap">
					<ul class="input-list">
						<li><label>Bet Status</label></li>
						<li>
							<select id="betStatus"><option value="">Matched</option>
								{/*<option value="Unmatched">Unmatched</option>
						<option value="Matched">Matched</option>*/}
							</select>
						</li>
						<li></li>

						<li><label>Order By</label></li>
						<li>
							<input id="orderBetPlaced" type="checkbox" />
							<label for="orderBetPlaced">Bet placed</label>
							<input id="orderMarket" type="checkbox" /><label for="orderMarket">Market</label>
						</li>
					</ul>
				</div>

				<table id="Matched_Table" class="table-s">
					<caption>Matched</caption>
					<tbody><tr>
						<th width="9%" class="align-L">Bet ID
						</th>
						<th width="9%" class="align-L">PL ID
						</th>
						<th width="" class="align-L">Market
						</th>
						<th width="12%">Selection
						</th>
						<th width="4%">Type
						</th>
						<th width="8%">Bet placed
						</th>

						<th width="8%">Stake
						</th>
						<th width="8%">Avg. odds matched
						</th>

						<th width="10%" id="userCancelTitle" style={{ display: 'none' }}>User Cancel
						</th>
					</tr>




						{currentBet.map((item, index) => {
							var matchName;
							var runner;
							var odds;
							var matchtype;

							if (item.eventType == '4') {
								matchName = 'CRICKET';
							} else if (item.eventType == '1') {
								matchName = 'SOCCER';
							}
							else if (item.eventType == '2') {
								matchName = 'TENNIS';
							}
							if (item.betType == 'fancy') {
								runner = item.runnerName;
								odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
							}
							else if (item.betType == 'match') {
								odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
								if (item.teamName == 'A') {
									runner = item.runnerName1;
								}
								else if (item.teamName == 'B') {
									runner = item.runnerName2;
								}
								else if (item.teamName == 'T') {
									runner = 'The Draw';
								}
							}


							if (item.type === 'LAGAI') {
								matchtype = 'BACK';
							}
							else if (item.type === 'KHAI') {
								matchtype = 'LAY';
							}
							else {
								matchtype = item.type;
							}




							return (
								<tr key={index} id="matchRow0" style={{ display: 'table-row' }}>
									<td class="align-L"><a id="betID" href="javascript: void(0);" class="expand-close" onclick="BettingHistoryUtilHandler.toggleTx(0)">{item.id}</a></td>
									<td class="align-L" id="playerID">{item.clientId}</td>
									<td id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong><img class="fromto" src={Transparent} />{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
									<td id="matchSelection">{runner}</td>
									<td><span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
									<td><span id="betPlaced" class="small-date">{item.betTime}</span></td>

									<td id="matchStake">{item.amount}</td>
									<td id="matchAvgOdds">{odds}</td>

									<td id="userCancel" style={{ display: 'none' }}></td>
								</tr>
							)
						})}

						<tr id="expand0" class="expand" style={{ display: 'none' }}>
							<td colspan="2"></td>
							<td colspan="8">
								<img class="expand-arrow" src={Transparent} />
								<table id="txTable0">
									<tbody><tr>
										<th>Bet taken
										</th>
										<th width="16%">Odds req.
										</th>
										<th width="17%">Stake
										</th>
										<th width="24%">Liability
										</th>
										<th width="24%">Odds matched
										</th>
									</tr>

										<tr id="txRowTemplate" style={{ display: 'none' }}>
											<td><span id="betTaken" class="small-date"></span></td>
											<td id="txOddsReq"></td>
											<td id="txStake"></td>
											<td id="txLiability"></td>
											<td id="txOddsMatched"></td>
										</tr>
										<tr id="txRow0" style={{ display: 'table-row' }} class="even">
											<td><span id="betTaken" class="small-date">2020-10-27 20:54:45</span></td>
											<td id="txOddsReq">1.16</td>
											<td id="txStake">10.00</td>
											<td id="txLiability"><span class="red">(1.60)</span></td>
											<td id="txOddsMatched">1.16</td>
										</tr></tbody></table>
							</td>
						</tr>


					</tbody>
					{currentBet.length === 0 && <tbody id="Matched_Content"><tr id="noDataTemplate" style={{ display: 'table-row' }}>
						<td class="no-data" colspan="9">
							<p>You have no active bets.</p>
						</td>
					</tr></tbody>}
				</table>
				<table id="Matched_Table" class="table-s">
					<caption>Unmatched</caption>
					<tbody><tr>
						<th width="9%" class="align-L">Bet ID
						</th>
						<th width="9%" class="align-L">PL ID
						</th>
						<th width="" class="align-L">Market
						</th>
						<th width="12%">Selection
						</th>
						<th width="4%">Type
						</th>
						<th width="8%">Bet placed
						</th>

						<th width="8%">Stake
						</th>
						<th width="8%">Avg. odds matched
						</th>

						<th width="10%" id="userCancelTitle" style={{ display: 'none' }}>User Cancel
						</th>
					</tr>




						{currentBet.map((item, index) => {
							var matchName;
							var runner;
							var odds;
							var matchtype;

							if (item.eventType == '4') {
								matchName = 'CRICKET';
							} else if (item.eventType == '1') {
								matchName = 'SOCCER';
							}
							else if (item.eventType == '2') {
								matchName = 'TENNIS';
							}
							if (item.betType == 'fancy') {
								runner = item.runnerName;
								odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
							}
							else if (item.betType == 'match') {
								odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
								if (item.teamName == 'A') {
									runner = item.runnerName1;
								}
								else if (item.teamName == 'B') {
									runner = item.runnerName2;
								}
								else if (item.teamName == 'T') {
									runner = 'The Draw';
								}
							}


							if (item.type === 'LAGAI') {
								matchtype = 'BACK';
							}
							else if (item.type === 'KHAI') {
								matchtype = 'LAY';
							}
							else {
								matchtype = item.type;
							}




							return (
								<tr key={index} id="matchRow0" style={{ display: 'table-row' }}>
									<td class="align-L"><a id="betID" href="javascript: void(0);" class="expand-close" onclick="BettingHistoryUtilHandler.toggleTx(0)">{item.id}</a></td>
									<td class="align-L" id="playerID">{item.clientId}</td>
									<td id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong><img class="fromto" src={Transparent} />{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
									<td id="matchSelection">{runner}</td>
									<td><span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
									<td><span id="betPlaced" class="small-date">{item.betTime}</span></td>

									<td id="matchStake">{item.amount}</td>
									<td id="matchAvgOdds">{odds}</td>

									<td id="userCancel" style={{ display: 'none' }}></td>
								</tr>
							)
						})}

						<tr id="expand0" class="expand" style={{ display: 'none' }}>
							<td colspan="2"></td>
							<td colspan="8">
								<img class="expand-arrow" src={Transparent} />
								<table id="txTable0">
									<tbody><tr>
										<th>Bet taken
										</th>
										<th width="16%">Odds req.
										</th>
										<th width="17%">Stake
										</th>
										<th width="24%">Liability
										</th>
										<th width="24%">Odds matched
										</th>
									</tr>

										<tr id="txRowTemplate" style={{ display: 'none' }}>
											<td><span id="betTaken" class="small-date"></span></td>
											<td id="txOddsReq"></td>
											<td id="txStake"></td>
											<td id="txLiability"></td>
											<td id="txOddsMatched"></td>
										</tr>
										<tr id="txRow0" style={{ display: 'table-row' }} class="even">
											<td><span id="betTaken" class="small-date">2020-10-27 20:54:45</span></td>
											<td id="txOddsReq">1.16</td>
											<td id="txStake">10.00</td>
											<td id="txLiability"><span class="red">(1.60)</span></td>
											<td id="txOddsMatched">1.16</td>
										</tr></tbody></table>
							</td>
						</tr>


					</tbody>
					{currentBet.length === 0 && <tbody id="Matched_Content"><tr id="noDataTemplate" style={{ display: 'table-row' }}>
						<td class="no-data" colspan="9">
							<p>You have no active bets.</p>
						</td>
					</tr></tbody>}
				</table>


			</div>
		</React.Fragment>

	)
}
