import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Transparent from '../images/transparent.gif'
import {toast} from 'react-toastify'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
toast.configure()

window.oneclick = 0;
window.oneclickStake = 0;
window.isOneClick = false;
export default function Oneclickbet(props) {
    const [clickbtn, setClickbtn] = useState(1)
    const [editStake, seteditStake]=useState(false);
    const [buttonStake, setbuttonStake] = useState(true);

    const [clickok, setClickOk] = useState(false);
    const [stake1, setStake1] = useState(0);
    const [stake2, setStake2] = useState(0);
    const [stake3, setStake3] = useState(0);
    const [stake4, setStake4] = useState(0);

    

    useEffect(() => {
      
      if(cookies.get('defaultStake') == null){  
        cookies.set('defaultStake', 1, { path: '/' });
      }
     
      var defaultStake = cookies.get('defaultStake');
      setClickbtn(parseInt(defaultStake));

      axios.post('https://millionbet247.com/api/client/clientgetclickstake',{
         id:props.userid,
         token:props.utoken,
        
       })
       .then(result => {
          if(result.status === 210){
           
              
               setStake1(result.data[0].Stake1);
               setStake2(result.data[0].Stake2);
               setStake3(result.data[0].Stake3);
               setStake4(result.data[0].Stake4);
               if(defaultStake == 1){
                 window.oneclickStake = result.data[0].Stake1;
               }
               else if(defaultStake == 2){
                 window.oneclickStake = result.data[0].Stake2;
               }
               else if(defaultStake == 3){
                 window.oneclickStake = result.data[0].Stake3;
               }
               else if(defaultStake == 4){
                window.oneclickStake = result.data[0].Stake4;
               }
              
             }
         
        }
        
      ).catch(e => {
        //setIsError(true);
      });
         
        
    }, [])



    const handleChange4 = (event) => {
      setStake4(event.target.value);
    };

    const handleChange3 = (event) => {
      setStake3(event.target.value);
    };
    const handleChange2 = (event) => {
      setStake2(event.target.value);
    };
    const handleChange1 = (event) => {
      setStake1(event.target.value);
    };

   const handleClickOk= () =>{
     setClickOk(!clickok)
     window.oneclick = window.oneclick + 1;
   }
    
  const handleEditClick = () => {
    if(window.oneclick){
      seteditStake(true);
      setbuttonStake(false);
    }
  };

  const hadleSaveClick = (e) => {
     e.preventDefault();
    seteditStake(false);
    setbuttonStake(true);
   
   
    axios.post('https://millionbet247.com/api/client/clientsaveclickstake',{
       id:props.userid,
       token:props.utoken,
       stake1:stake1,
       stake2:stake2,
       stake3:stake3,
       stake4:stake4
     })
     .then(result => {
        if(result.status === 210){
             //console.log('saveclick successful');
             toast.success('One click bet saved Succefully', {position:toast.POSITION.TOP_CENTER})
          
           }
       
        if(result.status === 220){
        //  console.log('saveclick unsucessful');
          
          
        }
      }
      
    ).catch(e => {
      //setIsError(true);
    });
  };





    return (
        <React.Fragment>
            <div class="one_click-wrap">
             { window.oneclick === 0 &&
             <div>
                 <div class="overlay"></div>
             <div id="oneClickBetDialog" class="dialog-wrap white-halo">
                <h4>One Click Bet ON</h4>
                <div class="dialog-content">
                   <p>Stake selected will be placed immediately once you click on the market odds.
                      <br></br><span class="attention">
                      Attention: Back/Lay at your own risk</span>
                   </p>
                </div>
                <ul class="btn-wrap break">
                   <li><a id="OkayAccept"  class="btn-send" onClick={handleClickOk} style={{cursor:' pointer'}}>OK</a></li>
                </ul>
                <img class="arrow-dialog" src={Transparent} alt="arrow"/>
             </div>
             </div>
            }
             {editStake === false && (
             <div id="oneClickBetStakeBox" class="one_click-setting" >
                <h4>One Click Bet Stake</h4>
                <ul class="one_click-stake">
             <li onClick ={()=>{if(window.oneclick){setClickbtn(1);cookies.set('defaultStake', 1, { path: '/' }); window.oneclickStake = stake1;}}}><a  className={`btn ${(clickbtn===1 )? "select": "null"}`}  style={{cursor:' pointer'}}>{stake1}</a></li>
             <li onClick ={()=>{if(window.oneclick){setClickbtn(2);cookies.set('defaultStake', 2, { path: '/' });window.oneclickStake = stake2;}}}><a  className={`btn ${(clickbtn===2 )? "select": "null"}`} style={{cursor:' pointer'}}>{stake2}</a></li>
             <li onClick ={()=>{if(window.oneclick){setClickbtn(3);cookies.set('defaultStake', 3, { path: '/' });window.oneclickStake = stake3;}}}><a  className={`btn ${(clickbtn===3 )? "select": "null"}`} style={{cursor:' pointer'}}>{stake3}</a></li>
             <li onClick ={()=>{if(window.oneclick){setClickbtn(4);cookies.set('defaultStake', 4, { path: '/' });window.oneclickStake = stake4;}}}><a  className={`btn ${(clickbtn===4 )? "select": "null"}`} style={{cursor:' pointer'}}>{stake4}</a></li>
                   
                 
                </ul>
                <a  id="one_click_edit" onClick={handleEditClick} class="a-edit" style={{cursor:' pointer', display:'inherit'}}>Edit<img src={Transparent} alt="edit"/></a>
             </div>)}

             {buttonStake === false && (
             <div id="editOneClickBetStakeBox" class="one_click-setting">
                <h4>Edit One Click Bet Stake</h4>
                <ul class="one_click-stake">
                   <li><input type="text"  name="editBetStake" onChange={handleChange1} value={stake1} maxLength="6" /></li>
                   <li><input type="text"  name="editBetStake" onChange={handleChange2} value={stake2} maxLength="6" /></li>
                   <li><input type="text"  name="editBetStake" onChange={handleChange3} value={stake3} maxLength="6" /></li>
                   <li><input type="text"  name="editBetStake" onChange={handleChange4} value={stake4} maxLength="6" /></li>
                   
                  
                </ul>
                <a  id="one_click_save" onClick={(e)=>{hadleSaveClick(e)}}  class="btn-send" style={{cursor:' pointer'}}>Save</a>
             </div>)}
          </div>
        </React.Fragment>
    )
}
