import React, { useState } from 'react'
import Cup from '../images/cup.gif'
import OpenBets from './openBets'


export default function LeftSide(props) {


  return (
    <div>

      <div id="openBetsLeftSide" className="overlay left-side" >
        <div id="openBetSlide" className="side-wrap">
          <div id="sideHead" className="side-head">
            <h3 className="a-open_bets"><img src={Cup} alt="a-open_bets" />Open Bets</h3>
            <a onClick={props.handleleftsideclose} className="close">.</a>
          </div>
          <div className="side-content">

            <OpenBets utoken={props.utoken} userid={props.userid} />

          </div>
        </div>
      </div>
    </div>

  )
}
