import React, { useState, useEffect } from 'react'
import Transparent from '../images/transparent.gif'
import Loading from "../images/loading40.gif"
import CricketMatches from './CricketMatches';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import axios from 'axios'

// import Ipl1 from '../images/vatility.png'
import Ipl2 from '../images/photo12.png'
import Soccer from '../images/photo13.png'
import Tennis from '../images/photo14.png'
import Eid from '../images/kv/hundred.png'

const photos = [
   {
      name: 'photo 1',
      url: [Eid]
   },
   {
      name: 'photo 2',
      url: [Soccer]
   },
   {
      name: 'photo 3',
      url: [Tennis]
   },

   // {
   //    name:'photo 4',
   //    url:[Ipl1]
   // }
   ,
   {
      name: 'photo 5',
      url: [Ipl2]
   }

]

export function Home(props) {


   const [eventType, setEvent] = useState(4);
   //const [nav2, setNav2] = useState();
   //const slider2 = useRef(null);
   // vvvv




   const settings = {
      dots: true,
      slide: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 800,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
      className: 'slidesN'
   }

   return (
      <div>
         <div class="kv-wrap ">
            <Slider  {...settings}>
               {photos.map((photo, id) => {
                  return (
                     <div key={id}>
                        <img width="100%" src={photo.url} alt="cric" />
                     </div>
                  )
               })}
            </Slider>

         </div>
         <div id="gameHighlightWrap" style={{ marginBottom: '0' }} class="game-highlight-wrap col3 ">
            <h3 style={{ textAlign: 'left', fontWeight: 'bold', padding: '3px 2px 3px 10px' }}>Sports Highlights<div class="highlight-sorting">
               <label for="lang">View by</label>
               <div class="select">
                  <select name="View">
                     <option value="competitionName" orderby="asc" priority="asc">Competition</option>
                     <option value="openDateTime" orderby="asc" inplay="desc">Time</option>
                     <option value="totalMatched" orderby="desc">Matched</option>
                  </select>
               </div>
            </div>
            </h3>
            <ul id="highlightLabel" class="tab-highlight">
               <li onClick={() => { setEvent(4) }} class="">
                  <a id="highlightTab0" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 4) ? "select" : "null"}`}>Cricket</a></li>
               <li onClick={() => { setEvent(1) }} class=""><a id="highlightTab1" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 1) ? "select" : "null"}`}>Soccer</a></li>
               <li onClick={() => { setEvent(2) }} class=""><a id="highlightTab2" style={{ cursor: ' pointer' }} className={`HLTab ${(eventType === 2) ? "select" : "null"}`} >Tennis</a></li>
            </ul>
         </div>
         <div class="game-list">


            <div class="game-list tab-content">

               <ul class="slip-head">
                  <li class="col-game" style={{ width: 'calc(64% - 42px)' }}></li>
                  <li class="col-visit">1</li>
                  <li class="col-draw">x</li>
                  <li class="col-home">2</li>
                  <li class="col-info"></li>
               </ul>
               <div id="tab2">
                  {eventType === 4 && <CricketMatches changeState={props.changeState} />}
                  {eventType === 1 && <SoccerMatches changeState={props.changeState} />}
                  {eventType === 2 && <TennisMatches changeState={props.changeState} />}



               </div>


            </div>

         </div>



         <div id="fullMarketEventRight" class="event-right" style={{ display: 'none' }}></div>



         <div id="multiMarketsWrap" style={{ display: 'none' }}>

            <h2 id="header">Multi Markets</h2>

            <p id="noMultiMarkets" style={{ display: 'none' }}>There are currently no followed multi markets.</p>


            <div id="multiMarketsTemplate" class="game-wrap" style={{ display: 'none' }}>
               <h3>
                  <a id="multiMarketPin" class="add-pin" style={{ cursor: ' pointer' }} title="Add to Multi Markets">.</a>
                  <a id="multiMarketRefresh" class="multi_refresh" style={{ cursor: ' pointer' }}>.</a>
                  <a id="eventHeader" class="multi_name" style={{ cursor: ' pointer' }}>
                     <span id="marketName" class="multi-event_type"></span>


                  </a>
                  <span class="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>
               </h3>

               <div id="multiMarketBetsWrap" class="bets-wrap">

                  <span id="selectionCounts" class="refer_only">For reference only</span>

                  <div id="multiMarketLoading" class="loading-wrap multi-load" style={{ display: 'none' }}>
                     <ul class="loading">
                        <li><img src={Loading} alt="loading" /></li>
                        <li>Loading...</li>
                     </ul>
                  </div><table id="multiMarketBoard" class="bets">




                     <tbody>
                        <tr class="bet-all">
                           <td></td>
                           <td id="backPercent" class="refer-bet" colSpan="2"></td>
                           <td><a id="backAll" class="back-all"><img src={Transparent} alt="backAll" /><span>Back all</span></a></td>
                           <td><a id="layAll" class="lay-all"><img src={Transparent} alt="layAll" /><span>Lay all</span></a></td>
                           <td id="layPercent" class="refer-book" colSpan="2"></td>
                        </tr>
                     </tbody>
                  </table>

               </div>
            </div>

         </div>
      </div>

   )
}

export default React.memo(Home);