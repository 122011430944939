import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
require('moment-timezone');

export default function CricketMatches(props) {

  const [match, setMatch] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";


  // vvvv

  useEffect(() => {

    Axios.get(
      `https://millionbet247.com/api/client/clientcricket`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }

      })


      .catch(error => console.log(error));

    Axios.get(
      `https://millionbet247.com/api/client/clientchecktv`)

      .then(result => {
//console.log(result.data,'data');
        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://millionbet247.com/api/client/clientcheckbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://millionbet247.com/api/client/clientfancy`)

      .then(result => {

        setFancy(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }

      })


      .catch(error => console.log(error));




  }, []);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");
    if (props.isLoggedIn == true) {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }

    }


  }, [match, cricketUpdate]);

  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

    //  console.log('2', list);
      //  localStorage.clear();

    }
  }




  return (
    <React.Fragment>

      {newrefresh === true &&
        <div class="loading-overlay" id="loading">
          <div class="loading-wrap" style={{ display: " flex" }}>
            <div class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}

      {newrefresh === false && <div  >

        {match.map(function (item, id) {

          var showColor = false;
          var showFancy = false;
          var isFancy = false;
          var showtv = false;
          var isbookmaker = false;


          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }



          if (fancy.includes(item.eventId)) {
            isFancy = true;

          }
         // console.log(tv.includes(item.eventId),'tvicon');
          if (tv.includes(item.eventId)) {
            showtv = true;
          }
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          }

          if (moment().diff(moment(item.marketStartTime), 'minutes') >= 0) {
            showColor = true;
            showFancy = true;
          }

          return (
            <div key={id}>

              <li id="highlightEvent_-10020846" className={`inplay${showColor ? "-on" : "-off"}`}>
                <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`} id="eventName" onClick={() => { props.changeState(true); }}>
                  <dl>
                    <dt>
                      
                        <span id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>
                      <span id="lowLiquidityTag" className="game-low_liq" style={{ display: "none" }}>Low Liquidity</span>
                      {isFancy && <span id="fancyBetIcon" className={`game-fancy ${showFancy ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>Fancy</span>}
                      {isbookmaker &&
                        <span id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

                      <span id="feedingSiteIcon" className="game-sportsbook" style={{ display: "none" }}>Sportsbook</span>
                      <span id="sportsBookIcon_1" className="game-sportsbook" style={{ display: "none" }}>Premium Tennis</span>
                      <span id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "none" }}>Premium Cricket</span>
                      {moment() >= moment(item.marketStartTime) &&
                        <span id="dateTimeInfo" className="time">In-Play</span>}
                      {moment() < moment(item.marketStartTime) && <span id="dateTimeInfo" className="time">{matchTime}</span>}
                      <span id="sportsBookEIcon_1" className="game-E" style={{ display: "none" }}><i></i>Soccer</span>
                      <span id="sportsBookEIcon_4" className="game-E" style={{ display: "none" }}><i></i>Cricket</span>
                    </dt>
                    <dd>
                      {item.eventName} </dd>
                  </dl>
                </Link>
                <a id={MKPIN + id} className={`pin-off`} onClick={() => Multimarketpin(id, item.eventId)}></a>
              </li>
            </div>
          )
        })}
      </div>}
    </React.Fragment>
  )
}
