import React, { useEffect, useState } from 'react'
import Transparent from '../images/transparent.gif'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Loading from "../images/loading40.gif"
import moment from 'moment';
require('moment-timezone');


export default function InplayContent(props) {


  const [CricketBetslip, setCricketBetslip] = useState(false);
  const [SoccerBetslip, setSoccerBetslip] = useState(false);
  const [TennisBetslip, setTennisBetslip] = useState(false);
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);

  useEffect(() => {
    axios
      .get(`https://millionbet247.com/api/client/clienttennis`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }

        });
        setTennis(obj);
      })
      .catch((error) => console.log('error'));

    axios
      .get(`https://millionbet247.com/api/client/clientsoccer`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log('error'));

    axios
      .get(`https://millionbet247.com/api/client/clientcricket`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });

        setcricket(obj);
      })

      .catch((error) => console.log('error'));

    axios
      .get(`https://millionbet247.com/api/client/clientfancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log('error'));
  }, []);


  return (
    <React.Fragment>

      {refresh &&

        <div className="loading-overlay" id="loading">
          <div className="loading-wrap" style={{ display: 'flex' }}>
            <div className="loading"><div /><div /></div><p>Loading...</p>
          </div></div>

      }

      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0) && refresh === false && <React.Fragment>

        {cricket.length > 0 && <div onClick={() => { setCricketBetslip(!CricketBetslip) }} className={`game-wrap col3 ${(CricketBetslip === true) ? "close" : "null"}`}>

          <h3>
            <a class="to-expand ng-binding" toggle-class="">Cricket</a>
          </h3>
          <ul class="slip-head">
            <li class="col-game"></li>
            <li class="col-visit" style={{ paddingLeft: '142px' }}>1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>
          <div id="inplayGameList" class="game-list first">
            <div id="noDataDiv" class="no-data" style={{ display: 'none' }}>
              <p>There are no events to be displayed.</p>
            </div>
          </div>


          <React.Fragment >
            {cricket.map(function (item, id) {

              var showColor = false;
              var showFancy = false;
              var isFancy = false;

              if (fancy.includes(item.eventId)) {
                isFancy = true;

              };

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showColor = true;
              }
              if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                showFancy = true;
              }


              if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
                item.runner1BackRate1 = '--';
              }
              if (item.runner1BackRate2 == null || item.runner1BackRate2 === ' ') {
                item.runner1BackRate2 = '--';
              }
              if (item.runner1BackRate3 == null || item.runner1BackRate3 === ' ') {
                item.runner1BackRate3 = '--';
              }
              if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
                item.runner1LayRate1 = '--';
              }
              if (item.runner1LayRate2 == null || item.runner1LayRate2 === ' ') {
                item.runner1LayRate2 = '--';
              }
              if (item.runner1LayRate3 == null || item.runner1LayRate3 === ' ') {
                item.runner1LayRate3 = '--';
              }





              return (
                <React.Fragment key={id}>

                  {moment() >= moment(item.marketStartTime) &&
                    <>
                      {/* <dl class="game-list-col" key={id}>
                <dt>
                {isFancy && 
                 <a    id="fancyBetIcon"><span className={`game-fancy ${showFancy ? "in-play" : "no-play"}`}>Fancy</span></a> } 
                {moment() >= moment(item.marketStartTime)  &&  
                    <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                   
                   <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`}  class="ng-binding" onClick={()=>{props.changeState(true);}}>{item.eventName}</Link>
                   {moment() >= moment(item.marketStartTime)  &&  
                   <span id="dateTimeInfo" class="game-list-time"><span class="in_play">In-Play</span></span>}
                   <a   id="iconLive" style={{cursor:' pointer', display:'none'}}><img class="icon-live" src={Transparent} alt="In-Play"/></a>
                </dt>
                <dd id="selectTemp" class="col-visit">
                   
                   <a   id="back1_0" class="btn-back ng-binding" >{item.runner1BackRate1}</a>
                   <a   id="lay1_0" class="btn-lay ng-binding" >{item.runner1LayRate1}</a>
                </dd>
                <dd class="col-draw">
                  <a   id="btnBack"  class="btn-back">{item.runner1BackRate2}</a>
                  <a   id="btnLay"  class="btn-lay">{item.runner1LayRate2}</a>
                </dd>
                <dd class="col-home">
                   <a   id="back2_0" class="btn-back ng-binding">{item.runner1BackRate3}</a> 
                   <a   id="lay2_0" class="btn-lay ng-binding" >{item.runner1LayRate3}</a>
                </dd>
                <dd class="col-info">
                <a   class="add-pin" style={{cursor:' pointer'}} title="Add to Multi Markets" >.</a>
                </dd>
            </dl>

          */}
                      <dl key={id} className={`game-list-col-rate  ${!showColor ? "disable" : ""}`} style={{ display: 'flex' }} >
                        <dt id="eventInfo" style={{ cursor: 'pointer', width: 'calc(51.4% - -60px)' }}>
                          {moment() >= moment(item.marketStartTime) &&
                            <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                          {moment() < moment(item.marketStartTime) &&
                            <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}

                          <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }} >{item.eventName}</Link>
                          {moment() >= moment(item.marketStartTime) &&
                            <span id="dateTimeInfo" class="game-list-time">
                              <span class="in_play">In-Play</span></span>}
                          <span className="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>
                          {isFancy &&
                            <a id="fancyBetIcon">
                              <span className={`game-fancy ${showFancy ? "in-play" : "no-play"}`}>Fancy</span></a>}

                          <span class="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>
                          <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex', marginLeft: '3px' }}>BookMaker</span>
                        </dt>

                        <dd id="matched" className="col-matched" style={{ display: 'block' }}>PTE0</dd>
                        <dd id="selectTemp" class="col-visit">

                          <a id="back1_0" class="btn-back ng-binding" >{item.runner1BackRate1}</a>
                          <a id="lay1_0" class="btn-lay ng-binding" >{item.runner1LayRate1}</a>
                        </dd>

                        <dd class="col-draw">
                          <a id="btnBack" class="btn-back">{item.runner1BackRate2}</a>
                          <a id="btnLay" class="btn-lay">{item.runner1LayRate2}</a>
                        </dd>
                        <dd class="col-home">
                          <a id="back2_0" class="btn-back ng-binding">{item.runner1BackRate3}</a>
                          <a id="lay2_0" class="btn-lay ng-binding" >{item.runner1LayRate3}</a>
                        </dd>
                        <dd className="col-info">
                          <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
                        </dd>
                      </dl>

                    </>
                  }

                </React.Fragment>
              )
            })}
          </React.Fragment>

        </div>}



        {Soccer.length > 0 && <div onClick={() => { setSoccerBetslip(!SoccerBetslip) }} className={`game-wrap col3 ${(SoccerBetslip === true) ? "close" : "null"}`}>
          <h3>
            <a class="to-expand ng-binding" toggle-class="">Soccer</a>
          </h3>
          <ul class="slip-head">
            <li class="col-game"></li>
            <li class="col-visit" style={{ paddingLeft: '142px' }}>1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>
          <div id="noDataDiv" class="no-data" style={{ display: 'none' }} >
            <p>There are no events to be displayed.</p>
          </div>


          {Soccer.map(function (item, id) {

            var showColor = false;

            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showColor = true;
            }

            if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
              item.runner1BackRate1 = '--';
            }
            if (item.runner1BackRate2 == null || item.runner1BackRate2 === ' ') {
              item.runner1BackRate2 = '--';
            }
            if (item.runner1BackRate3 == null || item.runner1BackRate3 === ' ') {
              item.runner1BackRate3 = '--';
            }
            if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
              item.runner1LayRate1 = '--';
            }
            if (item.runner1LayRate2 == null || item.runner1LayRate2 === ' ') {
              item.runner1LayRate2 = '--';
            }
            if (item.runner1LayRate3 == null || item.runner1LayRate3 === ' ') {
              item.runner1LayRate3 = '--';
            }





            return (
              <React.Fragment key={id}>
                {moment() >= moment(item.marketStartTime) &&
                  <>
                    {/* <dl key={id} className={`game-list-col  ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
                    <dt>
                      {moment() >= moment(item.marketStartTime) &&
                        <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                      {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}


                      <Link to={`/exchange/member/fullmarket/1/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.eventName}</Link>

                      {moment() >= moment(item.marketStartTime) &&
                        <span id="dateTimeInfo" class="game-list-time"><span class="in_play">In-Play</span></span>}
                      {moment() < moment(item.marketStartTime) && <span class="game-list-time ng-binding ">{item.marketStartTime}</span>}

                    </dt>
                    <dd id="selectTemp" class="col-visit">

                      <a id="back1_0" class="btn-back ng-binding" >{item.runner1BackRate1}</a>
                      <a id="lay1_0" class="btn-lay ng-binding" >{item.runner1LayRate1}</a>
                    </dd>
                    <dd class="col-draw">
                      <a id="btnBack" class="btn-back ">{item.runner1BackRate2}</a>
                      <a id="btnLay" class="btn-lay ">{item.runner1LayRate2}</a>
                    </dd>
                    <dd class="col-home">
                      <a id="back2_0" class="btn-back ng-binding">{item.runner1BackRate3}</a>
                      <a id="lay2_0" class="btn-lay ng-binding" >{item.runner1LayRate3}</a>
                    </dd>
                    <dd class="col-info">
                      <a class="add-pin " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                    </dd>
                  </dl> */}
                    <dl key={id} className={`game-list-col-rate  ${!showColor ? "disable" : ""}`} style={{ display: 'flex' }} >
                      <dt id="eventInfo" style={{ cursor: 'pointer', width: 'calc(51.4% - -60px)' }}>
                        {moment() >= moment(item.marketStartTime) &&
                          <img id="playIcon" className="icon-in_play" src={Transparent} />
                        }
                        {moment() < moment(item.marketStartTime) &&
                          <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />
                        }

                        <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }} >{item.eventName}</Link>
                        <span id="dateTimeInfo" className="game-list-time">
                          <span className="in_play">In-Play</span>
                        </span>
                        <span className="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>

                        <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex', marginLeft: '3px' }}>BookMaker</span>
                      </dt>

                      <dd id="matched" className="col-matched" style={{ display: 'block' }}>PTE0</dd>
                      <dd id="selectTemp" class="col-visit">

                        <a id="back1_0" class="btn-back ng-binding" >{item.runner1BackRate1}</a>
                        <a id="lay1_0" class="btn-lay ng-binding" >{item.runner1LayRate1}</a>
                      </dd>
                      <dd class="col-draw">
                        <a id="btnBack" class="btn-back ">{item.runner1BackRate2}</a>
                        <a id="btnLay" class="btn-lay ">{item.runner1LayRate2}</a>
                      </dd>
                      <dd class="col-home">
                        <a id="back2_0" class="btn-back ng-binding">{item.runner1BackRate3}</a>
                        <a id="lay2_0" class="btn-lay ng-binding" >{item.runner1LayRate3}</a>
                      </dd>
                      <dd className="col-info">
                        <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
                      </dd>
                    </dl>

                  </>
                }

              </React.Fragment>


            )
          })}

        </div>}




        {Tennis.length > 0 && <div onClick={() => { setTennisBetslip(!TennisBetslip) }} className={`game-wrap col3 ${(TennisBetslip === true) ? "close" : "null"}`}>
          <h3>
            <a class="to-expand ng-binding" toggle-class="">Tennis</a>
          </h3>
          <ul class="slip-head">
            <li class="col-game tennis-game-col" style={{ width: 'calc(51.4 % - -140px)' }}></li>
            <li class="col-visit">1</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>
          <div id="noDataDiv" class="no-data" style={{ display: 'none' }}>
            <p>There are no events to be displayed.</p>
          </div>

          {Tennis.map(function (item, id) {


            var showColor = false;

            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showColor = true;
            }

            if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
              item.runner1BackRate1 = '--';
            }
            if (item.runner1BackRate2 == null || item.runner1BackRate2 === ' ') {
              item.runner1BackRate2 = '--';
            }
            if (item.runner1BackRate3 == null || item.runner1BackRate3 === ' ') {
              item.runner1BackRate3 = '--';
            }
            if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
              item.runner1LayRate1 = '--';
            }
            if (item.runner1LayRate2 == null || item.runner1LayRate2 === ' ') {
              item.runner1LayRate2 = '--';
            }
            if (item.runner1LayRate3 == null || item.runner1LayRate3 === ' ') {
              item.runner1LayRate3 = '--';
            }






            return (

              <React.Fragment key={id}>
                {/* {moment() >= moment(item.marketStartTime) &&
                  <dl key={id} className={`game-list-col  ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
                    <dt>
                      {moment() >= moment(item.marketStartTime) &&
                        <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                      {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}


                      <Link to={`/exchange/member/fullmarket/2/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.eventName}</Link>

                      {moment() >= moment(item.marketStartTime) &&
                        <span id="dateTimeInfo" class="game-list-time"><span class="in_play">In-Play</span></span>}
                      {moment() < moment(item.marketStartTime) && <span class="game-list-time ng-binding ">{item.marketStartTime}</span>}

                    </dt>
                    <dd id="selectTemp" class="col-visit">

                      <a id="back1_0" class="btn-back ng-binding" >{item.runner1BackRate1}</a>
                      <a id="lay1_0" class="btn-lay ng-binding" >{item.runner1LayRate1}</a>
                    </dd>
                    <dd class="col-draw">
                      <a id="btnBack" class="btn-back ">--</a>
                      <a id="btnLay" class="btn-lay ">--</a>
                    </dd>
                    <dd class="col-home">
                      <a id="back2_0" class="btn-back ng-binding">{item.runner1BackRate2}</a>
                      <a id="lay2_0" class="btn-lay ng-binding" >{item.runner1LayRate2}</a>
                    </dd>
                    <dd class="col-info">
                      <a class="add-pin " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                    </dd>
                  </dl>
                } */}
                {moment() >= moment(item.marketStartTime) &&
                  <dl key={id} className={`game-list-col-rate  ${!showColor ? "disable" : ""}`} style={{ display: 'flex' }} >
                    <dt id="eventInfo" style={{ cursor: 'pointer', width: 'calc(51.4% - -187px)' }}>
                      {moment() >= moment(item.marketStartTime) &&
                        <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                      {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}

                      <Link to={`/exchange/member/fullmarket/2/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.eventName}</Link>
                      {moment() >= moment(item.marketStartTime) &&
                        <span id="dateTimeInfo" class="game-list-time"><span class="in_play">In-Play</span></span>}
                      {moment() < moment(item.marketStartTime) && <span class="game-list-time ng-binding ">{item.marketStartTime}</span>}   <span className="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>

                      <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex', marginLeft: '3px' }}>BookMaker</span>
                    </dt>

                    <dd id="matched" className="col-matched" style={{ display: 'block' }}>PTE0</dd>
                    <dd id="selectTemp" className="col-visit">
                      <div className="suspend" style={{ display: 'none' }} >
                        <span>Suspend</span>
                      </div>
                      <a id="btnBack" className="btn-back">1.02</a>
                      <a className="btn-lay" side="Lay" >55</a>
                    </dd>
                    <dd className="col-home">
                      <div className="suspend" style={{ display: 'none' }} id="suspend_31969996_9635344_0_00">
                        <span>Suspend</span>
                      </div>
                      <a id="btnBack" className="btn-back" side="Back" selectionid={9635344} selectionkey="9635344_0_00" runner="P Aggarwal">1.02</a> <a className="btn-lay" side="Lay"
                        selectionid={9635344} selectionkey="9635344_0_00" runner="P Aggarwal">55</a>
                    </dd>
                    <dd className="col-info">
                      <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
                    </dd>
                  </dl>}

              </React.Fragment>
            )
          })}
        </div>
        }
      </React.Fragment>}


      {
        (cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0) && refresh === false && <div id="noDataDiv" class="no-data">
          <p>There are no events to be displayed.</p>
        </div>
      }




    </React.Fragment >
  )
}
