import React from 'react'

export const HorseTomorrow = () => {
    return (
        <React.Fragment>
            <div id="contentBox">
    <div id="countryGroup_US" className="game-wrap-horse" firstmarkettime="2021-12-28 00:01">
        <h3>
            <a className="to-expand">
        <span id="heading">US</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 39 (Next in 12 hrs 54 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31147133" className="game-list-col hs-time" firstmarkettime="2021-12-28 00:01">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Philadelphia (US) 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192781994" title="R4 6f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192781994&quot;,true)" marketdatetime={1640629860000}>00:01</a></li>
                    <li id="liTemp"><a id="market_1_192781997" title="R5 1m Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192781997&quot;,true)" marketdatetime={1640631480000}>00:28</a></li>
                    <li id="liTemp"><a id="market_1_192782000" title="R6 6f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192782000&quot;,true)" marketdatetime={1640633100000}>00:55</a></li>
                    <li id="liTemp"><a id="market_1_192782003" title="R7 1m Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192782003&quot;,true)" marketdatetime={1640634720000}>01:22</a></li>
                    <li id="liTemp"><a id="market_1_192782006" title="R8 1m1f Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192782006&quot;,true)" marketdatetime={1640636340000}>01:49</a></li>
                    <li id="liTemp"><a id="market_1_192782009" title="R9 6f Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192782009&quot;,true)" marketdatetime={1640637960000}>02:16</a></li>
                    <li id="liTemp"><a id="market_1_192782012" title="R10 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147133&marketId=1.192782012&quot;,true)" marketdatetime={1640639580000}>02:43</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31147118" className="game-list-col hs-time" firstmarkettime="2021-12-28 00:20">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Laurel Park (US) 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192781898" title="R4 1m Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781898&quot;,true)" marketdatetime={1640631000000}>00:20</a></li>
                    <li id="liTemp"><a id="market_1_192781901" title="R5 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781901&quot;,true)" marketdatetime={1640632800000}>00:50</a></li>
                    <li id="liTemp"><a id="market_1_192781904" title="R6 6f Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781904&quot;,true)" marketdatetime={1640634600000}>01:20</a></li>
                    <li id="liTemp"><a id="market_1_192781907" title="R7 7f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781907&quot;,true)" marketdatetime={1640636400000}>01:50</a></li>
                    <li id="liTemp"><a id="market_1_192781910" title="R8 6f Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781910&quot;,true)" marketdatetime={1640638200000}>02:20</a></li>
                    <li id="liTemp"><a id="market_1_192781913" title="R9 1m Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147118&marketId=1.192781913&quot;,true)" marketdatetime={1640640000000}>02:50</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31147111" className="game-list-col hs-time" firstmarkettime="2021-12-28 00:25">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Hawthorne (US) 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192781862" title="R1 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781862&quot;,true)" marketdatetime={1640631300000}>00:25</a></li>
                    <li id="liTemp"><a id="market_1_192781863" title="R2 6f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781863&quot;,true)" marketdatetime={1640633100000}>00:55</a></li>
                    <li id="liTemp"><a id="market_1_192781864" title="R3 7f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781864&quot;,true)" marketdatetime={1640634900000}>01:25</a></li>
                    <li id="liTemp"><a id="market_1_192781865" title="R4 1m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781865&quot;,true)" marketdatetime={1640636700000}>01:55</a></li>
                    <li id="liTemp"><a id="market_1_192781866" title="R5 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781866&quot;,true)" marketdatetime={1640638500000}>02:25</a></li>
                    <li id="liTemp"><a id="market_1_192781867" title="R6 1m1f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781867&quot;,true)" marketdatetime={1640640300000}>02:55</a></li>
                    <li id="liTemp"><a id="market_1_192781868" title="R7 6f Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781868&quot;,true)" marketdatetime={1640642100000}>03:25</a></li>
                    <li id="liTemp"><a id="market_1_192781869" title="R8 6f Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781869&quot;,true)" marketdatetime={1640643900000}>03:55</a></li>
                    <li id="liTemp"><a id="market_1_192781870" title="R9 6f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147111&marketId=1.192781870&quot;,true)" marketdatetime={1640645700000}>04:25</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31147126" className="game-list-col hs-time" firstmarkettime="2021-12-28 01:10">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Turf Paradise (US) 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192781943" title="R1 6f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781943&quot;,true)" marketdatetime={1640634000000}>01:10</a></li>
                    <li id="liTemp"><a id="market_1_192781946" title="R2 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781946&quot;,true)" marketdatetime={1640635800000}>01:40</a></li>
                    <li id="liTemp"><a id="market_1_192781949" title="R3 6f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781949&quot;,true)" marketdatetime={1640637600000}>02:10</a></li>
                    <li id="liTemp"><a id="market_1_192781952" title="R4 6f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781952&quot;,true)" marketdatetime={1640639400000}>02:40</a></li>
                    <li id="liTemp"><a id="market_1_192781955" title="R5 5f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781955&quot;,true)" marketdatetime={1640641200000}>03:10</a></li>
                    <li id="liTemp"><a id="market_1_192781958" title="R6 6f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781958&quot;,true)" marketdatetime={1640643000000}>03:40</a></li>
                    <li id="liTemp"><a id="market_1_192781961" title="R7 7f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781961&quot;,true)" marketdatetime={1640644800000}>04:10</a></li>
                    <li id="liTemp"><a id="market_1_192781964" title="R8 1m Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147126&marketId=1.192781964&quot;,true)" marketdatetime={1640646600000}>04:40</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31147120" className="game-list-col hs-time" firstmarkettime="2021-12-28 01:30">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Santa Anita Park (US) 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192781916" title="R1 1m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781916&quot;,true)" marketdatetime={1640635200000}>01:30</a></li>
                    <li id="liTemp"><a id="market_1_192781919" title="R2 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781919&quot;,true)" marketdatetime={1640637240000}>02:04</a></li>
                    <li id="liTemp"><a id="market_1_192781922" title="R3 1m1f Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781922&quot;,true)" marketdatetime={1640639100000}>02:35</a></li>
                    <li
                        id="liTemp"><a id="market_1_192781925" title="R4 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781925&quot;,true)" marketdatetime={1640640960000}>03:06</a></li>
                        <li id="liTemp"><a id="market_1_192781928" title="R5 7f Allw" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781928&quot;,true)" marketdatetime={1640642820000}>03:37</a></li>
                        <li id="liTemp"><a id="market_1_192781931" title="R6 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781931&quot;,true)" marketdatetime={1640644620000}>04:07</a></li>
                        <li id="liTemp"><a id="market_1_192781934" title="R7 1m Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781934&quot;,true)" marketdatetime={1640646480000}>04:38</a></li>
                        <li
                            id="liTemp"><a id="market_1_192781937" title="R8 7f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781937&quot;,true)" marketdatetime={1640648400000}>05:10</a></li>
                            <li id="liTemp"><a id="market_1_192781940" title="R9 1m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31147120&marketId=1.192781940&quot;,true)" marketdatetime={1640650380000}>05:43</a></li>
                </ul>
            </dd>
        </dl>
    </div>
    <div id="countryGroup_GB" className="game-wrap-horse" firstmarkettime="2021-12-28 00:10">
        <h3>
            <a className="to-expand">
        <span id="heading">GB</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 23 (Next in 13 hrs 3 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31146951" className="game-list-col hs-time" firstmarkettime="2021-12-28 00:10">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Wolverhampton 27th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192776948" title="1m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146951&marketId=1.192776948&quot;,true)" marketdatetime={1640630400000}>00:10</a></li>
                    <li id="liTemp"><a id="market_1_192776953" title="1m1f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146951&marketId=1.192776953&quot;,true)" marketdatetime={1640632200000}>00:40</a></li>
                    <li id="liTemp"><a id="market_1_192776958" title="5f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146951&marketId=1.192776958&quot;,true)" marketdatetime={1640634000000}>01:10</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146914" className="game-list-col hs-time" firstmarkettime="2021-12-28 17:25">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Leicester 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192775921" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775921&quot;,true)" marketdatetime={1640692500000}>17:25</a></li>
                    <li id="liTemp"><a id="market_1_192775926" title="2m Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775926&quot;,true)" marketdatetime={1640694480000}>17:58</a></li>
                    <li id="liTemp"><a id="market_1_192775931" title="2m4f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775931&quot;,true)" marketdatetime={1640696580000}>18:33</a></li>
                    <li id="liTemp"><a id="market_1_192775936" title="2m4f Nov Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775936&quot;,true)" marketdatetime={1640698680000}>19:08</a></li>
                    <li id="liTemp"><a id="market_1_192775941" title="1m7f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775941&quot;,true)" marketdatetime={1640700780000}>19:43</a></li>
                    <li id="liTemp"><a id="market_1_192775946" title="2m4f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775946&quot;,true)" marketdatetime={1640702700000}>20:15</a></li>
                    <li id="liTemp"><a id="market_1_192775951" title="1m7f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146914&marketId=1.192775951&quot;,true)" marketdatetime={1640704800000}>20:50</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146930" className="game-list-col hs-time" firstmarkettime="2021-12-28 17:40">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Catterick 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192776748" title="2m3f Cond Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776748&quot;,true)" marketdatetime={1640693400000}>17:40</a></li>
                    <li
                        id="liTemp"><a id="market_1_192776753" title="2m3f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776753&quot;,true)" marketdatetime={1640695500000}>18:15</a></li>
                        <li id="liTemp"><a id="market_1_192776758" title="3m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776758&quot;,true)" marketdatetime={1640697900000}>18:55</a></li>
                        <li id="liTemp"><a id="market_1_192776763" title="2m3f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776763&quot;,true)" marketdatetime={1640700000000}>19:30</a></li>
                        <li id="liTemp"><a id="market_1_192776768" title="1m7f Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776768&quot;,true)" marketdatetime={1640701800000}>20:00</a></li>
                        <li
                            id="liTemp"><a id="market_1_192776773" title="1m7f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146930&marketId=1.192776773&quot;,true)" marketdatetime={1640703720000}>20:32</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146921" className="game-list-col hs-time" firstmarkettime="2021-12-28 20:45">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Newcastle 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192776201" title="1m2f Nov Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776201&quot;,true)" marketdatetime={1640704500000}>20:45</a></li>
                    <li id="liTemp"><a id="market_1_192776206" title="7f Nursery" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776206&quot;,true)" marketdatetime={1640706600000}>21:20</a></li>
                    <li id="liTemp"><a id="market_1_192776211" title="7f Nov Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776211&quot;,true)" marketdatetime={1640708400000}>21:50</a></li>
                    <li id="liTemp"><a id="market_1_192776216" title="7f Nov Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776216&quot;,true)" marketdatetime={1640710200000}>22:20</a></li>
                    <li id="liTemp"><a id="market_1_192776221" title="1m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776221&quot;,true)" marketdatetime={1640712000000}>22:50</a></li>
                    <li id="liTemp"><a id="market_1_192776226" title="1m Nov Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776226&quot;,true)" marketdatetime={1640713800000}>23:20</a></li>
                    <li id="liTemp"><a id="market_1_192776231" title="7f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776231&quot;,true)" marketdatetime={1640715600000}>23:50</a></li>
                </ul>
            </dd>
        </dl>
    </div>
    <div id="countryGroup_NZ" className="game-wrap-horse" firstmarkettime="2021-12-28 04:30">
        <h3>
            <a className="to-expand">
        <span id="heading">NZ</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 28 (Next in 17 hrs 23 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31146772" className="game-list-col hs-time" firstmarkettime="2021-12-28 04:30">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Westport (NZL) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192774411" title="R1 2600m Trot S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774411&quot;,true)" marketdatetime={1640646000000}>04:30</a></li>
                    <li id="liTemp"><a id="market_1_192774413" title="R2 2000m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774413&quot;,true)" marketdatetime={1640647560000}>04:56</a></li>
                    <li id="liTemp"><a id="market_1_192774415" title="R3 2600m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774415&quot;,true)" marketdatetime={1640649120000}>05:22</a></li>
                    <li id="liTemp"><a id="market_1_192774417" title="R4 2600m Trot S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774417&quot;,true)" marketdatetime={1640651100000}>05:55</a></li>
                    <li id="liTemp"><a id="market_1_192774419" title="R5 2000m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774419&quot;,true)" marketdatetime={1640652900000}>06:25</a></li>
                    <li id="liTemp"><a id="market_1_192774421" title="R6 2000m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774421&quot;,true)" marketdatetime={1640654940000}>06:59</a></li>
                    <li id="liTemp"><a id="market_1_192774423" title="R7 2600m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774423&quot;,true)" marketdatetime={1640656920000}>07:32</a></li>
                    <li id="liTemp"><a id="market_1_192774425" title="R8 2600m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774425&quot;,true)" marketdatetime={1640658480000}>07:58</a></li>
                    <li id="liTemp"><a id="market_1_192774427" title="R9 2600m Trot S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774427&quot;,true)" marketdatetime={1640660160000}>08:26</a></li>
                    <li id="liTemp"><a id="market_1_192774429" title="R10 2600m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146772&marketId=1.192774429&quot;,true)" marketdatetime={1640662320000}>09:02</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146486" className="game-list-col hs-time" firstmarkettime="2021-12-28 05:28">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Phar Lap (NZL) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192766330" title="R1 1200m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766330&quot;,true)" marketdatetime={1640649480000}>05:28</a></li>
                    <li id="liTemp"><a id="market_1_192766332" title="R2 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766332&quot;,true)" marketdatetime={1640651520000}>06:02</a></li>
                    <li id="liTemp"><a id="market_1_192766334" title="R3 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766334&quot;,true)" marketdatetime={1640653500000}>06:35</a></li>
                    <li id="liTemp"><a id="market_1_192766336" title="R4 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766336&quot;,true)" marketdatetime={1640655600000}>07:10</a></li>
                    <li id="liTemp"><a id="market_1_192766338" title="R5 1600m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766338&quot;,true)" marketdatetime={1640657880000}>07:48</a></li>
                    <li id="liTemp"><a id="market_1_192766340" title="R6 1600m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766340&quot;,true)" marketdatetime={1640659680000}>08:18</a></li>
                    <li id="liTemp"><a id="market_1_192766342" title="R7 1600m Listed" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766342&quot;,true)" marketdatetime={1640661840000}>08:54</a></li>
                    <li id="liTemp"><a id="market_1_192766344" title="R8 1600m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766344&quot;,true)" marketdatetime={1640664000000}>09:30</a></li>
                    <li id="liTemp"><a id="market_1_192766346" title="R9 2200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766346&quot;,true)" marketdatetime={1640665980000}>10:03</a></li>
                    <li id="liTemp"><a id="market_1_192766348" title="R10 2200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146486&marketId=1.192766348&quot;,true)" marketdatetime={1640668080000}>10:38</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146485" className="game-list-col hs-time" firstmarkettime="2021-12-28 05:42">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Rotorua (NZL) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192766038" title="R1 2200m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766038&quot;,true)" marketdatetime={1640650320000}>05:42</a></li>
                    <li id="liTemp"><a id="market_1_192766040" title="R2 1400m 3yo" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766040&quot;,true)" marketdatetime={1640652420000}>06:17</a></li>
                    <li id="liTemp"><a id="market_1_192766042" title="R3 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766042&quot;,true)" marketdatetime={1640654520000}>06:52</a></li>
                    <li id="liTemp"><a id="market_1_192766044" title="R4 1215m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766044&quot;,true)" marketdatetime={1640656620000}>07:27</a></li>
                    <li id="liTemp"><a id="market_1_192766046" title="R5 1560m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766046&quot;,true)" marketdatetime={1640658840000}>08:04</a></li>
                    <li id="liTemp"><a id="market_1_192766048" title="R6 1560m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766048&quot;,true)" marketdatetime={1640660940000}>08:39</a></li>
                    <li id="liTemp"><a id="market_1_192766050" title="R7 1400m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766050&quot;,true)" marketdatetime={1640663040000}>09:14</a></li>
                    <li id="liTemp"><a id="market_1_192766052" title="R8 1215m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146485&marketId=1.192766052&quot;,true)" marketdatetime={1640665140000}>09:49</a></li>
                </ul>
            </dd>
        </dl>
    </div>
    <div id="countryGroup_AU" className="game-wrap-horse" firstmarkettime="2021-12-28 07:45">
        <h3>
            <a className="to-expand">
        <span id="heading">AU</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 60 (Next in 20 hrs 38 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31146460" className="game-list-col hs-time" firstmarkettime="2021-12-28 07:45">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Yarra Valley (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192764438" title="R1 1009m 3yo" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764438&quot;,true)" marketdatetime={1640657700000}>07:45</a></li>
                    <li id="liTemp"><a id="market_1_192764440" title="R2 1009m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764440&quot;,true)" marketdatetime={1640659800000}>08:20</a></li>
                    <li id="liTemp"><a id="market_1_192764442" title="R3 1209m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764442&quot;,true)" marketdatetime={1640661900000}>08:55</a></li>
                    <li id="liTemp"><a id="market_1_192764444" title="R4 1514m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764444&quot;,true)" marketdatetime={1640664000000}>09:30</a></li>
                    <li id="liTemp"><a id="market_1_192764446" title="R5 1514m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764446&quot;,true)" marketdatetime={1640666100000}>10:05</a></li>
                    <li id="liTemp"><a id="market_1_192764448" title="R6 1968m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764448&quot;,true)" marketdatetime={1640668200000}>10:40</a></li>
                    <li id="liTemp"><a id="market_1_192764450" title="R7 1009m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764450&quot;,true)" marketdatetime={1640670300000}>11:15</a></li>
                    <li id="liTemp"><a id="market_1_192764452" title="R8 1209m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146460&marketId=1.192764452&quot;,true)" marketdatetime={1640672400000}>11:50</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146504" className="game-list-col hs-time" firstmarkettime="2021-12-28 08:24">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Corowa (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192766975" title="R1 900m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766975&quot;,true)" marketdatetime={1640660040000}>08:24</a></li>
                    <li id="liTemp"><a id="market_1_192766977" title="R2 1600m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766977&quot;,true)" marketdatetime={1640662140000}>08:59</a></li>
                    <li id="liTemp"><a id="market_1_192766979" title="R3 1300m CL2" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766979&quot;,true)" marketdatetime={1640664240000}>09:34</a></li>
                    <li id="liTemp"><a id="market_1_192766981" title="R4 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766981&quot;,true)" marketdatetime={1640666340000}>10:09</a></li>
                    <li id="liTemp"><a id="market_1_192766983" title="R5 1400m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766983&quot;,true)" marketdatetime={1640668440000}>10:44</a></li>
                    <li id="liTemp"><a id="market_1_192766985" title="R6 1200m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766985&quot;,true)" marketdatetime={1640670540000}>11:19</a></li>
                    <li id="liTemp"><a id="market_1_192766987" title="R7 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146504&marketId=1.192766987&quot;,true)" marketdatetime={1640672640000}>11:54</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146513" className="game-list-col hs-time" firstmarkettime="2021-12-28 08:35">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Bowraville (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192768361" title="R1 1370m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768361&quot;,true)" marketdatetime={1640660700000}>08:35</a></li>
                    <li id="liTemp"><a id="market_1_192768363" title="R2 1370m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768363&quot;,true)" marketdatetime={1640662800000}>09:10</a></li>
                    <li id="liTemp"><a id="market_1_192768365" title="R3 1000m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768365&quot;,true)" marketdatetime={1640664900000}>09:45</a></li>
                    <li id="liTemp"><a id="market_1_192768367" title="R4 2200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768367&quot;,true)" marketdatetime={1640667000000}>10:20</a></li>
                    <li id="liTemp"><a id="market_1_192768369" title="R5 1000m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768369&quot;,true)" marketdatetime={1640669100000}>10:55</a></li>
                    <li id="liTemp"><a id="market_1_192768371" title="R6 1000m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146513&marketId=1.192768371&quot;,true)" marketdatetime={1640671200000}>11:30</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146478" className="game-list-col hs-time" firstmarkettime="2021-12-28 08:45">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Orange (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192764543" title="R1 1280m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764543&quot;,true)" marketdatetime={1640661300000}>08:45</a></li>
                    <li id="liTemp"><a id="market_1_192764545" title="R2 1600m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764545&quot;,true)" marketdatetime={1640663400000}>09:20</a></li>
                    <li id="liTemp"><a id="market_1_192764547" title="R3 1400m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764547&quot;,true)" marketdatetime={1640665500000}>09:55</a></li>
                    <li id="liTemp"><a id="market_1_192764549" title="R4 2100m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764549&quot;,true)" marketdatetime={1640667600000}>10:30</a></li>
                    <li id="liTemp"><a id="market_1_192764551" title="R5 1000m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764551&quot;,true)" marketdatetime={1640669700000}>11:05</a></li>
                    <li id="liTemp"><a id="market_1_192764553" title="R6 1000m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764553&quot;,true)" marketdatetime={1640671800000}>11:40</a></li>
                    <li id="liTemp"><a id="market_1_192764555" title="R7 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146478&marketId=1.192764555&quot;,true)" marketdatetime={1640673900000}>12:15</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146464" className="game-list-col hs-time" firstmarkettime="2021-12-28 09:06">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Townsville (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192764461" title="R1 1400m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764461&quot;,true)" marketdatetime={1640662560000}>09:06</a></li>
                    <li id="liTemp"><a id="market_1_192764463" title="R2 1300m 3yo" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764463&quot;,true)" marketdatetime={1640664660000}>09:41</a></li>
                    <li id="liTemp"><a id="market_1_192764465" title="R3 1609m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764465&quot;,true)" marketdatetime={1640666760000}>10:16</a></li>
                    <li id="liTemp"><a id="market_1_192764467" title="R4 1200m CL6" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764467&quot;,true)" marketdatetime={1640668860000}>10:51</a></li>
                    <li id="liTemp"><a id="market_1_192764469" title="R5 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764469&quot;,true)" marketdatetime={1640670960000}>11:26</a></li>
                    <li id="liTemp"><a id="market_1_192764471" title="R6 1000m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764471&quot;,true)" marketdatetime={1640673060000}>12:01</a></li>
                    <li id="liTemp"><a id="market_1_192764473" title="R7 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146464&marketId=1.192764473&quot;,true)" marketdatetime={1640675220000}>12:37</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146494" className="game-list-col hs-time" firstmarkettime="2021-12-28 12:09">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Belmont (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192766435" title="R1 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766435&quot;,true)" marketdatetime={1640673540000}>12:09</a></li>
                    <li id="liTemp"><a id="market_1_192766437" title="R2 1200m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766437&quot;,true)" marketdatetime={1640675940000}>12:49</a></li>
                    <li id="liTemp"><a id="market_1_192766439" title="R3 1000m CL3" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766439&quot;,true)" marketdatetime={1640678100000}>13:25</a></li>
                    <li id="liTemp"><a id="market_1_192766441" title="R4 2100m CL3" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766441&quot;,true)" marketdatetime={1640680380000}>14:03</a></li>
                    <li id="liTemp"><a id="market_1_192766443" title="R5 1400m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766443&quot;,true)" marketdatetime={1640682600000}>14:40</a></li>
                    <li id="liTemp"><a id="market_1_192766445" title="R6 1400m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766445&quot;,true)" marketdatetime={1640684640000}>15:14</a></li>
                    <li id="liTemp"><a id="market_1_192766447" title="R7 1600m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766447&quot;,true)" marketdatetime={1640686620000}>15:47</a></li>
                    <li id="liTemp"><a id="market_1_192766449" title="R8 1400m CL5" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146494&marketId=1.192766449&quot;,true)" marketdatetime={1640688660000}>16:21</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146482" className="game-list-col hs-time" firstmarkettime="2021-12-28 12:32">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Maitland (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192765424" title="R1 1646m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765424&quot;,true)" marketdatetime={1640674920000}>12:32</a></li>
                    <li id="liTemp"><a id="market_1_192765426" title="R2 2422m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765426&quot;,true)" marketdatetime={1640677020000}>13:07</a></li>
                    <li id="liTemp"><a id="market_1_192765428" title="R3 2422m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765428&quot;,true)" marketdatetime={1640679060000}>13:41</a></li>
                    <li id="liTemp"><a id="market_1_192765430" title="R4 2422m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765430&quot;,true)" marketdatetime={1640681100000}>14:15</a></li>
                    <li id="liTemp"><a id="market_1_192765432" title="R5 2422m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765432&quot;,true)" marketdatetime={1640683500000}>14:55</a></li>
                    <li id="liTemp"><a id="market_1_192765434" title="R6 2044m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765434&quot;,true)" marketdatetime={1640685540000}>15:29</a></li>
                    <li id="liTemp"><a id="market_1_192765436" title="R7 2044m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765436&quot;,true)" marketdatetime={1640687580000}>16:03</a></li>
                    <li id="liTemp"><a id="market_1_192765438" title="R8 1646m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765438&quot;,true)" marketdatetime={1640689560000}>16:36</a></li>
                    <li id="liTemp"><a id="market_1_192765440" title="R9 2044m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146482&marketId=1.192765440&quot;,true)" marketdatetime={1640691720000}>17:12</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146547" className="game-list-col hs-time" firstmarkettime="2021-12-28 13:58">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Bunbury (AUS) 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192769409" title="R1 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769409&quot;,true)" marketdatetime={1640680080000}>13:58</a></li>
                    <li id="liTemp"><a id="market_1_192769411" title="R2 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769411&quot;,true)" marketdatetime={1640682120000}>14:32</a></li>
                    <li id="liTemp"><a id="market_1_192769413" title="R3 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769413&quot;,true)" marketdatetime={1640684520000}>15:12</a></li>
                    <li id="liTemp"><a id="market_1_192769415" title="R4 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769415&quot;,true)" marketdatetime={1640686560000}>15:46</a></li>
                    <li id="liTemp"><a id="market_1_192769417" title="R5 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769417&quot;,true)" marketdatetime={1640688600000}>16:20</a></li>
                    <li id="liTemp"><a id="market_1_192769419" title="R6 2503m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769419&quot;,true)" marketdatetime={1640690700000}>16:55</a></li>
                    <li id="liTemp"><a id="market_1_192769421" title="R7 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769421&quot;,true)" marketdatetime={1640692620000}>17:27</a></li>
                    <li id="liTemp"><a id="market_1_192769423" title="R8 2100m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146547&marketId=1.192769423&quot;,true)" marketdatetime={1640694180000}>17:53</a></li>
                </ul>
            </dd>
        </dl>
    </div>
    <div id="countryGroup_IE" className="game-wrap-horse" firstmarkettime="2021-12-28 17:30">
        <h3>
            <a className="to-expand">
        <span id="heading">IE</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 14 (Next in 30 hrs 23 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31146871" className="game-list-col hs-time" firstmarkettime="2021-12-28 17:30">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Leopardstown 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192775077" title="2m4f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775077&quot;,true)" marketdatetime={1640692800000}>17:30</a></li>
                    <li id="liTemp"><a id="market_1_192775084" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775084&quot;,true)" marketdatetime={1640694900000}>18:05</a></li>
                    <li id="liTemp"><a id="market_1_192775091" title="3m Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775091&quot;,true)" marketdatetime={1640697000000}>18:40</a></li>
                    <li id="liTemp"><a id="market_1_192775098" title="3m Grd1 Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775098&quot;,true)" marketdatetime={1640699100000}>19:15</a></li>
                    <li id="liTemp"><a id="market_1_192775105" title="3m Grd1 Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775105&quot;,true)" marketdatetime={1640701200000}>19:50</a></li>
                    <li id="liTemp"><a id="market_1_192775112" title="2m5f Beg Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775112&quot;,true)" marketdatetime={1640703300000}>20:25</a></li>
                    <li id="liTemp"><a id="market_1_192775119" title="2m INHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146871&marketId=1.192775119&quot;,true)" marketdatetime={1640705400000}>21:00</a></li>
                </ul>
            </dd>
        </dl>
        <dl id="racingBox_31146936" className="game-list-col hs-time" firstmarkettime="2021-12-28 17:50">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Limerick 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192776820" title="2m Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776820&quot;,true)" marketdatetime={1640694000000}>17:50</a></li>
                    <li id="liTemp"><a id="market_1_192776825" title="2m5f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776825&quot;,true)" marketdatetime={1640696100000}>18:25</a></li>
                    <li id="liTemp"><a id="market_1_192776830" title="2m6f Nov Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776830&quot;,true)" marketdatetime={1640698200000}>19:00</a></li>
                    <li id="liTemp"><a id="market_1_192776835" title="2m5f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776835&quot;,true)" marketdatetime={1640700300000}>19:35</a></li>
                    <li id="liTemp"><a id="market_1_192776840" title="2m4f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776840&quot;,true)" marketdatetime={1640702400000}>20:10</a></li>
                    <li id="liTemp"><a id="market_1_192776845" title="3m Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776845&quot;,true)" marketdatetime={1640704200000}>20:40</a></li>
                    <li id="liTemp"><a id="market_1_192776850" title="2m2f INHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146936&marketId=1.192776850&quot;,true)" marketdatetime={1640706000000}>21:10</a></li>
                </ul>
            </dd>
        </dl>
    </div>
</div>
        </React.Fragment>
    )
}
