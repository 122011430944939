import React, { useState, useEffect } from "react";
import Loading from '../images/loading40.gif'
import Transparent from '../images/transparent.gif'
import axios from 'axios';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import moment from 'moment';

window.pnlView = 1;
window.daypnl = 3;

var globalArray1 = [];

toast.configure()

export default function Balanceoverview(props) {
	const [LedgerData, setLedgerData] = useState([])
	const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
	const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const [startDate, setStartDate] = useState(moment().toDate());
	const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
	const [Refresh, setRefresh] = useState(false);

	var NOW = moment().subtract(7, 'days').toDate();

	const GetLedgerData = () => {

		var start = '';
		var end = '';

		if (window.day === 3) {
			var now = moment().subtract(7, 'days');
			start = now.startOf('day').format("YYYY-MM-DD");
			end = moment().endOf('day').format("YYYY-MM-DD");
		}
		// else if(window.day === 1){
		//   var now = moment();	
		//   start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
		//   end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");


		// }
		// else if(window.day === 2){
		//   var now = moment().subtract(1, 'days');	
		//   start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
		//   end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


		// }
		globalArray1 = [];
		setRefresh(true);
		axios.post('https://betplace247.com/api/agent/LedugerReport', {
			id: props.userid,
			token: props.utoken,
			fromdate: start,
			todate: end,
		})

			.then(result => {
				if (result.data.length == 0) {
					toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
				}

				if (result.status === 200) {

					for (let key in result.data) {
						if (result.data.hasOwnProperty(key)) {
							result.data[key].user = key;
							globalArray1.push(result.data[key]);
							//    console.log(result.data[key],'data');  
						}
					}
					setRefresh(false)
					let arr = [];
					globalArray1.map((item) => {
						arr.push(item);
					});
					setLedgerData(arr)
				}

			}

			).catch(e => {
				//setIsError(true);
			});

	}

	return (
		<React.Fragment>

			<div class="col-left">
				<div class="sub_path">
					<div class="path">
						<a href="#path-pop" class="path-back">...</a>
						<ul>
							<li class="path-last"><a href="/#">My Account</a></li>
						</ul>
					</div>
					<ul id="path-pop" class="path-pop">
					</ul>
					<ul class="menu-list">
						<li><a id="sub_menu_detail" href="/exchange/member/myprofile">My Profile</a></li>
						<li><a id="sub_menu_summary" href="/exchange/member/balance" class="select">Balance Overview</a></li>
						<li><a id="sub_menu_accountCashStatement_log" href="/exchange/member/AccountStatement">Account Statement
						</a></li>
						<li><a id="sub_menu_my_bets" href="/exchange/member/mybets">My Bets</a></li>
						<li><a id="sub_menu_activity_log" href="/exchange/member/activitylog">Activity Log</a></li>
						<li><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div class="col-center report">
				{Refresh &&
					<div className="loading-overlay" id="loading">
						<div className="loading-wrap" style={{ display: 'flex' }}>
							<div className="loading"><div />
								<div /></div><p>Loading...</p>
						</div></div>
				}
				<div id="message" class="message-wrap success">
					<a href="/#" class="btn-close">Close</a>
					<p></p>
				</div>
				<h2>Summary</h2>
				<div class="white-wrap">
					<dl class="head-balance">
						<dt>Your Balances</dt>
						<dd id="summaryTotalBalance">{props.balance.toFixed(2)} <span>USD</span></dd>
					</dl>
					<h3>Welcome,</h3>
					<p>View your account details here. You can manage funds, review and change your settings and see the performance of your betting activity.</p>
				</div>

				<ul className="input-list">
					<li><label>Period</label></li>
					<li>
						<ul className="input-list" style={{ display: 'inline-flex' }}>
							<DatePicker
								selectsStart
								dateFormat="yyyy-MM-dd"
								selected={startDate}
								placeholderText="YYYY-MM-DD"
								minDate={NOW}
								className="cal-input"
								onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
							/>
							<input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

							<DatePicker
								selectsEnd
								dateFormat="yyyy-MM-dd"
								placeholderText="YYYY-MM-DD"
								selected={endDate}
								onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
								className="cal-input"
							/>
							<input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


						</ul>

					</li>

					<li><a id="getPL" onClick={() => { window.day = 3; window.betHistoryView = 1; GetLedgerData(); }} className="btn-send">Get Data</a></li>
				</ul>



				<table class="table01">
					<tbody><tr>
						<th width="13%" class="align-L">Date</th>
						<th width="30%" class="align-L">Description №</th>
						<th width="13%">Debits</th>
						<th width="13%">Credits</th>
						<th width="13%">Balance</th>
						<th>Remarks</th>

					</tr>
						{LedgerData.map((item, index) => {

							var DebitBalance = parseFloat(item.Debit).toFixed(2);
							var CreditBalance = parseFloat(item.Credit).toFixed(2);
							var BalanceValue = parseFloat(item.balance).toFixed(2);
							return (
								<tr key={index} id="dataTemplate">
									<td id="date" class="align-L">{item.date}</td>
									<td id="statementSerial" class="align-L"><strong>{item.eventName}</strong></td>
									<td id="debits"><span className={`${DebitBalance >= 0 ? "" : "red"}`}>{DebitBalance >= 0 ? DebitBalance : '(' + Math.abs(DebitBalance) + ')'}</span></td>
									<td id="credits">{CreditBalance}</td>
									<td id="balance">{BalanceValue}</td>
									<td id="remarks">{item.comment}</td>
									{/* <td id="fromTo"><img class="fromto" src={Transparent} alt="gif"/></td> */}
								</tr>
							)
						})}


					</tbody>
					{LedgerData.length == 0 &&
						<tbody id="Matched_Content">
							<tr id="noDataTemplate" style={{ display: "table-row" }}>
								<td class="no-data" colspan="7"><p>Select date to see your Balance Overview past 7 Days</p></td></tr>
						</tbody>}
				</table>
				<script type="text/javascript" src="/js/util/PageUtil.js?v=20200907_1599446400000"></script>
				{LedgerData.length > 0 &&
					<div><ul id="pageNumberContent" className="pages"><li id="prev"><a className="disable">Prev</a></li>
						<li id="pageNumber"><a className="select" style={{ pointerEvents: 'none' }}>1</a>
						</li><li id="next"><a className="disable">Next</a></li></ul></div>}

			</div>
		</React.Fragment>

	)
}
