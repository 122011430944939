import React, { useState } from 'react'

import News from './News';

function Result() {
    const [tab, settab] = useState(1);
    return (
        <div style={{ backgroundColor: 'rgb(239 236 225)' }}>
            <div style={{width: '1020px', marginLeft: '20px', float: 'left', backgroundColor: 'white', overflowY: 'scroll' }}>
                <News />
                <div style={{ height: '50px' }}>
                    <div class="result-search" style={{ marginTop: '10px' }}>
                        <div >
                            <div style={{ float: 'left' }} >
                                <ul id="resultTab" class="tab col2">
                                    <li><a id="today" href="#Today" className={`${(tab == 1) ? "select" : "null"}`} onClick={() => { settab(1) }} >Today</a></li>
                                    <li><a id="yesterday" href="#Yesterday" className={`${(tab == 2) ? "select" : "null"}`} onClick={() => { settab(2) }} >Yesterday</a></li>
                                </ul>
                            </div>
                            <div style={{ float: 'right' }}>
                                <select id="sports" style={{ height: '30px', width: '200%', marginTop: '2px' }}>
                                    <option value="4">CRICKET</option>
                                    <option value="1">SOCCER</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="over-wrap" style={{ height: 'calc(100% - 39px - 31px - 35px)' }}>
                        {tab == 1 &&
                            <table id="Result_Table" class="table01">
                                <caption class="align-L" colspan="4">Result</caption>
                                <tbody><tr>
                                    <th width="20%" class="align-L">Event Date/Time</th>
                                    <th class="align-L">Event Name</th>
                                    <th id="Result_Title_1" width="20%">Home</th>
                                    <th id="Result_Title_2" width="20%">Away</th>
                                </tr>
                                </tbody><tbody id="Result_Content"><tr id="noDataTemplate" style={{ display: "table-row" }}>
                                    <td class="no-data" colspan="9">
                                        <p>There are no events to be displayed.</p>
                                    </td>
                                </tr></tbody>
                            </table>
                        }
                        {tab == 2 &&
                            <table id="Result_Table" class="table01">
                                <caption class="align-L" colspan="4">Result</caption>
                                <tbody><tr>
                                    <th width="20%" class="align-L">Event Date/Time</th>
                                    <th class="align-L">Event Name</th>
                                    <th id="Result_Title_1" width="20%">Home</th>
                                    <th id="Result_Title_2" width="20%">Away</th>
                                </tr>
                                </tbody>
                                <tbody id="Result_Content"><tr id="tempTr">
                                    <td id="eventDate" class="align-L">2021-12-19 23:30</td>
                                    <td id="eventName" class="align-L">Mumbai Indians SRL T20 v Delhi Capitals SRL T20</td>
                                    <td><strong><span id="resultItem1">162/9</span></strong></td>
                                    <td><strong><span id="resultItem2">175/6</span></strong></td>
                                </tr></tbody>
                            </table>
                        }




                    </div>
                </div>







            </div>
     



        </div>
    )
}

export default Result
