import Axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react'
import Transparent from '../images/transparent.gif'
import ScoreTracker from './ScoreTracker'
import Loading from "../images/loading40.gif"
import Hls from './Hls'
import Countdown from './Countdown'
import Script from 'next/script'
import Cookies from 'universal-cookie';
import TennisScoreboard from './TennisScoreboard';
import SoccerScore from './SoccerScore';
import moment from 'moment';
require('moment-timezone');

var qs = require('qs');
const cookies = new Cookies();



cookies.set('dupBet', "2", { path: '/' });

window.book_odd = '';
window.book_oddType = '';
window.book_team = '';
window.book_index = 0;
window.fancy_odd = '';
window.fancy_size = '';
window.fancy_index = -1;

const io = require('socket.io-client');
const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
    ENDPOINT = "https://millionbet247.com:2053";
}
else if (ranNum == 2) {
    ENDPOINT = "https://millionbet247.com:2083";
}
else if (ranNum == 3) {
    ENDPOINT = "https://millionbet247.com:2087";
}
else {
    ENDPOINT = "https://millionbet247.com:2096";
}

var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;


export default function NewFullmarket(props) {
    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [defaultStake, setdefaultStake] = useState('');
    const [Clickonback_1, setClickonback_1] = useState(false);
    const [Clickonback_2, setClickonback_2] = useState(false);
    const [Clickonlay_1, setClickonlay_1] = useState(false);
    const [Clickonlay_2, setClickonlay_2] = useState(false);
    const [Bookclick, setBookclick] = useState(false)
    const [Bookclick2, setBookclick2] = useState(false)
    const [Bookclick3, setBookclick3] = useState(false)
    const [FancyBet, setFancyBet] = useState(false)
    const [selectBack, setselectBack] = useState(null)
    const [selectLay, setselectLay] = useState(true)
    const [selectFancy, setselectFancy] = useState(null)
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [loading, setloading] = useState(false);
    const [Betbar, setBetbar] = useState(false);
    const [count, setCount] = useState(0);
    const [countInTimeout, setCountInTimeout] = useState(0);
    const [Inputvaluestate, setInputvaluestate] = useState('')
    const [Tvopen, setTvopen] = useState(true)
    const [isShown, setisShown] = useState(false)
    const [marketData, setmarketData] = useState({})
    const [fancyData, setfancyData] = useState([])
    const [click, setclick] = useState(-1);
    const [field, setfield] = useState(0);
    const [bookData, setbookData] = useState({})
    const [bOdds, setbOdds] = useState(' ')
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [marketStartTime, setmarketStartTime] = useState('')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('')
    const [bookData2, setbookData2] = useState({})
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)
    const [bookBetSelection, setbookBetSelection] = useState(0)
    const [bookMsg, setbookMsg] = useState({})
    const [hidematchOdds, sethidematchOdds] = useState({})
    const [fancySelection, setfancySelection] = useState(-1)
    const [fancyMsg, setfancyMsg] = useState({})

    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])

    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState({})
    const [liveStream, setliveStream] = useState(null);
    const [ActiveTeam, setActiveTeam] = useState('');
    const [matchEndedMarket, setmatchEndedMarket] = useState(false);
    const [matchEndedBook, setmatchEndedBook] = useState(false);
    const [liquidityStatus, setliquidityStatus] = useState(0);


    const [coinFactor, setcoinFactor] = useState(1);





    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackRate2, setrunner1BackRate2] = useState(' ');
    const [runner1BackRate3, setrunner1BackRate3] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1BackSize2, setrunner1BackSize2] = useState(' ');
    const [runner1BackSize3, setrunner1BackSize3] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LayRate2, setrunner1LayRate2] = useState(' ');
    const [runner1LayRate3, setrunner1LayRate3] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner1LaySize2, setrunner1LaySize2] = useState(' ');
    const [runner1LaySize3, setrunner1LaySize3] = useState(' ');

    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackRate2, setrunner2BackRate2] = useState(' ');
    const [runner2BackRate3, setrunner2BackRate3] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2BackSize2, setrunner2BackSize2] = useState(' ');
    const [runner2BackSize3, setrunner2BackSize3] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LayRate2, setrunner2LayRate2] = useState(' ');
    const [runner2LayRate3, setrunner2LayRate3] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');
    const [runner2LaySize2, setrunner2LaySize2] = useState(' ');
    const [runner2LaySize3, setrunner2LaySize3] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieBackRate2, settieBackRate2] = useState(' ');
    const [tieBackRate3, settieBackRate3] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieBackSize2, settieBackSize2] = useState(' ');
    const [tieBackSize3, settieBackSize3] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieLayRate2, settieLayRate2] = useState(' ');
    const [tieLayRate3, settieLayRate3] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [tieLaySize2, settieLaySize2] = useState(' ');
    const [tieLaySize3, settieLaySize3] = useState(' ');
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [getScoreId, setgetScoreId] = useState('');
    const [getSecScoreId, setgetSecScoreId] = useState('');
    const [rateMode, setRateMode] = useState('2')

    //console.log('after function');


    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[6];
    const eventid = para[7];
    const marketid = para[8];



    useEffect(() => {
        socket = io(ENDPOINT);
        console.log('socket connected');
        socket.on('connect', function (data) {
            socket.emit('room1', eventid);
        });
        return () => {
            socket.close();
            console.log('socket disconnected');
            props.setOnClick(0);
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
            s25 = -1; s26 = -1; s27 = -1; s28 = -1; s29 = -1; s30 = -1; s31 = -1; s32 = -1; s33 = -1; s34 = -1; s35 = -1; s36 = -1;
        }
    }, [])

    //console.log(eventid,'sdfgh');

    useEffect(() => {

        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientssgetclickstake', {

            id: props.userid,
            token: props.utoken,

        })
            .then(result => {

                if (result.status === 210) {

                    setcoinFactor(parseInt(result.data[0].coin_type));
                    setdefaultStake(result.data[0].defaultStake);
                    setSsstake1(result.data[0].SSstake1);
                    setSsstake2(result.data[0].SSstake2);
                    setSsstake3(result.data[0].SSstake3);
                    setSsstake4(result.data[0].SSstake4);
                    setSsstake5(result.data[0].SSstake5);
                    setSsstake6(result.data[0].SSstake6);


                }
            }

            ).catch(e => {

            });

        return () => {

        }
    }, [props.userid]);



    useEffect(() => {
        if (!eventid) {
            setliveStream(null);
            return;
        }

        Axios.post("https://millionbet247.com/api/client/clientlivestreaming", {
            eventid: eventid,
        })
            .then((result) => {
                if (result.status === 210) {

                    if (result.data[0].tvId && result.data[0].tvId != '') {
                        setliveStream(result.data[0].tvId);
                        setScoreData(result.data[0].scoreId);

                        setActiveTeam(result.data[0].activeTeam);
                    }
                }
            })
            .catch((e) => {
                //setIsError(true);
            });
    }, [eventid]);


    //  const renderIframe = useCallback(() => {
    //     const _bt = [];
    //     _bt.push(['server', 'https://integrations01.betgames.tv']);
    //     _bt.push(['partner', 'gridcsp']);
    //     _bt.push(['token', bgToken]);
    //     _bt.push(['language', 'en']);
    //     _bt.push(['timezone', '0']);
    //     if (window.BetGames) {
    //       while (iframeRef.current.firstChild)
    //         iframeRef.current.removeChild(iframeRef.current.firstChild);
    //       window.BetGames.frame(_bt);
    //     }
    //   }, [bgToken, iframeRef]);



    useEffect(() => {
        if (!eventid) return;

        Axios.post('https://millionbet247.com/api/client/clientgetFullMarket', {

            eventId: eventid,

        })
            .then(result => {

                if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                    setmatchEndedMarket(true);

                }

                if (result.data[0]) {
                    let obj = result.data[0];
                    setmarketStartTime(obj.marketStartTime)
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1BackRate2(obj.runner1BackRate2);
                    setrunner1BackRate3(obj.runner1BackRate3);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner1LayRate2(obj.runner1LayRate2);
                    setrunner1LayRate3(obj.runner1LayRate3);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2BackRate2(obj.runner2BackRate2);
                    setrunner2BackRate3(obj.runner2BackRate3);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    setrunner2LayRate2(obj.runner2LayRate2);
                    setrunner2LayRate3(obj.runner2LayRate3);
                    sethidematchOdds(obj.oddsHide)

                    if (obj.runnerId3) {
                        settieBackRate1(obj.tieBackRate1);
                        settieBackRate2(obj.tieBackRate2);
                        settieBackRate3(obj.tieBackRate3);
                        settieLayRate1(obj.tieLayRate1);
                        settieLayRate2(obj.tieLayRate2);
                        settieLayRate3(obj.tieLayRate3);

                    }

                    obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm')

                    setmarketData(obj);
                }

            })
            .catch(e => {
            });

        Axios.post('https://millionbet247.com/api/client/clientgetBookMakerMarket', {
            eventId: eventid,
        })
            .then(result => {
                if (result.data.length === 0 || (result.data.length > 0 && result.data[0].matchStatus != 1)) {
                    setmatchEndedBook(true);
                }

                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                }

            })
            .catch(e => {

            });


    }, [eventid]);


    useEffect(() => {
        if (matchEndedBook && matchEndedMarket) {

            window.location.href = '/';
        }
    }
        , [matchEndedBook, matchEndedMarket]);


    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);

            // if(value.messageType === 'match_score'){
            //     let temp = value;
            //     setScoreData(temp);
            // }


            if (value[0] && value[0].messageType === 'session_rate') {
                let temp = value;
                // console.log(value[0].skymode,'skyfgh');
                if (value[0].skymode == 'skyRate') {
                    temp.sort(function (a, b) {
                        return a.selectionId - b.selectionId;
                    });
                }
                setfancyData(temp);
            }

            if (value.messageType === 'bookmaker_match_rate') {
                //console.log('book1',value);
                let temp = { ...bookData };

                temp.messageType = value.messageType;
                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1BackRate2 = value.runner1BackRate2;
                temp.runner1BackRate3 = value.runner1BackRate3;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner1LayRate2 = value.runner1LayRate2;
                temp.runner1LayRate3 = value.runner1LayRate3;

                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2BackRate2 = value.runner2BackRate2;
                temp.runner2BackRate3 = value.runner2BackRate3;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.runner2LayRate2 = value.runner2LayRate2;
                temp.runner2LayRate3 = value.runner2LayRate3;

                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieBackRate2 = value.tieBackRate2;
                temp.tieBackRate3 = value.tieBackRate3;
                temp.tieLayRate1 = value.tieLayRate1;
                temp.tieLayRate2 = value.tieLayRate2;
                temp.tieLayRate3 = value.tieLayRate3;
                //console.log('bookmaker',bookData.gameStatus,'runner1',bookData.runner1Status,'runner2',bookData.runner2Status);

                setbookData(temp);

            }




            if (value.messageType === 'betfair_match_rate') {

                setmarketBetStatus(value.status);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);

                }
                if (s1 !== value.runner1BackRate1) {
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(() => { c1 = 0; }, 700);
                }
                if (s2 !== value.runner1BackRate2) {
                    setrunner1BackRate2(value.runner1BackRate2);
                    s2 = value.runner1BackRate2;
                    c2 = 1;
                    setTimeout(() => { c2 = 0; }, 700);
                }
                if (s3 !== value.runner1BackRate3) {
                    setrunner1BackRate3(value.runner1BackRate3);
                    c3 = 1;
                    s3 = value.runner1BackRate3;
                    setTimeout(() => { c3 = 0; }, 700);
                }
                if (s4 !== value.runner1BackSize1) {
                    setrunner1BackSize1(value.runner1BackSize1);
                    s4 = value.runner1BackSize1;
                }
                if (s5 !== value.runner1BackSize2) {
                    setrunner1BackSize2(value.runner1BackSize2);
                    s5 = value.runner1BackSize2;
                }
                if (s6 !== value.runner1BackSize3) {
                    setrunner1BackSize3(value.runner1BackSize3);
                    s6 = value.runner1BackSize3;
                }
                if (s7 !== value.runner1LayRate1) {
                    setrunner1LayRate1(value.runner1LayRate1);
                    s7 = value.runner1LayRate1;
                    c4 = 1;
                    setTimeout(() => { c4 = 0; }, 700);
                }
                if (s8 !== value.runner1LayRate2) {
                    setrunner1LayRate2(value.runner1LayRate2);
                    s8 = value.runner1LayRate2;
                    c5 = 1;
                    setTimeout(() => { c5 = 0; }, 700);
                }
                if (s9 !== value.runner1LayRate3) {
                    setrunner1LayRate3(value.runner1LayRate3);
                    s9 = value.runner1LayRate3;
                    c6 = 1;
                    setTimeout(() => { c6 = 0; }, 700);
                }
                if (s10 !== value.runner1LaySize1) {
                    setrunner1LaySize1(value.runner1LaySize1);
                    s10 = value.runner1LaySize1;
                }
                if (s11 !== value.runner1LaySize2) {
                    setrunner1LaySize2(value.runner1LaySize2);
                    s11 = value.runner1LaySize2;
                }
                if (s12 !== value.runner1LaySize3) {
                    setrunner1LaySize3(value.runner1LaySize3);
                    s12 = value.runner1LaySize3;
                }
                if (s13 !== value.runner2BackRate1) {
                    setrunner2BackRate1(value.runner2BackRate1);
                    s13 = value.runner2BackRate1;
                    c7 = 1;
                    setTimeout(() => { c7 = 0; }, 700);
                }
                if (s14 !== value.runner2BackRate2) {
                    setrunner2BackRate2(value.runner2BackRate2);
                    s14 = value.runner2BackRate2;
                    c8 = 1;
                    setTimeout(() => { c8 = 0; }, 700);
                }
                if (s15 !== value.runner2BackRate3) {
                    setrunner2BackRate3(value.runner2BackRate3);
                    s15 = value.runner2BackRate3;
                    c9 = 1;
                    setTimeout(() => { c9 = 0; }, 700);
                }
                if (s16 !== value.runner2BackSize1) {
                    setrunner2BackSize1(value.runner2BackSize1);
                    s16 = value.runner2BackSize1;
                }
                if (s17 !== value.runner2BackSize2) {
                    setrunner2BackSize2(value.runner2BackSize2);
                    s17 = value.runner2BackSize2;
                }
                if (s18 !== value.runner2BackSize3) {
                    setrunner2BackSize3(value.runner2BackSize3);
                    s18 = value.runner2BackSize3;
                }
                if (s19 !== value.runner2LayRate1) {
                    setrunner2LayRate1(value.runner2LayRate1);
                    s19 = value.runner2LayRate1;
                    c10 = 1;
                    setTimeout(() => { c10 = 0; }, 700);
                }
                if (s20 !== value.runner2LayRate2) {
                    setrunner2LayRate2(value.runner2LayRate2);
                    s20 = value.runner2LayRate2;
                    c11 = 1;
                    setTimeout(() => { c11 = 0; }, 700);
                }
                if (s21 !== value.runner2LayRate3) {
                    setrunner2LayRate3(value.runner2LayRate3);
                    s21 = value.runner2LayRate3;
                    c12 = 1;
                    setTimeout(() => { c12 = 0; }, 700);
                }
                if (s22 !== value.runner2LaySize1) {
                    setrunner2LaySize1(value.runner2LaySize1);
                    s22 = value.runner2LaySize1;
                }
                if (s23 !== value.runner2LaySize2) {
                    setrunner2LaySize2(value.runner2LaySize2);
                    s23 = value.runner2LaySize2;
                }
                if (s24 !== value.runner2LaySize3) {
                    setrunner2LaySize3(value.runner2LaySize3);
                    s24 = value.runner2LaySize3;
                }


                if (s25 !== value.tieBackRate1) {
                    settieBackRate1(value.tieBackRate1);
                    s25 = value.tieBackRate1;
                    c13 = 1;
                    setTimeout(() => { c13 = 0; }, 700);
                }
                if (s26 !== value.tieBackRate2) {
                    settieBackRate2(value.tieBackRate2);
                    s26 = value.tieBackRate2;
                    c14 = 1;
                    setTimeout(() => { c14 = 0; }, 700);
                }
                if (s27 !== value.tieBackRate3) {
                    settieBackRate3(value.tieBackRate3);
                    s27 = value.tieBackRate3;
                    c15 = 1;
                    setTimeout(() => { c15 = 0; }, 700);
                }
                if (s28 !== value.tieBackSize1) {
                    settieBackSize1(value.tieBackSize1);
                    s28 = value.tieBackSize1;
                }
                if (s29 !== value.tieBackSize2) {
                    settieBackSize2(value.tieBackSize2);
                    s29 = value.tieBackSize2;
                }
                if (s30 !== value.tieBackSize3) {
                    settieBackSize3(value.tieBackSize3);
                    s30 = value.tieBackSize3;
                }
                if (s31 !== value.tieLayRate1) {
                    settieLayRate1(value.tieLayRate1);
                    s31 = value.tieLayRate1;
                    c16 = 1;
                    setTimeout(() => { c16 = 0; }, 700);
                }
                if (s32 !== value.tieLayRate2) {
                    settieLayRate2(value.tieLayRate2);
                    s32 = value.tieLayRate2;
                    c17 = 1;
                    setTimeout(() => { c17 = 0; }, 700);
                }
                if (s33 !== value.tieLayRate3) {
                    settieLayRate3(value.tieLayRate3);
                    s33 = value.tieLayRate3;
                    c18 = 1;
                    setTimeout(() => { c18 = 0; }, 700);
                }
                if (s34 !== value.tieLaySize1) {
                    settieLaySize1(value.tieLaySize1);
                    s34 = value.tieLaySize1;
                }
                if (s35 !== value.tieLaySize2) {
                    settieLaySize2(value.tieLaySize2);
                    s35 = value.tieLaySize2;
                }
                if (s36 !== value.tieLaySize3) {
                    settieLaySize3(value.tieLaySize3);
                    s36 = value.tieLaySize3;
                }

            }
        })

        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner1BackRate2(' '); setrunner1BackRate3(' '); setrunner2BackRate1(' '); setrunner2BackRate2(' '); setrunner2BackRate3(' ');
            setrunner1LayRate1(' '); setrunner1LayRate2(' '); setrunner1LayRate3(' '); setrunner2LayRate1(' '); setrunner2LayRate2(' '); setrunner2LayRate3(' ');
            setrunner1BackSize1(' '); setrunner1BackSize2(' '); setrunner1BackSize3(' '); setrunner2BackSize1(' '); setrunner2BackSize2(' '); setrunner2BackSize3(' ');
            setrunner1LaySize1(' '); setrunner1LaySize2(' '); setrunner1LaySize3(' '); setrunner2LaySize1(' '); setrunner2LaySize2(' '); setrunner2LaySize3(' ');
            settieBackRate1(' '); settieBackRate2(' '); settieBackRate3(' '); settieLayRate1(' '); settieLayRate2(' '); settieLayRate3(' '); settieBackSize1(' ');
            settieBackSize2(' '); settieBackSize3(' '); settieLaySize1(' '); settieLaySize2(' '); settieLaySize3(' ');
            setfancyData([]);
        }


    }, [eventid])



    const changeDefaultStake = (event) => {

        setdefaultStake(event.target.value);

    };

    const handle_Refresh = () => {

        const utoken = cookies.get('token');
        const user = cookies.get('id');
        Axios.post('https://millionbet247.com/api/client/clientrefresh', {
            id: user,
            token: utoken
        })
            .then(result => {
                if (result.status === 210) {


                    if (result.data[1].val) {
                        props.updateBalance(result.data[0].val - Math.abs(result.data[1].val), Math.abs(result.data[1].val));
                        //props.changeExposure(Math.abs(result.data[1].val));
                    }
                    else {
                        props.updateBalance(result.data[0].val - Math.abs(result.data[1].val), 0);
                        //props.changeExposure(0);
                    }
                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    };



    const placeBet = () => {

        var teamNumber = 0;

        if ((teamName === 'A' && !props.oneclick) || (props.oneclick && window.book_team === 'A')) {
            teamNumber = 1;
        }
        else if ((teamName === 'B' && !props.oneclick) || (props.oneclick && window.book_team === 'B')) {
            teamNumber = 2;
        }
        else if ((teamName === 'T' && !props.oneclick) || (props.oneclick && window.book_team === 'T')) {
            teamNumber = 3;
        }

        var book_Stake = defaultStake;
        var book_odds = (bOdds / 100 + 1);
        var book_oddT = oddsType;
        var book_teamName = teamName;
        if (props.oneclick === true) {
            book_Stake = window.oneclickStake;
            book_odds = (window.book_odd / 100 + 1);
            book_oddT = window.book_oddType;
            book_teamName = window.book_team;

        }
        book_odds = parseFloat(book_odds).toFixed(2).toString();
        var dupBet = cookies.get('dupBet');


        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId,
            odds: book_odds,
            oddsType: book_oddT,
            betType: 'match',
            coin: book_Stake,
            teamName: book_teamName,
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {



                if (result.data == 6) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 1;
                    obj.msg = 'Bet Placed Successfully!';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                    props.isanybets(!props.anyBets);
                    handle_Refresh();
                    plBook();
                    //handleRefresh();   
                }
                else if (result.data == 1) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Blocked !';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }

                else if (result.data == 2) {

                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Closed !';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data == 3) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Insufficient Coin !';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data == 4) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Odds Changed !';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data == 5) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet can not be placed!';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data == 7) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Enter valid coin value!';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data == 123) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Over 75 not accepted!';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                // else if(result.data == 13){
                //     var obj = {};
                //     obj.number = teamNumber;
                //     obj.msgNumber = 2;
                //     obj.msg = 'Duplicate Bet Not allowed!';
                //     setbookMsg(obj);

                //     setTimeout(()=>{
                //         setbookMsg({});
                //     },2000);
                // }
                else if (result.data.charAt(0) == 8) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Min bet is' + ' ' + arr[1];
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else if (result.data.charAt(0) == 9) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Maximum bet is' + ' ' + arr[1];
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }
                else {

                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet can not be placed!';
                    setbookMsg(obj);

                    setTimeout(() => {
                        setbookMsg({});
                    }, 2000);
                }


            }

            ).catch(e => {
                console.log('e');

            });

    };

    const fancyBet = (selectionId, fld, index) => {


        var teamNumber = index;


        var dupBet = cookies.get('dupBet');
        var oddT;
        if (fld === 1) {
            oddT = 'NO';
        }
        else if (fld === 2) {
            oddT = 'YES';
        }
        var fancy_odds = fOdds;
        var fancy_stake = defaultStake;
        var fancy_teamName = fsize;

        if (props.oneclick) {
            teamNumber = window.fancy_index;
            fancy_odds = window.fancy_odd;
            fancy_stake = window.oneclickStake;
            fancy_teamName = window.fancy_size;

        }


        Axios.post('https://millionbet247.com/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: selectionId, //fancy selection id
            odds: fancy_odds,                  //run 
            oddsType: oddT,           //YES NO
            betType: 'fancy',            //fancy
            coin: fancy_stake,
            teamName: fancy_teamName,          //rate 110,100
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {


                if (result.data == 6) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 1;
                    obj.msg = 'Bet Placed Successfully!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                    props.isanybets(!props.anyBets);
                    handle_Refresh();
                    plFancy();
                    //handleRefresh();   
                }
                else if (result.data == 1) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Blocked !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }

                else if (result.data == 2) {

                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Closed !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 3) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Insufficient Coin !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 4) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'fancy Changed !';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 5) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet can not be placed!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 7) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Enter valid coin value!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data == 123) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    obj.msg = 'Bet Over 75 not accepted!';
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                // else if(result.data == 13){
                //     var obj = {};
                //     obj.number = teamNumber;
                //     obj.msgNumber = 2;
                //     obj.msg = 'Duplicate Bet Not allowed!';
                //     setfancyMsg(obj);

                //     setTimeout(()=>{
                //         setfancyMsg({});
                //     },2000);
                // }
                else if (result.data.charAt(0) == 8) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Min bet is' + ' ' + arr[1];
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                else if (result.data.charAt(0) == 9) {
                    var obj = {};
                    obj.number = teamNumber;
                    obj.msgNumber = 2;
                    let arr = result.data.split('_');
                    obj.msg = 'Max bet is' + ' ' + arr[1];
                    setfancyMsg(obj);

                    setTimeout(() => {
                        setfancyMsg({});
                    }, 2000);
                }
                //    else{

                //     var obj = {};
                //     obj.number = teamNumber;
                //     obj.msgNumber = 2;
                //     obj.msg = 'Duplicate Bet Not allowed!';
                //     setfancyMsg(obj);

                //     setTimeout(()=>{
                //         setfancyMsg({});
                //     },2000);
                //     }

            }

            ).catch(e => {
                console.log('e');

            });

    };





    const plMarket = () => {

        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId

        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlMarket(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }



    const plBook = () => {
        if (!props.userid) return;
        Axios.post('https://millionbet247.com/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlBook(obj);

                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });

    }



    const plFancy = () => {
        if (!props.userid || !eventid) return;
        Axios.post('https://millionbet247.com/api/client/clientplfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);


                }

                if (result.status === 220) {


                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }



    useEffect(() => {

        if (marketData.matchStatus == 1) plMarket();
        if (bookData2.matchStatus == 1) plBook();

        return () => {

            setpnlMarket({});
            setpnlBook({});

        }
    }, [props.userid, bookData2.marketId, marketData.marketId, props.anyBets])




    useEffect(() => {
        plFancy();

    }, [props.userid, eventid])


    useEffect(() => {


        if (props.betClick) {

            var amount = props.dStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (props.betType == 'LAGAI') {
                if (props.team == 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(props.odds - 1);
                }
                else if (props.team == 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(props.odds - 1);

                }
            }

            else {

                if (props.team == 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else if (props.team == 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(props.odds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(props.odds - 1);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);



        }

        else if (Bookclick || Bookclick2 || Bookclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }

    }, [defaultStake, props.dStake, props.odds, props.betType, props.team])





    const changeBook = (val1, val2, val3) => {

        if (val2 === 2) {
            setBookclick(true);
            setBookclick2(false);
            setBookclick3(false);
            setteamName('B');
        }
        else if (val2 === 1) {
            setBookclick2(true);
            setBookclick(false);
            setBookclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {

            setBookclick3(true);
            setBookclick2(false);
            setBookclick(false);
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);
        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }
    const closeBookBet = () => {

        setselectBack(0);
        setBookclick2(false);
        setBookclick(false)
        setBookclick3(false);

    }

    const openBookLay = () => {
        if (!Bookclick) {
            setselectLay(1)
            setBookclick(true)
        }
    }

    const OpenfancyBet = (index, price, size, selectionId) => {

        if (props.oneclick) {

            window.fancy_odd = price;
            window.fancy_size = size;
            window.fancy_index = index;
            setTimeout(() => { window.fancy_index = -1; }, 3000);
            fancyBet(selectionId, 1, index);


        }

        setclick(index);
        setselectFancy(1);
        setfield(1);

    }

    const OpenfancyBetLay = (index, price, size, selectionId) => {

        if (props.oneclick) {
            window.fancy_odd = price;
            window.fancy_size = size;
            window.fancy_index = index;
            setTimeout(() => { window.fancy_index = -1; }, 3000);
            fancyBet(selectionId, 2, index);

        }

        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }

    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleRefresh = () => {
        setloading(true);
        setTimeout(() => { setloading(false); }, 1000);
    }

    const BetbarBook = () => {
        setBetbar(true);
        setBookclick(false)
        setselectBack()
        setselectLay()
        setTimeout(() => {
            setBetbar(false);
        }, 5000)
    }

    const ChangeInputValue = (e) => {
        setInputvaluestate(e.target.value)
    }


    const ChangeTv = () => {
        setTvopen(true)
    }

    const ChangeSetTv = () => {
        setTvopen(false)
    }

    const handleMatchOddsBet = (val1, val2, val3, val4, val5, val6, val7, val8) => {

        if (props.oneclick == true) {
            props.changeWaiting(true);

            //setTimeout(()=>{props.oneClickMarket()},4800);

            window.eventIId = eventid;
            window.marketIId = marketData.marketId;
            window.odds_oneClick = val2;

            props.oneClickMarket();


        } else {

            props.showBetForm(val1, val2, val3, val4, val5, val6, val7, val8);


        }

    }



    const handleBookBets = (val1, val2, val3) => {

        if (props.oneclick == true) {
            if (val2 === 1) {
                window.book_index = 1;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'A';
            }
            else if (val2 === 2) {

                window.book_index = 2;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'B';
            }


            else if (val2 === 3) {
                window.book_index = 3;
                setTimeout(() => { window.book_index = 0; }, 5000);
                window.book_team = 'T';
            }


            if (val1 === 1 || val1 === 3 || val1 === 5) {

                window.book_oddType = 'LAGAI';

            }
            else if (val1 === 2 || val1 === 4 || val1 === 6) {

                window.book_oddType = 'KHAI';
            }
            setselectBack(val1);

            window.book_odd = val3;
            //setTimeout(()=>{placeBet()},4800);
            placeBet();
        }

        else {

            changeBook(val1, val2, val3);

        }


    }

    const handlefancyBetLay = (val1, val2, val3, val4, val5, val6, val7, val8) => {


        props.showBetForm(val1, val2, val3, val4, val5, val6, val7, val8);


    }

    const handlefancyBetBack = (val1, val2, val3, val4, val5, val6, val7, val8) => {
        props.showBetForm(val1, val2, val3, val4, val5, val6, val7, val8);
    }



    useEffect(() => {

        if (ActiveTeam == '1') {
            Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/m` + liveStream).then((result) => {

                var MatchId = result.data.queryUrl;
                setgetScoreId(result.data.doc[0].event);
                var str = MatchId;
                var matches = str.match(/(\d+)/);
                var ScoreId = matches[0];

                window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", { layout: "double", scoreboard: "extended", momentum: "extended", collapseTo: "momentum", "activeSwitcher": "scoreDetails", "silent": true, matchId: ScoreId });
            }).catch((err) => {
                console.log(err);
            })

        }

        else if (ActiveTeam == '0' || getScoreId === 'exception') {
            if (liveStream) {
                Axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/cricket/get_scorecard/` + ScoreData).then((result) => {

                    var MatchId = result.data.queryUrl;
                    setgetSecScoreId(result.data.doc[0].event);
                    var str = MatchId;
                    var matches = str.match(/(\d+)/);
                    var ScoreId = matches[0];

                    window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", { layout: "double", scoreboard: "extended", momentum: "bars", collapseTo: "momentum", matchId: ScoreId });
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
        else if (ActiveTeam == '0' || getScoreId === 'exception' && getSecScoreId === 'exception') {
            if (liveStream) {
                Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/` + ScoreData).then((result) => {

                    var MatchId = result.data.queryUrl;
                    setgetSecScoreId(result.data.doc[0].event);
                    var str = MatchId;
                    var matches = str.match(/(\d+)/);
                    var ScoreId = matches[0];

                    window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", { layout: "double", scoreboard: "extended", momentum: "bars", collapseTo: "momentum", matchId: ScoreId });
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }, [ActiveTeam])


    return (
        <React.Fragment>
            {loading === true && <div id="oneClickLoading" class="loading-wrap" ><ul class="loading"><li><img src={Loading} alt="Place_bet" /></li><li>Loading...</li></ul></div>}
            <div id="overWrap" class="over-wrap live-match" style={props.isLoggedIn ? props.oneclick ? { height: "calc(100% - 55px)" } : { height: "calc(100% - 26px)" } : { height: "calc(100% - 0px)" }}>
                <div id="liveMatchGameHead" class="game-head">


                    <div id="matchTrackerWrap" class="match-tracker-wrap">

                        <div class="widgets">
                            <div><div class="sr-widget sr-widget-1"></div></div>
                        </div>


                        <ul id="liveMatchTrackerBtn" class="match-btn">
                            <li><a id="liveMultiMarketPin" style={{ cursor: 'pointer' }} name="gameHeadPin" class="btn-pin" title="Add to Multi Markets" eventtype="4" eventid="30035305" marketid="1.173506114"></a></li>
                            <li><a style={{ cursor: 'pointer' }} onClick={handleRefresh} class="btn-refresh">

                            </a></li>
                        </ul>
                    </div>

                    {/*<!-- Game Scroes Event -->*/}
                    <div id="scoresEvent" class="match-odds">Match Odds<img src={Transparent} /></div>



                    {/*<!-- Game Information -->*/}
                    <ul class="game-info" style={{ display: 'block' }}>
                        {moment() < moment(marketData.marketStartTime) && <li id="gameInfoDate" class=""><img class="icon-time" src={Transparent} />{marketData.marketStartTime}</li>}
                        {moment() >= moment(marketData.marketStartTime) && <li id="gameInfoDate" class="green"><img class="icon-irun" src={Transparent} /> In-Play</li>}
                        {liquidityStatus == 1 && fancyData.length == 0 && ((bookData2.matchStatus == 1 && bookData.runner1Status == 2 && bookData.runner2Status == 2 && bookData.runner3Status == 2) || bookData2.matchStatus != 1 || (bookData2.matchStatus == 1 && bookData.gameStatus != 2)) && <li><span id="lowLiquidityTag" class="game-low_liq" style={{ padding: "0px", color: "#1e1e1e" }} >Low Liquidity</span></li>}

                    </ul>
                    {props.isLoggedIn === true &&
                        <a id="lmtOpenStreaming" class="open-tv-wrap" onClick={ChangeTv}>Live</a>}

                    {/*<dl class="game-matched" style={{display:'block'}}>
					<dt>Matched</dt>
					<dd id="liveGameMatched">USD 4,577</dd>
				</dl>*/}
                    <dl id="minMaxBox" class="fancy-info" >

                        <dt id="maxDt" >Min</dt>
                        <dd id="minMaxInfo">{parseInt(marketData.minStack) * coinFactor}</dd>
                        <dt id="maxDt" >Max</dt>
                        <dd id="minMaxInfo">{parseInt(marketData.maxStack) * coinFactor}</dd>

                    </dl>
                </div>

                {/* && liveStream && liveStream != '' */}

                {Tvopen === true && moment() >= moment(marketData.marketStartTime) && liveStream && liveStream != '' && props.isLoggedIn &&
                    <div id="tv-wrap" class="game-streaming-wrap" onMouseEnter={() => setisShown(true)} onMouseLeave={() => setisShown(false)}>
                        <div id="tv" class="tv">
                            {isShown &&
                                <ul class="tv-control">
                                    <li><a class="close" id="closeStreamingBox" onClick={ChangeSetTv}></a></li>
                                </ul>}

                            {/* <Hls liveStream = {liveStream} />	 */}

                            <iframe style={{ height: '100%', width: '100%', border: 0 }}
                                title="mob-live-stream"
                                allowFullScreen={false}
                                src={`https://vid.dreamcasino.live/GetAPI.html?MatchID=${liveStream}`}
                                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups"
                            ></iframe>
                        </div>
                    </div>}


                {/*  <!-- Horse Coming Up Start -->*/}
                {/* <!-- Horse Coming Up End -->*/}



                {/*<!-- Left Event Wrap -->*/}


                {/*<!-- Right Event Wrap --> */}



                {/*  <!-- Markets Closed Wrap -->*/}


                {/*   <!-- Game Bets Table -->*/}
                {marketData.matchStatus == 1 && (marketBetStatus != 1 || marketBetStatus != 0) && hidematchOdds != '1' &&
                    <div id="fullMarketBetsWrap" class="bets-wrap" eventtype="4" eventid="30035305" marketid="1.173506114">
                        <span id="selectionCounts" class="refer_only">{marketData.runnerId3 && marketData.runnerId3 != ' ' ? '3 Selections' : '2 Selections'}</span>
                        <table id="fullMarketBoard" style={(marketBetStatus != 1) ? { pointerEvents: 'none' } : null} className={`bets ${(marketBetStatus != 1) ? "disabled" : ""}`}>
                            <tbody><tr class="bet-all">
                                <td></td>
                                <td id="backPercent" class="refer-bet" colspan="2">101.7%</td>
                                <td><a id="backAll" class="back-all"><img src={Transparent} /><span>Back all</span></a></td>
                                <td><a id="layAll" class="lay-all"><img src={Transparent} /><span>Lay all</span></a></td>
                                <td id="layPercent" class="refer-book" colspan="2">97.9%</td>
                            </tr>
                                <tr id="fullSelection_4164048" style={{ display: 'table-row' }} eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="4164048" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="1" style={(runner1BackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(runner1BackRate1 >= 25) ? "disabled" : "null"}`}>
                                    <th class="predict">
                                        <p>
                                            <a>
                                                <img class="icon-predict" src={Transparent} /></a>{marketData.runnerName1}</p>
                                        {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) &&
                                            <span id="withoutBet" className={`${pnlMarket.teamA > 0 ? "win" : "lose"}`} >{pnlMarket.teamA > 0 ? pnlMarket.teamA : '(' + Math.abs(pnlMarket.teamA) + ')'}</span>}

                                        {props.betClick && props.dStake != ' ' && props.dStake != '' &&
                                            <span id="after" className={`${pnlMarket2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamA > 0 ? pnlMarket2.teamA : '(' + Math.abs(pnlMarket2.teamA) + ')'}</span>}
                                    </th>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate3 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_3" className={`back-3 ${(c3 === 1) ? "spark" : "null"}`} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.88" inplay="0"><a>{runner1BackRate3}<span>{runner1BackSize3}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate2 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_2" className={`back-2 ${(c2 === 1) ? "spark" : "null"} `} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.89" inplay="0"><a>{runner1BackRate2}<span>{runner1BackSize2}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1BackRate1 === ' ') return; props.setOnClick(1); handleMatchOddsBet(true, runner1BackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="back_1" className={`back-1 ${(props.onSelect === 1) ? "select" : "null"} ${(c1 === 1) ? "spark" : "null"}`} side="Back" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.9" inplay="0"><a>{runner1BackRate1}<span>{runner1BackSize1}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate1 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_1" className={`lay-1 ${(props.onSelect === 2) ? "select" : "null"} ${(c4 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.97" inplay="0"><a>{runner1LayRate1}<span>{runner1LaySize1}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate2 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_2" className={`lay-2 ${(c5 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.98" inplay="0"><a>{runner1LayRate2}<span>{runner1LaySize2}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner1LayRate3 === ' ') return; props.setOnClick(2); handleMatchOddsBet(true, runner1LayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName1); }} id="lay_3" className={`lay-3 ${(c6 === 1) ? "spark" : "null"}`} side="Lay" selectionid="4164048" runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0"><a>{runner1LayRate3}<span>{runner1LaySize3}</span></a></td>
                                </tr>

                                <tr id="fullSelection_2954266" style={{ display: 'table-row' }} eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="2954266" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="2" style={(runner2BackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(runner2BackRate1 >= 25) ? "disabled" : "null"}`}>
                                    <th class="predict">
                                        <p>
                                            <a>
                                                <img class="icon-predict" src={Transparent} /></a>{marketData.runnerName2}</p>
                                        {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) && <span id="withoutBet" className={`${pnlMarket.teamB > 0 ? "win" : "lose"}`} >{pnlMarket.teamB > 0 ? pnlMarket.teamB : '(' + Math.abs(pnlMarket.teamB) + ')'}</span>}
                                        {props.betClick && props.dStake != ' ' && props.dStake != '' && <span id="after" className={`${pnlMarket2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.teamB > 0 ? pnlMarket2.teamB : '(' + Math.abs(pnlMarket2.teamB) + ')'}</span>}
                                    </th>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate3 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_3" className={`back-3  ${(c9 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay="0"  ><a>{runner2BackRate3}<span>{runner2BackSize3}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate2 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_2" className={`back-2 ${(c8 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay="0" ><a>{runner2BackRate2}<span>{runner2BackSize2}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2BackRate1 === ' ') return; props.setOnClick(3); handleMatchOddsBet(true, runner2BackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="back_1" className={`back-1 ${(props.onSelect === 3) ? "select" : "null"} ${(c7 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0" >
                                        <a>{runner2BackRate1}<span>{runner2BackSize1}</span></a></td>

                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate1 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_1" className={`lay-1 ${(props.onSelect === 4) ? "select" : "null"} ${(c10 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay="0" >
                                        <a>{runner2LayRate1}<span>{runner2LaySize1}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate2 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_2" className={`lay-2 ${(c11 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay="0" >
                                        <a>{runner2LayRate2}<span>{runner2LaySize2}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (runner2LayRate3 === ' ') return; props.setOnClick(4); handleMatchOddsBet(true, runner2LayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, marketData.runnerName2); }} id="lay_3" className={`lay-3 ${(c12 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay="0" >
                                        <a>{runner2LayRate3}<span>{runner2LaySize3}</span></a></td>
                                </tr>
                                {marketData.runnerId3 && marketData.runnerId3 != ' ' && <tr id="fullSelection_2954266" style={{ display: 'table-row' }} eventtype="4" eventid="30035305" marketid="1_173506114" selectionid="2954266" eventname="Royal Challengers Bangalore v Rajasthan Royals" status="1" sortpriority="2" style={(tieBackRate1 >= 25) ? { pointerEvents: 'none' } : null} className={`${(tieBackRate1 >= 25) ? "disabled" : "null"}`}>
                                    <th class="predict">
                                        <p>
                                            <a>
                                                <img class="icon-predict" src={Transparent} /></a>The Draw</p>

                                        {pnlMarket.teamA && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0) &&
                                            <span id="withoutBet" className={`${pnlMarket.tie > 0 ? "win" : "lose"}`} >{pnlMarket.tie > 0 ? pnlMarket.tie : '(' + Math.abs(pnlMarket.tie) + ')'}</span>}
                                        {props.betClick && props.dStake != ' ' && props.dStake != '' &&
                                            <span id="after" className={`${pnlMarket2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlMarket2.tie > 0 ? pnlMarket2.tie : '(' + Math.abs(pnlMarket2.tie) + ')'}</span>}
                                    </th>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate3 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_3" className={`back-3  ${(c15 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay="0"  >
                                        <a>{tieBackRate3}<span>{tieBackSize3}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate2 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_2" className={`back-2 ${(c14 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay="0" ><a>{tieBackRate2}<span>{tieBackSize2}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieBackRate1 === ' ') return; props.setOnClick(5); handleMatchOddsBet(true, tieBackRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="back_1" className={`back-1 ${(props.onSelect === 5) ? "select" : "null"} ${(c13 === 1) ? "spark" : "null"}`} side="Back" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay="0" ><a>{tieBackRate1}<span>{tieBackSize1}</span></a></td>
                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate1 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate1, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_1" className={`lay-1 ${(props.onSelect === 6) ? "select" : "null"} ${(c16 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay="0" ><a>{tieLayRate1}<span>{tieLaySize1}</span></a></td>

                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate2 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate2, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_2" className={`lay-2 ${(c17 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay="0" ><a>{tieLayRate2}<span>{tieLaySize2}</span></a></td>

                                    <td onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (tieLayRate3 === ' ') return; props.setOnClick(6); handleMatchOddsBet(true, tieLayRate3, eventid, marketData.marketId, parseInt(marketData.minStack) * coinFactor, parseInt(marketData.maxStack) * coinFactor, marketData.eventName, 'The Draw'); }} id="lay_3" className={`lay-3 ${(c18 === 1) ? "spark" : "null"}`} side="Lay" selectionid="2954266" runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay="0" ><a>{tieLayRate3}<span>{tieLaySize3}</span></a></td>
                                </tr>}


                            </tbody></table>

                    </div>}
                {bookData2.matchStatus == 1 &&
                    <div id="bookMakerWrap" class="bets-wrap  bookmaker_bet" >
                        <table id="bookMakerMarketList" class="bets bets-bookmaker" >
                            <colgroup>
                                <col span="1" width="280" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                            </colgroup>
                            <tbody id="bookMakerMarket_30035305_37909" style={{ display: ' table-row-group' }}>
                                <tr id="bookMakerSpecialBet" class="special_bet">
                                    <td colspan="7">
                                        <h3>
                                            <a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a>
                                            Bookmaker Market
                                            <p>| Zero Commission</p>
                                        </h3>
                                        <dl class="fancy-info">
                                            <dt><span>Min</span></dt>
                                            <dd id="min">{parseInt(bookData2.minStack) * coinFactor}</dd>
                                            <dt><span>Max</span></dt>
                                            <dd id="max">{parseInt(bookData2.maxStack) * coinFactor}</dd>

                                        </dl>
                                    </td>
                                </tr>

                                <tr class="bet-all">
                                    <td colspan="3"></td>
                                    {/*  <!-- <td class="refer-bet"></td> --> */}
                                    <td>Back</td>
                                    <td>Lay</td>
                                    <td class="refer-book" colspan="2"></td>
                                </tr>

                                {(bookData.gameStatus != 2 || bookData.runner1Status == 2) && <tr id="bookMakerSuspend_30035305_37909_111798" class="fancy-suspend-tr" marketid="37909">
                                    <th colspan=""></th>
                                    <td class="fancy-suspend-td" colspan="6">
                                        <div id="suspendClass" class="fancy-suspend"><span id="info">{bookData.gameStatus == 10 && bookData.runner1Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                <tr id="bookMakerSelection_30035305_37909_111798" style={{ display: 'table-row' }}>
                                    <th class="predict">
                                        <p id="runnerName">{marketData.runnerName1}</p>

                                        {pnlBook.teamA && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span id="before" className={`${pnlBook.teamA > 0 ? "win" : "lose"}`} >{pnlBook.teamA > 0 ? pnlBook.teamA : '(' + Math.abs(pnlBook.teamA) + ')'}</span>}

                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamA > 0 ? pnlBook2.teamA : '(' + Math.abs(pnlBook2.teamA) + ')'}</span>}



                                    </th>
                                    <td class="" colspan="3">
                                        <dl class="back-gradient">
                                            <dd id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate3 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1BackRate3}</p></dd>
                                            <dd id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate2 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1BackRate2}</p></dd>
                                            <dd id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1BackRate1 === ' ') return; handleBookBets(1, 1, bookData.runner1BackRate1) }} className={`${(selectBack === 1) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner1BackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td class="" colspan="3">
                                        <dl class="lay-gradient">
                                            <dd id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate1 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate1) }} className={`${(selectBack === 2) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner1LayRate1}</a></dd>
                                            <dd id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate2 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1LayRate2}</p></dd>
                                            <dd id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner1LayRate3 === ' ') return; handleBookBets(2, 1, bookData.runner1LayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner1LayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>

                                {(Bookclick2 === true && !(bookData.gameStatus != 2 || bookData.runner1Status == 2)) &&
                                    <tr id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td colspan="7">
                                            <dl id="classWrap" className={`quick_bet-wrap ${(selectBack === 1) ? "slip-back" : "slip-lay"}`} >
                                                <dt id="bookMakerBetHeader">

                                                    <span id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input onChange={() => { setacceptAnyOdds(!acceptAnyOdds) }} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label htmlFor="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd id="oddsHeader" class="col-odd">
                                                    <ul class="quick-bet-confirm">
                                                        <li id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd class="col-stake">

                                                    <input id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd class="col-send">
                                                    <a onClick={(e) => { setbookBetSelection(1); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} id="placeBet" class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>}


                                {(bookBetSelection === 1 || (props.oneclick === true && window.book_index === 1)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 1 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd id="info">
                                                <strong id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 1 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd id="info">
                                                <strong id="header">{bookMsg.msg}</strong>

                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}

                                {((bookData.gameStatus != 2) || bookData.runner2Status == 2) && <tr id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                    <th colspan=""></th>
                                    <td class="fancy-suspend-td" colspan="6">
                                        <div id="suspendClass" class="fancy-suspend" ><span id="info">{bookData.gameStatus == 10 && bookData.runner2Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                <tr id="bookMakerSelection_30035305_37909_111799" style={{ display: 'table-row' }}>
                                    <th class="predict">
                                        <p id="runnerName">{marketData.runnerName2}</p>

                                        {pnlBook.teamB && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >{pnlBook.teamB > 0 ? pnlBook.teamB : '(' + Math.abs(pnlBook.teamB) + ')'}</span>}

                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.teamB > 0 ? pnlBook2.teamB : '(' + Math.abs(pnlBook2.teamB) + ')'}</span>}

                                    </th>
                                    <td class="" colspan="3">
                                        <dl class="back-gradient">
                                            <dd id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate3 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate3) }} style={{ cursor: 'pointer' }}> <p>{bookData.runner2BackRate3}</p></dd>
                                            <dd id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate2 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate2) }} style={{ cursor: 'pointer' }}> <p>{bookData.runner2BackRate2}</p></dd>
                                            <dd id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2BackRate1 === ' ') return; handleBookBets(3, 2, bookData.runner2BackRate1) }} className={`${(selectBack === 3) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2BackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td class="" colspan="3">
                                        <dl class="lay-gradient">
                                            <dd id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate1 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate1) }} className={`${(selectBack === 4) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.runner2LayRate1}</a></dd>
                                            <dd id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate2 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.runner2LayRate2}</p></dd>
                                            <dd id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.runner2LayRate3 === ' ') return; handleBookBets(4, 2, bookData.runner2LayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.runner2LayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>

                                {(Bookclick === true && !(bookData.gameStatus != 2 || bookData.runner2Status == 2)) &&
                                    <tr id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td colspan="7">
                                            <dl id="classWrap" className={`quick_bet-wrap ${(selectBack === 3) ? "slip-back" : "slip-lay"}`}>
                                                <dt id="bookMakerBetHeader">

                                                    <span id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input onChange={() => setacceptAnyOdds(!acceptAnyOdds)} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label for="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd id="oddsHeader" class="col-odd">
                                                    <ul class="quick-bet-confirm">
                                                        <li id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd class="col-stake">

                                                    <input id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd class="col-send">
                                                    <a id="placeBet" onClick={(e) => { setbookBetSelection(2); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>
                                }


                                {(bookBetSelection === 2 || (props.oneclick === true && window.book_index === 2)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 2 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd id="info">
                                                <strong id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 2 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd id="info">
                                                <strong id="header">{bookMsg.msg}</strong>

                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}



                                {/*The Draw */}

                                {bookData2.runnerId3 && bookData2.runnerId3 != ' ' && ((bookData.gameStatus != 2) || bookData.runner3Status == 2) && <tr id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                    <th colspan=""></th>
                                    <td class="fancy-suspend-td" colspan="6">
                                        <div id="suspendClass" class="fancy-suspend" ><span id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1 ? 'Ball Running' : 'Suspend'}</span></div>
                                    </td>
                                </tr>}

                                {bookData2.runnerId3 && bookData2.runnerId3 != ' ' && <tr id="bookMakerSelection_30035305_37909_111799" style={{ display: 'table-row' }}>
                                    <th class="predict">
                                        <p id="runnerName">The Draw</p>

                                        {pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && <span id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >{pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</span>}


                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && <span id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >{pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</span>}

                                    </th>
                                    <td class="" colspan="3">
                                        <dl class="back-gradient">
                                            <dd id="back_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate3 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate3) }} style={{ cursor: 'pointer' }}> <p>{bookData.tieBackRate3}</p></dd>
                                            <dd id="back_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate2 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate2) }} style={{ cursor: 'pointer' }}> <p>{bookData.tieBackRate2}</p></dd>
                                            <dd id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieBackRate1 === ' ') return; handleBookBets(5, 3, bookData.tieBackRate1) }} className={`${(selectBack === 5) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieBackRate1}</a></dd>
                                        </dl>
                                    </td>
                                    <td class="" colspan="3">
                                        <dl class="lay-gradient">
                                            <dd id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate1 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate1) }} className={`${(selectBack === 6) ? "select" : "null"}`} style={{ cursor: 'pointer' }}><a>{bookData.tieLayRate1}</a></dd>
                                            <dd id="lay_2" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate2 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate2) }} style={{ cursor: 'pointer' }}><p>{bookData.tieLayRate2}</p></dd>
                                            <dd id="lay_3" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } if (bookData.tieLayRate3 === ' ') return; handleBookBets(6, 3, bookData.tieLayRate3) }} style={{ cursor: 'pointer' }}><p>{bookData.tieLayRate3}</p></dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {(Bookclick3 === true && !(bookData.gameStatus != 2 || bookData.runner3Status == 2)) &&
                                    <tr id="bookMakerBetBoard_30045511_40466_119397_1" class="fancy-quick-tr">
                                        <td colspan="7">
                                            <dl id="classWrap" className={`quick_bet-wrap ${(selectBack === 5) ? "slip-back" : "slip-lay"}`}>
                                                <dt id="bookMakerBetHeader">

                                                    <span id="bookMakerBetAcceptCheck" class="bet-check">
                                                        <input onChange={() => setacceptAnyOdds(!acceptAnyOdds)} id="bookMakerBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                        <label for="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
                                                    </span>

                                                </dt>
                                                <dd class="col-btn"><a id="cancel" onClick={() => { closeBookBet(); }} class="btn" style={{ cursor: 'pointer' }}>Cancel
                                                </a></dd>
                                                <dd id="oddsHeader" class="col-odd">
                                                    <ul class="quick-bet-confirm">
                                                        <li id="odds">{bOdds}</li>
                                                    </ul>
                                                </dd>
                                                <dd class="col-stake">

                                                    <input id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                </dd>
                                                <dd class="col-send">
                                                    <a id="placeBet" onClick={(e) => { setbookBetSelection(3); setTimeout(() => { setbookBetSelection(0); }, 5000); placeBet(); closeBookBet(); }} class="btn-send" style={{ cursor: 'pointer' }}>Place Bets
                                                    </a>
                                                </dd>
                                                <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} bookmakerbetboard="bookMakerBetBoard_30045511_40466_119397_1">
                                                    <ul>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake1); }} id="selectStake_1" stake="700" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake2); }} id="selectStake_2" stake="700" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake3); }} id="selectStake_3" stake="1000" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake4); }} id="selectStake_4" stake="1000" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake5); }} id="selectStake_5" stake="5000" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                        <li><a class="btn" onClick={() => { setdefaultStake(ssstake6); }} id="selectStake_6" stake="10000" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </td>
                                    </tr>
                                }

                                {(bookBetSelection === 3 || (props.oneclick === true && window.book_index === 3)) && <Countdown t={5} />}

                                {bookMsg.number && bookMsg.number === 3 && bookMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                            <dd id="info">
                                                <strong id="header">Bet Matched</strong>
                                                USD at odds: {props.oneclick === false ? bOdds : window.book_odd}
                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}
                                {bookMsg.number && bookMsg.number === 3 && bookMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                    <td colspan="7">
                                        <dl id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                            <dd id="info">
                                                <strong id="header">{bookMsg.msg}</strong>

                                                <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                            </dd>
                                        </dl>
                                    </td>
                                </tr>}

                            </tbody>
                        </table>
                    </div>}

                {eventT == 4 &&
                    <div id="fancyBetTable_30035305" class="bets-wrap fancy_bet" name="multiMarketItem">
                        <div id="fancyBetHead" class="fancy-head">
                            <h4 class="in-play">
                                {/*<!-- a id="multiMarketPin" class="add-pin" name="te" style={{cursor:'pointer'}} title="Add to Multi Markets"></a-->*/}
                                <span id="headerName">Fancy Bet</span>
                                <a class="btn-fancybet_rules" ></a>
                            </h4>

                        </div>



                        <table id="fancyBetMarketList" class="bets" >
                            <colgroup>
                                <col span="1" width="280" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                                <col span="1" width="70" />
                            </colgroup>
                            <tbody>
                                <tr id="fancyBetSpecialBet" class="special_bet">
                                    <td colspan="7">
                                        <h3>
                                            <a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a>
                                            Fancy Bet
                                        </h3>
                                    </td>
                                </tr>
                                <tr class="bet-all">
                                    <td></td>
                                    <td class="refer-bet" colspan="2"></td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td class="refer-book" colspan="2"></td>
                                </tr>







                                {fancyData.map(function (item, index) {


                                    var pnl = null;
                                    var pnl2 = null;


                                    if (pnlFancy[item.selectionId]) {
                                        pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                    }
                                    if (click === index) {

                                        if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                            pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                        }
                                        else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                            pnl2 = -Math.abs(defaultStake);
                                        }

                                    }


                                    return (
                                        <React.Fragment key={index}>

                                            {(item.gameStatus != 2) && <tr id="bookMakerSuspend_30035305_37909_111799" class="fancy-suspend-tr" marketid="37909">
                                                <th colspan="3"></th>
                                                <td class="fancy-suspend-td" colspan="2">
                                                    <div id="suspendClass" class="fancy-suspend" ><span id="info">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</span></div>
                                                </td>
                                            </tr>}

                                            <tr id="fancyBetMarket_168674" >
                                                <th colspan="3">
                                                    <dl class="fancy-th-layout">
                                                        <dt>
                                                            <p id="marketName">{item.runnerName}</p>

                                                            {pnl && <span id="before" className={`${pnl >= 0 ? "win" : "lose"}`}>{pnl >= 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span>}

                                                        </dt>
                                                        <dd class="dd-tips">
                                                            <ul class="fancy-tips">

                                                            </ul>

                                                        </dd>
                                                    </dl>
                                                </th>


                                                <td id="lay_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } OpenfancyBet(index, item.layPrice, item.laySize, item.selectionId); setfOdds(item.layPrice); setfsize(item.laySize); }} className={`lay-1 ${(selectFancy === 1 && click === index && field === 1) ? "select" : "null"}`} >
                                                    <a id="runsInfo" style={{ cursor: 'pointer' }}>{item.layPrice ? item.layPrice : '--'}<span>{item.laySize ? item.laySize : '--'}</span></a>
                                                </td>
                                                <td id="back_1" onClick={() => { if (!props.isLoggedIn) { props.handleOpen(); return; } OpenfancyBetLay(index, item.backPrice, item.backSize, item.selectionId); setfOdds(item.backPrice); setfsize(item.backSize); }} className={`back-1 ${(selectFancyLay === 1 && click === index && field === 2) ? "select" : "null"}`}>
                                                    <a id="runsInfo" style={{ cursor: 'pointer' }}>{item.backPrice ? item.backPrice : '--'}<span>{item.backSize ? item.backSize : '--'}</span></a>
                                                </td>
                                                <td class="td-fancy_merge" colspan="2">
                                                    <dl class="fancy-info">
                                                        <dt>Min/Max</dt>
                                                        <dd id="minMax"> {parseInt(item.minStack) * coinFactor} / {parseInt(item.maxStack) * coinFactor}</dd>
                                                    </dl>
                                                    <dl class="fancy-info">

                                                    </dl>
                                                </td>
                                            </tr>

                                            {click === index && props.oneclick === false &&

                                                <tr id="fancyBetBoard_30042557_170374_2" class="fancy-quick-tr">
                                                    <td colspan="7">
                                                        <dl id="classWrap" className={`quick_bet-wrap ${field === 1 ? "slip-lay" : "slip-back"}`}>
                                                            <dt id="fancyBetHeader" class="">

                                                                <span id="fancyBetAcceptCheck" class="bet-check">
                                                                    <input id="fancyBetAcceptAnyOdds" type="checkbox" style={{ float: 'initial', margin: '0 4px' }} />
                                                                    <label for="fancyBetAcceptAnyOdds">Accept Any Odds</label>
                                                                </span>

                                                            </dt>
                                                            <dd class="col-btn"><a id="cancel" class="btn" onClick={() => { closeFancyBet() }} style={{ cursor: 'pointer' }}>Cancel</a></dd>
                                                            <dd id="oddsHeader" class="col-odd">
                                                                <ul class="quick-bet-confirm">
                                                                    <li id="runs">{fOdds}</li>
                                                                    <li id="odds" class="quick-bet-confirm-title">{fsize}</li>
                                                                </ul>
                                                            </dd>
                                                            <dd class="col-stake">
                                                                <input id="inputStake" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake} />
                                                            </dd>
                                                            <dd class="col-send">
                                                                <a id="placeBet" class="btn-send" onClick={(e) => { setfancySelection(index); setTimeout(() => { setfancySelection(-1); }, 3000); fancyBet(item.selectionId, field, index); closeFancyBet(); }} style={{ cursor: 'pointer' }}>Place Bets</a>
                                                            </dd><dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} fancybetboard="fancyBetBoard_30042557_170374_2">
                                                                <ul>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake1); }} class="btn" id="selectStake_1" stake="30" style={{ cursor: 'pointer' }}>{ssstake1}</a></li>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake2); }} class="btn" id="selectStake_2" stake="40" style={{ cursor: 'pointer' }}>{ssstake2}</a></li>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake3); }} class="btn" id="selectStake_3" stake="50" style={{ cursor: 'pointer' }}>{ssstake3}</a></li>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake4); }} class="btn" id="selectStake_4" stake="60" style={{ cursor: 'pointer' }}>{ssstake4}</a></li>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake5); }} class="btn" id="selectStake_5" stake="70" style={{ cursor: 'pointer' }}>{ssstake5}</a></li>
                                                                    <li><a onClick={(e) => { setdefaultStake(ssstake6); }} class="btn" id="selectStake_6" stake="80" style={{ cursor: 'pointer' }}>{ssstake6}</a></li>
                                                                </ul>
                                                            </dd>
                                                        </dl>
                                                    </td>
                                                </tr>}

                                            {((fancySelection === index && !props.oneclick) || (props.oneclick && window.fancy_index === index)) && <Countdown t={3} />}

                                            {(fancyMsg.number || fancyMsg.number === 0) && fancyMsg.number === index && fancyMsg.msgNumber === 1 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                                <td colspan="7">
                                                    <dl id="classWrap" class="quick_bet-wrap quick_bet-message success">
                                                        <dd id="info">
                                                            <strong id="header">Bet Matched</strong>
                                                            USD at odds: {fOdds}/{fsize}
                                                            <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                                        </dd>
                                                    </dl>
                                                </td>
                                            </tr>}

                                            {(fancyMsg.number || fancyMsg.number === 0) && fancyMsg.number === index && fancyMsg.msgNumber === 2 && <tr id="bookMakerBetMessageTemplate" class="fancy-quick-tr" >
                                                <td colspan="7">
                                                    <dl id="classWrap" class="quick_bet-wrap quick_bet-message error">
                                                        <dd id="info">
                                                            <strong id="header">{fancyMsg.msg}</strong>

                                                            <a id="close" class="btn-close" style={{ cursor: 'pointer' }}></a>
                                                        </dd>
                                                    </dl>
                                                </td>
                                            </tr>}



                                        </React.Fragment>)
                                })}

                            </tbody>

                        </table>
                    </div>}




                <div id="fullMarketEventLeft" class="event-left" style={{ display: 'block' }}></div>


                <div id="fullMarketEventRight" class="event-right" style={{ display: 'block' }}></div>

            </div>
        </React.Fragment>
    )
}
