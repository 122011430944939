import React, { useState } from 'react'
import InplayContent from './InplayContent'
import Today from './Today'
import Tommorow from './Tomorrow'



export default function InplayDesktop(props) {
   const [Eventday, setEventday] = useState(1)


   return (

      <div>
         <div>

            <div id="centerColumn" class="inplay">

               <div style={{ marginTop: ' 10px' }}>
                  <ul class="tab col3">
                     <li onClick={() => { setEventday(1) }}>
                        <a className={`${(Eventday === 1) ? "select" : "null"}`}>In-Play</a></li>
                     <li onClick={() => { setEventday(2) }}>
                        <a className={`${(Eventday === 2) ? "select" : "null"}`}>Today</a></li>
                     <li onClick={() => { setEventday(3) }}>
                        <a className={`${(Eventday === 3) ? "select" : "null"}`}>Tomorrow</a></li>
                  </ul>
               </div>

               {Eventday === 1 && <InplayContent changeState={props.changeState} />}
               {Eventday === 2 && <Today changeState={props.changeState} />}
               {Eventday === 3 && <Tommorow changeState={props.changeState} />}

            </div>
         </div>

      </div>

   )
}
