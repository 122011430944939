import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Support from './Support';
import OneTouch from '../images/casino/onetouch.jpeg';
import axios from 'axios';
import Playgo from '../images/casino/playngoo.jpeg'
import microgaming from '../images/casino/micro.jpeg'
import caleta from '../images/casino/caleta.jpeg'
import Indi from '../images/casino/indi.jpeg'
import betsoft from '../images/casino/betsoft.jpeg'
import Poker from '../images/JILI.jpeg'

import Booming from '../images/casino/Booming.jpeg'
import Tangente from '../images/casino/tangente.jpeg'
import Golden from '../images/casino/Golden.jpeg'
import Tomhorn from '../images/casino/tomhorn.jpeg'
// import Push from '../images/casino/push.jpeg';
import Habanero from '../images/casino/habanero.jpeg'
import Thunderkick from '../images/casino/thunderkick.jpeg'
import Blueprint from '../images/casino/blueprint.jpeg'
import Genii from '../images/casino/genii.jpeg'
import Pragmatic from '../images/casino/PragmaticPlay.png'
import Hacksaw from '../images/casino/Hacksaw.jpeg'
import Gamomat from '../images/casino/gamomat.jpeg'
import Stormcraft from '../images/casino/stormCraft.jpeg'
import Nolimit from '../images/casino/nolimit.jpeg'
import Relax from '../images/casino/relax.jpeg'
import Big from '../images/casino/BTG.png'
import Sloti from '../images/casino/mrsloti.jpeg'
import Quickspin from '../images/casino/quickspin.png'
import Bgaming from '../images/casino/bgaming.jpeg'
import Kiron from '../images/casino/kiron.jpeg'
import Evoplay from '../images/casino/evoplay.jpeg'
import Fugaso from '../images/casino/Fugaso.jpeg'
import Lotto from '../images/casino/lotto.jpeg'
import Pragmatic1 from '../images/casino/Pragmatic.png'
import Green from '../images/casino/Capture.jpeg'
import JFTW from '../images/casino/jftw.png'
import by2 from '../images/casino/2by2.jpeg'
import TrueLab from '../images/casino/truelab.jpeg'
import fantasma from '../images/casino/fantasma.jpeg'
import Money from '../images/casino/MoneyCart2.png'
import Candy from '../images/casino/Candy.jpeg'
import Triplecherry from '../images/casino/triplecherry.jpeg'
import Triple from '../images/casino/tripleedge.jpeg'
import Neon from '../images/casino/casinonight.jpeg'
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export const CasinoList = (props) => {


    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [casinoWait, setcasinoWait] = useState(false);

    const openEvolution = (gameI) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);
            axios.post('https://millionbet247.com/api6/casino/game/url', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }
    return (
        <React.Fragment>

            {casinoWait &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }


            <div class="mian-wrap">

                <div class="gamehall">
                    {/* <Link to="/exchange/member/inplay" style={{gridColumn: 'span 2'}}>
<dl class="entrance-title">
    <dt>Sports</dt>
    <dd><span>Play Now</span></dd>
</dl>
<dl id="onLiveBoard" class="on_live">
    <dt>
        <p class="live_icon"><span></span> LIVE</p>
    </dt>
    
    <dd id="onLiveCount_CRICKET"><p>Cricket</p><span id="count">3</span></dd>
    
    <dd id="onLiveCount_SOCCER"><p>Soccer</p><span id="count">3</span></dd>
    
    <dd id="onLiveCount_TENNIS"><p>Tennis</p><span id="count">4</span></dd>
    
</dl>

</Link> */}

                    {/* <Link to="/inplay" style={{gridColumn: 'span 2'}}>
<dl class="entrance-title">
    <dt>Evolution Gaming</dt>
    <dd>Play Now</dd>
</dl>
<img src={Evolution} style={{height:'150px'}} alt="" draggable="false"/>
</Link> */}

                    {/* <Link to="/inplay" style={{gridColumn: 'span 2'}}>
<dl class="entrance-title">
    <dt>Ezugi Gaming</dt>
    <dd>Play Now</dd>
</dl>SlotsGames
<img src={Ezugi} style={{height:'150px'}} alt="" draggable="false"/>
</Link> */}
                    {/* <!-- SABA sports --> */}
                    {/* onClick = {()=>{openEvolution(13930);}} */}


                    {/* onClick = {()=>{openEzugi();}} */}


                    {/* onClick = {()=>{openEvolution(6511);}} */}

                    <a class="entrance-half">
                        <dl class="entrance-title">
                            <dt>Jili</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Poker} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openEvolution(11576); }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>Caleta Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={caleta} alt="" draggable="false" />

                    </a>


                    <Link onClick={() => { openEvolution(628); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Indi Slots</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Indi} alt="" draggable="false" />

                    </Link>

                    {/* onClick = {()=>{openEzugi('poker');}} */}

                    <a onClick={() => { openEvolution(755); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Betsoft</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={betsoft} alt="" draggable="false" />
                    </a>
                    <Link onClick={() => { openEvolution(907); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Booming Games</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Booming} alt="" draggable="false" />
                    </Link>

                    <Link onClick={() => { openEvolution(3784); }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>Tangente</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Tangente} alt="" draggable="false" />
                    </Link>

                    <Link onClick={() => { openEvolution(9073); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Play'n Go</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Playgo} alt="" draggable="false" />

                    </Link>


                    <Link onClick={() => { openEvolution(14189); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Golden Hero</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Golden} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(14301); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Tom Horn</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Tomhorn} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(1180); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Push Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        {/* <img src={Push} alt="" draggable="false"/> */}
                    </Link>
                    <Link onClick={() => { openEvolution(1230); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Habanero</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Habanero} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(1796); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Thunderkick</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Thunderkick} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(2275); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Blueprint Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Blueprint} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(3047); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Genii</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Genii} alt="" draggable="false" />
                    </Link>



                    <Link onClick={() => { openEvolution(3715); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Hacksaw Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Hacksaw} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(3762); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Gamomat</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Gamomat} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(15308); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Stormcraft</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Stormcraft} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(15995); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Nolimit City</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Nolimit} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(6606); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Triple Cherry</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Triplecherry} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(6657); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Relax Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Relax} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(6669); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Big Time Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Big} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(6846); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>MrSlotty</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Sloti} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(7219); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Quickspin</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Quickspin} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(10350); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Bgaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Bgaming} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(12623); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Kiron</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Kiron} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(13700); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Evoplay Entertainment</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Evoplay} alt="" draggable="false" />
                    </Link>

                    <Link onClick={() => { openEvolution(12957); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Fugaso</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Fugaso} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(9169); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Lotto Instant Win</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Lotto} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(14892); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Pragmatic Play Live</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Pragmatic} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(14271); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Green Jade</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Green} alt="" draggable="false" />
                    </Link>
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Oryx Gaming</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Foxium</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    <Link onClick={() => { openEvolution(12050); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>JFTW</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={JFTW} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(129); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>2By2</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={by2} alt="" draggable="false" />
                    </Link>
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>All41</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    <Link onClick={() => { openEvolution(12148); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Triple Edge</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Triple} alt="" draggable="false" />
                    </Link>
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Gameburger</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Pulse 8</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    {/* <Link  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Neon Valley</dt>
<dd>Play Now</dd>
</dl>

</Link> */}
                    <Link onClick={() => { openEvolution(12940); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Candy Tower</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Candy} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(8953); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Neon lights</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Neon} alt="" draggable="false" />
                    </Link>
                    <Link onClick={() => { openEvolution(14705); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Money Cart 2</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Money} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(9000); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>TrueLab</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={TrueLab} alt="" draggable="false" />

                    </Link>
                    <Link onClick={() => { openEvolution(13055); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Fantasma</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={fantasma} alt="" draggable="false" />
                    </Link>

                    {/* <!-- BetGames --> */}
                    {/* <Link to="/andarbahar" onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>Ander Bahar</dt>
<dd>Play Now</dd>
</dl>
<img src={AnderBaharN} alt="" draggable="false"/>
</Link> */}

                    {/* <!-- KM Andar Bahar --> */}

                    {/* onClick = {()=>{openEzugi('32cards');}} */}

                    {/* <a  onClick = {()=>{openEvolution(129);}} class="entrance-half"  style={{cursor: "pointer"}}>
<dl class="entrance-title">
<dt>32 Cards</dt>
<dd>Play Now</dd>
</dl>
<img src={Casino} alt="" draggable="false"/>
</a> */}
                    {/* <!-- KM_7up7down --> */}



                    {/* <!-- KM Jhandi Munda --> */}


                    {/* <!-- Teen Patti --> */}

                </div>

                <Support />
            </div>

        </React.Fragment>
    )
}
