import React, { useState } from 'react'
import Loading from "../images/loading40.gif"
import axios from 'axios'
import { Link } from "react-router-dom";
import Transparent from '../images/transparent.gif'


export function MenuList(props) {

   const fetchData = (item) => {
      if (item.MarketName) {
         props.changeMenu([{ MarketName: 'Match Odds', fullmarket: true, eventId: item.eventId, marketId: item.marketId, eventType: item.sportId }]);
      }

      if (item.eventId) {
         props.changeMenu([{ MarketName: 'Match Odds', eventId: item.eventId, marketId: item.marketId, eventType: item.sportId }]);
      }
      else if (item.competitionId) {
         axios.get(
            `https://millionbet247.com/api/client/clientevents/2`, {
            params: {
               eventType: item.sportId,
               competitionId: item.competitionId
            }
         })

            .then(result => {
               props.changeMenu(result.data);
               //setMenu(result.data); 
            })
            .catch(error => console.log('error'));
      }
      else if (item.eventType) {

         axios.get(
            `https://millionbet247.com/api/client/clientevents/1`, {
            params: {
               eventType: item.eventType
            }
         })

            .then(result => {
               props.changeMenu(result.data);
            })
            .catch(error => console.log('error'));
      }
      else {

      }
   }
   const handleData = (item) => {
      var arr1 = props.stack;
      if (item.eventId && props.stack.length < 4) {
         arr1.push({ eventId: item.eventId, label: item.eventName });
      }

      else if (item.competitionId) {
         arr1.push({ competitionId: item.competitionId, label: item.competitionName });


      }
      else if (item.eventType && item.sport) {
         arr1.push({ eventType: item.eventType, label: item.sport });

      }
      else {
      }

      props.changeState(arr1);



      fetchData(item);



   }



   return (
      <div>
         {props.menu.map(function (item, id) {
            let item1 = null;
            let item2 = null;

            if (item.MarketName) {
               item2 = item.MarketName;


            }
            if (item.eventId) {
               item2 = item.eventName;
               item1 = item.eventId;

            }

            else if (item.competitionId) {
               item2 = item.competitionName;
               item1 = item.competitionId;

            }
            else if (item.eventType && item.sport) {
               item2 = item.sport;
               item1 = item.eventType;

            }

            if (item.MarketName) {

               return (

                  <li key={id} style={{ display: 'list-item' }} title="">


                     <Link to={`/exchange/member/fullmarket/${item.eventType}/${item.eventId}/${item.marketId}`} style={{ cursor: 'pointer' }} ><img class="icon-in_play" src={Transparent} alt="playIcon" />{item.MarketName}</Link>
                  </li>
               );
            }

            else {
               return (
                  <li key={id} style={{ display: 'list-item' }} title="">

                     {/* {<a onClick={() => { handleData(item) }} class="Go" style={{ cursor: 'pointer' }} >Go</a>} */}
                     <a onClick={() => { handleData(item) }} style={{ cursor: 'pointer' }} >{item2}</a>
                  </li>
               );
            }

         })}


      </div>);
}

export function BarList(props) {

   //const [menu, setMenu] = useState(props.stack);

   const changeProps = (id) => {
      var arr = props.stack.slice(0, id + 1);;

      props.changeState(arr);

      if (arr.length === 1) {
         props.changeMenu([{ eventType: 4, sport: 'Cricket' }, { eventType: 1, sport: 'Soccer' }, { eventType: 2, sport: 'Tennis' }]);

      }
      else if (arr.length === 2) {
         axios.get(
            `https://millionbet247.com/api/client/clientevents/1`, {
            params: {
               eventType: arr[1].eventType
            }
         })

            .then(result => {
               props.changeMenu(result.data);




            })


            .catch(error => console.log('error'));


      }
      else if (arr.length === 3) {

         axios.get(
            `https://millionbet247.com/api/client/clientevents/2`, {
            params: {
               eventType: arr[1].eventType,
               competitionId: arr[2].competitionId
            }
         })

            .then(result => {
               props.changeMenu(result.data);


            })


            .catch(error => console.log('error'));

      }
      else if (arr.length === 4) {

      }


   }


   return (
      <div>
         {props.stack.map(function (item, id) {

            return (


               <li key={id} class="">
                  <a onClick={() => { changeProps(id); }}>{item.label}</a>
               </li>
            );


         })}


      </div>);
}

export default function Left(props) {

   const [stack, setStack] = useState([{ sportId: 0, label: 'Sports' }]);
   const [menu, setMenu] = useState([{ eventType: 4, sport: 'Cricket' }, { eventType: 1, sport: 'Soccer' }, { eventType: 2, sport: 'Tennis' }]);

   const changeState = (obj) => {
      var obj1 = [];
      for (let i = 0; i < obj.length; i++) {
         obj1[i] = obj[i];
      }
      setStack(obj1);

   };
   const changeMenu = (obj) => {
      if (obj[0] && obj[0].fullmarket) {
         props.changeState(true);

      }
      var obj1 = [];
      for (let i = 0; i < obj.length; i++) {
         obj1[i] = obj[i];
      }
      setMenu(obj1);

   };


   return (
      <div>

         <div class="col-left">
            <div class="sub_path" style={{ height: 'calc(100% - 0px)' }}>
               <div class="path">
                  Sports
               </div>

               <ul class="path-pop" id="show">
                  <li class=""  >
                     <a  >Cricket</a>
                  </li>
                  <li class="">
                     <a  >Tennis</a>
                  </li>
                  <li class="">
                     <a  >.</a>
                  </li>
                  <li class="node"><a   >Sports</a></li>

               </ul>


               <ul id="loadingTree" class="loading" style={{ display: 'none' }}>
                  <li><img src={Loading} alt="loading40" /></li>
                  <li>Loading...</li>
               </ul>

               <ul class="menu-list">
                  <MenuList stack={stack} changeState={changeState} menu={menu} changeMenu={changeMenu} />
               </ul>


            </div>
         </div>
      </div>
   )
}
