import React from 'react'

const Horsedayafter = () => {
    return (
        <React.Fragment>
            <div id="contentBox">
    <div id="countryGroup_GB" className="game-wrap-horse" firstmarkettime="2021-12-29 00:20">
        <h3>
            <a className="to-expand">
        <span id="heading">GB</span>
        <span id="closeInfo" className="close-info" style={{display: 'none'}}>Race 1 (Next in 36 hrs 44 mins)</span>
      </a>
        </h3>
        <div id="gameList" className="game-list" />
        <dl id="racingBox_31146921" className="game-list-col hs-time" firstmarkettime="2021-12-29 00:20">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>Newcastle 28th Dec</a>
        <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
      </dt>
            <dd>
                <ul id="timeRacing">
                    <li id="liTemp"><a id="market_1_192776236" title="6f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146921&marketId=1.192776236&quot;,true)" marketdatetime={1640717400000}>00:20</a></li>
                </ul>
            </dd>
        </dl>
    </div>
</div>
        </React.Fragment>
    )
}

export default Horsedayafter
