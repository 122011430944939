import React from 'react'
import Soccerjpg from '../images/kv/KV02.jpg';
import SoccerMatches from './SoccerMatches';
const axios = require("axios");


export default function Soccer(props) {
  return (
    <div>

      <div>
        <div class="kv-wrap">
          <img src={Soccerjpg} alt="" />
        </div>
        {/* <div class="upcome-wrap" id="upcomingHorseRace">
          <dl class="upcome-head" id="next">
            <dt id="nextHeader">Next Horse Race
            </dt>
            <dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31982473&amp;marketId=1.207925491" id="upcoming_1.207925491">19:57 Chantilly(FRA)</a>
              <span>1m1f Hcap</span>
            </dd></dl>

          <dl class="upcome" id="upcoming">
            <dt id="upcomeHeader">Coming Up
            </dt>
            <dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31982647&amp;marketId=1.207928764" id="upcoming_1.207928764">20:05 Meydan(UAE)</a>
              <span></span>
            </dd><dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31980728&amp;marketId=1.207900333" id="upcoming_1.207900333">20:20 Fairview(RSA)</a>
              <span></span>
            </dd><dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31982473&amp;marketId=1.207925493" id="upcoming_1.207925493">20:32 Chantilly(FRA)</a>
              <span></span>
            </dd><dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31982647&amp;marketId=1.207928769" id="upcoming_1.207928769">20:40 Meydan(UAE)</a>
              <span></span>
            </dd><dd>
              <a href="/exchange/member/fullMarket?eventType=7&amp;eventId=31982647&amp;marketId=1.207928774" id="upcoming_1.207928774">21:15 Meydan(UAE)</a>
              <span></span>
            </dd></dl>
        </div> */}

        <div id="gameHighlightWrap" style={{ marginBottom: '0' }} class="game-highlight-wrap col3">
          <h3 style={{
            textAlign: 'left', color: '#000', fontWeight: 'bold', padding: '3px 2px 3px 10px'
          }}>Sports Highlights
            <div class="highlight-sorting">
              <label for="lang">View by</label>
              <div class="select">
                <select name="View" onchange="HighlightsHandler.viewType();">
                  <option value="competitionName" orderby="asc" priority="asc">Competition</option>
                  <option value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
                  <option value="totalMatched" orderby="desc">Matched</option>
                </select>
              </div>
            </div></h3>


          <ul class="slip-head">
            <li class="col-game" style={{ width: 'calc(64% - 42px)' }}></li>
            <li class="col-visit">1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>
          <SoccerMatches changeState={props.changeState} />
        </div>
      </div>
    </div>
  )
}
