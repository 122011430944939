import React, { useState } from 'react'
import Transparent from '../images/transparent.gif'
import App from '../images/btn-appdl-android.png'
import betfair from '../images/logo-betfair.png'


export default function Support(props) {
	const [Socialicon, setSocialicon] = useState(0);



	return (
		<React.Fragment>
			{/* <div className="support-wrap extend-support">
				<div className="extend-btn">
					<img src={Transparent} title="customer" className="support-customer" />
					<a href="#" target="_blank">Customer support1</a>
					<a href="#" target="_blank" className="split-line">support2</a>
				</div>
				<div className="extend-btn">
					<img src={Transparent} title="WhatsApp" className="support-whatsapp" />
					<a href="#" target="_blank">+5352532535345</a>
					<a href="#" target="_blank" className="split-line">+260763986993</a>
				</div>
				<div className="extend-btn">
					<img src={Transparent} title="Telegram" className="support-telegram" />
					<a href="#" target="_blank">+445234523444</a>
					<a href="#" target="_blank" className="split-line">+235345235435</a>
				</div>
				<div className="support-social">
					<div className="social-btn">
						<img src={Transparent} title="Skype" className="support-skype" />
						<a href="#" target="_blank">Skype</a>
					</div>
					<div className="social-btn">
						<img src={Transparent} title="Email" className="support-mail" />
						<a href="#" target="_blank">Email</a>
					</div>
					<div className="social-btn">
						<img src={Transparent} title="Instagram" className="support-ig" />
						<a href="#" target="_blank" className="ui-link">Instagram</a>
					</div>
				</div>
			</div>
		 */}
			<div id="supportWrap" class="support-wrap" style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}>
				<div class="support-service">
					<a id="support_whatsapp" onClick={() => { setSocialicon(0) }} className={`support-whatsapp  ${(Socialicon === 0) ? "openn" : "null"}`}>
						<img src={Transparent} title="WhatsApp" /></a>

				</div>
				<div class="support-info" style={{ borderTop: '0.26667vw solid rgba(0, 0, 0, 0.2)' }}>

<div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
	{Socialicon === 0 &&
		<a href='https://api.whatsapp.com/send/?phone=13605835688&text&app_absent=0' style={{ color: '#000' }}><span>+13605835688</span></a>
	}
	{Socialicon === 0 &&
		<a href='https://api.whatsapp.com/send/?phone=447441441362&text&app_absent=0' style={{ color: '#000' }}><span>+447441441362</span></a>
	}
</div>

<div id="supportDetail_telegram" className={`support-detail ${(Socialicon === 2) ? "open" : "null"}`}>
	{Socialicon === 2 &&
		<a class="ui-link"><span style={{ color: '#666', textDecoration: 'underline' }}></span></a>
	}
</div>


</div>
			</div>

			<ul class="policy-link" style={{ display: ' block' }}>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(4, true) }}>KYC</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(6, true) }}>About Us</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(1, true) }}>Privacy Policy</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(5, true) }}>Responsible Gaming</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(2, true) }}>Terms and Conditions</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(8, true) }}>Underage Gaming Policy</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(7, true) }}>Self-Exclusion Policy</a></li>
				<li><a class="ui-link" onClick={() => { props.handlepolicy(3, true) }}>Rules and Regulations</a></li>
			</ul>

			<div class="extra-wrap">
				<div id="powerWrap" class="power-wrap-b" style={{ display: 'block' }}>
					<span>Powered by</span><img src={betfair} />
				</div>

			</div>
		</React.Fragment>
	)
}











{/* <a id="support_email"  
		onClick ={()=>{setSocialicon(0)}} 
		className={`support-mail ui-link ${(Socialicon===0 )? "openn": "null"}`}
		><img src={Transparent} title="Email"/></a> */}

{/* <a id="support_skype"   
		onClick ={()=>{setSocialicon(3)}} 
		className={`support-skype  ${(Socialicon===3 )? "openn": "null"}`}
		><img src={Transparent} title="Skype"/></a>
		
		
		<a id="support_instagram"  
		onClick ={()=>{setSocialicon(4)}} 
		className={`support-ig  ${(Socialicon===4 )? "openn": 
		"null"}`}
		><img src={Transparent} title="Instagram"/></a> */}
{/* { Socialicon ===0 &&
		<div id="supportDetail_email" 
		className={`support-detail ${(Socialicon===0 )? "open": "null"}`}
		><a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>info@lc247.com</span></a></div>
	} */}


{/* 		
		<div id="supportDetail_skype" className={`support-detail ${(Socialicon===3 )? "open": "null"}`}>
		{ Socialicon ===3 &&
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>lc247official</span></a>
		}
			</div>
		
		
		<div id="supportDetail_instagram" className={`support-detail ${(Socialicon===4 )? "open": "null"}`}>
		{ Socialicon ===4 &&
			
			<a   class="ui-link"><span style={{color: '#666' , textDecoration:'underline'}}>officiallc247</span></a>
			}
			</div> */}
