import React from 'react'

export default function Kabaddi() {
    return (
        <React.Fragment>
            <div id="noMultiMarkets" className="no-data">
    <h3>There are currently no events.</h3>
    <p>Please wait for upcoming events.</p>
</div>
        </React.Fragment>
    )
}
