import React,{useState} from 'react'

export const Fairhorsefullmarket = (props) => {


    return (
        <React.Fragment>
            <div id="mainWrap" className="mian-wrap" eventtype={7} eventid={31143466} marketid="1.192685423">
    <div className="game-wrap">
        <h4 id="gameInfo" className="game-info">Horse Racing
            <ul id="infoIcon" className="info-icon">
                <li id="inPlayIcon" style={{}}>
                    <img className="info-inplay" src="/images/mobile/transparent.gif" />In-Play
                </li>
                <li style={{display: 'none'}}><img className="info-cashout" src="/images/mobile/transparent.gif" /> Cash Out
                </li>
                <li id="fancyBetIcon" style={{display: 'none'}}><span className="game-fancy">Fancy</span></li>
                <li id="bookMakerIcon" style={{display: 'none'}}><span className="game-bookmaker">BookMaker</span></li>
                <li id="feedingSiteIcon" style={{display: 'none'}}><span className="game-sportsbook">Sportsbook</span></li>
                <li><span id="lowLiquidityTag" className="game-low_liq" style={{display: 'none'}}>Low</span></li>
            </ul>
        </h4>
        <table id="gameTeam" className="game-team" style={{}}>
            <tbody>
                <tr>
                    <th>
                        <a id="multiMarketPin" className="pin-off" href="#" />
                        <h4 id="teamHome">Dubbo (AUS) 26th Dec - R1 2120m Pace M</h4>
                        <h4 id="teamAway" style={{display: 'none'}} />
                        <ul id="time" className="scores-time">
                            <li>26 Dec, 12:53</li>
                        </ul>
                    </th>
                    <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
                </tr>
            </tbody>
        </table>
        <table id="cricketScoreBoard" className="game-team" name="scoreBoard" style={{display: 'none'}}>
            <tbody>
                <tr>
                    <th>
                        <a id="multiMarketPin" className="pin-off" href="#" />
                        <h4 id="teamHome">HOME</h4>
                        <h4 id="teamAway">AWAY</h4>
                        <ul id="time" className="scores-time">
                            <li id="description" />
                            <li id="currentDay" />
                        </ul>
                    </th>
                    <td id="inningsBox1" className="team-scores" style={{display: 'none'}}>
                        <h4 id="runsHome" />
                        <h4 id="runsAway" />
                        <span id="inningsIndex">Innings 1</span>
                    </td>
                    <td id="inningsBoxTemplate" className="team-scores" style={{display: 'none'}}>
                        <h4 id="runsHome" />
                        <h4 id="runsAway" />
                        <span id="inningsIndex" />
                    </td>
                    <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
                </tr>
            </tbody>
        </table>
        <table id="tennisScoreBoard" className="game-team tennis" name="scoreBoard" style={{display: 'none'}}>
            <tbody>
                <tr>
                    <th>
                        <a id="multiMarketPin" className="pin-off" href="#" />
                        <h4 id="teamHome" />
                        <h4 id="teamAway" />
                        <ul id="time" className="scores-time in-play">
                            <li id="currentSet" />
                            <li id="fullTimeElapsed" />
                            <li id="court" />
                        </ul>
                    </th>
                    <td id="setBox1" className="team-scores">
                        <h4 id="setHome">-</h4>
                        <h4 id="setAway">-</h4>
                        <span>1</span>
                    </td>
                    <td id="setBox2" className="team-scores">
                        <h4 id="setHome">-</h4>
                        <h4 id="setAway">-</h4>
                        <span>2</span>
                    </td>
                    <td id="setBox3" className="team-scores team-last_box">
                        <h4 id="setHome">-</h4>
                        <h4 id="setAway">-</h4>
                        <span>3</span>
                    </td>
                    <td id="points" className="team-scores team-last_box">
                        <h4 id="pointsHome" className="in-play">0</h4>
                        <h4 id="pointsAway" className="in-play">0</h4>
                        <span>Points</span>
                    </td>
                    <td id="aces" className="team-scores team-other">
                        <h4 id="acesHome">0</h4>
                        <h4 id="acesAway">0</h4>
                        <span>Aces</span>
                    </td>
                    <td id="faults" className="team-scores team-other">
                        <h4 id="faultsHome">0</h4>
                        <h4 id="faultsAway">0</h4>
                        <span>Faults</span>
                    </td>
                    <td id="breaks" className="team-scores team-other team-last_box">
                        <h4 id="breaksHome">0</h4>
                        <h4 id="breaksAway">0</h4>
                        <span>Breaks</span>
                    </td>
                    <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
                    <td id="setBoxTemplate" className="team-scores" style={{display: 'none'}}>
                        <h4 id="setHome">-</h4>
                        <h4 id="setAway">-</h4>
                        <span id="setIndex" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="soccerScoreBoard" className="game-team soccer" name="scoreBoard" style={{display: 'none'}}>
            <tbody>
                <tr>
                    <th><a id="multiMarketPin" className="pin-off" href="#" /></th>
                    <td className="team-scores team-last_box">
                        <h4 id="scoresHome" className="in-play" />
                        <h4 id="scoresAway" className="in-play" />
                        <span id="timeElapsed" />
                    </td>
                    <td>
                        <h4 id="teamHome" />
                        <h4 id="teamAway" />
                    </td>
                    <td className="team-refresh"><a id="refresh" className="refresh" href="#" /></td>
                </tr>
                <tr>
                    <td className="soccer-map" colSpan={4}>
                        <div className="bg-progress_bar">
                            <div id="progressPercent" className="progress_bar" style={{width: '0%'}} />
                            <img id="soccerEventTemplate" className style={{left: '0%', display: 'none'}} src="/images/mobile/transparent.gif" />
                            <span id="sumTemplate" className="sum-status" style={{left: '0%', display: 'none'}} />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div id="matchTrackerWrap" className="match-tracker-wrap" style={{display: 'none'}}>
            <div id="matchTrackerWidget" className="sr-widget" />
            <ul className="match-btn">
                <li><a id="liveMultiMarketPin" style={{cursor: 'pointer'}} name="gameHeadPin" className="btn-pin" title="Add to Multi Markets">Pin</a></li>
                <li><a  onClick={()=>{props.handleRefreshhh();}} style={{cursor: 'pointer'}} className="btn-refresh" onclick="$j('#loading').show();setTimeout(function () {$j('#loading').hide()}, 1000);">Refresh</a></li>
            </ul>
        </div>
    </div>
    <div id="naviMarket" className="market-type ps ps--theme_default ps--active-x" data-ps-id="84c40365-469a-ba2a-de7e-d40eeeb1b586">
        <ul id="naviMarketList">
            <li id="naviMarketTemaplate" style={{display: 'none'}}><a href="#" /></li>
            <li id="naviMarket_1_192685423" style={{display: 'list-item'}} className="select"><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685423&quot;,true)">12:53 Win</a></li>
            <li id="naviMarket_1_192685424" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685424&quot;,true)">12:53 Places</a></li>
            <li id="naviMarket_1_192685425" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685425&quot;,true)">13:24 Win</a></li>
            <li id="naviMarket_1_192685426" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685426&quot;,true)">13:24 Places</a></li>
            <li id="naviMarket_1_192685427" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685427&quot;,true)">13:52 Win</a></li>
            <li id="naviMarket_1_192685428" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685428&quot;,true)">13:52 Places</a></li>
            <li id="naviMarket_1_192685429" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685429&quot;,true)">14:26 Win</a></li>
            <li id="naviMarket_1_192685430" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685430&quot;,true)">14:26 Places</a></li>
            <li id="naviMarket_1_192685431" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685431&quot;,true)">15:01 Win</a></li>
            <li id="naviMarket_1_192685432" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685432&quot;,true)">15:01 Places</a></li>
            <li id="naviMarket_1_192685433" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685433&quot;,true)">15:31 Win</a></li>
            <li id="naviMarket_1_192685434" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685434&quot;,true)">15:31 Places</a></li>
            <li id="naviMarket_1_192685435" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685435&quot;,true)">16:01 Win</a></li>
            <li id="naviMarket_1_192685436" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685436&quot;,true)">16:01 Places</a></li>
            <li id="naviMarket_1_192685437" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685437&quot;,true)">16:31 Win</a></li>
            <li id="naviMarket_1_192685438" style={{display: 'list-item'}}><a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685438&quot;,true)">16:31 Places</a></li>
        </ul>
        <div className="ps__scrollbar-x-rail" style={{width: '369px', left: '0px', bottom: '0px'}}>
            <div className="ps__scrollbar-x" tabIndex={0} style={{left: '0px', width: '91px'}} /></div>
        <div className="ps__scrollbar-y-rail" style={{top: '0px', right: '0px'}}>
            <div className="ps__scrollbar-y" tabIndex={0} style={{top: '0px', height: '0px'}} /></div>
    </div>
    <div id="marketBetsWrap" className="bets-wrap">
        <div id="minMaxBox" className="limit_info-popup" style={{display: 'none'}}>
            <a className="close" onclick="javascript:$j('#minMaxBox').fadeOut();">Close</a>
            <dl>
                <dt id="minMaxDt" style={{display: 'none'}}>Min / Max</dt>
                <dt id="maxDt" style={{display: 'none'}}>Max</dt>
                <dd id="minMaxInfo">0 / 0</dd>
            </dl>
        </div>
        <a id="minMaxButton" onclick="javascript:$j('#minMaxBox').fadeIn().css('display', 'flex');" style={{display: 'none'}} />
        <dl id="betsHead" className="bets-selections-head">
            <dt>
        <a className="a-depth" href="#" id="marketDepthBtn">Markets Depth</a>
        <p>
          <span>Matched</span>
          <strong id="totalMatched">USD  15,716</strong>
        </p>
      </dt>
            <dd className="mode-land" />
            <dd className="mode-land" />
            <dd>Back
            </dd>
            <dd>Lay
            </dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
        </dl>
        <dl id="selection_2664438" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">1</li>
            <li id="stallDraw">(1)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1410741.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">1. La Bomba</li>
            <li id="jockyName">E M Turnbull</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>1. La Bomba</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3" href="#" fullmarketodds="1.4">1.4<span> 71</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#" fullmarketodds="2.2">2.2<span> 46</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds={10}>10<span> 269</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_27744230" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">2</li>
            <li id="stallDraw">(2)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1408723.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">2. Caribbean Pat</li>
            <li id="jockyName">M J Hutchings</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>2. Caribbean Pat</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3" href="#" fullmarketodds={4}>4<span> 66</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#" fullmarketodds={340}>340<span> 81</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds={1000}>1000<span> 26</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_37963796" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">3</li>
            <li id="stallDraw">(3)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1598130.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">3. Feelgood Express</li>
            <li id="jockyName">James Dean</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>3. Feelgood Express</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.27">1.27<span> 77</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#" fullmarketodds="1.34">1.34<span> 84</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds="2.62">2.62<span> 52</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_19215423" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">4</li>
            <li id="stallDraw">(4)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1321489.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">4. The Girls Have It</li>
            <li id="jockyName">Kellie Munro</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>4. The Girls Have It</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.16">1.16<span> 100</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2 spark" href="#" fullmarketodds="1.2">1.2<span> 90</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds="1.35">1.35<span> 22</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_13543505" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">5</li>
            <li id="stallDraw">(5)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1091853.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">5. Kempley Princess</li>
            <li id="jockyName">A T Ward</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>5. Kempley Princess</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.3">1.3<span> 83</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2 spark" href="#" fullmarketodds="1.33">1.33<span> 24</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1 spark" href="#" fullmarketodds="1.4">1.4<span> 71</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_24465398" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">6</li>
            <li id="stallDraw">(6)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1079657.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">6. Surfing Sensation</li>
            <li id="jockyName">D I Harris</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>6. Surfing Sensation</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.36">1.36<span> 820</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2 spark" href="#" fullmarketodds="1.4">1.4<span> 71</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1 spark" href="#" fullmarketodds="1.41">1.41<span> 299</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_27078533" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">7</li>
            <li id="stallDraw">(7)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1401692.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">7. Gotta Kick</li>
            <li id="jockyName">William Cassell</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>7. Gotta Kick</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.41">1.41<span> 83</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#" fullmarketodds="11.5">11.5<span> 21</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds={50}>50<span> 72</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_34140494" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">8</li>
            <li id="stallDraw">(8)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1010862.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">8. Bradness Shot</li>
            <li id="jockyName">A M Lincoln</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>8. Bradness Shot</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.34">1.34<span> 23</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2 spark" href="#" fullmarketodds="1.37">1.37<span> 279</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1 spark" href="#" fullmarketodds="1.41">1.41<span> 73</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_11072727" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">9</li>
            <li id="stallDraw">(9)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/751519.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">9. Digger Shed</li>
            <li id="jockyName">N A Turnbull</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>9. Digger Shed</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3 spark" href="#" fullmarketodds="1.26">1.26<span> 24</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2 spark" href="#" fullmarketodds="1.27">1.27<span> 76</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1 spark" href="#" fullmarketodds="1.35">1.35<span> 84</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <dl id="selection_12823181" className="bets-selections" style={{display: 'flex'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha">10</li>
            <li id="stallDraw">(10)</li>
          </ul>
          <div id="uniform" className="uniform"><img src="https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/c20211226-Dubbo (AUS)-H/1081598.png" /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName">10. Whiskiesonthebeach</li>
            <li id="jockyName">G J Rue</li>
          </ul>
        </div>
        <h4 id="runnerName" style={{display: 'none'}}>10. Whiskiesonthebeach</h4>
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend" style={{display: 'none'}}>
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend" style={{display: 'none'}}>
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend" style={{display: 'none'}}>
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3" href="#" fullmarketodds="1.21">1.21<span> 32</span></a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#" fullmarketodds="1.22">1.22<span> 80</span></a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#" fullmarketodds="1.28">1.28<span> 88</span></a>
            </dd>
            <dd id="lay_1">
            </dd>
            <dd id="lay_2" className="mode-land">
            </dd>
            <dd id="lay_3" className="mode-land">
            </dd>
        </dl>
        <div id="noRace" className="no-data" style={{display: 'none'}}>
            <h3>This race has ended</h3>
            <p />
        </div>
        <dl id="selectionTemplate" className="bets-selections" style={{display: 'none'}}>
            <dt>
        <div id="horseInfo" className="horse-info" style={{display: 'none'}}>
          <ul id="horseBox" className="horse-box">
            <li id="clothNumberAlpha" />
            <li id="stallDraw" />
          </ul>
          <div id="uniform" className="uniform"><img src /></div>
          <ul id="horseName" className="horse-name">
            <li id="runnerName" />
            <li id="jockyName" />
          </ul>
        </div>
        <h4 id="runnerName" />
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroProfit" className="win" style={{display: 'none'}} />
          <li id="zeroLiability" className="win" style={{display: 'none'}} />
          <li id="zeroProfitWithBet" className="win" style={{display: 'none'}} />
          <li id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend">
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend">
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend">
                <p>Non Runner
                </p>
            </dd>
            <dd id="back_3" className="mode-land">
                <a className="back-3" href="#">
          <span />
        </a>
            </dd>
            <dd id="back_2" className="mode-land">
                <a className="back-2" href="#">
          <span />
        </a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#">
          <span />
        </a>
            </dd>
            <dd id="lay_1">
                <a className="lay-1" href="#">
          <span />
        </a>
            </dd>
            <dd id="lay_2" className="mode-land">
                <a className="lay-2" href="#">
          <span />
        </a>
            </dd>
            <dd id="lay_3" className="mode-land">
                <a className="lay-3" href="#">
          <span />
        </a>
            </dd>
        </dl>
        <div id="betBoardTemplate" className="bet_slip-wrap back" style={{display: 'none'}}>
            <div id="messageWrap" className="message-wrap" style={{display: 'none'}}>
                <div className="message">
                    <h4 id="header">The Minimun stake is $25.00</h4>
                    <p id="info">
                        Your stake has been updated accordingly.
                    </p>
                </div>
                <a id="close" className="close" href="#">Close</a>
            </div>
            <ul className="btn-list">
                <li>
                    <p className="dynamic-min-bet">&nbsp;</p>
                    <div id="inputOdds" className="input-num">
                        <a id="oddsDown" className="icon-minus" href="#" />
                        <span id="odds" className="typed" />
                        <a id="oddsUp" className="icon-plus" href="#" />
                    </div>
                </li>
                <li>
                    <p className="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet" /></p>
                    <div id="inputStake" className="input-num">
                        <a id="stakeDown" className="icon-minus" href="#" />
                        <span id="stake" className="typed" />
                        <a id="stakeUp" className="icon-plus" href="#" />
                    </div>
                </li>
            </ul>
            <ul id="stakePopupList" className="coin-list">
                <li><a id="selectStake_1" href="#" stake={10}> 10</a></li>
                <li><a id="selectStake_2" href="#" stake={20}> 20</a></li>
                <li><a id="selectStake_3" href="#" stake={30}> 30</a></li>
                <li><a id="selectStake_4" href="#" stake={38}> 38</a></li>
                <li><a id="selectStake_5" href="#" stake={200}> 200</a></li>
                <li><a id="selectStake_6" href="#" stake={500}> 500</a></li>
            </ul>
            <div id="keyboard" className="keyboard-wrap">
                <ul id="numPad" className="btn-tel">
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li><a href="#">0</a></li>
                    <li><a href="#">00</a></li>
                    <li><a href="#">.</a></li>
                </ul>
                <a id="delete" className="btn-delete" href="#" />
            </div>
            <ul className="btn-list">
                <li>
                    <a id="cancel" href="#" className="btn">Cancel
          </a>
                </li>
                <li>
                    <a id="placeBet" href="#" className="btn-send disable">Place Bet
          </a>
                </li>
            </ul>
            <div id="acceptAnyOddsBox" className="bet-setting" style={{display: 'none'}}>
                <a id="acceptAnyOdds" href="#" className="bet-check">Accept Any Odds</a>
                <span id="sportsBookMaxStake" style={{display: 'none'}}><a id="getSportsBookMaxStakeBtn" className="max-bet" href="#">Max</a></span>
            </div>
        </div>
        {/* open bet_slip-wrap */}
        <div id="singleBetBoardTemplate" className="bet_slip-wrap back" style={{display: 'none'}}>
            <div id="messageWrap" className="message-wrap" style={{display: 'none'}}>
                <div className="message">
                    <h4 id="header">The Minimun stake is $25.00</h4>
                    <p id="info">
                        Your stake has been updated accordingly.
                    </p>
                </div>
                <a id="close" className="close" href="#">Close</a>
            </div>
            <ul className="btn-list btn-list-single">
                <li>
                    <div className="input-num input-odds disable">
                        <span className="max-type" id="odds" />
                    </div>
                </li>
                <li>
                    <div id="inputStake" className="input-num">
                        <a id="stakeDown" className="icon-minus" href="#" />
                        <span id="stake" className="typed" />
                        <a id="stakeUp" className="icon-plus" href="#" />
                    </div>
                </li>
            </ul>
            <ul id="stakePopupList" className="coin-list">
                <li><a id="selectStake_1" href="#" stake={10}> 10</a></li>
                <li><a id="selectStake_2" href="#" stake={20}> 20</a></li>
                <li><a id="selectStake_3" href="#" stake={30}> 30</a></li>
                <li><a id="selectStake_4" href="#" stake={38}> 38</a></li>
                <li><a id="selectStake_5" href="#" stake={200}> 200</a></li>
                <li><a id="selectStake_6" href="#" stake={500}> 500</a></li>
            </ul>
            <div id="keyboard" className="keyboard-wrap">
                <ul id="numPad" className="btn-tel">
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li><a href="#">0</a></li>
                    <li><a href="#">00</a></li>
                    <li><a href="#">.</a></li>
                </ul>
                <a id="delete" className="btn-delete" href="#" />
            </div>
            <ul className="btn-list">
                <li>
                    <a id="cancel" href="#" className="btn">Cancel
          </a>
                </li>
                <li>
                    <a id="placeBet" href="#" className="btn-send disable">Place Bet
          </a>
                    <p id="totalStakeP" style={{display: 'none'}}>Total Stake: <strong id="totalStake" /></p>
                </li>
            </ul>
        </div>
        {/* open bet_slip-wrap end */} {/* khadda */}
        <div id="khaddaBetBoardTemplate" className="bets-wrap fancy-bet khadda-bet" style={{display: 'none'}}>
            <div id="messageWrap" className="message-wrap" style={{display: 'none'}}>
                <div className="message">
                    <h4 id="header">The Minimun stake is $25.00</h4>
                    <p id="info">
                        Your stake has been updated accordingly.
                    </p>
                </div>
                <a id="close" className="close" href="#">Close</a>
            </div>
            {/* open bet_slip-wrap */}
            <div className="bet_slip-wrap back">
                <ul className="btn-list">
                    <li className="runs-wrap">
                        <strong>Runs</strong>
                        <div id="inputRuns" className="input-num input-runs">
                            <span id="runs" className="typed" />{/* typeing typed */}
                            <span id="extendedRuns" className="max-type" />
                        </div>
                    </li>
                    <li>
                        <div id="inputStake" className="input-num">
                            <a id="stakeDown" className="icon-minus" href="#" />
                            <span id="stake" className="typed" />
                            <a id="stakeUp" className="icon-plus" href="#" />
                        </div>
                    </li>
                </ul>
                <ul id="stakePopupList" className="coin-list">
                    <li><a id="selectStake_1" href="#" stake={10}> 10</a></li>
                    <li><a id="selectStake_2" href="#" stake={20}> 20</a></li>
                    <li><a id="selectStake_3" href="#" stake={30}> 30</a></li>
                    <li><a id="selectStake_4" href="#" stake={38}> 38</a></li>
                    <li><a id="selectStake_5" href="#" stake={200}> 200</a></li>
                    <li><a id="selectStake_6" href="#" stake={500}> 500</a></li>
                </ul>
                <div id="keyboard" className="keyboard-wrap">
                    <ul id="numPad" className="btn-tel">
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><a href="#">6</a></li>
                        <li><a href="#">7</a></li>
                        <li><a href="#">8</a></li>
                        <li><a href="#">9</a></li>
                        <li><a href="#">0</a></li>
                        <li><a href="#">00</a></li>
                        <li><a href="#">.</a></li>
                    </ul>
                    <a id="delete" className="btn-delete" href="#" />
                </div>
                <ul className="btn-list">
                    <li>
                        <a id="cancel" href="#" className="btn">Cancel
            </a>
                    </li>
                    <li>
                        <a id="placeBet" href="#" className="btn-send disable">Place Bet
            </a>
                    </li>
                </ul>
            </div>
            {/* open bet_slip-wrap end */}
        </div>
        {/* khadda end */}
    </div>
    <div id="bookMakerWrap" style={{display: 'none'}}>
        <div id="bookMakerTempTable" style={{display: 'none'}}>
            <div id="bookMakerMarketTemplate" className="bets-wrap bets-bookmaker">
                <h4>
                    <a id="multiMarketPin" className="pin-off" href="#">Pin to Muilti Markets</a>
                    <span>Bookmaker Market</span>
                    <p>| Zero Commission</p>
                    <a href="#" id="openBookMakerInfo" className="btn-fancy_info" />
                    <div id="bookMakerMinMax" className="fancy_info-popup"> {/* add class=show to show this wrap */}
                        <dl>
                            <dt>Min / Max</dt>
                            <dd id="minMax" />
                        </dl>
                        <dl>
                            <dt id="rebateName">Rebate</dt>
                            <dd id="rebate" />
                        </dl>
                        <a href id="closeBookMakerInfo" className="close">Close</a>
                    </div>
                </h4>
                <dl className="bets-selections-head">
                    <dt>
          </dt>
                    <dd className="mode-land" />
                    <dd className="mode-land" />
                    <dd>Back</dd>
                    <dd>Lay</dd>
                    <dd className="mode-land" />
                    <dd className="mode-land" />
                </dl>
            </div>
            <dl id="bookMakerSelectionTemplate" className="bets-selections">
                <dt>
          <h4 id="runnerName" />
          <ul>
            <li id="before" className="win" style={{display: 'none'}} />
            <li id="after" className="to-lose" style={{display: 'none'}} />
          </ul>
        </dt>
                <dd id="suspend" className="suspend" style={{display: 'none'}}>
                    <p id="info">Ball Running</p>
                </dd>
                <dd id="delayBetting" className="suspend-fancy" style={{display: 'none'}}>
                    <p id="info" />
                </dd>
                <dd className="backlay_box">
                    <dl className="back-gradient">
                        <dd id="back_3" className="mode-land">
                            <p />
                        </dd>
                        <dd id="back_2" className="mode-land">
                            <p />
                        </dd>
                        <dd id="back_1" className><a href /></dd>
                    </dl>
                    <dl className="lay-gradient">
                        <dd id="lay_1" className><a href /></dd>
                        <dd id="lay_2" className="mode-land">
                            <p />
                        </dd>
                        <dd id="lay_3" className="mode-land">
                            <p />
                        </dd>
                    </dl>
                </dd>
            </dl>
        </div>
    </div>
    <div id="fancyBetTag" style={{display: 'none'}} />
    <div id="fancyBetTableTemplate" style={{display: 'none'}}>
        <div id="fancyBetHead" className="fancy_bet-head" style={{display: 'none'}}>
            {/*a id="multiMarketPin" class="pin-off" href="#">Pin to Muilti Markets</a*/} <h4 className="in-play">
            <a id="multiMarketPin" className="pin-off" href="#">Pin to Muilti Markets</a>
            <span>Fancy Bet</span>
            <a className="btn-head_rules" onclick="javascript: $j('#fancyBetRulesWrap').fadeIn().css('display', 'flex');">Rules</a>
            </h4>
            <a id="showSportsBookBtn" className="other-tab" onclick="javascript: MobileSportsBookEventHandler.activeSportsBook();" style={{display: 'none'}}>
        <span className="tag-new">New</span>
        Premium Cricket
      </a>
        </div>
        <div id="fancyBetTabWrap" className="fancy_bet_tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li className="select"><a fancybettabtype={1}>All</a></li>
                <li><a fancybettabtype={2}>Fancy</a></li>
                <li><a fancybettabtype={6}>Ball by Ball</a></li>
                <li><a fancybettabtype={3}>Khadda</a></li>
                <li><a fancybettabtype={4}>Lottery</a></li>
                <li><a fancybettabtype={5}>Odd/Even</a></li>
            </ul>
        </div>
        <div id="noGameWrap" className="bets-wrap" style={{display: 'none'}}>
            <p className="no-game">Fancybet market will be available after Event at In-Play</p>
        </div>
        <div id="fancyBetField" className="bets-wrap fancy-bet" style={{display: 'none'}}>
            <dl className="bets-selections-head">
                <dt />
                <dd className="mode-land" />
                <dd className="mode-land" />
                <dd>No</dd>
                <dd>Yes</dd>
                <dd className="mode-land" />
                <dd className="mode-land" />
            </dl>
        </div>
        <div id="fancyBetTag" />
        <div id="fancyBetWrap" className="bets-wrap fancy-bet" style={{display: 'none'}}>
            <h5>
                <span id="marketName" />
                <a href="#" id="open-fancy_info" className="btn-fancy_info">fancybet info</a>
                <div id="fancy_popup2" className="fancy_info-popup">
                    <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax" />
                    </dl>
                    <dl>
                        <dt id="rebateName">Rebate</dt>
                        <dd id="rebate" />
                    </dl>
                    <a href="#" id="close-fancy_info" className="close">Close</a>
                </div>
            </h5>
            <dl className="bets-selections">
                <dt className="line_market-selection">
          <dl className="tips">
            <dt>
              <span id="before" className="win" style={{display: 'none'}} />
              <span id="after" className="to-lose" style={{display: 'none'}} />
            </dt>
                <dd id="remarkFirstRow" style={{display: 'none'}} />
                <dd id="remarkSecondRow" style={{display: 'none'}} />
            </dl>
            <a id="fancyBetBookBtn" href="#" className="btn-book" style={{display: 'none'}}>Book</a>
            </dt>
            {/* Suspend for Fancy Bet */}
            <dd id="suspend" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info">Ball Running</p>
            </dd>
            <dd id="delayBetting" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info" />
            </dd>
            <dd id="lay_1">
                <a className="lay-1" href="#">
            <span />
          </a>
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#">
            <span />
          </a>
            </dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
            </dl>
        </div>
        {/* Lottery */}
        <div id="fancyBetLotteryWrap" className="bets-wrap fancy-bet lottery-bet" style={{display: 'none'}}>
            <h5>
                <span id="marketName" />
                <a href="#" id="open-fancy_info" className="btn-fancy_info">fancybet info</a>
                <div id="fancy_popup2" className="fancy_info-popup">
                    <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax" />
                    </dl>
                    <dl>
                        <dt id="rebateName">Rebate</dt>
                        <dd id="rebate" />
                    </dl>
                    <a href="#" id="close-fancy_info" className="close">Close</a>
                </div>
            </h5>
            <dl className="bets-selections">
                <dt className="lottery-tips">
          <h4>Runs</h4>
          <a id="fancyBetBookBtn" href="#" className="btn-book" style={{display: 'none'}}>Book</a>
          <dl className="tips">
            <dt>
              <span id="before" className="win" style={{display: 'none'}} />
              <span id="after" className="to-lose" style={{display: 'none'}} />
            </dt>
            </dl>
            </dt>
            <dd id="suspend" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info">Ball Running</p>
            </dd>
            <dd id="delayBetting" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info" />
            </dd>
            <dd id="lotteryBall">
                <a className="lottery-ball" id="lotteryNo_0">0</a> {/* spark select */}
                <a className="lottery-ball" id="lotteryNo_1">1</a>
                <a className="lottery-ball" id="lotteryNo_2">2</a>
                <a className="lottery-ball" id="lotteryNo_3">3</a>
                <a className="lottery-ball" id="lotteryNo_4">4</a>
                <a className="lottery-ball" id="lotteryNo_5">5</a>
                <a className="lottery-ball" id="lotteryNo_6">6</a>
                <a className="lottery-ball" id="lotteryNo_7">7</a>
                <a className="lottery-ball" id="lotteryNo_8">8</a>
                <a className="lottery-ball" id="lotteryNo_9">9</a>
            </dd>
            </dl>
        </div>
        {/* Lottery end */} {/* khadda */}
        <div id="fancyBetKhaddaWrap" className="bets-wrap fancy-bet khadda-bet" style={{display: 'none'}}>
            <h5>
                <span id="marketName" />
                <a href="#" id="open-fancy_info" className="btn-fancy_info">fancybet info</a>
                <div id="fancy_popup2" className="fancy_info-popup">
                    <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax" />
                    </dl>
                    <dl>
                        <dt id="rebateName">Rebate</dt>
                        <dd id="rebate" />
                    </dl>
                    <a href="#" id="close-fancy_info" className="close">Close</a>
                </div>
            </h5>
            <dl className="bets-selections">
                <dt>
          <dl className="tips">
            <dt>
              <span id="before" className="win" style={{display: 'none'}} />
              <span id="after" className="to-lose" style={{display: 'none'}} />
            </dt>
            </dl>
            <a id="fancyBetBookBtn" href="#" className="btn-book" style={{display: 'none'}}>Book</a>
            </dt>
            <dd id="suspend" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info">Ball Running</p>
            </dd>
            <dd id="delayBetting" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info" />
            </dd>
            <dd id="back_1">
                <a className="back-1" href="#"><span>odds</span></a>
            </dd>
            <dd className="mode-land" />
            </dl>
        </div>
        {/* khadda end */} {/* multi selection for fancybet */}
        <div id="fancyBetThreeSelectionsWrap" className="bets-wrap fancy-bet" style={{display: 'none'}}>
            <h5>
                <span id="marketName" />
                <a href="#" id="open-fancy_info" className="btn-fancy_info">fancybet info</a>
                <div id="fancy_popup2" className="fancy_info-popup">
                    <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax" />
                    </dl>
                    <dl>
                        <dt id="rebateName">Rebate</dt>
                        <dd id="rebate" />
                    </dl>
                    <a href="#" id="close-fancy_info" className="close">Close</a>
                </div>
            </h5>
            <dl className="bets-selections">
                <dt>
          <dl className="tips">
            <dt>
              <span id="before" className="win" style={{display: 'none'}} />
              <span id="after" className="to-lose" style={{display: 'none'}} />
            </dt>
                <dd id="remarkFirstRow" style={{display: 'none'}} />
                <dd id="remarkSecondRow" style={{display: 'none'}} />
            </dl>
            <a id="fancyBetBookBtn" href="#" className="btn-book" style={{display: 'none'}}>Book</a>
            </dt>
            <dd id="suspend" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info">Ball Running</p>
            </dd>
            <dd id="delayBetting" className="suspend-fancy" style={{display: 'none'}}>
                <p id="info" />
            </dd>
            <dd className="select-more">
                <a id="lay_1" className="lay-1" href="#"> {/* spark select */}
            <span />
          </a>
                <a id="back_1" className="back-1" href="#">
            <span />
          </a>
                <a id="lay_2" className="lay-1" href="#"> {/* spark select */}
            <span />
          </a>
                <a id="back_2" className="back-1" href="#">
            <span />
          </a>
                <a id="lay_3" className="lay-1" href="#"> {/* spark select */}
            <span />
          </a>
                <a id="back_3" className="back-1" href="#">
            <span />
          </a>
            </dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
            </dl>
        </div>
        {/* multi selection for fancybet end */}
    </div>
    <div id="sportsBookWrap" style={{display: 'none'}}>
        <div id="sportsBookHead" className="sportsbook_bet-head">
            <h4 id="betHeader">
                <span id="sportsBookName"><pre id="inPlayTag" style={{display: 'none'}}>in-play</pre>Premium Cricket</span>
                <a id="sportsBookRules" className="btn-head_rules">Rules</a>
            </h4>
            <a id="showFancyBetBtn" className="other-tab" style={{}}>Fancy Bet</a>
            <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{display: 'none'}}>Min/Max</a>
            <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{display: 'none'}}>Min</a>
            <div id="minMaxWrap_1" className="fancy_info-popup" style={{display: 'none'}}>
                <dl>
                    <dt>Min/Max</dt>
                    <dd id="minMaxInfo_1">0.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName_1">Rebate</dt>
                    <dd id="rebateRatio_1">0.00%</dd>
                </dl>
                <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
            </div>
            <div id="minMaxWrap_2" className="fancy_info-popup" style={{display: 'none'}}>
                <dl>
                    <dt>Min</dt>
                    <dd id="minMaxInfo_2">0.00</dd>
                </dl>
                <dl>
                    <dt id="rebateName_2">Rebate</dt>
                    <dd id="rebateRatio_2">0.00%</dd>
                </dl>
                <a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>
            </div>
        </div>
        <div id="sportsBookTab_2_4" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Popular">Popular</a></li>
                <li><a id="tab_Match">Match</a></li>
                <li><a id="tab_Innings">Innings</a></li>
                <li><a id="tab_Over">Over</a></li>
                <li><a id="tab_Batsman">Batsman</a></li>
                <li><a id="tab_Partnership">Partnership</a></li>
                <li><a id="tab_Group">Group</a></li>
                <li><a id="tab_Range">Range</a></li>
                <li><a id="tab_HeadtoHead">Head-to-Head</a></li>
                <li><a id="tab_Player">Player</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_2" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Main">Main</a></li>
                <li><a id="tab_Total">Total</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Popular">Popular</a></li>
                <li><a id="tab_Half">Half</a></li>
                <li><a id="tab_Goals">Goals</a></li>
                <li><a id="tab_FastMarkets">Fast Markets</a></li>
                <li><a id="tab_Specials">Specials</a></li>
                <li><a id="tab_Corners">Corners</a></li>
                <li><a id="tab_Cards">Cards</a></li>
                <li><a id="tab_Players">Players</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_138" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Main">Main</a></li>
                <li><a id="tab_Half">Half</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab" />
        </div>
        <div id="sportsBookNoBet" className="bets-wrap" style={{display: 'none'}}>
            <p className="no-game">Sports Book has no market</p>
        </div>
        <div id="sportsBookMarketTemplate" className="bets-wrap sports-book" style={{display: 'none'}}>
            <h4>
                <a id="multiMarketPin" className="pin-off" title="Add to Multi Markets" />
                <span id="marketName" className="to-collapse">Total sixes</span>
                <div className="fancy_info-popup"> {/* add class=show to show this wrap */}
                    <dl>
                        <dt>Min / Max</dt>
                        <dd>100.00 / 10000.00</dd>
                    </dl>
                    <a className="close">Close</a>
                </div>
            </h4>
            <dl id="sportsBookSelectionTemplate" className="bets-selections" style={{display: 'none'}}>
                <dt className>
          <h4 id="selectionName" />
          <ul>
            <li id="before" className="win" style={{display: 'none'}} />
            <li id="after" className="to-lose" style={{display: 'none'}} />
          </ul>
        </dt>
                <dd id="suspend" className="suspend" style={{display: 'none'}}>
                    <p>Suspend</p>
                </dd>
                <dd>
                    <a id="back_1" className="back-1" href="#"><span /></a>
                </dd>
                <dd className="mode-land" />
            </dl>
        </div>
    </div>
    <div id="feedingSiteHead" className="sportsbook_bet-head" style={{display: 'none'}}>
        <h4><span>SportsBook</span></h4>
        <a id="minMaxBtn" className="btn-fancy_info" onclick="javascript: $j('#feedingSiteMinMax').fadeIn().css('display', 'flex');" style={{display: 'none'}}>Min/Max/Rebate</a>
        <div id="feedingSiteMinMax" className="fancy_info-popup">
            <dl>
                <dt>Min / Max</dt>
                <dd id="minMaxInfo">100.00 / 10000.00</dd>
            </dl>
            <dl id="rebateName">
                <dt>Rebate</dt>
                <dd id="rebateRatio">3.00%</dd>
            </dl>
            <a className="close" onclick="javascript: $j('#feedingSiteMinMax').fadeOut();">Close</a>
        </div>
    </div>
    <div id="feedingSiteNoBet" className="bets-wrap" style={{display: 'none'}}>
        <p className="no-game">Sports Book 1X2 market will be available after Event In-Play</p>
    </div>
    <div id="feedingSiteMarketTemplate" className="bets-wrap multi sportsbook" style={{display: 'none'}}>
        <dl id="marketHead" className="bets-selections-head">
            <dt />
            <dd>Back</dd>
            <dd className="mode-land" />
        </dl>
        <dl id="feedingSiteSelectionTemplate" className="bets-selections" style={{display: 'none'}}>
            <dt className="line_market-selection">
        <h4 id="selectionName" />
        <ul>
          <li id="before" className="win" style={{display: 'none'}} />
          <li id="after" className="to-lose" style={{display: 'none'}} />
        </ul>
      </dt>
            <dd id="suspend" className="suspend">
                <p>Suspend</p>
            </dd>
            <dd>
                <a id="back_1" className="back-1">
          <span />
        </a>
            </dd>
            <dd className="mode-land" />
        </dl>
    </div>
    <div id="lineMarketHead" className="line_market-head" style={{display: 'none'}}>
        <h4><span>Line Markets</span></h4>
        <a id="lineMarketRulesBtn" className="btn-rules" href="#">Rules</a>
    </div>
    <div id="lineMarketList" className="bets-wrap multi" style={{display: 'none'}}>
        <dl className="bets-selections-head">
            <dt />
            <dd className="mode-land" />
            <dd className="mode-land" />
            <dd>Back/Yes</dd>
            <dd>Lay/No</dd>
            <dd className="mode-land" />
            <dd className="mode-land" />
        </dl>
        <dl id="lineMarketTemplate" className="bets-selections" style={{display: 'none'}}>
            <dt id="marketHeader" className="line_market-selection">
        <h4 id="marketName" />
        <ul id="winLoss">
          <li id="withoutBet" className="win" style={{display: 'none'}} />
          <li id="lossWithoutBet" className="win" style={{display: 'none'}} />
          <li id="withBet" className="win" style={{display: 'none'}} />
          <li id="lossWithBet" className="win" style={{display: 'none'}} />
        </ul>
        <a href="#" className="btn-book" style={{display: 'none'}} />
      </dt>
            <dd id="suspend" className="suspend">
                <p>Suspend
                </p>
            </dd>
            <dd id="closed" className="suspend">
                <p>Closed
                </p>
            </dd>
            <dd id="nonRunner" className="suspend">
                <p>Non Runner
                </p>
            </dd>
            <dd className="mode-land">
                <a id="back_3" className="back-3" href="#">
          <span />
        </a>
            </dd>
            <dd className="mode-land">
                <a id="back_2" className="back-2" href="#">
          <span />
        </a>
            </dd>
            <dd>
                <a id="back_1" className="back-1" href="#">
          <span />
        </a>
            </dd>
            <dd>
                <a id="lay_1" className="lay-1" href="#">
          <span />
        </a>
            </dd>
            <dd className="mode-land">
                <a id="lay_2" className="lay-2" href="#">
          <span />
        </a>
            </dd>
            <dd className="mode-land">
                <a id="lay_3" className="lay-3" href="#">
          <span />
        </a>
            </dd>
        </dl>
    </div>
    <p className="info-rotate" style={{display: 'none'}}>Rotate Screen<br />to see Market Depth</p>
</div>
        </React.Fragment>
    )
}
