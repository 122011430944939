import React, { useEffect, useState } from 'react'
import Cup from '../images/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import moment from 'moment'
import Cookies from 'universal-cookie';
import Kabaddi from './Kabaddi';
import Support from './Support';
import Binary from './Binary';
import Transparent from '../images/transparent.gif'
import { Link } from 'react-router-dom';
import horse from '../images/horsel.6a5281de.svg'
import kvvertual from '../images/kv-virtual-cricket-m.jpg'
import MT5 from '../images/mt5.4f09b4e8.svg'

require('moment-timezone');
const cookies = new Cookies();

export default function Highlights(props) {
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	const [countCricket, setCountCricket] = useState(0);
	const [countSoccer, setCountSoccer] = useState(0);
	const [countTennis, setCountTennis] = useState(0);
	const [newrefresh, setnewrefresh] = useState(true);


	function changecount(cri, socc, tenn) {

		setCountCricket(cri);
		setCountSoccer(socc);
		setCountTennis(tenn);
	}



	const openAESexy = (gameI)=>{
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
	else{
		var utoken = cookies.get('token');
		var user = cookies.get('id');
		var mil =  Date.now();
	
		if(!user || !utoken){
		  return;
		}
		setnewrefresh(true);
		axios.post('https://millionbet247.com/api/gapcasino/game/url',{
		   user:user,
		   token:utoken,
		   platform:'GPL_DESKTOP',
		   lobby_url: window.location.href,
		   game_id: gameI
	  
		 })
		 .then(result => {
			
		  setTimeout(()=>{setnewrefresh(false);},4000);
		  if(result.data.url){
			// window.open(result.data.url);
			//var newTab=window.open('about:blank');
			window.location.href=result.data.url;
	  
		   }
		   
		 
		 })
		 .catch(e => {
		  
		 });
	  
	   }
	}

	useEffect(() => {
		axios.all([
			axios.get('https://millionbet247.com/api/client/clientcricket'),
			axios.get('https://millionbet247.com/api/client/clientsoccer'),
			axios.get('https://millionbet247.com/api/client/clienttennis')
		])
			.then(axios.spread((obj1, obj2, obj3) => {

				setnewrefresh(false)
				var count1 = 0;
				var count2 = 0;
				var count3 = 0;

				obj1.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj2.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})
				obj3.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj1.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count1 = count1 + 1;
					}
				});
				obj2.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count2 = count2 + 1;
					}
				});
				obj3.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count3 = count3 + 1;
					}
				});

				changecount(count1, count2, count3);

			}));
	}, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}
	return (

		<React.Fragment>
			{newrefresh === true &&
				<div class="loading-overlay" id="loading">
					<div class="loading-wrap" style={{ display: " flex" }}>
						<div class="loading">
							<div></div>
							<div></div>
						</div>
						<p>Loading...</p>
					</div>
				</div>}

			{newrefresh === false &&
				<div className='mian-wrap'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					<div id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div id="frame" className="wrap-highlight ps ps--theme_default ps--active-x">
							<a className="a-tab_prev" style={{ display: 'none' }}>prev</a>

							<ul id="label">
								{props.isLoggedIn &&
									<li class="menu-casino">
										<span class="tag-new">New</span>
										<a id="casinoLoginBtn" ><img class="icon-casino" src={Transparent} />Casino</a>
									</li>}

								{/* <li class="menu-casino"><span class="tag-new">New</span><Link to="/slots" id="casinoLoginBtn"><img class="icon-bet-game" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Slots</Link></li> */}

								<li id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span id="tagLive" className="tag-live"><strong></strong>{countCricket}</span>
									<a><img className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

								<li id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span id="tagLive" className="tag-live"><strong></strong>{countSoccer}</span>
									<a><img className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>

								<li id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span id="tagLive" className="tag-live"><strong></strong>{countTennis}</span>
									<a ><img className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>
								{/* <li id="highlightTab138" onClick={() => { sethighlightTab(3) }} className={`${(highlightTab === 3) ? "select" : "null"}`}>
									<span id="tagLive" className="tag-live" ><strong />0</span>
									<a><img className="icon-basketball" src={Cup} alt='gif' />Basketball</a>
								</li> */}

								{/* <li id="highlightTab3" onClick={() => { sethighlightTab(5) }} className={`${(highlightTab === 5) ? "select" : "null"}`}>
									<a><img class="icon-HR" src={Cup} />Horse Racing</a>
								</li> */}

								{/* <li id="highlightTab2378961" onClick={() => { sethighlightTab(6) }} className={`${(highlightTab === 6) ? "select" : "null"}`}>
									<a ><img class="icon-dog" src={Cup} />GreyHound</a>
							</li> */}
								{/*
						<li id="highlightTab2378961" onClick={() => { sethighlightTab(7) }} className={`${(highlightTab === 7) ? "select" : "null"}`}>
							<a><img class="icon-mt5" src={MT5} />MT5</a>
						</li> */}
							</ul>

							<div className="ps__scrollbar-x-rail"><div className="ps__scrollbar-x"  ></div></div><div className="ps__scrollbar-y-rail" ><div className="ps__scrollbar-y"  ></div></div></div>
					</div>

					<div className="wrap-highlight_list" style={{ height: 'auto', backgroundColor: '#fff' }}  >
						<h3>Highlights</h3>

						<ul id="highlightList" className="highlight-list">
							{highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 3 && <Kabaddi />}
							{highlightTab === 5 && <Binary />}
						</ul>
					</div>
					{/* <Support handlepolicy={props.handlepolicy} /> */}
					{highlightTab !== 1 && highlightTab !== 2 && highlightTab !== 3 && highlightTab !== 5 && highlightTab !== 6 && <div class="footer-kv">
						<a onClick = {()=>{openAESexy(800001)}} id="sportradarHighlightEntrance" class="entrance"  style={{ cursor: 'pointer' }} neua="BetRadar Banner">
							<img src={kvvertual} style={{ width: '100%' }} alt="" draggable="false" />
						</a>
					</div>}
				</div>}
		</React.Fragment>
	)
}
