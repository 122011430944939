import React,{ useState} from 'react'

export const HorseToday = () => {
    const [HorseRace, setHorseRace] = useState(false)

    const HorseClick=()=>{
        setHorseRace(!HorseRace)
            }
    return (
        <React.Fragment>
              <div id="contentBox">
        <div id="countryGroup_AU" className={`${HorseRace ? "game-wrap-horse closer" : "game-wrap-horse"}`} firstmarkettime="2021-12-26 12:05">
            <h3>
                <a onClick={HorseClick}  className={`${HorseRace ? "to-expand closer" : "to-expand"}`}>
          <span id="heading">AU</span>
          <span id="closeInfo" className="close-info"  style={ HorseRace?{display: 'block'}: {display: 'none'}}>Races 66 (Next in 2 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31143477" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:05">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Devonport (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192685439" title="R1 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685439&quot;,true)" marketdatetime={1640500500000} className="in-play">12:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685441" title="R2 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685441&quot;,true)" marketdatetime={1640502060000}>12:31</a></li>
                        <li id="liTemp">
                            <a id="market_1_192763949" title="R3 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192763949&quot;,true)" marketdatetime={1640504280000}>13:08</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685445" title="R4 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685445&quot;,true)" marketdatetime={1640506080000}>13:38</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685447" title="R5 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685447&quot;,true)" marketdatetime={1640507820000}>14:07</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685449" title="R6 2286m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685449&quot;,true)" marketdatetime={1640509920000}>14:42</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685451" title="R7 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685451&quot;,true)" marketdatetime={1640511900000}>15:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685453" title="R8 1930m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685453&quot;,true)" marketdatetime={1640513640000}>15:44</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685455" title="R9 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685455&quot;,true)" marketdatetime={1640515440000}>16:14</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685457" title="R10 2297m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685457&quot;,true)" marketdatetime={1640517240000}>16:44</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143509" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:10">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Nhill (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192689031" title="R7 1650m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143509&marketId=1.192689031&quot;,true)" marketdatetime={1640500800000} className="in-play">12:10</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31142138" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:12">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Doomben (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192654114" title="R8 1200m Listed" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142138&marketId=1.192654114&quot;,true)" marketdatetime={1640500920000} className="in-play">12:12</a></li>
                        <li id="liTemp">
                            <a id="market_1_192654116" title="R9 1350m CL6" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142138&marketId=1.192654116&quot;,true)" marketdatetime={1640503320000}>12:52</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143452" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:16">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Esperance (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192685240" title="R3 1100m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143452&marketId=1.192685240&quot;,true)" marketdatetime={1640501160000}>12:16</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685242" title="R4 1100m CL2" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143452&marketId=1.192685242&quot;,true)" marketdatetime={1640503560000}>12:56</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685244" title="R5 1300m CL5" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143452&marketId=1.192685244&quot;,true)" marketdatetime={1640505900000}>13:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685246" title="R6 1600m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143452&marketId=1.192685246&quot;,true)" marketdatetime={1640508120000}>14:12</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685248" title="R7 1400m CL2" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143452&marketId=1.192685248&quot;,true)" marketdatetime={1640510400000}>14:50</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31142148" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:20">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Caulfield (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192654209" title="R9 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142148&marketId=1.192654209&quot;,true)" marketdatetime={1640501400000}>12:20</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143507" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:25">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Newcastle (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192688989" title="R7 1350m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143507&marketId=1.192688989&quot;,true)" marketdatetime={1640501700000}>12:25</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143511" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:29">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Gold Coast (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192689074" title="R8 1400m CL1" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143511&marketId=1.192689074&quot;,true)" marketdatetime={1640501940000}>12:29</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31142143" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:33">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Morphettville (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192654134" title="R9 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142143&marketId=1.192654134&quot;,true)" marketdatetime={1640502180000}>12:33</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31142144" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:40">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Randwick (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192654155" title="R10 1600m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142144&marketId=1.192654155&quot;,true)" marketdatetime={1640502600000}>12:40</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143492" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:44">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Albany (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192686566" title="R3 1600m CL2" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143492&marketId=1.192686566&quot;,true)" marketdatetime={1640502840000}>12:44</a></li>
                        <li id="liTemp">
                            <a id="market_1_192686568" title="R4 1500m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143492&marketId=1.192686568&quot;,true)" marketdatetime={1640505060000}>13:21</a></li>
                        <li id="liTemp">
                            <a id="market_1_192686570" title="R5 1100m CL5" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143492&marketId=1.192686570&quot;,true)" marketdatetime={1640507220000}>13:57</a></li>
                        <li id="liTemp">
                            <a id="market_1_192686572" title="R6 1100m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143492&marketId=1.192686572&quot;,true)" marketdatetime={1640509500000}>14:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192686574" title="R7 1230m CL2" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143492&marketId=1.192686574&quot;,true)" marketdatetime={1640511720000}>15:12</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143575" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:47">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Bathurst (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192690970" title="R1 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690970&quot;,true)" marketdatetime={1640503020000}>12:47</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690972" title="R2 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690972&quot;,true)" marketdatetime={1640504760000}>13:16</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690974" title="R3 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690974&quot;,true)" marketdatetime={1640506500000}>13:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690976" title="R4 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690976&quot;,true)" marketdatetime={1640508540000}>14:19</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690978" title="R5 2790m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690978&quot;,true)" marketdatetime={1640510580000}>14:53</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690980" title="R6 2790m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690980&quot;,true)" marketdatetime={1640512380000}>15:23</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690982" title="R7 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690982&quot;,true)" marketdatetime={1640514120000}>15:52</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690984" title="R8 1730m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143575&marketId=1.192690984&quot;,true)" marketdatetime={1640515920000}>16:22</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143466" className="game-list-col hs-time" firstmarkettime="2021-12-26 12:53">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Dubbo (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192685423" title="R1 2120m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685423&quot;,true)" marketdatetime={1640503380000}>12:53</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685425" title="R2 2120m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685425&quot;,true)" marketdatetime={1640505240000}>13:24</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685427" title="R3 2120m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685427&quot;,true)" marketdatetime={1640506920000}>13:52</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685429" title="R4 2120m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685429&quot;,true)" marketdatetime={1640508960000}>14:26</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685431" title="R5 2120m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685431&quot;,true)" marketdatetime={1640511060000}>15:01</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685433" title="R6 1720m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685433&quot;,true)" marketdatetime={1640512860000}>15:31</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685435" title="R7 1720m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685435&quot;,true)" marketdatetime={1640514660000}>16:01</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685437" title="R8 1720m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143466&marketId=1.192685437&quot;,true)" marketdatetime={1640516460000}>16:31</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143498" className="game-list-col hs-time" firstmarkettime="2021-12-26 13:00">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Ballarat (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192688770" title="R1 1710m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688770&quot;,true)" marketdatetime={1640503800000}>13:00</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688772" title="R2 2200m Trot M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688772&quot;,true)" marketdatetime={1640505420000}>13:27</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688774" title="R3 2200m Trot M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688774&quot;,true)" marketdatetime={1640507400000}>14:00</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688776" title="R4 2200m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688776&quot;,true)" marketdatetime={1640509200000}>14:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688778" title="R5 1710m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688778&quot;,true)" marketdatetime={1640510880000}>14:58</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688780" title="R6 2200m Trot M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688780&quot;,true)" marketdatetime={1640512800000}>15:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192688782" title="R7 2200m Trot M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143498&marketId=1.192688782&quot;,true)" marketdatetime={1640514600000}>16:00</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143453" className="game-list-col hs-time" firstmarkettime="2021-12-26 13:32">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Leeton (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192685257" title="R1 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685257&quot;,true)" marketdatetime={1640505720000}>13:32</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685259" title="R2 1758m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685259&quot;,true)" marketdatetime={1640507520000}>14:02</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685261" title="R3 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685261&quot;,true)" marketdatetime={1640509740000}>14:39</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685263" title="R4 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685263&quot;,true)" marketdatetime={1640511540000}>15:09</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685265" title="R5 1758m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685265&quot;,true)" marketdatetime={1640513220000}>15:37</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685267" title="R6 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685267&quot;,true)" marketdatetime={1640515020000}>16:07</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685269" title="R7 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685269&quot;,true)" marketdatetime={1640516760000}>16:36</a></li>
                        <li id="liTemp">
                            <a id="market_1_192685271" title="R8 2147m Pace M" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143453&marketId=1.192685271&quot;,true)" marketdatetime={1640518680000}>17:08</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31143578" className="game-list-col hs-time" firstmarkettime="2021-12-26 16:26">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Busselton (AUS) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192690986" title="R1 2030m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690986&quot;,true)" marketdatetime={1640516160000}>16:26</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690988" title="R2 2030m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690988&quot;,true)" marketdatetime={1640517660000}>16:51</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690990" title="R3 2400m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690990&quot;,true)" marketdatetime={1640519160000}>17:16</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690992" title="R4 2400m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690992&quot;,true)" marketdatetime={1640520660000}>17:41</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690994" title="R5 2030m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690994&quot;,true)" marketdatetime={1640523060000}>18:21</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690996" title="R6 2030m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690996&quot;,true)" marketdatetime={1640524500000}>18:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192690998" title="R7 2030m Pace S" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143578&marketId=1.192690998&quot;,true)" marketdatetime={1640526000000}>19:10</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
        <div id="countryGroup_ZA" className="game-wrap-horse" firstmarkettime="2021-12-26 14:45">
            <h3>
                <a className="to-expand">
          <span id="heading">ZA</span>
          <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 9 (Next in 2 hrs 31 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31145041" className="game-list-col hs-time" firstmarkettime="2021-12-26 14:45">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Greyville (RSA) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192722538" title="R1 1000m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722538&quot;,true)" marketdatetime={1640510100000}>14:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722540" title="R2 1200m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722540&quot;,true)" marketdatetime={1640512200000}>15:20</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722542" title="R3 1200m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722542&quot;,true)" marketdatetime={1640514300000}>15:55</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722544" title="R4 2100m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722544&quot;,true)" marketdatetime={1640516400000}>16:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722546" title="R5 2400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722546&quot;,true)" marketdatetime={1640518500000}>17:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722548" title="R6 1600m Listed" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722548&quot;,true)" marketdatetime={1640520600000}>17:40</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722550" title="R7 1600m Grp3" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722550&quot;,true)" marketdatetime={1640522700000}>18:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722552" title="R8 1400m Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722552&quot;,true)" marketdatetime={1640524800000}>18:50</a></li>
                        <li id="liTemp">
                            <a id="market_1_192722554" title="R9 1400m Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145041&marketId=1.192722554&quot;,true)" marketdatetime={1640526780000}>19:23</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
        <div id="countryGroup_FR" className="game-wrap-horse" firstmarkettime="2021-12-26 15:35">
            <h3>
                <a className="to-expand">
          <span id="heading">FR</span>
          <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 9 (Next in 3 hrs 21 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31145387" className="game-list-col hs-time" firstmarkettime="2021-12-26 15:35">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Chantilly (FRA) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192728521" title="1m Mdn Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728521&quot;,true)" marketdatetime={1640513100000}>15:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728523" title="6f Mdn Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728523&quot;,true)" marketdatetime={1640514900000}>16:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728525" title="1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728525&quot;,true)" marketdatetime={1640516700000}>16:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728527" title="1m1f Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728527&quot;,true)" marketdatetime={1640518500000}>17:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728529" title="1m1f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728529&quot;,true)" marketdatetime={1640520300000}>17:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728531" title="6f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728531&quot;,true)" marketdatetime={1640522400000}>18:10</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728533" title="1m1f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728533&quot;,true)" marketdatetime={1640524500000}>18:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728535" title="1m1f Mdn Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728535&quot;,true)" marketdatetime={1640526600000}>19:20</a></li>
                        <li id="liTemp">
                            <a id="market_1_192728537" title="6f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31145387&marketId=1.192728537&quot;,true)" marketdatetime={1640529120000}>20:02</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
        <div id="countryGroup_GB" className="game-wrap-horse" firstmarkettime="2021-12-26 17:15">
            <h3>
                <a className="to-expand">
          <span id="heading">GB</span>
          <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 55 (Next in 5 hrs 1 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31144141" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:15">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Sedgefield 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192702174" title="2m4f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702174&quot;,true)" marketdatetime={1640519100000}>17:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702179" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702179&quot;,true)" marketdatetime={1640520900000}>17:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702184" title="2m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702184&quot;,true)" marketdatetime={1640523000000}>18:20</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702189" title="2m1f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702189&quot;,true)" marketdatetime={1640525100000}>18:55</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702194" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702194&quot;,true)" marketdatetime={1640527200000}>19:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702199" title="2m3f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702199&quot;,true)" marketdatetime={1640529300000}>20:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702204" title="2m1f NHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144141&marketId=1.192702204&quot;,true)" marketdatetime={1640531400000}>20:40</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144079" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:22">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Wetherby 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192701060" title="3m Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701060&quot;,true)" marketdatetime={1640519520000}>17:22</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701067" title="2m3f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701067&quot;,true)" marketdatetime={1640521620000}>17:57</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701074" title="2m3f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701074&quot;,true)" marketdatetime={1640523600000}>18:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701081" title="2m3f Nov Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701081&quot;,true)" marketdatetime={1640525700000}>19:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701088" title="3m Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701088&quot;,true)" marketdatetime={1640527800000}>19:40</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701095" title="2m5f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701095&quot;,true)" marketdatetime={1640529900000}>20:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192701102" title="2m Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144079&marketId=1.192701102&quot;,true)" marketdatetime={1640532000000}>20:50</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144134" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:25">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Market Rasen 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192702069" title="2m Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702069&quot;,true)" marketdatetime={1640519700000}>17:25</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702074" title="2m Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702074&quot;,true)" marketdatetime={1640521800000}>18:00</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702079" title="2m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702079&quot;,true)" marketdatetime={1640523900000}>18:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702084" title="2m5f Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702084&quot;,true)" marketdatetime={1640526000000}>19:10</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702089" title="3m3f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702089&quot;,true)" marketdatetime={1640528100000}>19:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702094" title="2m2f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702094&quot;,true)" marketdatetime={1640530200000}>20:20</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702099" title="2m4f Nov Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144134&marketId=1.192702099&quot;,true)" marketdatetime={1640532300000}>20:55</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144144" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:27">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Fontwell 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192702294" title="2m2f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702294&quot;,true)" marketdatetime={1640519820000}>17:27</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702299" title="3m2f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702299&quot;,true)" marketdatetime={1640521920000}>18:02</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702304" title="2m2f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702304&quot;,true)" marketdatetime={1640524020000}>18:37</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702309" title="2m2f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702309&quot;,true)" marketdatetime={1640526120000}>19:12</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702314" title="2m2f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702314&quot;,true)" marketdatetime={1640528220000}>19:47</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702319" title="3m2f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702319&quot;,true)" marketdatetime={1640530320000}>20:22</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702324" title="2m3f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144144&marketId=1.192702324&quot;,true)" marketdatetime={1640532420000}>20:57</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144133" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:36">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Huntingdon 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192702034" title="3m1f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702034&quot;,true)" marketdatetime={1640520360000}>17:36</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702039" title="2m3f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702039&quot;,true)" marketdatetime={1640522400000}>18:10</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702044" title="2m7f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702044&quot;,true)" marketdatetime={1640524500000}>18:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702049" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702049&quot;,true)" marketdatetime={1640526420000}>19:17</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702054" title="2m4f Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702054&quot;,true)" marketdatetime={1640528520000}>19:52</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702059" title="2m4f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702059&quot;,true)" marketdatetime={1640530620000}>20:27</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702064" title="2m NHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144133&marketId=1.192702064&quot;,true)" marketdatetime={1640532720000}>21:02</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144153" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:48">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Wincanton 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
          <dd>
             <ul id="timeRacing">
                     <li id="liTemp">
                            <a id="market_1_192702329" title="1m7f Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702329&quot;,true)" marketdatetime={1640521080000}>17:48</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702334" title="2m4f Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702334&quot;,true)" marketdatetime={1640523180000}>18:23</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702339" title="1m7f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702339&quot;,true)" marketdatetime={1640525280000}>18:58</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702344" title="3m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702344&quot;,true)" marketdatetime={1640527380000}>19:33</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702349" title="3m Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702349&quot;,true)" marketdatetime={1640529480000}>20:08</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702354" title="2m4f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702354&quot;,true)" marketdatetime={1640531580000}>20:43</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702359" title="1m7f NHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144153&marketId=1.192702359&quot;,true)" marketdatetime={1640533500000}>21:15</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144032" className="game-list-col hs-time" firstmarkettime="2021-12-26 18:15">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Kempton 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192700050" title="2m Nov Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700050&quot;,true)" marketdatetime={1640522700000}>18:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700057" title="2m4f Nov Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700057&quot;,true)" marketdatetime={1640524800000}>18:50</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700064" title="3m Grd1 Nov Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700064&quot;,true)" marketdatetime={1640526900000}>19:25</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700071" title="2m Grd1 Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700071&quot;,true)" marketdatetime={1640529000000}>20:00</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700078" title="3m Grd1 Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700078&quot;,true)" marketdatetime={1640531100000}>20:35</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700085" title="2m5f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144032&marketId=1.192700085&quot;,true)" marketdatetime={1640533200000}>21:10</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144140" className="game-list-col hs-time" firstmarkettime="2021-12-26 19:20">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Wolverhampton 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192702139" title="1m Nursery" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702139&quot;,true)" marketdatetime={1640526600000}>19:20</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702144" title="5f Nov Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702144&quot;,true)" marketdatetime={1640528700000}>19:55</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702149" title="6f Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702149&quot;,true)" marketdatetime={1640530800000}>20:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702154" title="7f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702154&quot;,true)" marketdatetime={1640532900000}>21:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702159" title="1m1f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702159&quot;,true)" marketdatetime={1640535000000}>21:40</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702164" title="1m1f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702164&quot;,true)" marketdatetime={1640537100000}>22:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192702169" title="1m4f Hcap" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144140&marketId=1.192702169&quot;,true)" marketdatetime={1640539200000}>22:50</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
        <div id="countryGroup_IE" className="game-wrap-horse" firstmarkettime="2021-12-26 17:30">
            <h3>
                <a className="to-expand">
          <span id="heading">IE</span>
          <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 21 (Next in 5 hrs 16 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31144044" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:30">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Leopardstown 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192700303" title="2m Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700303&quot;,true)" marketdatetime={1640520000000}>17:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700310" title="2m Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700310&quot;,true)" marketdatetime={1640522100000}>18:05</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700317" title="2m Grd2 Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700317&quot;,true)" marketdatetime={1640524200000}>18:40</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700324" title="2m Nov Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700324&quot;,true)" marketdatetime={1640526300000}>19:15</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700331" title="2m1f Grd1 Nov Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700331&quot;,true)" marketdatetime={1640528400000}>19:50</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700338" title="2m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700338&quot;,true)" marketdatetime={1640530500000}>20:25</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700345" title="2m INHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144044&marketId=1.192700345&quot;,true)" marketdatetime={1640532600000}>21:00</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144055" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:43">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Down Royal 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192700401" title="2m4f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700401&quot;,true)" marketdatetime={1640520780000}>17:43</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700408" title="2m1f Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700408&quot;,true)" marketdatetime={1640522880000}>18:18</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700415" title="2m1f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700415&quot;,true)" marketdatetime={1640524980000}>18:53</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700422" title="2m4f Beg Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700422&quot;,true)" marketdatetime={1640527080000}>19:28</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700429" title="2m1f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700429&quot;,true)" marketdatetime={1640529180000}>20:03</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700436" title="2m5f Hunt Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700436&quot;,true)" marketdatetime={1640530980000}>20:33</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700443" title="2m1f INHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144055&marketId=1.192700443&quot;,true)" marketdatetime={1640532900000}>21:05</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31144047" className="game-list-col hs-time" firstmarkettime="2021-12-26 17:55">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Limerick 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192700352" title="2m Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700352&quot;,true)" marketdatetime={1640521500000}>17:55</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700359" title="2m Mdn Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700359&quot;,true)" marketdatetime={1640523480000}>18:28</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700366" title="2m4f Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700366&quot;,true)" marketdatetime={1640525580000}>19:03</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700373" title="2m4f Hcap Hrd" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700373&quot;,true)" marketdatetime={1640527680000}>19:38</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700380" title="2m4f Nov Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700380&quot;,true)" marketdatetime={1640529600000}>20:10</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700387" title="2m4f Hcap Chs" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700387&quot;,true)" marketdatetime={1640531700000}>20:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192700394" title="2m INHF" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31144047&marketId=1.192700394&quot;,true)" marketdatetime={1640533800000}>21:20</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
        <div id="countryGroup_US" className="game-wrap-horse" firstmarkettime="2021-12-26 22:30">
            <h3>
                <a className="to-expand">
          <span id="heading">US</span>
          <span id="closeInfo" className="close-info" style={{display: 'none'}}>Races 10 (Next in 10 hrs 16 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
            <dl id="racingBox_31146215" className="game-list-col hs-time" firstmarkettime="2021-12-26 22:30">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Gulfstream Park (US) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192756751" title="R1 1m1f Mdn" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146215&marketId=1.192756751&quot;,true)" marketdatetime={1640538000000}>22:30</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756754" title="R2 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146215&marketId=1.192756754&quot;,true)" marketdatetime={1640539800000}>23:00</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756757" title="R3 6f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146215&marketId=1.192756757&quot;,true)" marketdatetime={1640541480000}>23:28</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756760" title="R4 1m Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146215&marketId=1.192756760&quot;,true)" marketdatetime={1640543160000}>23:56</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31146251" className="game-list-col hs-time" firstmarkettime="2021-12-26 22:45">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Tampa Bay Downs (US) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192756854" title="R1 1m Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146251&marketId=1.192756854&quot;,true)" marketdatetime={1640538900000}>22:45</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756857" title="R2 7f Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146251&marketId=1.192756857&quot;,true)" marketdatetime={1640540580000}>23:13</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756860" title="R3 1m Allw Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146251&marketId=1.192756860&quot;,true)" marketdatetime={1640542320000}>23:42</a></li>
                    </ul>
                </dd>
            </dl>
            <dl id="racingBox_31146230" className="game-list-col hs-time" firstmarkettime="2021-12-26 22:55">
                <dt>
          
          <a id="eventName" style={{cursor: 'default'}}>Laurel Park (US) 26th Dec</a>
          <span id="placeInfo" className="game-list-place" style={{display: 'none'}}>--</span>
        </dt>
                <dd>
                    <ul id="timeRacing">
                        <li id="liTemp">
                            <a id="market_1_192756794" title="R1 6f Mdn Claim" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146230&marketId=1.192756794&quot;,true)" marketdatetime={1640539500000}>22:55</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756797" title="R2 7f Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146230&marketId=1.192756797&quot;,true)" marketdatetime={1640541240000}>23:24</a></li>
                        <li id="liTemp">
                            <a id="market_1_192756800" title="R3 7f Stks" href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31146230&marketId=1.192756800&quot;,true)" marketdatetime={1640542920000}>23:52</a></li>
                    </ul>
                </dd>
            </dl>
        </div>
    </div>
 
        </React.Fragment>
    )
}
