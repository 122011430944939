import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import { event } from 'jquery';
require('moment-timezone');

export default function SoccerMatches(props) {


  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [text, settext] = useState('')

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";
  // vvvv

  useEffect(() => {

    Axios.get(
      `https://millionbet247.com/api/client/clientsoccer`)

      .then(result => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }

      })



      .catch(error => console.log(error));

    Axios.get(
      `https://millionbet247.com/api/client/clientchecktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    Axios.get(
      `https://millionbet247.com/api/client/clientcheckbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

  }, []);
  console.log(text);
  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");


    if (props.isLoggedIn == true) {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }

    }


  }, [match, cricketUpdate]);

  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      //console.log('2', list);

    }
  }

  // console.log('Soccer');
  return (

    <React.Fragment>

      {newrefresh === true &&
        <div class="loading-overlay" id="loading">
          <div class="loading-wrap" style={{ display: " flex" }}>
            <div class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}


      {newrefresh === false && <div >

        {match.map(function (item, id) {

          var showtv = false;
          var isbookmaker = false;

          var showColor = false;

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (tv.includes(item.eventId)) {
            showtv = true;
          }
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          }

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }


          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showColor = true;
          }

          return (


            <li key={id} id="highlightEvent_29869541" className={`inplay${showColor ? "-on" : "-off"}`}>
              <Link to={`/exchange/member/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={() => { props.changeState(true); }}>
                <dl>
                  <dt>
                    {/* {showtv && */}

                    {moment() >= moment(item.marketStartTime) && <span id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
                    {isbookmaker &&
                      <span id="bookMakerIcon" className={`game-bookmaker ${showColor ? "in-play" : "no-play"}`} style={{ display: "flex" }}>
                        <pre>in-play</pre>BookMaker</span>}
                    {moment() >= moment(item.marketStartTime) &&
                      <span id="dateTimeInfo" className="time">In-Play</span>}
                    {moment() < moment(item.marketStartTime) && <span id="dateTimeInfo" className="time">{matchTime}</span>}
                  </dt>
                  <dd id="eventName">{item.eventName}
                    {/* <span>2 - 2</span> */}
                  </dd>
                </dl>
              </Link>
              <a id={MKPINSC + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
            </li>
          )
        })}
      </div>}
    </React.Fragment>
  )
}
