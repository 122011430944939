import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Horsedayafter from './Horsedayafter'
import { HorseToday } from './HorseToday'
import { HorseTomorrow } from './HorseTomorrow'
 
export const HorseRacingmatches = () => {
    const [Eventdays, setEventdays] = useState(1)



    return (
        <React.Fragment>
            <div className="mian-wrap">
    <h3 id="nextHeader">Next Horse Race</h3>
    <ul id="nextUpComing" className="upcoming-list">
        <li id="upcoming_1.192685240" className style={{}}>
            <Link to="/exchange/mobile/Fairhorsefullmarket"><span className="time">R3 1100m Hcap</span>12:16 Esperance(AUS)</Link>
        </li>
        <li id="upcomingWrap" className="upcoming-list-col3">
            <h4>Coming Up</h4>
            <ul id="upcoming">
                <li id="upcomingRaceTempLate" className style={{display: 'none'}}>
                    <Link href="/exchange/mobile/Fairhorsefullmarket">
            <span className="time">14:00</span>
            Pakenham (AUS)
          </Link>
                </li>
                <li id="upcoming_1.192763473" className style={{}}>
                    <a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142148&marketId=1.192763473&quot;,true)"><span className="time">12:20</span>Caulfield(AUS)</a>
                </li>
                <li id="upcoming_1.192688989" className style={{}}>
                    <a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143507&marketId=1.192688989&quot;,true)"><span className="time">12:25</span>Newcastle(AUS)</a>
                </li>
                <li id="upcoming_1.192689074" className style={{}}>
                    <a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143511&marketId=1.192689074&quot;,true)"><span className="time">12:29</span>GoldCoast</a>
                </li>
                <li id="upcoming_1.192685441" className style={{}}>
                    <a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31143477&marketId=1.192685441&quot;,true)"><span className="time">12:31</span>Devonport(AUS)</a>
                </li>
                <li id="upcoming_1.192654273" className style={{}}>
                    <a href="javascript:MobileMenuHandler.loadPage(&quot;fullMarket.jsp?eventType=7&eventId=31142143&marketId=1.192654273&quot;,true)"><span className="time">12:33</span>Morphettville(AUS)</a>
                </li>
            </ul>
        </li>
    </ul>
    <div className="tab-wrap">
        <ul>
            <li id="today"  onClick ={()=>{setEventdays(1)}} className={`${(Eventdays=== 1 )? "select": "null"}`}><a >Today
        </a></li>
            <li  onClick ={()=>{setEventdays(2)}} className={`${(Eventdays=== 2 )? "select": "null"}`} id="tomorrow"><a >Tomorrow
        </a></li>
            <li  onClick ={()=>{setEventdays(3)}} className={`${(Eventdays=== 3 )? "select": "null"}`}  id="dayAfter"><a >Day After
        </a></li>
        </ul>
    </div>
    <div id="noDataDiv" className="game-wrap-horse" style={{display: 'none'}}>
        <div className="no-data">
            <p>No races available yet.
            </p>
        </div>
    </div>
    {Eventdays === 1 &&  <HorseToday/>}
    {Eventdays === 2 && <HorseTomorrow/>}
    {Eventdays === 3 && <Horsedayafter/>}
  
     <div style={{display: 'none'}}>
        <div id="countryGroupTemplate"   className= "game-wrap-horse">
      

            <h3> 
                <a className="">
          <span id="heading">--</span>
        

          <span id="closeInfo" className="close-info"   style={ {display: 'none'}}>Race 12 (Next in 12 mins)</span>
        </a>
            </h3>
            <div id="gameList" className="game-list" />
        </div>
        <li id="liTemp"><a /></li>
        <dl id="racingBoxTemplate" className="game-list-col hs-time">
            <dt>
        <a id="eventName" style={{cursor: 'default'}}>--</a>
        <span id="placeInfo" className="game-list-place" />
      </dt>
            <dd>
                <ul id="timeRacing">
                </ul>
            </dd>
        </dl>
    </div>
</div>
        </React.Fragment>
    )
}
