import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment'
import DragonNTiger from '../images/casino/banner_DragonNTiger-half.png'
import horserace from '../images/casino/banner_horsebook-half.png'
import up7down from '../images/banner_7up7down-half.png'
import teenpatti from '../images/casino/banner_TeenPatti2020-half.png'
import royalnew from '../images/banner_royalgaming.png'
import Ae from '../images/banner_AESexy-half.png'
import up7down7 from '../images/banner_7up7down-halfnew.png'
import callquick from '../images/banner_CallbreakQuick-half.png'
import binary from '../images/banner_binary-half.png'
import SicBo from '../images/banner_SicBo-Jili-half.png'
import daskadam from '../images/banner_DusKaDumVR-half.png'
import teenpattinew from '../images/banner_teenPatti-half.png'
import ludobet from '../images/banner_ludo-half.png'
import DragonNTigernew from '../images/banner_dragonTiger-half.png'
import worlimatka from '../images/banner_worliMatkaVR-half.png'
import baccarat from '../images/banner_Baccarat-half.png'
import Bonusdice from '../images/banner_BonusDice-half.png'
import bigsmall from '../images/banner_BigSmall-half.png'
import heist from '../images/banner_Heist-half.png'
import Lucky7 from '../images/banner_betgames-half.png';
import superover from '../images/banner_superOverVR-half.png'
import Roulleteauto from '../images/banner_autoRoulette-half.png'
import saba from '../images/banner_SabaSport.png'
import jokerpatti from '../images/banner_TeenPattiJoker-half.png'
import andervr from '../images/banner_andarBaharVR-half.png'
import teenpati from '../images/banner_teenPatti-half.png'
import bacarrat from '../images/kv/Bacarrat.jpeg'
import updown from '../images/banner_sevenUpDown-half.png'
import cardhilo from '../images/kv/cardshilow.jpeg'
import pokgend from '../images/casino/pokdeng.jpeg'
import anderbahar from '../images/banner_andarBahar-half.png'
import CoinToss from '../images/kv/CoinToss.png'
import blackjack from '../images/banner_blackjack-half.png'
import numbermatka from '../images/kv/numberMatka.png'
import NumberKing from '../images/casino/banner_NumberKing-half.png'
import Sports from '../images/banner_sports.png'
import cardmatka from '../images/kv/cardMatka.png'
import mineswiper from '../images/banner_minesweeper-half.png'
import { Link } from 'react-router-dom';
import Support from './Support';
import sibco from '../images/banner_sicbo-half.png'
import mojos from '../images/banner_7mojos-half.png';
import europianR from '../images/kv/europeanroulette.jpeg'
import betradar from '../images/casino/banner_virtualsports.png'
import livecasino from '../images/banner_casino-half.png'
import colorgame from '../images/banner_ColorGame-half.png'
import ezugy from '../images/ezugi-casino.webp'
import card5poker from '../images/banner_5CardPoker-half.png'
import rummyking from '../images/banner_rummy-half.png'
import Baccarats from '../images/casino/banner_Baccarat-half.png'
import Pragmatic from '../images/casino/PragmaticPlay.png'
import betgames from '../images/ebet.jpeg';
import agganes from '../images/ag.jpeg'
import OneTouch from '../images/casino/onetouch.jpeg';
import NumberMatka from '../images/superspade.jpeg'
import teenpattijili from '../images/JILI.jpeg'
import Kings9 from '../images/kv/32card-half.png'

import Cookies from 'universal-cookie';


const cookies = new Cookies();
export default function Home(props) {

    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [casinoWait, setcasinoWait] = useState(false);


    function changecount(cri, socc, tenn) {
        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
    }

    useEffect(() => {
        axios.all([
            axios.get('https://millionbet247.com/api/client/clientcricket'),
            axios.get('https://millionbet247.com/api/client/clientsoccer'),
            axios.get('https://millionbet247.com/api/client/clienttennis')
        ])
            .then(axios.spread((obj1, obj2, obj3) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                setrefresh(false);
                setTimeout(() => { setloading(false); }, 5000);
                changecount(count1, count2, count3);



            }));
    }, [])

    const openLudo = (gameI) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);
            axios.post('https://millionbet247.com/api/gapcasino/game/hurl', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }

    const openAESexy = (gameI) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);
            axios.post('https://millionbet247.com/api/gapcasino/game/url', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }

    const openEvolution = (gameI) => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        else {
            var utoken = cookies.get('token');

            var user = cookies.get('id');
            var mil = Date.now();

            if (!user || !utoken) {
                return;
            }
            setcasinoWait(true);
            axios.post('https://millionbet247.com/api6/casino/game/url', {
                user: user,
                token: utoken,
                platform: 'GPL_DESKTOP',
                lobby_url: window.location.href,
                game_id: gameI

            })
                .then(result => {

                    setTimeout(() => { setcasinoWait(false); }, 4000);
                    if (result.data.url) {
                        // window.open(result.data.url);
                        //var newTab=window.open('about:blank');
                        window.location.href = result.data.url;

                    }


                })
                .catch(e => {

                });

        }
    }

    const handleRefresh = () => {
        setloading(true);
    }

    return (
        <React.Fragment>

            {casinoWait &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }







            {refresh === true &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }


            <div class="mian-wrap">
                <div class="gamehall">
                    <Link to="/exchange/member/inplay" style={{ gridColumn: 'span 2' }}>
                        <dl class="entrance-title">
                            <dt>Sports</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <dl id="onLiveBoard" class="on_live">
                            <dt>
                                <p class="live_icon"><span></span> LIVE</p>
                            </dt>

                            <dd id="onLiveCount_CRICKET"><p>Cricket</p><span id="count">{countCricket}</span></dd>

                            <dd id="onLiveCount_SOCCER"><p>Soccer</p><span id="count">{countSoccer}</span></dd>

                            <dd id="onLiveCount_TENNIS"><p>Tennis</p><span id="count">{countTennis}</span></dd>


                        </dl>

                        <img src={Sports} alt="" draggable="false" />
                    </Link>

                    <a onClick={() => { openAESexy(800001) }} style={{ gridColumn: 'span 2' }}>
                        <dl class="entrance-title">
                            <dt>Saba Sports Book</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={saba} alt="" draggable="false" />
                    </a>

                    <a onClick={() => { openAESexy(800001) }} style={{ gridColumn: 'span 2' }}>
                        <dl class="entrance-title">
                            <dt>Betradar</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={betradar} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(800001) }} style={{ gridColumn: 'span 2' }}>
                        <dl class="entrance-title">
                            <dt>Royal Gaming</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={royalnew} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400010); }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>AE Sexy</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src={Ae} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400451) }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>Live Casino</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={livecasino} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400077); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>BlackJack Casino</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={blackjack} alt="" draggable="false" />
                    </a>


                    <a onClick={() => { openLudo(900000) }} class="entrance-half" >
                        <dl class="entrance-title">
                            <dt>7 Up Down</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={up7down} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openLudo(900000) }} class="entrance-half" >
                        <dl class="entrance-title">
                            <dt>Andar Bahar VR</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={andervr} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openEvolution(13930); }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>7 Moojos </dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src={mojos} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400080); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Mine Sweeper</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={mineswiper} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400080); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Teen Patti</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={teenpati} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openEvolution(13930); }} class="entrance-half">
                        <dl class="entrance-title">
                            <dt>Super Over VR </dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img src={superover} alt="" draggable="false" />
                    </a>
                    <a class="entrance-half" onClick={() => { openAESexy(600011); }} neua="Binary Banner">
                        <dl class="entrance-title">
                            <dt>Teenpatti 20-20</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={teenpatti} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600015); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>NumberKing</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={NumberKing} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600015); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Big Small</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={bigsmall} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600015); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>TeenPatti Joker</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={jokerpatti} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600015); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>7 Up 7 Down</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={up7down7} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Dragonn Tiger</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={DragonNTiger} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Auto Roulette</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Roulleteauto} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Dus Ka Dum</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={daskadam} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>CallbreakQuick</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={callquick} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>SicBo</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={SicBo} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Baccarat</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={baccarat} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Bonus Dice</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Bonusdice} alt="" draggable="false" />
                    </a>

                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Heist</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={heist} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>5 Card Poker</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={card5poker} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(600017); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Color Game</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={colorgame} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400078); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>32 Cards</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Kings9} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400078); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Rummy</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={rummyking} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400078); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Dragon Tiger</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={DragonNTigernew} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400078); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Worli Matka VR</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={worlimatka} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openEvolution(6511); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Betgames.tv</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={Lucky7} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400071); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Ander Baher</dt>
                            <dd>Play Now</dd>
                        </dl>


                        <img src={anderbahar} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400056); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Sibco</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={sibco} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400067); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>7 UP 7 Down</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={updown} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400075); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Coin Toss</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={CoinToss} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400075); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Teen Patti</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={teenpattinew} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400062); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Card Matka</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={cardmatka} alt="" draggable="false" />
                    </a>

                    <a onClick={() => { openAESexy(400061); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Number Matka</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={numbermatka} alt="" draggable="false" />
                    </a>
                    <a onClick={() => { openAESexy(400061); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Ludo Bet</dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={ludobet} alt="" draggable="false" />
                    </a>
                    <Link onClick={() => { openEvolution(13932); }} class="entrance-half" style={{ cursor: "pointer" }}>
                        <dl class="entrance-title">
                            <dt>Binary </dt>
                            <dd>Play Now</dd>
                        </dl>
                        <img src={binary} alt="" draggable="false" />
                    </Link>
                </div>
                <Support handlepolicy={props.handlepolicy} />
            </div>

        </React.Fragment>
    )
}
