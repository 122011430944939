import React, { useState } from 'react'
import Transparent from '../images/transparent.gif'
import Loading from "../images/loading40.gif"
import Home from '../desktopcomponent/Home'
import Cricket from './Cricket'
import Tennis from './Tennis'
import Soccer from './Soccer'
import Inplay from './InplayDesktop'
import Multimarketdesk from './Multimarketdesk'
import Oneclickbet from './Oneclickbet'
import News from './News'
import NewFullmarket from './NewFullmarket'
import Result from './Result'
// import HorseRacing from './HorseRacing'






export default function Center(props) {

  const [Socialicon, setSocialicon] = useState(0);






  return (


    <React.Fragment>
      <div id="centerColumn" className={`${(props.tabmenu === 2) ? "col-center inplay" : "col-center"}`} >

        <News userid={props.userid} utoken={props.utoken} isLoggedIn={props.isLoggedIn} />
        <div id="loading" class="loading-wrap" style={{ display: 'none' }}>
          <ul class="loading">
            <li><img src={Loading} alt="Loading" /></li>
            <li>Loading...</li>
          </ul>
        </div>


        <div id="message" class="message-wrap success">
          <a class="btn-close">Close</a>
          <p></p>
        </div>

        <div id="oneClickLoading" class="loading-wrap" style={{ display: 'none' }}>
          <ul class="loading">
            <li><img src={Loading} alt="Place_bet" /></li>
            <li >Place Bets</li>
          </ul>
        </div>
        {props.tabmenu === 0 && <NewFullmarket betType={props.betType} oneclick={props.oneclick} changeWaiting={props.changeWaiting} oneClickMarket={props.oneClickMarket} updateBalance={props.updateBalance} anyBets={props.anyBets} isanybets={props.isanybets} dStake={props.dStake} team={props.team} betClick={props.betClick} odds={props.odds} handleOpen={props.handleOpen} onSelect={props.onSelect} setOnClick={props.setOnClick} showBetForm={props.showBetForm} EventId={props.EventId} MarketId={props.MarketId} EventT={props.EventT} isLoggedIn={props.isLoggedIn} oneclick={props.oneclick} userid={props.userid} utoken={props.utoken}

        />}
        {props.tabmenu > 0 &&

          <div id="overWrap" class="over-wrap" style={props.isLoggedIn ? props.oneclick ? { height: "calc(100% - 55px)" } : { height: "calc(100% - 26px)" } : { height: "calc(100% - 0px)" }}>

            {props.tabmenu === 4 && <Cricket changeState={props.changeState} />}
            {props.tabmenu === 5 && <Soccer changeState={props.changeState} />}
            {props.tabmenu === 6 && <Tennis changeState={props.changeState} />}
            {props.tabmenu === 2 && <Inplay changeState={props.changeState} />}
            {props.tabmenu === 3 && <Multimarketdesk changeState={props.changeState} />}
            {/* {props.tabmenu === 13 && <HorseRacing />} */}
            {props.tabmenu === 31 && <Result changeState={props.changeState} />}


            {props.tabmenu !== 2 && props.tabmenu !== 3 && <div class="footer" >
              {/* <div class="support-wrap extend-support">
                <div class="extend-wrap">
                  <div class="extend-btn">

                    <img src={Transparent} title="customer" class="support-customer" />
                    <a href="https://api.whatsapp.com/send?phone=919867473673" target="_blank">Customer support1</a>


                    <a href="https://api.whatsapp.com/send?phone=260767320392" target="_blank" class="split-line">support2</a>

                  </div>
                  <div class="extend-btn">

                    <img src={Transparent} title="WhatsApp" class="support-whatsapp" />
                    <a href="https://api.whatsapp.com/send?phone=917777009073" target="_blank">+917777009073</a>


                    <a href="https://api.whatsapp.com/send?phone=260763986993" target="_blank" class="split-line">+260763986993</a>

                  </div>
                </div>
                <div class="extend-btn">


                  <img src={Transparent} title="Telegram" class="support-telegram" />
                  <a href="http://www.t.me/+917777002932" target="_blank">+917777002932</a>


                  <a href="http://www.t.me/+917900007644" target="_blank" class="split-line">+917900007644</a>

                </div>
                <div class="extend-wrap">
                  <div class="support-social">

                    <img src={Transparent} title="Skype" class="support-skype" />
                    <a href="skype:skyexchangeofficial?chat" target="_blank">skyexchangeofficial</a>

                  </div>
                  <div class="support-social">

                    <img src={Transparent} title="Email" class="support-mail" />
                    <a href="mailto:info@skyexchange.com" target="_blank">info@skyexchange.com</a>

                  </div>
                  <div class="support-social">

                    <img src={Transparent} title="Instagram" class="support-ig" />

                    <a href="https://www.instagram.com/sky_exch_?igshid=YmMyMTA2M2Y=/" target="_blank">officialskyexchange</a>


                  </div>
                </div>
              </div> */}
              <div id="supportWrap" class="support-wrap">
                <div class="support-service">

                  <a id="support_whatsapp" onMouseOver={() => { setSocialicon(0) }} className={`support-whatsapp ${(Socialicon === 0) ? "open" : "null"}`}><img src={Transparent} title="WhatsApp" /></a>
                  {/* <a id="support_telegram" onMouseOver={() => { setSocialicon(1) }} className={`support-telegram ${(Socialicon === 1) ? "open" : "null"}`}><img src={Transparent} title="Telegram" /></a> */}

                </div>
                {/* <div id="supportDetail_whatsapp" className={`support-detail ${(Socialicon === 0) ? "open" : "null"}`}>
                                {Socialicon === 0 &&
                                    <a href='https://api.whatsapp.com/send/?phone=917980153252&text&app_absent=0'><span>+917980153252 </span></a>
                                }
                                {Socialicon === 0 &&
                                    <a href='https://api.whatsapp.com/send/?phone=15678037291&text&app_absent=0'><span>+1(567)8037291</span></a>
                                }
                            </div> */}
              </div>



              <div class="power-wrap">

                <h3 id="powerWrapW" style={{ height: '100px' }}>
                  <span>Powered by</span>
                  <img src={Transparent} /></h3>



                <p>lc247 is operated by lc247 Infotech N.V. a limited liability company incorporated under the laws of Curacao with
                  company Registration number 152377 with registered office at Abraham de Veerstraat 9 , Curacao P.O Box 3421 and is licensed
                  and regulated by the Curacao authority as the regulatory body responsible holding a (Sub-license with License number 365/JAZ
                  Sub-License GLH- OCCHKTW0707072017 granted on 6.07.2017).
                  <br />
                  Players are requested not to contact any untrusted sources for lc247.com accounts as this is an online site and they can only
                  register independently without any agents. Only deposit through the account details generated by the system or provided by our
                  official support team.
                </p>



              </div>
              <div class="browser-wrap">

                <img src={Transparent} alt="gif" />
                <br></br>
                Our website works best in the newest and last prior version of these browsers: <br></br>Google Chrome.
              </div>


              <ul class="policy-link">
                <li><a >Privacy Policy</a></li>
                <li><a>Terms and Conditions</a></li>
                <li><a >Rules and Regulations</a></li>
                <li><a >KYC</a></li>
                <li><a >Responsible Gaming</a></li>
                <li><a >About Us</a></li>
                <li><a >Self-exclusion Policy</a></li>
                <li><a >Underage Policy</a></li>
              </ul>

              <ul class="RnR-link">
                <li><a >Rules and Regulations</a></li>
              </ul>
            </div>}
          </div>}
        {props.oneclick === true && <Oneclickbet userid={props.userid} utoken={props.utoken} />}
      </div>
    </React.Fragment>


  )
}
