import React, { useState, useEffect } from 'react'
import Transparent from '../images/transparent.gif'
import axios from 'axios';
import Cookies from 'universal-cookie';
import LeftSide from './LeftSide';
import moment from 'moment'

import Rightside from './Rightside'


const cookies = new Cookies();

export default function Header(props) {
	const [loading, setloading] = useState(false);
	const [balance, setBalance] = useState(0);
	const [exposure, setExposure] = useState(0);



	//tvIcon





	const handleRefresh = () => {
		setloading(true);
		setTimeout(() => { setloading(false); }, 1000);
		const utoken = cookies.get('token');
		const user = cookies.get('id');
		axios.post('https://millionbet247.com/api/client/clientrefresh', {
			id: user,
			token: utoken
		})
			.then(result => {
				if (result.status === 210) {

					//props.setLoggedIn(true);
					setBalance(result.data[0].val - Math.abs(result.data[1].val));
					props.changeBalance(result.data[0].val - Math.abs(result.data[1].val));
					if (result.data[1].val) {
						setExposure(Math.abs(result.data[1].val));
						props.changeExposure(Math.abs(result.data[1].val));
					}
					else {
						setExposure(0);
						props.changeExposure(0);

					}
				}

				if (result.status === 220) {
					//  console.log('refresh unsucessful');
					props.setLoggedIn(false);



				}
			}

			).catch(e => {
				//  setIsError(true);
			});
	};
	// console.log(props.liveStream,'livestream');

	useEffect(() => {
		handleRefresh();

	}, [props.userid])


	return (
		<React.Fragment >
			{props.openLeftside === true &&
				<LeftSide userid={props.userid} utoken={props.utoken} handleleftsideclose={props.handleleftsideclose} />
			}

			<Rightside handlerightsideclose={props.handlerightsideclose} openRightside={props.openRightside} userid={props.userid} utoken={props.utoken} />



			{props.refreshhh === true &&
				<div class="loading-overlay" id="loading">
					<div class="loading-wrap" style={{ display: " flex" }}>
						<div class="loading">
							<div></div>
							<div></div>
						</div>
						<p>{props.refreshMsg}</p>
					</div>
				</div>}
			<header>
				{props.isLoggedIn === false &&
					<h1 class="top-logo"></h1>}

				{props.isLoggedIn === false &&
					<>
						<a class="btn-signup ui-link" onclick="window.open('https://linktr.ee/SKY_EXCH','_blank');">Sign up</a>
						<a class="login-index ui-link" onClick={() => { props.checkShowLogin(true); }}>Login</a>
					</>}


				{props.isLoggedIn === true &&
					<ul>
						<li class="li-tv_bet">
							{props.tabMenu === 0 && props.liveStream && props.liveStream != '' && <a id="openTV" onClick={() => { props.ChangeTv() }} class="a-open_tv ui-link"  >
								<img src={Transparent} /></a>}
							<a id="openBetsBtn" class="a-open_bets ui-link" onClick={props.handleleftside}  ><img src={Transparent} />Bets</a>
						</li>

						<li class="main-wallet">
							{/*/<!-- open-->*/}
							<a id="multiWallet" class="a-wallet ui-link">
								{loading === false &&
									<ul id="accountCredit">
										<li ><span>Main</span> <span id="betCredit">USD {props.balance.toFixed(2)}</span></li>
										<li><span>Exposure</span> <span id="totalExposure" className={`${props.exposure > 0 ? "red" : ""}`}>
											{props.exposure > 0 ? '(' + parseFloat(props.exposure).toFixed(2) + ')' : props.exposure.toFixed(2)}</span></li>
										<li class="nums">+<span id="vendorQuantity">1</span> </li>
									</ul>}

								{loading === true &&
									<p class="loading-bar" id="menuRefreshLoading" >
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
									</p>}
							</a>
							<a class="a-refresh ui-link" id="menuRefresh" onClick={() => { handleRefresh(); }} title="Refresh Main Wallet"><img src={Transparent} /></a>
						</li>
						<li><a class="a-setting ui-link" onClick={() => { props.handlerightside() }} title="Setting"><img src={Transparent} /></a></li>
					</ul>
				}


				{props.msgNumber === 1 && <div id="msgBox" class="message-wrap success to-open_bets">
					<div class="message">
						<h4 id="header">Bet Matched</h4>
						<p id="info">
							<span id="sideType" class="lay">{props.betInfo.oddstype}</span>
							<strong id="selectionName">{props.betInfo.runner}</strong> <strong id="stake">{props.betInfo.amount}</strong> at odds <strong id="odds">{props.betInfo.rate}</strong>
						</p>
					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}



				{props.msgNumber === 2 && <div id="msgBox" class="message-wrap warning to-open_bets" >
					<div class="message">
						<h4 id="header">The stake you have entered are below the minimum.</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}


				{/*error messages */}
				{props.msgNumber === 3 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Bet Blocked</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 4 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Bet Closed</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 5 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Insufficient Coin</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 6 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Odds Changed</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 7 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Bet can not be placed!</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}
				{props.msgNumber === 8 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Enter valid coin value!</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 9 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Bet Over 75 not accepted!</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}
				{/* 
{props.msgNumber === 10 && 	<div id="msgBox" class="message-wrap error to-open_bets">
		<div class="message">
			<h4 id="header">Duplicate Bet Not allowed!</h4>
			
		</div>
		<a id="close" class="close ui-link" onClick = {()=>props.changeMsgNumber(0)}>Close</a>
    </div>} */}
				{props.msgNumber === 11 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Min bet is {props.stakeInfo.minStake}</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

				{props.msgNumber === 12 && <div id="msgBox" class="message-wrap error to-open_bets">
					<div class="message">
						<h4 id="header">Max bet is {props.stakeInfo.maxStake}</h4>

					</div>
					<a id="close" class="close ui-link" onClick={() => props.changeMsgNumber(0)}>Close</a>
				</div>}

			</header>
		</React.Fragment>
	)
}
