import React,{useState} from 'react'
import TodayResult from "./TodayResult"

export const Rusult = () => {
    const [ChangeResult, setChangeResult] = useState(1)
    
    return (
        <React.Fragment>
         <div className="mian-wrap result">
    <div className="a-search">
        <ul>
            <li id="today"onClick ={()=>{setChangeResult(1)}} className={`${(ChangeResult=== 1 )? "select": "null"}`}><a href="javascript:MobileEventResultHandler.changeTab('today');">Today</a></li>
            <li id="yesterday" onClick ={()=>{setChangeResult(2)}} className={`${(ChangeResult=== 2 )? "select": "null"}`}><a href="javascript:MobileEventResultHandler.changeTab('yesterday');">Yesterday</a></li>
        </ul>
        <div className="custom-select">
            <select id="sportsSelect">
        <option value={4}>CRICKET</option>
        <option value={1}>SOCCER</option>
      </select>
        </div>
    </div>
   {ChangeResult === 2 && <div className="result-wrap" id="eventResultData">
       
        {/* <dl id="eventResultTemplate" style={{display: 'flex'}}>
            <dt>
        <p id="eventDate">2021-12-23 17:30</p>
        <strong id="eventName">Delhi Capitals SRL T20 v Kolkata Knight Riders SRL T20</strong>
      </dt>
            <dd>
                <p id="resultTitle1">Home</p>
                <strong id="resultItem1">164/8</strong>
            </dd>
            <dd>
                <p id="resultTitle2">Away</p>
                <strong id="resultItem2">154/5</strong>
            </dd>
        </dl>
   */}

    </div>
 }  { ChangeResult ===1 && <TodayResult/>}
</div>
        </React.Fragment>
    )
}
