import React, { useState, useEffect } from 'react'
import Transparent from '../images/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment';
require('moment-timezone');



export default function TennisMatches(props) {
  const [match, setMatch] = useState([]);

  // vvvv

  useEffect(() => {

    axios.get(
      `https://millionbet247.com/api/client/clienttennis`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);


      })


      .catch(error => console.log('error'));
  }, []);



  return (
    <div>

      {match.map(function (item, id) {
        //   console.log('rendering');

        var showColor = false;
        var Livetv = false
        if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
          item.runner1BackRate1 = '--';
        }
        if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
          item.runner1LayRate1 = '--';
        }
        if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
          item.runner2BackRate1 = '--';
        }
        if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
          item.runner2LayRate1 = '--';
        }
        if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
          item.tieBackRate1 = '--';
        }
        if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
          item.tieLayRate1 = '--';
        }


        if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
          showColor = true;

        }
        if (moment().diff(moment(item.marketStartTime), 'second') >= -30) {
          Livetv = true;
        }

        var matchTime;
        var today = moment();
        var tommorow = moment().add(1, 'day');

        if (moment(item.marketStartTime).isSame(today, 'day')) {
          matchTime = moment(item.marketStartTime).format('LT');
        }
        else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
          matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
        }
        else {
          matchTime = item.marketStartTime;
        }



        return (
          <>

            <dl key={id} className={`game-list-col-rate  ${!showColor ? "disable" : ""}`} style={{ display: 'flex' }} >
              <dt id="eventInfo" style={{ cursor: 'pointer', width: 'calc(51.4% - -126px)' }}>
                {moment() < moment(item.marketStartTime) && <img class="icon-no_play" src={Transparent} />}
                {moment() >= moment(item.marketStartTime) && <img id="playIcon" className="icon-in_play" src={Transparent} />}


                <Link to={`/exchange/member/fullmarket/2/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }} >{item.eventName}</Link>
                {moment() >= moment(item.marketStartTime) && <span id="dateTimeInfo" className="game-list-time">
                  <span className="in_play">In-Play</span></span>}
                {moment() < moment(item.marketStartTime) && <span class="game-list-time  ">{matchTime}</span>}
                {moment() >= moment(item.marketStartTime) && <span className="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}


              </dt>

              <dd id="matched" className="col-matched" style={{ display: 'block' }}>PTE0</dd>
              <dd id="selectTemp" class="col-visit">

                <a id="back1_0" class="btn-back " >{item.runner1BackRate1}</a>
                <a id="lay1_0" class="btn-lay " >{item.runner1LayRate1}</a>
              </dd>
              <dd class="col-home">
                <a id="back2_0" class="btn-back ">{item.runner2BackRate1}</a>
                <a id="lay2_0" class="btn-lay " >{item.runner2LayRate1}</a>
              </dd>
              <dd className="col-draw" style={{ display: 'none' }}>
                <div className="suspend" style={{ display: 'none' }}>
                  <span>Suspend</span>
                </div>
                <a id="btnBack" className="btn-back" side="Back">--</a>
                <a className="btn-lay" side="Lay">--</a>
              </dd>
              <dd className="col-info">
                <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
              </dd>
            </dl>

          </>
        )
      })}
    </div>

  )
}


