import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie/es6'
import Loading from "../images/loading40.gif"


var cookies = new Cookies();
export default function ActivityLog(props) {
   const [activity, setActivity] = useState([])
   // https://betplace247.com/api/agent/myactivitylog

   useEffect(() => {
      var ssid = cookies.get('sid')
      if (!ssid) return;
      axios.post('https://betplace247.com/api/agent/myactivitylog', {
         sid: ssid

      }).then(result => {
         setActivity(result.data)
      })
   })

   return (
      <React.Fragment>
         <div class="col-left">

            <div class="sub_path">

               <div class="path">

                  <ul>
                     <li class="path-last"><a href="/#">My Account</a></li>
                  </ul>
               </div>

               <ul id="path-pop" class="path-pop">
               </ul>

               <ul class="menu-list">
                  <li><a id="sub_menu_detail" href="/exchange/member/myprofile">My Profile</a></li>
                  <li><a id="sub_menu_summary" href="/exchange/member/balance" >Balance Overview</a></li>
                  <li><a id="sub_menu_accountCashStatement_log" href="/AccountStatement">Account Statement
                  </a></li>
                  <li><a id="sub_menu_my_bets" href="/exchange/member/mybets">My Bets</a></li>
                  <li><a id="sub_menu_activity_log" href="/exchange/member/activitylog" class="select">Activity Log</a></li>
                  {/* <li><a id="sub_menu_activity_log"  onClick={props.handlepassmodel} >Change password</a></li>		 */}
               </ul>
            </div>
         </div>

         <div class="col-center report" >

            <div id="loading" class="loading-wrap" style={{ display: ' none', marginTop: ' 100px' }}>
               <ul class="loading">
                  <li><img src={Loading} alt="load" /></li>
                  <li>Loading...</li>
               </ul>
            </div>

            <div id="message" class="message-wrap success">
               <a href="/#" class="btn-close">Close</a>
               <p></p>
            </div>

            <h2>Activity Log
            </h2>
            <table style={{ display: 'none' }} >
               <tbody>
                  <tr >
                     <td class="align-L" id="loginDate"></td>
                     <td class="align-L" ><span class="green"></span></td>
                     <td ></td>
                     <td ></td>
                     <td ></td>
                  </tr>
               </tbody>
            </table>

            <table class="table01">
               <tbody>
                  <tr>
                     <th class="align-L">Login Date &amp; Time
                     </th>
                     <th class="align-L">Login Status
                     </th>
                     <th >IP Address
                     </th>
                     <th  >ISP
                     </th>
                     <th>City/State/Country
                     </th>
                     <th>User Agent Type
                     </th>

                  </tr>
               </tbody>
               <tbody id="content">
                  {/* <tr>
                     <td class="no-data" colspan="5">
                        <p>No Data Found</p>
                     </td>
                  </tr> */}

                  <tr id="tempTr">
                     <td class="align-L" id="loginDate">2023-02-04 15:40:34</td>
                     <td class="align-L green" id="loginMessage">Login Success</td>
                     <td id="ipAddress">3.109.193.244</td>
                     <td id="isp">Amazon Data Services India</td>
                     <td id="location">kochi, kerala, IN</td>
                     <td id="userAgentType">Browser</td>

                  </tr>
               </tbody>
            </table>


            <div>
               <ul style={{ display: "none" }}>
                  <li><a href="/#">Prev</a></li>
                  <li><a href="/#">Next</a></li>
                  <li><a href="/#">.</a></li>
                  <li id="more"><a href="/#">...</a></li>
               </ul>
               <ul id="pageNumberContent" class="pages" style={{ display: "none" }}>
                  <li id="prev"><a href="/#" class="disable" style={{ pointerEvents: "none;" }}>Prev</a></li>
                  <li><a href="/#" class="select" style={{ pointerEvents: "none;" }}>1</a></li>
                  <li><a href="/#">2</a></li>
                  <li><a href="/#">3</a></li>
                  <li><a href="/#">4</a></li>
                  <li><a href="/#">5</a></li>
                  <li><a href="/#">6</a></li>
                  <li><a href="/#">7</a></li>
                  <li id="next"><a href="/#">Next</a></li>
               </ul>
            </div>
         </div>


      </React.Fragment>
   )
}
