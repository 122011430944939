import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link} from "react-router-dom";
import moment from 'moment'
require('moment-timezone');

export default function Tommorrow(props) {

    const [cricket, setcricket] = useState([]);
    const [Soccer, setSoccer] = useState([]);
    const [Tennis, setTennis] = useState([]);
    const [fancy, setFancy] = useState([]);
    const [refresh, setrefresh] = useState(true);
    const [cricketUpdate, setcricketUpdate] = useState(false);

    var MKPIN = "MKPIN",MKPINSC="MKPINSC",MKPINTN="MKPINTN";


    useEffect(() => {
        axios.get(
          `https://millionbet247.com/api/client/clientcricket`)
          
          .then(result => {

            result.data.map(item=>{
              item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
              return item;
              }) 
            result.data.sort(function(a,b){
             
             return moment(a.marketStartTime) - moment(b.marketStartTime);
            });
            var obj = [];

            result.data.map((item)=>{
                         
            var tommorow = moment().add(1, 'day');
               

            if(moment(item.marketStartTime).isSame(tommorow, 'day')){
               obj.push(item);
            }
            
             });


            
            setcricket(obj); 
            })
          .catch(error => console.log(error));
            
          axios.get(
            `https://millionbet247.com/api/client/clientfancy`)
            
            .then(result => {
              
              setFancy(result.data); 
              
              })
              
            
            .catch(error => console.log(error));
    
          axios.get(
            `https://millionbet247.com/api/client/clienttennis`)
            
            .then(result => {
              result.data.map(item=>{
                item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                return item;
                }) 
             result.data.sort(function(a,b){
               
               return moment(a.marketStartTime) - moment(b.marketStartTime);
              }); 
              var obj = [];

            result.data.map((item)=>{
              var tommorow = moment().add(1, 'day');
               

            if(moment(item.marketStartTime).isSame(tommorow, 'day')){
               obj.push(item);
            }
            
            
             });


            setTennis(obj); 
              })
            .catch(error => console.log(error));
    
          axios.get(
            `https://millionbet247.com/api/client/clientsoccer`)
            .then(result => {
              result.data.map(item=>{
                item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                return item;
                }) 
              result.data.sort(function(a,b){
               return moment(a.marketStartTime) - moment(b.marketStartTime);
              }); 
              var obj = [];

            result.data.map((item)=>{
              var tommorow = moment().add(1, 'day');
               

              if(moment(item.marketStartTime).isSame(tommorow, 'day')){
                 obj.push(item);
              }
              
            
             });


              setSoccer(obj);
              setrefresh(false); 
              })
            .catch(error => console.log(error));
    
           }, [])
           useEffect(() => {
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
        
            if(props.isLoggedIn==true)
            {
        
            for (var i = 0; i < cricket.length; i++) {
              
              if (!document.getElementById(MKPIN + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(cricket[i].eventId)) {
                var mkpin = document.getElementById(MKPIN + i);
                mkpin.className = "pin-on"
              }
              else {
                var mkpin = document.getElementById(MKPIN + i);
                mkpin.className = "pin-off";
              }
            }
          }
          else
          {
            for (var i = 0; i < cricket.length; i++) {
              
              if (!document.getElementById(MKPIN + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(cricket[i].eventId)) {
                var mkpin = document.getElementById(MKPIN + i);
                mkpin.className = "pin-off"
              }
              else {
                var mkpin = document.getElementById(MKPIN + i);
                mkpin.className = "pin-off";
              }
            }
          }
        
          }, [cricket, cricketUpdate]);
        
          useEffect(() => {
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
        
            if(props.isLoggedIn==true)
            {
        
            for (var i = 0; i < Soccer.length; i++) {
              
              if (!document.getElementById(MKPINSC + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(Soccer[i].eventId)) {
                var mkpin = document.getElementById(MKPINSC + i);
                mkpin.className = "pin-on"
              }
              else {
                var mkpin = document.getElementById(MKPINSC + i);
                mkpin.className = "pin-off";
              }
            }
          }
          else
          {
            for (var i = 0; i < Soccer.length; i++) {
              
              if (!document.getElementById(MKPINSC + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(Soccer[i].eventId)) {
                var mkpin = document.getElementById(MKPINSC + i);
                mkpin.className = "pin-off"
              }
              else {
                var mkpin = document.getElementById(MKPINSC + i);
                mkpin.className = "pin-off";
              }
            }
          }
        
          }, [Soccer, cricketUpdate]);
        
          useEffect(() => {
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
        
            if(props.isLoggedIn==true)
            {
        
            for (var i = 0; i < Tennis.length; i++) {
              
              if (!document.getElementById(MKPINTN + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(Tennis[i].eventId)) {
                var mkpin = document.getElementById(MKPINTN + i);
                mkpin.className = "pin-on"
              }
              else {
                var mkpin = document.getElementById(MKPINTN + i);
                mkpin.className = "pin-off";
              }
            }
          }
          else{
            for (var i = 0; i < Tennis.length; i++) {
              
              if (!document.getElementById(MKPINTN + i)) {
                setcricketUpdate(!cricketUpdate);
                return;
              }
              if (list.includes(Tennis[i].eventId)) {
                var mkpin = document.getElementById(MKPINTN + i);
                mkpin.className = "pin-off"
              }
              else {
                var mkpin = document.getElementById(MKPINTN + i);
                mkpin.className = "pin-off";
              }
            }
          }
        
          }, [Tennis, cricketUpdate]);
        
        
        
          const Multimarketpin = (index, event_id) => {
            if (props.isLoggedIn === false) {
              props.checkShowLogin(true)
             }else{
            var list = JSON.parse(localStorage.getItem("multi") || "[]");
            let ctr = 0;
            for (let i = 0; i < list.length; i++) {
              if (list[i] == event_id) {
                let j = i + 1;
                ctr = 1;
                for (; i < list.length; j++, i++) {
                  list[i] = list[j];
                }
                list.splice([i - 1]);
              }
            }
            if (ctr == 0) {
              list.push(event_id);
            }
            localStorage.setItem("multi", JSON.stringify(list));
            setcricketUpdate(!cricketUpdate);
        
           // console.log('2', list);
            //  localStorage.clear();
          }
        }
        

    return (
    
    <React.Fragment>

    {refresh === true &&
	     <div class="loading-overlay"  id="loading">
	     <div class="loading-wrap" style={{display:" flex"}}>
		    <div class="loading">
		   	<div></div>
		  	<div></div>
		    </div>
		     <p>Loading...</p>
	     </div>
     </div>}        

  {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0) && refresh === false && <div class="wrap-highlight_list" id="inPlayData">
    
  {cricket.length > 0 &&  <div id="inPlayEventType_4" style={{display:'block'}}>
   	<h3>Cricket</h3>
	   <ul class="highlight-list" id="inPlayList_4">
    {cricket.map(function(item,id){
      
      var showtommorow = false;
      
      var isFancy = false;
      var showFancy = false;
      

      if(moment().diff(moment(item.marketStartTime),'minutes') >= -30){
        showFancy = true;
      }

      if(fancy.includes(item.eventId)){
        isFancy = true;
        
      };
      var tommorow = moment().add(1, 'day');
               

      if(moment(item.marketStartTime).isSame(tommorow, 'day')){
        showtommorow = true;
      }
      
     
      
  
      return(
        <React.Fragment key = {id}>
          
        { 
        showtommorow
        &&
        
        <li id="inPlayEvent_30062394" key={id} class="inplay-off">
		<Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`} id="info" onClick={()=>{props.changeState(true);}}>
			<dl>
				<dt>
				<span id="streamingIcon" class="game-live" style={{display:'flex'}}>game-live</span>
				<span id="lowLiquidityTag" class="game-low_liq" style={{display:'none'}}>Low Liquidity</span>
                {isFancy &&	<span id="fancyBetIcon" className={`game-fancy ${showFancy ? "in-play" : "no-play"}`} style={{display:"flex"}}><pre>in-play</pre>Fancy</span>}
                {isFancy &&
				<span id="bookMakerIcon" className={`game-bookmaker ${showFancy ? "in-play" : "no-play"}`}  style={{display:'flex'}}><pre>in-play</pre>BookMaker</span>}
			
				<span id="inPlayStatus_30062394" class="time">Tomorrow</span>
			
				</dt>
              <dd id="eventName">{item.eventName}</dd>
			</dl>
		</Link>
    <a id={MKPIN + id} className={`pin-off`} onClick={() => Multimarketpin(id, item.eventId)}></a>
    </li>
    }
    </React.Fragment>
    )})} 
    </ul>
  </div>}


  {Soccer.length > 0 && <div id="inPlayEventType_1" style={{display:'block'}}>
	<h3>Soccer</h3>
	<ul class="highlight-list" id="inPlayList_1">
        
    {Soccer.map(function(item,id){
     var showtommorow = false;
      
    
     var showFancy = false;
     

     if(moment().diff(moment(item.marketStartTime),'minutes') >= -30){
       showFancy = true;
     }

    
     var tommorow = moment().add(1, 'day');
              

     if(moment(item.marketStartTime).isSame(tommorow, 'day')){
       showtommorow = true;
     }
     
      return(
         <React.Fragment key = {id}>
        { showtommorow &&

        <li id="inPlayEvent_-10065371" key={id} class="inplay-off">
	
        <Link to={`/exchange/member/fullmarket/1/${item.eventId}/${item.marketId}`} id="info" onClick={()=>{props.changeState(true);}}>
			<dl>
				<dt>
				<span id="streamingIcon" class="game-live" style={{display:'flex'}}>game-live</span>
			
		
				<span id="inPlayStatus_-10065371" class="time">Tomorrow</span>
			
				</dt>
				<dd id="eventName">{item.eventName}</dd>
			</dl>
		</Link>
    <a id={MKPINSC + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
	</li>
    }
            
    </React.Fragment>
 )})} 
    
   </ul>
 </div>}


{Tennis.length > 0 && <div id="inPlayEventType_2" style={{display:'block'}}>
	<h3>Tennis</h3>
	<ul class="highlight-list" id="inPlayList_2">
        

    {Tennis.map(function(item,id){
     // console.log('rendering');
     var showtommorow = false;
      
    
     var showFancy = false;
     

     if(moment().diff(moment(item.marketStartTime),'minutes') >= -30){
       showFancy = true;
     }

    
     var tommorow = moment().add(1, 'day');
              

     if(moment(item.marketStartTime).isSame(tommorow, 'day')){
       showtommorow = true;
     }
      return(
         <React.Fragment key = {id}>
         { showtommorow &&
        <li id="inPlayEvent_-10065496" key={id} class="inplay-off">
		<Link to={`/exchange/member/fullmarket/2/${item.eventId}/${item.marketId}`} id="info" onClick={()=>{props.changeState(true);}}>
			<dl>
				<dt>
				<span id="streamingIcon" class="game-live" style={{display:'none'}}>game-live</span>
				
			
				<span id="inPlayStatus_-10065496" class="time">Tomorrow</span>
			
				</dt>
				<dd id="eventName">{item.eventName}</dd>
			</dl>
		</Link>
    <a id={MKPINTN + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
	</li>
    }
    </React.Fragment>
)})} 
      
    </ul>
</div>}

</div>}

{(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0)  && refresh === false &&
        <div id="noMultiMarkets" className="no-data">
        <h3>There are currently no matches tommorow.</h3>
        <p>Please check upcoming matches.</p>
       </div>
      }
        </React.Fragment>
    )
}
