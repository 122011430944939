import React, { useEffect, useState } from "react";
import axios from "axios";


export default function News(props) {
  const [Announcement, setAnnouncement] = useState("");


  //console.log(props.utoken);

  useEffect(() => {
    axios
      .post("https://millionbet247.com/api/client/clientnews", {
        id: props.userid,
        token: props.utoken,
      })
      .then((result) => {
        if (result.status === 210) {

          setAnnouncement(result.data[0].message);
        }
      })
      .catch((e) => {
        //setIsError(true);
      });
  }, []);

  //console.log(props.userid,'dsfg');
  return (
    <div>
      {props.isLoggedIn === true && (
        <div class="marquee-box">
          <h4>News</h4>
          <div class="marquee">
            <div class="js-marquee-wrapper">
              <div
                class="js-marquee"
                style={{ marginRight: "0px", float: "left" }}
              >
                <a>
                  {" "}
                  {/* <span>2 Jan 2022</span> */}
                  {Announcement}
                </a>
                {/* <a  ><span>15 Sep 2020</span>lc247 IS NOW POWERED BY BETFAIR .</a>*/}{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
