import React from 'react'

const TodayResult = () => {
    return (
        <React.Fragment>
            <div className="result-wrap" id="eventResultData">
    {/* <dl id="eventResultTemplate" style={{display: 'flex'}}>
        <dt>
      <p id="eventDate">2021-12-27 12:30</p>
      <strong id="eventName">Denmark SRL v Finland SRL</strong>
    </dt>
        <dd>
            <p id="resultTitle1">HT</p>
            <strong id="resultItem1">0 - 0</strong>
        </dd>
        <dd>
            <p id="resultTitle2">FT</p>
            <strong id="resultItem2">1 - 0</strong>
        </dd>
    </dl>
   */}
   
</div>
        </React.Fragment>
    )
}

export default TodayResult
