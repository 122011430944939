import React from 'react'
import lc247Id from '../images/banner.jpeg'
const Logoutbanner = () => {
    return (
        <React.Fragment>
            <div id="promoteWrap" className="promo-banner-wrap active">
                <div className="promo-banner">
                    <ul id="slides" className="slides">
                        <li className="banner" style={{ display: 'list-item' }}>
                            <a onclick="window.open('https://skyonlineid.com/skyexchange','_blank');" className="ui-link">
                                <img src={lc247Id} alt="" /></a></li>
                    </ul>
                </div>
                <div className="promo-banner-loading" />
            </div>

        </React.Fragment>
    )
}

export default Logoutbanner
