import React from 'react'
import Transparent from '../images/transparent.gif'
import Loading from '../images/loading40.gif'

export default function Multimarketdesk() {
    return (
        <div>
           <style type="text/css">

</style>
<div>
  <div id="multiMarketsWrap">
    <h2 id="header">Multi Markets</h2>
    <div  class="game-wrap" style={{display:'none'}}>
      <h3>
        <a    class="delete-pin"  style={{cursor: "pointer"}} title="Remove from Multi Markets">.</a>
      
        <a    id="eventHeader"  class="multi_name" style={{cursor: "pointer"}}>RR SRL T20 v Delhi SRL T20
          
          <span id="marketName" class="multi-event_type">Match Odds</span>
          <img class="icon-irun"  src={Transparent} alt="icon-irun"/>
        </a>
        <span id="lowLiquidityTag" class="game-low_liq" style={{display: "none"}}>Low Liquidity</span>
      </h3>
      <div id="multiMarketBetsWrap" class="bets-wrap">
        <span id="selectionCounts" class="refer_only"> selections</span>
        <div id="multiMarketLoading" class="loading-wrap multi-load" style={{display: "none"}}>
          <ul class="loading">
            <li><img src={Loading} alt="loading40"/></li>
            <li>Loading...</li>
          </ul>
        </div>
        <table id="{{market.bfId  | removeSpace}}" class="bets">
          <tbody>
            <tr class="bet-all">
              <td></td>
              <td id="backPercent" class="refer-bet" colspan="2"></td>
              <td><a   id="backAll" class="back-all"><img src={Transparent} alt="backAll"/><span>Back all</span></a></td>
              <td><a   id="layAll" class="lay-all"><img src={Transparent} alt="layAll"/><span>Lay all</span></a></td>
              <td id="layPercent" class="refer-book" colspan="2"></td>
            </tr>
            <tr   style={{display: "table-row"}}>
              <th class="predict">
                <p><a  ><img  class="icon-predict" src={Transparent} alt="icon-predict"/></a>Player A</p>
                <span id="exposure" class="win"></span>
                <span id="exposureAfter" class="" style={{display: "none"}}></span>
                
              </th>
              <td id="suspend"  style={{display: "none"}} colspan="6" class="suspend"><span>Suspend</span></td>
              <td id="closed"  style={{display: "none"}} colspan="6" class="suspend"><span>Closed</span></td>
              <td id="nonRunner" colspan="6" class="suspend" style={{display: "none"}}><span>Non Runner</span></td>

              <td id="runner{{$index}}back3" style={{display: "none"}}  class="back-3 back2">
              <a   >
               <span ></span>
               </a>
               </td>
               <td id="runner{{$index}}back2" style={{display: "none"}} class="back-2 back2">
               <a   >
               <span ></span>
               </a>
               </td>
               <td id="runner{{$index}}back1" style={{display: "none"}} class="back-1 back1">
               <a   >
               <span ></span>
               </a>
               </td>
               <td id="runner{{$index}}lay1" style={{display: "none"}} class="lay-1 lay1">
               <a   >
               <span ></span>
               </a>
               </td>
               <td id="runner{{$index}}lay2" style={{display: "none"}} class="lay-2 lay2">
               <a   >
               <span ></span>
               </a>
               </td>
               <td id="runner{{$index}}lay3" style={{display: "none"}} class="lay-3 lay2">
               <a   >
               <span ></span>
               </a>
               </td>
               <td colspan="6" class="suspended">
               <div ></div>
               </td>
            </tr>
            <tr id="fullSelection_10226350" style={{display: "table-row"}}>
               <th>
               <p><a  ><img class="icon-predict" src={Transparent} alt="icon-predict"/>Player B</a><br/><span ></span></p>
               <span class="matchWinner" style={{display:'none'}}>WINNER</span>
                  <span id="withoutBet" class="win" style={{display: "none"}}></span>
                  <span id="lossWithoutBet" class="win" style={{display: "none"}}></span>
                  <span id="withBet" class="win" style={{display: "none"}}></span>
                  <span id="lossWithBet" class="win" style={{display: "none"}}></span>
                  <span id="zeroProfit" class="win" style={{display: "none"}}></span>
                  <span id="zeroLiability" class="win" style={{display: "none"}}></span>
                  <span id="zeroProfitWithBet" class="win" style={{display: "none"}}></span>
                  <span id="zeroLiabilityWithBet" class="win" style={{display: "none"}}></span>
               </th>
               <td colspan="6" class="suspended">
               <div></div>
               </td>
               </tr>
          
          </tbody>
        </table>
      </div>
    </div>
    <p id="noMultiMarkets">There are currently no followed multi markets.</p>
    
  </div>
</div> 
        </div>
    )
}
