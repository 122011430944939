import React, { useState, useEffect } from 'react'
import Transparent from '../images/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
require('moment-timezone');



export default function CricketMatches(props) {
  const [match, setMatch] = useState([]);
  const [fancy, setFancy] = useState([]);
  // vvvv

  useEffect(() => {

    axios.get(
      `https://millionbet247.com/api/client/clientcricket`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);

      })


      .catch(error =>
        console.log('error')
      )
      ;

    axios.get(
      `https://millionbet247.com/api/client/clientfancy`)

      .then(result => {

        setFancy(result.data);

      })


      .catch(error => console.log('error'));
  }, []);



  return (
    <div>

      {match.map(function (item, id) {

        var showColor = false;
        var showFancy = false;
        var isFancy = false;

        if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
          item.runner1BackRate1 = '--';
        }
        if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
          item.runner1LayRate1 = '--';
        }
        if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
          item.runner2BackRate1 = '--';
        }
        if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
          item.runner2LayRate1 = '--';
        }
        if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
          item.tieBackRate1 = '--';
        }
        if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
          item.tieLayRate1 = '--';
        }

        if (fancy.includes(item.eventId)) {
          isFancy = true;

        };

        if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
          showColor = true;

        }
        if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
          showFancy = true;
        }


        var matchTime;
        var today = moment();
        var tommorow = moment().add(1, 'day');

        if (moment(item.marketStartTime).isSame(today, 'day')) {
          matchTime = moment(item.marketStartTime).format('LT');
        }
        else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
          matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
        }
        else {
          matchTime = item.marketStartTime;
        }


        return (
          <>
            {/* <dl key = {id} className={`game-list-col  ${!showColor ? "disable" : ""}`} style={{cursor:'pointer'}}>
           
         <dt>
              {isFancy &&
               <span className="game-fancy in-play" id="fancyBetIcon" style={{cursor: 'pointer', display: 'inline-flex'}}>Fancy</span>

                } 

                  {moment() >= moment(item.marketStartTime)  &&  
                    <img id="playIcon" class="icon-in_play" src={Transparent} alt="playIcon" />}
                 {moment() < moment(item.marketStartTime) &&  <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}
                 
                   
                   <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`}   onClick={()=>{props.changeState(true);}}>{item.eventName}</Link>
                   
                 {moment() >= moment(item.marketStartTime)  &&  
                   <span id="dateTimeInfo" class="game-list-time"><span class="in_play">In-Play</span></span>}
                 {moment() < moment(item.marketStartTime) &&  <span  class="game-list-time">{matchTime}</span>}
                   
                </dt>
                <dd id="selectTemp" class="col-visit">
                   
                   <a  id="back1_0" class="btn-back"    >{item.runner1BackRate1}</a>
                   <a  id="lay1_0" class="btn-lay"   >{item.runner1LayRate1}</a>
                </dd>
                <dd class="col-draw">
                  <a id="btnBack"  class="btn-back"   >{item.tieBackRate1}</a>
                  <a id="btnLay"  class="btn-lay"   >{item.tieLayRate1}</a>
                </dd>
                <dd class="col-home">
                <a    id="btnBack" class="btn-back" side="Back" selectionid="215817" runner="Atalanta" marketname="Match Odds" inplay="0" markettype="MATCH_ODDS">{item.runner2BackRate1}</a>
                   
                   <a    id="btnLay" class="btn-lay" side="Lay" selectionid="215817" runner="Atalanta" marketname="Match Odds" inplay="0" markettype="MATCH_ODDS">{item.runner2LayRate1}</a> 
                   
                </dd>
                <dd class="col-info">
                <a    class="add-pin" style={{cursor:' pointer'}} title="Add to Multi Markets" >.</a>
                </dd>
         
          </dl> 
       */}
            <dl key={id} className={`game-list-col-rate  ${!showColor ? "disable" : ""}`} style={{ display: 'flex' }} >
              <dt id="eventInfo" style={{ cursor: 'pointer', width: 'calc(51.4% - -37px)' }}>
                {moment() >= moment(item.marketStartTime) && <img id="playIcon" className="icon-in_play" src={Transparent} />}
                {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}

                <Link to={`/exchange/member/fullmarket/4/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }} >{item.eventName}</Link>
                {moment() >= moment(item.marketStartTime) && <span id="dateTimeInfo" className="game-list-time">
                  <span className="in_play">In-Play</span></span>}
                {moment() < moment(item.marketStartTime) && <span class="game-list-time  ">{matchTime}</span>}

                {moment() >= moment(item.marketStartTime) && <span className="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}

                {/* {isFancy && <span className="game-fancy" id="fancyBetIcon" style={{ cursor: 'pointer' }}>Fancy</span>} */}
                {isFancy && <span class="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>}
                {moment() >= moment(item.marketStartTime) && <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex', marginLeft: '3px' }}>BookMaker</span>}
              </dt>

              <dd id="matched" className="col-matched" style={{ display: 'block' }}>PTE0</dd>
              <dd id="selectTemp" className="col-visit">
                <div className="suspend" style={{ display: 'none' }} id="suspend_31969996_49948605_0_00">
                  <span>Suspend</span>
                </div>
                <a id="btnBack" className="btn-back" side="Back" selectionid={49948605} selectionkey="49948605_0_00" runner="O Jaya Prakash" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>1.02</a> <a id="btnLay" className="btn-lay" side="Lay"
                  selectionid={49948605} selectionkey="49948605_0_00" runner="O Jaya Prakash" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>55</a>
              </dd>
              <dd id="selectTemp" className="col-visit">
                <div className="suspend" style={{ display: 'none' }} id="suspend_31969996_49948605_0_00">
                  <span>Suspend</span>
                </div>
                <a id="btnBack" className="btn-back" side="Back" selectionid={49948605} selectionkey="49948605_0_00" runner="O Jaya Prakash" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>1.02</a> <a id="btnLay" className="btn-lay" side="Lay"
                  selectionid={49948605} selectionkey="49948605_0_00" runner="O Jaya Prakash" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>55</a>
              </dd>
              <dd className="col-draw" style={{ display: 'none' }}>
                <div className="suspend" style={{ display: 'none' }}>
                  <span>Suspend</span>
                </div>
                <a id="btnBack" className="btn-back" side="Back">--</a> <a id="btnLay" className="btn-lay" side="Lay">--</a>
              </dd>
              <dd className="col-home">
                <div className="suspend" style={{ display: 'none' }} id="suspend_31969996_9635344_0_00">
                  <span>Suspend</span>
                </div>
                <a id="btnBack" className="btn-back" side="Back" selectionid={9635344} selectionkey="9635344_0_00" runner="P Aggarwal" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>1.02</a> <a id="btnLay" className="btn-lay" side="Lay"
                  selectionid={9635344} selectionkey="9635344_0_00" runner="P Aggarwal" marketname="Match Odds" inplay={1} markettype="MATCH_ODDS" handicap={0}>55</a>
              </dd>
              <dd className="col-info">
                <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets">Pin</a>
              </dd>
            </dl>
          </>
        )
      })}
    </div >

  )
}


