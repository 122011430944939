import React from 'react'

export default function SearchBar(props) {
    return (
        <React.Fragment>
            <div id="searchWrap" class="overlay" style={{display:'block'}}>
	<div id="searchPop" class="search-pop">
		<form>
			<a id="searchBack" class="search-back ui-link" onClick={props.closeSearchbar}  >Back</a>
			<input id="searchInput" class="search-input" data-role="none" type="text" autocapitalize="off" autocomplete="off" autocorrect="off" placeholder="Search Events"/>
			<button id="searchClear" class="search-clear ui-btn ui-shadow ui-corner-all" type="reset">Reset</button>
			<button id="searchBtn" class="search-collapse ui-btn ui-shadow ui-corner-all">Search</button>
		</form>
		<div id="searchResult" class="suggestion-wrap" style={{display:'none'}}>
			<dl id="noMatching" class="no-matching" style={{display:'none'}}>
				<dt></dt>
				<dd>No events found matching ...</dd>
			</dl>
			<ul id="searchList" style={{display:'none'}}>
				<li id="resultTemplate" style={{display:'none'}}>
					<a class="ui-link"><span id="marketTime" class="time"></span></a>
				</li>
			</ul>
		</div>
	</div>
</div>
        </React.Fragment>
    )
}
