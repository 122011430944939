import React from 'react'
import TermsnconDocs from './TermsnconDocs'

export default function FooterGtc(props) {
    return (
        <React.Fragment>
            <div id="footer-gtc" class="overlay" style={{display:'flex'}}>
	<div class="frame-wrap">
		
		<TermsnconDocs handlepolicy={props.handlepolicy}/>
		
	</div>
</div>
        </React.Fragment>
    )
}
