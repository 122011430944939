import React, { useEffect, useState } from 'react'
//import './style.css'
import axios from 'axios';
import Loading from '../images/loading40.gif'
import Transparent from '../images/transparent.gif'

export default function AccountStatement(props) {
   const [Agentid, setAgentid] = useState('');
   const [Coin, setCoin] = useState('');
   const [FixLimit, setFixLimit] = useState('');
   const [CurrentTime, setCurrentTime] = useState('')
   const [accountStatement, setaccountStatement] = useState([])

   useEffect(() => {

      axios.post('https://millionbet247.com/api/client/clientaccountstatement', {
         id: props.userid,
         token: props.utoken,

      })
         .then(result => {


            setaccountStatement(result.data)

            //console.log(result);
            //setAgentid(result.data[0].agentId);
            //setCoin(result.data[0].coin);
            //setFixLimit(result.data[0].fixLimit);
            //setCurrentTime(result.data[0].timeCurr);



         }

         ).catch(e => {
            //setIsError(true);
         });

   }, [])

   return (
      <React.Fragment>


         <div class="col-left">

            <div class="sub_path">

               <div class="path">
                  <ul>
                     <li class="path-last"><a href="/#">My Account</a></li>
                  </ul>
               </div>
               <ul id="path-pop" class="path-pop">
               </ul>

               <ul class="menu-list">
                  <li><a id="sub_menu_detail" href="/exchange/member/myprofile">My Profile</a></li>
                  <li><a id="sub_menu_summary" href="/exchange/member/balance" >Balance Overview</a></li>
                  <li><a id="sub_menu_accountCashStatement_log" href="/exchange/member/AccountStatement" class="select">Account Statement
                  </a></li>
                  <li><a id="sub_menu_my_bets" href="/exchange/member/mybets">My Bets</a></li>
                  <li><a id="sub_menu_activity_log" href="/exchange/member/activitylog">Activity Log</a></li>
                  {/* <li><a id="sub_menu_activity_log"  onClick={props.handlepassmodel} >Change password</a></li>		 */}
               </ul>
            </div>
         </div>

         <div class="col-center report" >



            <div id="loading" class="loading-wrap" style={{ display: 'none' }}>
               <ul class="loading">
                  <li><img src={Loading} alt="loading" /></li>
                  <li>Loading...</li>
               </ul>
            </div>


            <div id="message" class="message-wrap success">
               <a href="/#" class="btn-close">Close</a>
               <p></p>
            </div>

            <h2>Account Statement</h2>

            <div class="filters" style={{ display: 'none' }}>
               <div class="datePickers">
                  <ul class="input-list">
                     <li><label>Type :</label></li>

                     <li>

                        <select>

                           <option value="0" selected="">All</option>
                           <option value="1">Show D|W Point</option>
                           <option value="2">Show Market P|L</option>
                           <option value="3">Cash</option>
                           <option value="4">Credit</option>
                           <option value="5">Cash &amp; P|L</option>
                        </select>

                     </li>
                     <li>
                        <input id="startDate" class="cal-input" type="text" style={{ width: '150px' }} placeholder="YYYY-MM-DD" />
                        to
                        <input id="endDate" class="cal-input" type="text" style={{ width: '150px' }} placeholder="YYYY-MM-DD" />
                     </li>
                     <li><a id="getPL" href="/#" class="btn-send" >Search</a></li>
                  </ul>


               </div>
            </div>

            <table id="table_log" class="table01">
               <tbody><tr>
                  <th width="15%" class="align-L">Date/Time</th>
                  <th width="18%">Deposit</th>
                  <th width="18%">Withdraw</th>
                  <th width="18%">Balance</th>
                  <th width="16%">Remark</th>
                  <th width="">From/To</th>
               </tr>

               </tbody><tbody id="content">
                  {accountStatement.map(function (item, index) {

                     var obj1;
                     var obj2;
                     if (item.coin >= 0) {
                        obj1 = parseFloat(item.coin).toFixed(2);
                        obj2 = '-';
                     }
                     if (item.coin < 0) {
                        obj1 = '-';
                        obj2 = parseFloat(item.coin).toFixed(2);
                     }
                     var obj3 = parseFloat(item.creditLimit).toFixed(2);

                     return (
                        <tr id="tempTr" key={index}>
                           <td id="createDate" class="align-L">{item.currTime}</td>
                           <td id="deposit"> {obj1}</td>
                           <td id="withdraw">{obj2}</td>
                           <td id="balance"> {obj3}</td>
                           <td id="remark"></td>
                           <td>
                              <spen id="from">Master Agent</spen>
                              <img class="fromto" src={Transparent} />
                              <spen id="to">{item.agentId}</spen>
                           </td>
                        </tr>
                     )
                  })}
               </tbody>
            </table>

            <table id="table_log" style={{ display: "none" }}>
               <tbody><tr>
                  <th style={{ width: '15%' }} class="align-L">Date/Time</th>

                  <th style={{ width: '12%' }} >Deposit</th>

                  <th style={{ width: '12%' }} >Withdraw</th>

                  <th style={{ width: '12%' }} >Balance</th>

                  <th style={{ width: '49%' }} >Remark</th>

               </tr>

               </tbody>
               <tbody id="content">

                  <tr>
                     <td class="no-data" colspan="5">
                        <p>No Data</p>
                     </td>
                  </tr>

                  <tr style={{ display: 'none' }}>
                     <td id="createDate" class="align-L"></td>
                     <td id="withdraw"><span class="(accountdata.cr<0)?'red':'green'"></span></td>
                     <td id="deposit"><span class="(accountdata.dr<0)?'red':'green'"></span></td>
                     <td id="balance"><span class="(accountdata.balance<0)?'red':'green'"></span></td>
                     <td id="remark"></td>

                  </tr>

               </tbody>
            </table>
            <div>
               <ul style={{ display: "none" }}>
                  <li id="prev"><a href="javascript:void(0);">Prev</a></li>
                  <li id="next"><a href="javascript:void(0);">Next</a></li>
                  <li id="pageNumber"><a href="javascript:void(0);"></a></li>
                  <li id="more"><a href="javascript:void(0);">...</a></li>
                  <input type="text" id="goToPageNumber" onkeyup="return PageHandler.validatePageNumber(this)" maxlength="6" size="4" />
                  <li><a id="goPageBtn">GO</a></li>
               </ul>

               <ul id="pageNumberContent" class="pages"><li id="prev"><a href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Prev</a></li><li id="pageNumber"><a href="javascript:void(0);" class="select" style={{ pointerEvents: "none" }}>1</a></li><li id="next"><a href="javascript:void(0);" class="disable" style={{ pointerEvents: "none" }}>Next</a></li></ul>
            </div>
         </div>
      </React.Fragment>
   )
}
