import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'
import { trim } from 'jquery';

export const AnnounceNews = (props) => {

    const [Announcement, setAnnouncement] = useState([])

    useEffect(() => {
     
        axios.post('https://millionbet247.com/api/client/clientnews',{
           id:props.userid,
           token:props.utoken,
          
         })
         .then(result => {
			// if(result.status === 210){
			 var arr = [];
				      //  console.log(result.data);
                result.data.map((item)=>{
                  arr.push(item)
                })
			//	console.log(arr);
        setAnnouncement(arr);
			  //  }
		   
		  }
        ).catch(e => {
          //setIsError(true);
        });
  
     }, [])



    return (

        
        <React.Fragment>

            <div id="announcementPopUp" className="overlay">
    <div className="news-wrap marquee-pop">
        {/* news-wrap */} 
    
        {/* <header class="announce-header"><h1>Announcement</h1><a class="close" href="vueRiskManagement.html">Close</a></header>  */}

        <div className="side-head">
            <h3 className="a-depth">Announcement</h3>
            <a className="close ui-link"onClick={()=>{props.handleOpen(false)}} href="javascript:$j('#announcementPopUp').css('display', 'none');">Close</a>
        </div>
       
        <div className="announce-wrap" id="announcementContent">
        {Announcement.map(function(item,id){
           
           var Date = moment(item.date).format('ll');
             const myArray = Date.split(" ", 3);

            
                var FinalDate =myArray[1].replace(/,\s*$/, "");
               
            return(
            <div id="announcementTemplate" className="article-wrap">
                <dl className="article-date">
                    <dt id="date_0">{FinalDate}</dt>
                    <dd id="month_0">{myArray[0]}</dd>
                    <dd id="year_0">{myArray[2]}</dd>
                </dl>
                <p id="textByLanguageType_0">{item.message}</p>
            </div>
                  )})} 
  
   
    
        </div>
        <div>
            <ul style={{display: 'none'}}>
                <li id="prev">
                    <a  className="ui-link">Prev</a></li>
                <li id="next">
                    <a  className="ui-link">Next</a></li>
                <li id="pageNumber">
                    <a  className="ui-link" /></li>
                <li id="more">
                    <a  className="ui-link">...</a></li>
            </ul>
            <ul id="pageNumberContent" className="pages">
                <li id="prev">
                    <a  className="ui-link disable" style={{pointerEvents: 'none'}}>Prev</a></li>
                <li id="pageNumber">
                    <a  className="ui-link select" style={{pointerEvents: 'none',backgroundColor:'#222222',color:'#67f0c2'}}>1</a></li>
                <li id="next">
                    <a  className="ui-link disable" style={{pointerEvents: 'none'}}>Next</a></li>
            </ul>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
