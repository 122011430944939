import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import Highlights from './Highlights'
import Header from './Header'
import Loginmobile from './Loginmobile'
import Carousel from './Carousel'
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify'
import { suid } from 'rand-token';
import axios from 'axios';
import Account from './Account'
import { Newfullmarket } from './Newfullmarket'
import ANnouncement from './ANnouncement'
import Hls from './Hls'
import TVStremaing from './TVStremaing'
import Multimarket from "./Multimarket"
import InPlay from "./MobileInplay"
import { HorseRacingmatches } from './HorseRacingmatches'
import { Fairhorsefullmarket } from './Fairhorsefullmarket'
import FancyRules from './FancyRules'
import FooterPolicy from './policylink/FooterPolicy'
import FooterGtc from './policylink/FooterGtc'
import FooterRnr from './policylink/FooterRnr'
import Footerkyc from './policylink/FooterKyc'
import FooterResGaming from './policylink/FooterResGaming'
import FooterAboutus from './policylink/FooterAboutus'
import FooterSepolicy from './policylink/FooterSepolicy'
import FooterUpolicy from './policylink/FooterUpolicy'
import Home from './Home'
import { CasinoList } from './CasinoList'
import Logoutbanner from './Logoutbanner'








const cookies = new Cookies();

window.flag = 0;


var cc;
var tt;
toast.configure()

export default function Combine(props) {


  var eventId = null;
  var marketId = null;
  var eventT = 3;
  if (props.match.params.eventT === '4' || props.match.params.eventT === '1' || props.match.params.eventT === '2') {
    eventId = props.match.params.eventId;
    marketId = props.match.params.marketId;
    eventT = 0;
  }
  if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'sports') {
    eventT = 1;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'inplay') {
    eventT = 2;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'home') {
    eventT = 3;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'multimarket') {
    eventT = 4;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'myaccount') {
    eventT = 5;
  }

  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'HorseRacingmatches') {
    eventT = 9;
  }
  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'Fairhorsefullmarket') {
    eventT = 10;
  }

  else if (props.match.params.eventType === 'exchange' && props.match.params.subType === 'member' && props.match.params.belowType === 'CasinoList') {
    eventT = 11;
  }

  const [SHowLogin, setSHowLogin] = useState(false)
  const [showFullMarket, setFullMarket] = useState(false);
  const [tabMenu, setTabMenu] = useState(eventT);
  const [countCricket, setCountCricket] = useState(0);
  const [countSoccer, setCountSoccer] = useState(0);
  const [countTennis, setCountTennis] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [isError, setIsError] = useState(false);
  const [userid, setId] = useState("");
  const [password, setPassword] = useState("");
  const [captchaSuccess, setCaptcha] = useState(false);
  const [refreshhh, setrefreshhh] = useState(false);
  const [balance, setBalance] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [userIDname, setuserIDname] = useState(false)
  const [passPass, setpassPass] = useState(false)
  const [newOpen, setnewOpen] = useState(false);
  const [expoSu, setexpoSu] = useState(false)
  const [refreshMsg, setrefreshMsg] = useState('Loading...')
  const [balan, setbalan] = useState(false)
  const [accOpen, setaccOpen] = useState(false)
  const [Tvopen, setTvopen] = useState(true)
  const [openLeftside, setopenLeftside] = useState(false)
  const [openRightside, setopenRightside] = useState(false)
  const [FancyRulespopup, setFancyRulespopup] = useState(false)
  const [betSucess1, setbetSucess1] = useState(false)
  const [msgNumber, setMsgNumber] = useState(0);
  const [betInfo, setbetInfo] = useState({});
  const [stakeInfo, setstakeInfo] = useState({});
  const [isShown, setisShown] = useState(false);
  const [liveStream, setliveStream] = useState(null);
  const [PrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [TermnCo, setTermnCo] = useState(false);
  const [RnR, setRnR] = useState(false);
  const [kyc, setkyc] = useState(false);
  const [Resg, setResg] = useState(false);
  const [Abtus, setAbtus] = useState(false);
  const [SEP, setSEP] = useState(false);
  const [UApolcy, setUApolcy] = useState(false);




  const changeLiveStreaming = (val) => {
    setliveStream(val);
    setTvopen(true);

  }


  const NewsClick = () => {
    setnewOpen(!newOpen)
  }


  const handlepolicy = (val1, val2) => {

    if (val1 === 1) {
      setPrivacyPolicy(val2);

    }
    if (val1 === 2) {
      setTermnCo(val2)

    }
    if (val1 === 3) {
      setRnR(val2)
    }
    if (val1 === 4) {
      setkyc(val2)
    }
    if (val1 === 5) {
      setResg(val2)
    }
    if (val1 === 6) {
      setAbtus(val2)
    }
    if (val1 === 7) {
      setSEP(val2)
    }
    if (val1 === 8) {
      setUApolcy(val2)
    }

  }

  const handlerightside = () => {
    setopenRightside(true)
  }

  const handlerightsideclose = () => {
    setopenRightside(false)
  }

  const handleleftside = () => {
    setopenLeftside(true)
  }

  const handleleftsideclose = () => {
    setopenLeftside(false)
  }


  const changeMsgNumber = (val) => {
    setMsgNumber(val);
  }

  const changeBetInfo = (obj) => {
    var obj2 = obj;
    setbetInfo(obj2);
  }

  const changeStakeInfo = (obj) => {
    var obj2 = obj;
    setstakeInfo(obj2);
  }


  const handlePass = (value) => {
    setpassPass(value)
  }

  const handlebal = (value) => {
    setbalan(value)
  }

  const handleExpo = (value) => {
    setexpoSu(value)
  }


  useEffect(() => {
    setTabMenu(eventT);

  }, [eventT]);


  useEffect(() => {
    if (cookies.get('token') == null) {
      setLoggedIn(false);
      return;
    }
    else {
      var utoken = cookies.get('token');
      tt = utoken;
      var user = cookies.get('id');
      setId(user);
      axios.post('https://millionbet247.com/api/client/clientrefresh', {
        id: user,
        token: utoken
      })
        .then(result => {
          if (result.status === 210) {
            // console.log('refresh successful');
            setLoggedIn(true);
            setBalance(result.data[0].val - Math.abs(result.data[1].val));

            if (result.data[1].val) {
              setExposure(Math.abs(result.data[1].val));
            }
            else {
              setExposure(0);

            }

          }

          if (result.status === 220) {
            //	console.log('refresh unsucessful');
            setLoggedIn(false);

          }
        }

        ).catch(e => {
          setIsError(true);
        });
    }
  }, []);




  const changeState = (obj) => {
    var obj1 = true;
    obj1 = obj;
    setFullMarket(obj1);
    changeTab(0);
  };

  const changeExposure = (obj) => {
    setExposure(obj);
  }

  const changeBalance = (obj) => {
    setBalance(obj);
  }


  const changeTab = (val) => {
    setTabMenu(val);
  }
  const checkShowLogin = (bool) => {
    setSHowLogin(bool);
  }



  const handleLogout = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('token', { path: '/' });
    setTabMenu(3);
    setTimeout(() => { window.location.reload(); }, 200);

  }


  const handleRefreshhh = (t = 1000, msg = 'Loading...', flag = 0) => {

    if (flag === 1) {
      setrefreshhh(true);
      setrefreshMsg(msg);
    }
    else if (flag === 2) {
      setrefreshhh(false);

    }
    else {
      setrefreshhh(true);
      setrefreshMsg(msg)
      setTimeout(() => { setrefreshhh(false); }, t);
    }
  }


  const ChangeTv = () => {
    setTvopen(true)
    TvControl();
  }


  const ChangeSetTv = () => {
    setTvopen(false)
  }

  const hnadlefancyrules = () => {
    setFancyRulespopup(true)
  }
  const closefancyrules = () => {
    setFancyRulespopup(false)
  }
  const TvControl = () => {
    setisShown(true);
    setTimeout(() => {
      setisShown(false)
    }, 2700);
  }

  return (
    <React.Fragment>


      {SHowLogin && <Loginmobile checkShowLogin={checkShowLogin} handlepolicy={handlepolicy} />}
      {!SHowLogin && <Header liveStream={liveStream} stakeInfo={stakeInfo} betInfo={betInfo} msgNumber={msgNumber} changeMsgNumber={changeMsgNumber} refreshMsg={refreshMsg} ChangeTv={ChangeTv} changeExposure={changeExposure} changeBalance={changeBalance} refreshhh={refreshhh} tabMenu={tabMenu} userid={userid} utoken={tt} exposure={exposure} balance={balance} openLeftside={openLeftside} openRightside={openRightside} handlerightside={handlerightside} handlerightsideclose={handlerightsideclose} handleleftsideclose={handleleftsideclose} handleleftside={handleleftside} isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} checkShowLogin={checkShowLogin} />}
      {!SHowLogin && isLoggedIn === true && <ANnouncement NewsClick={NewsClick} userid={userid} utoken={tt} />}
      {/* {!SHowLogin && Tvopen === true && tabMenu ===0 && isLoggedIn === true && liveStream && liveStream != '' &&<TVStremaing liveStream = {liveStream} isShown={isShown} TvControl={TvControl} ChangeSetTv={ChangeSetTv} Tvopen={Tvopen}/>} */}
      {!SHowLogin && (tabMenu === 1 || tabMenu === 3) && isLoggedIn === true && <Carousel />}
      {!SHowLogin && (tabMenu === 1 || tabMenu === 3) && isLoggedIn === false && <Logoutbanner />}
      {!SHowLogin && (tabMenu === 3) && <Home isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} />}

      {!SHowLogin && (tabMenu === 6 || tabMenu === 7 || tabMenu === 9 || tabMenu === 10 || tabMenu === 11 || tabMenu === 12 || tabMenu === 13 || tabMenu === 14) && <Carousel />}
      {!SHowLogin && (tabMenu === 1 || (tabMenu === 5 && !isLoggedIn)) && <Highlights isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} handlepolicy={handlepolicy} countTennis={countTennis} countSoccer={countSoccer} countCricket={countCricket} showFullMarket={showFullMarket} changeState={changeState} />}


      {!SHowLogin && (tabMenu === 11) && <CasinoList isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} />}

      {!SHowLogin && tabMenu === 0 && <Newfullmarket setliveStream={setliveStream} liveStream={liveStream} isShown={isShown} TvControl={TvControl} ChangeSetTv={ChangeSetTv} Tvopen={Tvopen} changeLiveStreaming={changeLiveStreaming} isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeExposure={changeExposure} changeBalance={changeBalance} changeStakeInfo={changeStakeInfo} changeBetInfo={changeBetInfo} changeMsgNumber={changeMsgNumber} userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} hnadlefancyrules={hnadlefancyrules} />}
      {!SHowLogin && tabMenu === 2 && <InPlay isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeState={changeState} userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} />}
      {!SHowLogin && tabMenu === 4 && <Multimarket isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeState={changeState} userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} />}
      {!SHowLogin && <Footer isLoggedIn={isLoggedIn} changeTab={changeTab} checkShowLogin={checkShowLogin} tabMenu={tabMenu} />}
      {!SHowLogin && tabMenu === 5 && isLoggedIn === true && <Account userid={userid} handleLogout={handleLogout} />}
      {!SHowLogin && tabMenu === 9 && <HorseRacingmatches />}
      {!SHowLogin && tabMenu === 10 && <Fairhorsefullmarket handleRefreshhh={handleRefreshhh} />}
      {FancyRulespopup === true && <FancyRules closefancyrules={closefancyrules} />}
      {PrivacyPolicy === true && <FooterPolicy handlepolicy={handlepolicy} />}
      {TermnCo === true && <FooterGtc handlepolicy={handlepolicy} />}
      {RnR === true && <FooterRnr handlepolicy={handlepolicy} />}
      {kyc === true && <Footerkyc handlepolicy={handlepolicy} />}
      {Resg === true && <FooterResGaming handlepolicy={handlepolicy} />}
      {Abtus === true && <FooterAboutus handlepolicy={handlepolicy} />}
      {SEP === true && <FooterSepolicy handlepolicy={handlepolicy} />}
      {UApolcy === true && <FooterUpolicy handlepolicy={handlepolicy} />}

      {/* <div id="fc_frame" class="fc-widget-normal fc-mobile-view" style={{ bottom: '15px', right: '15px' }}><iframe id="fc_widget" name="fc_widget" title="Chat" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div> */}

    </React.Fragment>
  )
}
